import TvSubIcon from "../assets/tv-subscription.svg";
import SellPerfectMoneyIcon from "../assets/sell-perfect-money.svg";
import SellBitcoinIcon from "../assets/sell-bitcoin.svg";
import ElectricityIcon from "../assets/electricity-sm.svg";
import AirtimePurchaseIcon from "../assets/airtime-data-sub-svg.svg";
import FundDepositIcon from "../assets/deposit-icon.svg";
import GiftcardIcon from "../assets/giftcard-icon.svg";

export const statusColorMap = {
  Successful: "#58C283",
  Declined: "#D60000",
  Pending: "#FFCC00",
  success: "#58C283",
  declined: "#D60000",
  pending: "#FFCC00",
};

export const iconCategory = {
  "Cable TV Subscription": `${TvSubIcon}`,
  "Instant Transfer": `${ElectricityIcon}`,
  "Airtime Purchase": `${AirtimePurchaseIcon}`,
  "Data Purchase": `${AirtimePurchaseIcon}`,
  "Electricity Bill Payment": `${ElectricityIcon}`,
  "Fund Deposit": `${FundDepositIcon}`,
  "Withdrawal Made": `${FundDepositIcon}`,
  "Sell Bitcoin": `${SellBitcoinIcon}`,
  "Sell Giftcards": `${GiftcardIcon}`,
  "Giftcard Purchase": `${GiftcardIcon}`,
  "Giftcard Sell": `${GiftcardIcon}`,
  "Sell Perfect Money": `${SellPerfectMoneyIcon}`,
};
