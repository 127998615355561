import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import ProductSvg from "../../assets/product-bg-svg.svg";
import GiftCardSvg from "../../assets/giftcard-svg.svg";
// import CryptoSvg from "../../assets/crypto-svg.svg";
// import PerfectMoneySvg from "../../assets/perfect-money-svg.svg";
import RenewSvg from "../../assets/renew-svg.svg";
// import AirtimeSvg from "../../assets/airtime-to-cash-svg.svg";
// import DoMoreSvg from "../../assets/do-more-svg.svg";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div``;

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ProductCmpWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  flex: 0 0 32%;
  max-width: 32%;
  width: 100%;
  height: 156px;
  border-radius: 24px;
  background: ${(props) =>
    `url(${ProductSvg}),
    ${props.bgColor} no-repeat`};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  @media only screen and (max-width: 1263px) {
    flex: 0 0 48%;
    max-width: 48%;
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

const ProductContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => `${props.textColor}`};
`;

const ProductTitle = styled.h2`
  font-weight: 700;
  font-size: 15px;
`;

const ProductDesc = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 57px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconImg = styled.img``;

const Product = () => {
  const navigate = useNavigate();
  const ProductCmp = ({ bgColor, textColor, title, desc, iconImg, link }) => {
    return (
      <>
        <ProductCmpWrapper
          bgColor={bgColor}
          onClick={() => {
            navigate(link);
          }}
        >
          <ProductWrapper>
            <ProductContentWrapper textColor={textColor}>
              <ProductTitle>{title}</ProductTitle>
              <ProductDesc>{desc}</ProductDesc>
            </ProductContentWrapper>
            <IconWrapper>
              <IconImg src={iconImg} alt={title} />
            </IconWrapper>
          </ProductWrapper>
        </ProductCmpWrapper>
      </>
    );
  };
  const productsArr = [
    {
      title: "Giftcards",
      desc: "Buy and Sell giftcard at mouth watering rates",
      bgColor: "#2164D5",
      textColor: "#fff",
      iconImg: `${GiftCardSvg}`,
      link: "/products/giftcard",
    },
    // {
    //   title: "Crypto",
    //   desc: "Buy, Sell, Send, Receive and store crypto with ease",
    //   bgColor: "#F6C13D",
    //   textColor: "#323232",
    //   iconImg: `${CryptoSvg}`,
    //   link: "/dashboard",
    // },
    // {
    //   title: "Perfect Money",
    //   desc: "Buy and Sell giftcard at mouth watering rates",
    //   bgColor: "#DE5348",
    //   textColor: "#fff",
    //   iconImg: `${PerfectMoneySvg}`,
    //   link: "/dashboard",
    // },
    {
      title: "Renew",
      desc: "Pay bills easily with Jessie",
      bgColor: "#3B30A6",
      textColor: "#fff",
      iconImg: `${RenewSvg}`,
      link: "/products/renew",
    },
    // {
    //   title: "Airtime To Cash",
    //   desc: "Convert Excess Airtime to Cash the easiest way",
    //   bgColor: "#303030",
    //   textColor: "#fff",
    //   iconImg: `${AirtimeSvg}`,
    //   link: "/dashboard",
    // },
    // {
    //   title: "Do More with Jessie",
    //   desc: "Enjoy Endless Possibilities with Jessiepay",
    //   bgColor: "#2164D5",
    //   textColor: "#fff",
    //   iconImg: `${DoMoreSvg}`,
    //   link: "/dashboard",
    // },
  ];
  return (
    <>
      <DashboardLayout pageTitle="Products">
        <Wrapper>
          <ItemContainer>
            {productsArr.map((v, i) => {
              return (
                <>
                  <ProductCmp
                    key={i}
                    bgColor={v.bgColor}
                    iconImg={v.iconImg}
                    textColor={v.textColor}
                    title={v.title}
                    desc={v.desc}
                    link={v.link}
                  />
                </>
              );
            })}
          </ItemContainer>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Product;
