import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BalanceSvg from "../../assets/balance-bg.svg";
import appTheme from "../../constants/theme";
import { EyeOutline, EyeOffOutline } from "@styled-icons/evaicons-outline";
import SolidButton from "../ButtonCmp/Button";
import { PlusCircleFill } from "@styled-icons/bootstrap";
import { MoneyBillAlt } from "@styled-icons/fa-regular";
import { getUserBalance } from "../../redux/actions/dashboardAction";
import { connect } from "react-redux";
import MoneyFormatter from "../MoneyFormatterCmp/MoneyFormatter";

const Wrapper = styled.div`
  max-width: 350px;
  width: 100%;
  height: 238px;
  background: url(${BalanceSvg});
  background-color: rgb(0, 104, 185);
  background-size: cover;
  border-radius: 24px;
  padding: 1.5rem;

  @media only screen and (min-width: 1500px) {
    max-width: unset;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AvailableBalText = styled.h2`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: ${appTheme.WEIGHT.small2};
`;

const BalanceDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Balance = styled.h2`
  font-size: 2rem;
  color: ${appTheme.COLORS.white};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
`;

const HideIcon = styled(EyeOffOutline)`
  cursor: pointer;
`;

const ShowIcon = styled(EyeOutline)`
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const BalanceCmp = ({
  dashboardBalanceLoading,
  dashboardBalance,
  dashboardBalanceError,
  setGetUserBalance,
}) => {
  const [showText, setShowText] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function callGetUserBalance() {
      await setGetUserBalance();
    }

    callGetUserBalance();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Wrapper>
        <Container>
          <BalanceWrapper>
            <AvailableBalText>Available Balance</AvailableBalText>
            <BalanceDiv>
              <Balance>
                {showText ? (
                  <MoneyFormatter value={dashboardBalance?.data?.balance} />
                ) : (
                  "******"
                )}
              </Balance>
              {showText ? (
                <ShowIcon
                  size={22}
                  color="#fff"
                  onClick={() => {
                    setShowText(!showText);
                  }}
                />
              ) : (
                <HideIcon
                  size={22}
                  color="#fff"
                  onClick={() => {
                    setShowText(!showText);
                  }}
                />
              )}
            </BalanceDiv>
          </BalanceWrapper>
          <ButtonWrapper>
            <SolidButton
              text={
                <>
                  <PlusCircleFill color="#fff" size={14} />
                  &nbsp;&nbsp;&nbsp;Deposit
                </>
              }
              type="submit"
              weighty="500"
              textFontSize="14px"
              onClick={() => {
                navigate("/wallets/deposit/naira");
              }}
              specifyPadding="0.8rem 0.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              maxWidth="125px"
              borderRadius="6px"
              backColor="#1a8fdd"
              backgroundHoverColor="#1a8fdd"
              textHoverColor="#fff"
              margin="0rem auto 0"
            />
            <SolidButton
              text={
                <>
                  <MoneyBillAlt color="#fff" size={18} />
                  &nbsp;&nbsp;&nbsp;Withdraw
                </>
              }
              type="submit"
              weighty="500"
              textFontSize="14px"
              onClick={() => {
                navigate("/wallets/withdrawal/choose");
              }}
              specifyPadding="0.8rem 0.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              maxWidth="125px"
              borderRadius="6px"
              backColor="#1a8fdd"
              backgroundHoverColor="#1a8fdd"
              textHoverColor="#fff"
              margin="0rem auto 0"
            />
          </ButtonWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  dashboardBalanceLoading: state.dashboard.dashboardBalanceLoading,
  dashboardBalance: state.dashboard.dashboardBalance,
  dashboardBalanceError: state.dashboard.dashboardBalanceError,
});

const mapDispatch = (dispatch) => ({
  setGetUserBalance: () => dispatch(getUserBalance()),
});

export default connect(mapState, mapDispatch)(BalanceCmp);
