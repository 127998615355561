import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { getCountryCallingCode } from "react-phone-number-input";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import CustomPhoneInputCmp from "../../../components/InputsCmp/CustomPhoneInputCmp";
import appTheme from "../../../constants/theme";
import { countryCodeSwap } from "../../../constants/countries";
import CardWithIconCmp from "../../../components/CardWithIconCmp/CardWithIconCmp";
import NairaIcon from "../../../assets/naira.svg";
import DstvIcon from "../../../assets/dstv.svg";
import GotvIcon from "../../../assets/gotv.svg";
import StartimesIcon from "../../../assets/startimes.svg";
import ShowmaxIcon from "../../../assets/showmax.svg";
import { connect } from "react-redux";
import { getDataVariationCodes } from "../../../redux/actions/dataActions";
import { useEffect } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useNavigate } from "react-router-dom";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import buyCableSchema from "../../../validators/buyCableValidator";
import { getSmartCardVerificationDetails } from "../../../redux/actions/cableActions";
import { getSettings } from "../../../redux/actions/walletActions";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  max-width: 550px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: 700;
  color: ${appTheme.COLORS.grey1};
  padding-bottom: 0.5rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const CablePurchase = ({
  getVariationCodesLoading,
  getVariationCodesSuccess,
  getVariationCodesError,
  verifySmartCardLoading,
  verifySmartCardError,
  verifySmartCardSuccess,
  setGetDataVariationCodes,
  setGetSettings,
}) => {
  const navigate = useNavigate();
  const [selectedWallet, setSelectedWallet] = useState("naira-wallet");
  const [settingsData, setSettingsData] = useState({});

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetSettings();

      setSettingsData(response.data);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchVariationCodes = (props) => {
    const {
      values: { provider, variationCode, phone },
      setFieldValue,
      validateForm,
    } = useFormikContext();
    const [variationData, setVariationData] = useState();

    async function getVariationCodes() {
      const response = await getDataVariationCodes({
        serviceId: `${provider}`,
      });
      return response;
    }

    useEffect(() => {
      async function fetchCodes() {
        if (provider) {
          // setFieldValue("variationCode", "");
          // setFieldValue("serviceId", "");
          // setFieldValue("planName", "");
          // setFieldValue("amount", "");
          validateForm();
          const response = await getVariationCodes();
          setVariationData(response.data);
        } else {
          return;
        }
      }

      fetchCodes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider, variationCode, phone]);

    return (
      <>
        {variationData && variationData.length > 0 ? (
          <>
            <InputLabel>Select Plan</InputLabel>
            <div style={{ height: "200px", overflowY: "scroll" }}>
              <CardWrapper>
                {variationData.map((v, i) => {
                  return (
                    <CardWithIconCmp
                      message={`${props.error}`}
                      showVariation
                      removeIcon
                      type={v.variation_code}
                      planPrice={v.variation_amount}
                      planName={v.name}
                      selectedItem={variationCode}
                      maxWidth={"100px"}
                      onSelect={() => {
                        setFieldValue("variationCode", v.variation_code);
                        setFieldValue("serviceId", `${provider}`);
                        setFieldValue("planName", v.name);
                        setFieldValue("amount", v.variation_amount);
                      }}
                    />
                  );
                })}
              </CardWrapper>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const FetchSmartCardDetails = (props) => {
    const {
      values: { smartCardNumber, provider, subscriberName },
      setFieldValue,
    } = useFormikContext();

    async function getSmartCardDetails() {
      const response = await getSmartCardVerificationDetails({
        serviceId: provider,
        smartCardNumber: `${smartCardNumber}`,
      });
      return response;
    }

    useEffect(() => {
      async function fetchSmartCard() {
        if (provider && smartCardNumber && `${smartCardNumber}`.length >= 10) {
          setFieldValue("subscriberName", "loading...");
          const response = await getSmartCardDetails();
          setFieldValue("subscriberName", response.data.Customer_Name);
        } else {
          setFieldValue("subscriberName", "");
        }
      }

      fetchSmartCard();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider, smartCardNumber, setFieldValue]);

    return (
      <>
        <InputLabel style={{ marginTop: "-10px", fontWeight: "300" }}>
          {subscriberName}
        </InputLabel>
      </>
    );
  };
  return (
    <>
      <Formik
        validationSchema={buyCableSchema}
        initialValues={{
          serviceId: "",
          amount: "",
          phone: "",
          variationCode: "",
          planName: "",
          provider: "",
          smartCardNumber: "",
          subscriberName: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          const fullPhoneNo = parsePhoneNumber(
            `+${getCountryCallingCode(countryCodeSwap[`Nigeria`])}${
              values.phone
            }`
          ).getNumber("e164");
          navigate("/products/renew/cable/confirm", {
            state: {
              ...values,
              fullPhoneNo,
              charge: settingsData?.cableCharge,
            },
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          setFieldValue,
          touched,
        }) => (
          <>
            <Wrapper2 noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <InputLabel>Select Payment Method</InputLabel>
                  <CardWrapper>
                    <CardWithIconCmp
                      type="naira-wallet"
                      text="Naira Wallet"
                      icon={NairaIcon}
                      selectedItem={selectedWallet}
                      maxWidth={"150px"}
                      onSelect={() => {
                        setSelectedWallet("naira-wallet");
                      }}
                    />
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Select Provider</InputLabel>
                  <CardWrapper>
                    {[
                      {
                        type: "dstv",
                        text: "DSTV",
                        icon: `${DstvIcon}`,
                      },
                      {
                        type: "gotv",
                        text: "GOTV",
                        icon: `${GotvIcon}`,
                      },
                      {
                        type: "startimes",
                        text: "STARTIMES",
                        icon: `${StartimesIcon}`,
                      },
                      {
                        type: "showmax",
                        text: "SHOWMAX",
                        icon: `${ShowmaxIcon}`,
                      },
                    ].map((v, i) => {
                      return (
                        <CardWithIconCmp
                          message={`${
                            errors["provider"] ? errors["provider"] : ""
                          }`}
                          type={v.type}
                          text={v.text}
                          icon={v.icon}
                          selectedItem={values.provider}
                          maxWidth={"110px"}
                          onSelect={() => {
                            setFieldValue("provider", v.type);
                          }}
                          hideText
                        />
                      );
                    })}
                  </CardWrapper>
                </InputWrapper>
                {values.provider !== "showmax" && (
                  <InputWrapper>
                    <InputLabel>Enter Smart Card Number</InputLabel>
                    <CustomInputCmp
                      background="rgba(225, 235, 245, 0.1)"
                      placeholder="Enter smart card number"
                      required={true}
                      name={"smartCardNumber"}
                      onChange={handleChange}
                      values={values}
                      showMessage={false}
                      message={`${
                        errors["smartCardNumber"]
                          ? errors["smartCardNumber"]
                          : ""
                      }`}
                      type="number"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      min={0}
                    />
                  </InputWrapper>
                )}
                <FetchSmartCardDetails />
                <InputWrapper>
                  <InputLabel>Enter Recipient's Phone Number</InputLabel>
                  <CustomPhoneInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    name="phone"
                    placeholder="Enter Recipient's Phone Number"
                    values={values}
                    phoneCode={`+${getCountryCallingCode(
                      countryCodeSwap[`Nigeria`]
                    )}`}
                    flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      countryCodeSwap[`Nigeria`]
                    }.svg`}
                    onChange={handleChange}
                    message={`${errors["phone"] ? errors["phone"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FetchVariationCodes
                    error={`${
                      errors["variationCode"] ? errors["variationCode"] : ""
                    }`}
                    handleChange={handleChange}
                    value={values.provider}
                  />
                </InputWrapper>
              </FormWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={"Pay"}
                  // onClick={() => {
                  //   console.log(errors);
                  // }}
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  // disabled={!isValid ? true : false}
                />
              </ButtonWrapper>
            </Wrapper2>
          </>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => ({
  getVariationCodesLoading: state.data.getVariationCodesLoading,
  getVariationCodesSuccess: state.data.getVariationCodesSuccess,
  getVariationCodesError: state.data.getVariationCodesError,
  verifySmartCardLoading: state.cable.verifySmartCardLoading,
  verifySmartCardError: state.cable.verifySmartCardError,
  verifySmartCardSuccess: state.cable.verifySmartCardSuccess,
  settingsLoading: state.wallet.getSettingsLoading,
  settingsSuccess: state.wallet.getSettingsSuccess,
  settingsError: state.wallet.getSettingsError,
});

const mapDispatch = (dispatch) => ({
  setGetSettings: () => dispatch(getSettings()),
  setGetDataVariationCodes: (data) => dispatch(getDataVariationCodes(data)),
});

export default connect(mapState, mapDispatch)(CablePurchase);
