import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import TwoFaStep1 from "./TwoFaStep1";
import TwoFaStep2 from "./TwoFaStep2";
import TwoFaStep3 from "./TwoFaStep3";
import TwoFaStep31 from "./TwoFaStep3-1";
import TwoFaStep4 from "./TwoFaStep4";

const Wrapper = styled.div`
  max-width: 550px;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 1px 1px 17px 0px rgba(218, 216, 216, 0.33);
  margin: 0 auto;
  padding: 1.5rem 1rem;
`;

const Setup2fa = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [qrCodeLink, setQrCodeLink] = useState("");

  useEffect(() => {
    if (!location.state) {
      navigate("/settings");
    }
  }, [location.state, navigate]);
  return (
    <>
      <DashboardLayout pageTitle="2FA">
        <Wrapper>
          {step === 1 && <TwoFaStep1 setStep={setStep} />}
          {step === 2 && <TwoFaStep2 setStep={setStep} />}
          {step === 3 && (
            <TwoFaStep3 setStep={setStep} setQrCodeLink={setQrCodeLink} />
          )}
          {step === 4 && (
            <TwoFaStep31 setStep={setStep} qrCodeLink={qrCodeLink} />
          )}
          {step === 5 && <TwoFaStep4 setStep={setStep} />}
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Setup2fa;
