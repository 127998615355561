import React, { useEffect } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AddBankFormBusiness from "../../components/FormsCmp/AddBankFormBusiness";
import AddBankFormIndividual from "../../components/FormsCmp/AddBankFormIndividual";
// import SetPinModal from "../../components/Modals/SetPinModal";
// import SuccessModal from "../../components/Modals/SuccessModal";
import appTheme from "../../constants/theme";
import { connect } from "react-redux";
import { getAccountType } from "../../redux/actions/setupActions";

const Wrapper = styled.div`
  border-radius: 24px;
  background-color: ${appTheme.COLORS.white};
`;

const AddBank = ({ loading, error, setGetAccountType, userType }) => {
  useEffect(() => {
    async function callCheckAccountType() {
      await setGetAccountType();
    }

    callCheckAccountType();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <DashboardLayout pageTitle="Add Bank Details">
        <Wrapper>
          {userType === "individual" && <AddBankFormIndividual />}

          {userType === "business" && <AddBankFormBusiness />}
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  loading: state.setup.loading,
  userType: state.setup.userType,
  error: state.setup.error,
});

const mapDispatch = (dispatch) => ({
  setGetAccountType: () => dispatch(getAccountType()),
});

export default connect(mapState, mapDispatch)(AddBank);
