import { ArrowBack, Copy } from "@styled-icons/boxicons-regular";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import { generate2faCode } from "../../../redux/actions/settingActions";

const Wrapper = styled.div``;

const BackIconWrapper = styled.div`
  cursor: pointer;
`;

const Desc = styled.p`
  color: #323232;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 400;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Cancel = styled.h2`
  color: #1a8fdd;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
`;

const Bar = styled.div`
  max-width: 300px;
  width: 100%;
  border-radius: 6px;
  background: rgba(208, 218, 228, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin: 1.5rem auto;
`;

const SecretText = styled.h2`
  color: #878484;
  font-size: 10px;
  font-weight: 500;
`;

const SecretWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Secret = styled.h2`
  color: #1a8fdd;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding-bottom: 1rem;
`;

const Details = styled.p`
  margin: 0;
  color: #323232;
  font-size: 13px;
  font-weight: 600;
  max-width: 233px;
  width: 100%;
`;

const TwoFaStep3 = ({
  setStep,
  setQrCodeLink,
  setGenerate2faCode,
  generate2faCodeError,
  generate2faCodeLoading,
  generate2faCodeSuccess,
}) => {
  const [codeData, setCodeData] = useState();
  useEffect(() => {
    if (!generate2faCodeSuccess) {
      async function callGenerate2faCode() {
        const response = await setGenerate2faCode();
        setCodeData(response?.data);
      }

      callGenerate2faCode();
    } else {
      setCodeData(generate2faCodeSuccess?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Wrapper>
        <BackIconWrapper
          onClick={() => {
            setStep(2);
          }}
        >
          <ArrowBack color="#323232" size={20} />
        </BackIconWrapper>
        <Desc>Copy the secret key below or scan the QR code</Desc>
        <Bar>
          <SecretText>Secret Key</SecretText>
          <SecretWrapper>
            {generate2faCodeLoading ? (
              "Loading..."
            ) : (
              <>
                <Secret>{codeData?.token}</Secret>
                <CopyToClipboard
                  text={codeData?.token}
                  onCopy={() => {
                    toast.success("Copied!", { toastId: "Clipboard" });
                  }}
                >
                  <Copy
                    style={{ cursor: "pointer" }}
                    size={12}
                    color="#016CBA"
                  />
                </CopyToClipboard>
              </>
            )}
          </SecretWrapper>
        </Bar>
        <DetailsWrapper>
          <Details>A. Select “ Enter a provided key”</Details>
          <Details>B. Enter “Jessie” in account name</Details>
          <Details>
            C. Copy and Paste the above secret key in ”Your key”
          </Details>
        </DetailsWrapper>
        {!generate2faCodeLoading && (
          <>
            <ButtonWrapper>
              <SolidButton
                text={"Continue"}
                type="submit"
                weighty="500"
                textFontSize="14px"
                specifyPadding="0.5rem 1rem"
                color="#fff"
                widthWebkit="100%"
                widthMoz="100%"
                widthNormal="100%"
                maxWidth="250px"
                borderRadius="8px"
                backColor="#1a8fdd"
                backgroundHoverColor="#1a8fdd"
                textHoverColor="#fff"
                margin="0rem auto 0"
                onClick={() => {
                  setStep(5);
                }}
              />
            </ButtonWrapper>
            <Cancel
              onClick={() => {
                setStep(4);
                setQrCodeLink(codeData?.url);
              }}
            >
              Scan QR code instead
            </Cancel>
          </>
        )}
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  generate2faCodeLoading: state.setting.generate2faCodeLoading,
  generate2faCodeError: state.setting.generate2faCodeError,
  generate2faCodeSuccess: state.setting.generate2faCodeSuccess,
});

const mapDispatch = (dispatch) => ({
  setGenerate2faCode: () => dispatch(generate2faCode()),
});

export default connect(mapState, mapDispatch)(TwoFaStep3);
