import React, { useEffect } from "react";
import DashboardLayout from "../../../../components/DashboardLayout/DashboardLayout";
import styled from "styled-components";
import { Formik } from "formik";
import SolidButton from "../../../../components/ButtonCmp/Button";
import { Lock } from "@styled-icons/boxicons-regular";
import CustomInputPasswordIconCmp from "../../../../components/InputsCmp/CustomInputPasswordIconCmp";
import resetValidatorSchema from "../../../../validators/resetPasswordValidator";
import { useLocation, useNavigate } from "react-router-dom";
import { createNewPassword } from "../../../../redux/actions/settingActions";
import { connect } from "react-redux";
import SpinnerCmp from "../../../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../../../constants/theme";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  @media only screen and (max-width: 767px) {
    padding: 1rem 0;
  }
`;

const Wrapper = styled.div`
  max-width: 491px;
  width: 100%;
  border-radius: 24px;
  background: #fff;
  box-shadow: 1px 1px 17px 0px rgba(218, 216, 216, 0.33);
  padding: 3rem 1.5rem;

  @media only screen and (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SmallText = styled.p`
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
  max-width: 362px;
  width: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputWrapper = styled.div``;

const ButtonWrapper = styled.div`
  padding-top: 0.8rem;
  text-align: right;
`;

const EnterNewPassword = ({
  setCreateNewPassword,
  getCreateNewPasswordError,
  getCreateNewPasswordLoading,
  getCreateNewPasswordSuccess,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state && !location.state?.token) {
      navigate("/settings");
    }
  }, [location.state, navigate]);
  return (
    <>
      <DashboardLayout backButton={true}>
        <Container>
          <Wrapper>
            <TextWrapper>
              <SmallText>
                It's a good idea to use a strong password that you don't use
                elsewhere
              </SmallText>
            </TextWrapper>
            <Formik
              validationSchema={resetValidatorSchema}
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={async (values, { resetForm }) => {
                const response = await setCreateNewPassword({
                  password: values.password,
                  confirmPassword: values.confirmPassword,
                  createNewPinToken: location.state?.token,
                });

                if (
                  response &&
                  response.status === 200 &&
                  response.message === "Password set successfully"
                ) {
                  toast.success(response.message, {
                    toastId: "Password reset successful",
                  });
                  navigate("/settings");
                }
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <>
                  <form noValidate onSubmit={handleSubmit}>
                    <FormWrapper>
                      <InputWrapper>
                        <CustomInputPasswordIconCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          icon={
                            <>
                              <Lock
                                size={30}
                                color="rgba(107, 114, 128, 0.5)"
                              />
                            </>
                          }
                          name="password"
                          placeholder="New Password"
                          values={values}
                          onChange={handleChange}
                          message={`${
                            errors["password"] ? errors["password"] : ""
                          }`}
                          showMessage={true}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <CustomInputPasswordIconCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          icon={
                            <>
                              <Lock
                                size={30}
                                color="rgba(107, 114, 128, 0.5)"
                              />
                            </>
                          }
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          values={values}
                          onChange={handleChange}
                          message={`${
                            errors["confirmPassword"]
                              ? errors["confirmPassword"]
                              : ""
                          }`}
                          showMessage={true}
                        />
                      </InputWrapper>
                    </FormWrapper>
                    <ButtonWrapper>
                      <SolidButton
                        text={
                          getCreateNewPasswordLoading ? (
                            <>
                              <SpinnerCmp
                                enabled={true}
                                color={appTheme.COLORS.bluePrimary}
                                size={20}
                                secondaryColor={appTheme.COLORS.white}
                              />
                            </>
                          ) : (
                            "Submit"
                          )
                        }
                        type="submit"
                        weighty="500"
                        textFontSize="14px"
                        specifyPadding="0.8rem 1.5rem"
                        color="#fff"
                        widthWebkit="107px"
                        widthMoz="107px"
                        widthNormal="107px"
                        borderRadius="6px"
                        backColor="#1a8fdd"
                        backgroundHoverColor="#1a8fdd"
                        textHoverColor="#fff"
                        margin="0rem auto 0"
                        disabled={getCreateNewPasswordLoading ? true : false}
                      />
                    </ButtonWrapper>
                  </form>
                </>
              )}
            </Formik>
          </Wrapper>
        </Container>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  getCreateNewPasswordLoading: state.setting.createNewPasswordLoading,
  getCreateNewPasswordSuccess: state.setting.createNewPasswordSuccess,
  getCreateNewPasswordError: state.setting.createNewPasswordError,
});

const mapDispatch = (dispatch) => ({
  setCreateNewPassword: (data) => dispatch(createNewPassword(data)),
});

export default connect(mapState, mapDispatch)(EnterNewPassword);
