import {
  BUY_EDUCATIONAL_LOADING,
  BUY_EDUCATIONAL_SUCCESS,
  BUY_EDUCATIONAL_ERROR,
  VERIFY_PROFILE_ID_LOADING,
  VERIFY_PROFILE_ID_SUCCESS,
  VERIFY_PROFILE_ID_ERROR,
} from "./types";

const initialState = {
  buyEducationalLoading: null,
  buyEducationalSuccess: null,
  buyEducationalError: null,
  verifyProfileIdLoading: null,
  verifyProfileIdError: null,
  verifyProfileIdSuccess: null,
};

export default function educationalReducer(state = initialState, action) {
  switch (action.type) {
    case BUY_EDUCATIONAL_LOADING:
      return { ...state, buyEducationalLoading: action.payload };
    case BUY_EDUCATIONAL_SUCCESS:
      return { ...state, buyEducationalSuccess: action.payload };
    case BUY_EDUCATIONAL_ERROR:
      return { ...state, buyEducationalError: action.payload };
    case VERIFY_PROFILE_ID_LOADING:
      return { ...state, verifyProfileIdLoading: action.payload };
    case VERIFY_PROFILE_ID_SUCCESS:
      return { ...state, verifyProfileIdSuccess: action.payload };
    case VERIFY_PROFILE_ID_ERROR:
      return { ...state, verifyProfileIdError: action.payload };
    default:
      return state;
  }
}
