import React from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import MarketCmp from "../../components/MarketCmp/MarketCmp";

const Market = () => {
  return (
    <DashboardLayout pageTitle="Market">
      <MarketCmp />
    </DashboardLayout>
  );
};

export default Market;
