import { ArrowBackCircle } from "@styled-icons/ionicons-outline";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import CustomInputSelectCmp from "../../../components/InputsCmp/CustomInputSelectCmp";
import CustomMultiSelect from "../../../components/InputsCmp/CustomMultiSelect";
import StepperCmp from "../../../components/StepperCmp/StepperCmp";
import SelectGiftcardValueDrawer from "./SelectGiftcardValueDrawer";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const BackWrapper = styled.div`
  padding: 1rem 0 0;
  cursor: pointer;
`;

const MainContentWrapper = styled.div`
  padding: 2rem 0;
`;

const StepWithContentWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  max-width: 500px;
`;

const StepWrapper = styled.div``;

const ContentWrapper = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media only screen and (max-width: 600px) {
    gap: 30px;
  }
`;

const TextInputWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Text = styled.h2`
  font-weight: 700;
  font-size: 14px;
  color: #323232;
  max-width: 200px;
  width: 100%;
`;

const InputWrapper = styled.div`
  /* max-width: 250px; */
  width: 100%;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapperDesktop = styled.div`
  text-align: right;
  max-width: 150px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    text-align: center;
    max-width: 100%;
  }
`;

const SellGiftcardValue = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cardTypeState, setCardTypeState] = useState("");

  useEffect(() => {
    if (!location?.state) {
      navigate("/products/giftcard/sell/international");
    }
  }, [location.state, navigate]);

  return (
    <DashboardLayout pageTitle="Sell Giftcard">
      <Wrapper>
        <StepperCmp
          activeStep={2}
          stepData={[
            { title: "Card" },
            { title: "Country" },
            { title: "Value" },
            { title: "Summary" },
            { title: "Upload cards" },
            { title: "Finish" },
          ]}
        />
        <BackWrapper>
          <ArrowBackCircle
            size={30}
            color="#1A8FDD"
            onClick={() => navigate(-1)}
          />
        </BackWrapper>

        <MainContentWrapper>
          <StepWithContentWrapper>
            <StepWrapper></StepWrapper>

            <Formik
              //   validationSchema={addBankFormBusinessSchema}
              initialValues={{
                cardType: "",
                giftcardValue: null,
              }}
              onSubmit={async (values, { resetForm }) => {}}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                isValid,
                setFieldTouched,
                setFieldValue,
                touched,
              }) => (
                <>
                  <ContentWrapper noValidate onSubmit={handleSubmit}>
                    <TextInputWrapper>
                      <Text>What card type do you want to sell?</Text>
                      <InputWrapper>
                        <CustomInputSelectCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          name="cardType"
                          values={values}
                          onChange={(e) => {
                            handleChange(e);
                            setCardTypeState(e.target.value);
                          }}
                          placeholder="Choose card type"
                          data={location?.state?.giftcardType?.map(
                            (v) => v.type
                          )}
                          message={`${
                            errors["cardType"] ? errors["cardType"] : ""
                          }`}
                          showMessage={false}
                        />
                      </InputWrapper>
                    </TextInputWrapper>

                    <TextInputWrapper>
                      <Text>
                        Select the values of your giftcard and their quantities
                      </Text>
                      <InputWrapper>
                        <CustomMultiSelect
                          isDisabled={cardTypeState ? false : true}
                          isOptionDisabled={() =>
                            values?.giftcardValue &&
                            values?.giftcardValue.length >= 1
                          }
                          name="giftcardValue"
                          placeholder="Select Giftcard Value"
                          isMulti={true}
                          noOptionsMessage={() => null}
                          options={
                            [
                              // { value: "giftcard", label: "giftcard" },
                              // { value: "perfect money", label: "perfect money" },
                              // { value: "renew", label: "renew" },
                              // { value: "crypto", label: "crypto" },
                            ]
                          }
                          message={`${
                            errors["giftcardValue"]
                              ? errors["giftcardValue"]
                              : ""
                          }`}
                          showMessage
                          value={values.giftcardValue}
                          isValid={isValid}
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          touched={touched}
                          onMenuOpen={() => {
                            if (
                              !values.giftcardValue ||
                              !values.giftcardValue?.length
                            ) {
                              setOpenDrawer(true);
                            }
                          }}
                        />
                      </InputWrapper>
                    </TextInputWrapper>
                  </ContentWrapper>
                  <ButtonWrapper>
                    <ButtonWrapperDesktop>
                      <SolidButton
                        text={"Continue"}
                        type="submit"
                        weighty="500"
                        textFontSize="14px"
                        specifyPadding="0.8rem 1.5rem"
                        color="#fff"
                        widthWebkit="100%"
                        widthMoz="100%"
                        widthNormal="100%"
                        borderRadius="8px"
                        backColor="#1a8fdd"
                        backgroundHoverColor="#1a8fdd"
                        textHoverColor="#fff"
                        margin="0rem auto 0"
                        disabled={values?.giftcardValue?.length ? false : true}
                        onClick={() => {
                          navigate("/products/giftcard/sell/summary", {
                            state: {
                              giftcardValue: values?.giftcardValue,
                              country: location?.state?.country,
                              brand: location?.state?.brand,
                              giftcardType: cardTypeState,
                              recipientCurrencyCode:
                                location?.state?.recipientCurrencyCode,
                            },
                          });
                        }}
                      />
                    </ButtonWrapperDesktop>
                  </ButtonWrapper>
                  <SelectGiftcardValueDrawer
                    isOpen={openDrawer}
                    onClose={() => {
                      setOpenDrawer(false);
                    }}
                    data={location?.state}
                    selectedGiftcardType={cardTypeState}
                    setFieldValue={setFieldValue}
                    giftcardValue={values.giftcardValue}
                  />
                </>
              )}
            </Formik>
          </StepWithContentWrapper>
        </MainContentWrapper>
      </Wrapper>
    </DashboardLayout>
  );
};

export default SellGiftcardValue;
