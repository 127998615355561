/* eslint-disable no-useless-escape */
import * as yup from "yup";

const sellGiftcardUploadSchema = yup.object().shape({
  files: yup
    .mixed()
    .required("Please add an image")
    .test("fileEmpty", "Please add an image", (value) => {
      if (value.length) {
        return true;
      }
    })
    .test("fileSize", "File Size is too large", (value) => {
      if (value.length >= 1) {
        return value[0].size <= 1000000;
      }
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value[0]?.type) {
        if (["image/jpg", "image/jpeg", "image/png"].includes(value[0].type)) {
          return true;
        }
      }
    })
    .test(
      "len",
      "Upload minimum of two images",
      (val) => val && val.length >= 2
    ),
});

export default sellGiftcardUploadSchema;
