import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../../components/DashboardLayout/DashboardLayout";
import styled from "styled-components";
import EnterOtpForm from "../EnterOtpForm";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  @media only screen and (max-width: 767px) {
    padding: 1rem 0;
  }
`;

const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  border-radius: 24px;
  background: #fff;
  box-shadow: 1px 1px 17px 0px rgba(218, 216, 216, 0.33);
  padding: 3rem 1.5rem;

  @media only screen and (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SmallText = styled.p`
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
  max-width: 364px;
  width: 100%;
`;

const EnterOtpForPasswordReset = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate("/settings");
    }
  }, [location.state, navigate]);
  return (
    <>
      <DashboardLayout backButton={true}>
        <Container>
          <Wrapper>
            <TextWrapper>
              <SmallText>
                Let’s get you secured. A verification code has been sent to your
                email to reset your password. Please enter code below
              </SmallText>
            </TextWrapper>
            <EnterOtpForm
              title={"Input Pin to confirm"}
              path="/settings/reset-password/new"
            />
          </Wrapper>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default EnterOtpForPasswordReset;
