import {
  BUY_CABLE_LOADING,
  BUY_CABLE_SUCCESS,
  BUY_CABLE_ERROR,
  VERIFY_SMART_CARD_LOADING,
  VERIFY_SMART_CARD_SUCCESS,
  VERIFY_SMART_CARD_ERROR,
} from "./types";

const initialState = {
  buyCableLoading: null,
  buyCableSuccess: null,
  buyCableError: null,
  verifySmartCardLoading: null,
  verifySmartCardError: null,
  verifySmartCardSuccess: null,
};

export default function cableReducer(state = initialState, action) {
  switch (action.type) {
    case BUY_CABLE_LOADING:
      return { ...state, buyCableLoading: action.payload };
    case BUY_CABLE_SUCCESS:
      return { ...state, buyCableSuccess: action.payload };
    case BUY_CABLE_ERROR:
      return { ...state, buyCableError: action.payload };
    case VERIFY_SMART_CARD_LOADING:
      return { ...state, verifySmartCardLoading: action.payload };
    case VERIFY_SMART_CARD_SUCCESS:
      return { ...state, verifySmartCardSuccess: action.payload };
    case VERIFY_SMART_CARD_ERROR:
      return { ...state, verifySmartCardError: action.payload };
    default:
      return state;
  }
}
