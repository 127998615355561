import React from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import PrevImg from "../../assets/prev.svg";
import NextImg from "../../assets/next.svg";
import { GlobalFilter } from "./Filter";

const FilterAndSearchWrapper = styled.div`
  padding: 1rem 0 1.5rem;
`;

const TableWrapper = styled.div`
  background-color: ${appTheme.COLORS.white};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const Container = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  width: ${(props) => (props.width ? props.width : "100%")};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  /* max-width: 80%;
  width: 100%; */
`;

const TableTag = styled.table``;

const TableHead = styled.thead`
  border-bottom: 1px solid #c4c4c4;
  th {
    padding: 1rem 0;
  }
  tr,
  th {
    font-weight: ${appTheme.WEIGHT.bold};
    font-size: 15px;
    color: #323232;
  }
`;

const TableBody = styled.tbody`
  tr:hover {
    box-shadow: 1px 1px 17px rgba(218, 216, 216, 0.33);
  }
  tr td {
    padding: 1rem 0;
  }
`;

const TableR = styled.tr``;

const TableH = styled.th``;

const TableD = styled.td``;

const PaginationWrapper = styled.div`
  padding: 1rem 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

const PrevNextImg = styled.img`
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  pointer-events: ${(props) => props.disabled && "none"};
`;

const PageSpan = styled.span`
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  color: #323232;
  font-weight: ${appTheme.WEIGHT.small};
  font-size: 18px;
  background-color: ${(props) => (props.setBackColor ? "#fff" : "transparent")};
  border-radius: ${(props) => props.setBackColor && "10px"};
`;

const TableCmp = (props) => {
  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };
  const data = React.useMemo(
    () => props.data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = React.useMemo(
    () => props.columns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // const defaultColumn = React.useMemo(
  //   () => ({
  //     // Let's set up our default Filter UI
  //     Filter: DefaultColumnFilter,
  //   }),
  //   []
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    gotoPage,
    previousPage,
    state: { pageIndex, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn,
      initialState: { pageSize: props.defaultPageSize || 2 },
    },
    useFilters,
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );

  const fetchVisiblePages = getVisiblePages(pageIndex + 1, pageOptions.length);
  const fetchFilteredPages = filterPages(fetchVisiblePages, pageOptions.length);
  return (
    <>
      {(props.showSearch || props.showCustomFilter) && (
        <FilterAndSearchWrapper>
          {props.showSearch && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              searchPlaceholderText={props.searchPlaceholderText}
            />
          )}
        </FilterAndSearchWrapper>
      )}
      <TableWrapper>
        <Container
          width={props.containerWidth}
          maxWidth={props.containerMaxWidth}
          height={props.containerHeight}
          maxHeight={props.containerMaxHeight}
        >
          <TableTag {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableR {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableH
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                        },
                      })}
                    >
                      {column.render("Header")}
                      {/* Render the columns filter UI */}
                      {/* <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div> */}
                    </TableH>
                  ))}
                </TableR>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {props.enablePagination
                ? page.map((row) => {
                    prepareRow(row);
                    return (
                      <TableR {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <TableD {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </TableD>
                          );
                        })}
                      </TableR>
                    );
                  })
                : rows.map((row) => {
                    prepareRow(row);
                    return (
                      <TableR {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <TableD {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </TableD>
                          );
                        })}
                      </TableR>
                    );
                  })}
            </TableBody>
          </TableTag>
        </Container>
      </TableWrapper>
      {props.enablePagination && (
        <PaginationWrapper>
          <PrevNextImg
            src={PrevImg}
            alt="Previous"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          {fetchFilteredPages.map((page, index, array) => {
            return (
              <PageSpan
                setBackColor={pageIndex + 1 === page ? true : false}
                onClick={() => {
                  gotoPage(page - 1);
                }}
              >
                {array[index - 1] + 2 < page ? `...${page}` : page}
              </PageSpan>
            );
          })}
          <PrevNextImg
            src={NextImg}
            alt="Next"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default TableCmp;
