import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";
import { VALIDATE_2FA_ERROR, VALIDATE_2FA_LOADING } from "../reducers/types";

export const validateTwoFa = (data, token) => async (dispatch) => {
  dispatch({ type: VALIDATE_2FA_LOADING, payload: true });
  dispatch({ type: VALIDATE_2FA_ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/2fa/validate`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: VALIDATE_2FA_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    return response.data;
  } catch (err) {
    dispatch({ type: VALIDATE_2FA_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.code}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
