/* eslint-disable no-useless-escape */
import * as yup from "yup";

const nairaDepositSchema = yup.object().shape({
  bank: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
      accountNumber: yup.string().required(),
    })
    .nullable()
    .required("Select bank detail"),
  amount: yup
    .string()
    .required("Type an amount")
    .test("Check minimum value", "Deposit minimum of ₦1,000", function (value) {
      if (parseInt(this.parent.amount) < 1000) {
        return false;
      }
      return true;
    })
    .strict(),
});

export default nairaDepositSchema;
