import React, { useState } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BellSvg from "../../assets/bell.svg";
import { ChevronUp } from "@styled-icons/boxicons-regular";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import SettingsIconImg from "../../assets/settings-icon.svg";
import LogoutIconImg from "../../assets/logout-icon.svg";
import { useNavigate } from "react-router-dom";
import { Menu } from "@styled-icons/boxicons-regular";
import SidebarCmp from "../SidebarCmp/SidebarCmp";
import { useWindowSize } from "react-use";
import { ChevronBackCircle } from "@styled-icons/ionicons-outline";
import { connect } from "react-redux";
import { getUserProfilePicture } from "../../redux/actions/settingActions";
import { useEffect } from "react";
import PlaceholderImg from "../../assets/placeholder.svg";

const HeaderWrapper = styled.div`
  padding: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 1020;
  background-color: rgb(245, 246, 248);

  @media only screen and (max-width: 767px) {
    background-color: rgba(245, 246, 248, 0.5);
    backdrop-filter: blur(3px);
  }

  @media only screen and (max-width: 374px) {
    padding: 1.5rem 1rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  margin: 0 auto;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GreetingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Hamburger = styled(Menu)`
  display: none;

  @media only screen and (max-width: 767px) {
    display: unset;
    cursor: pointer;
  }
`;

const BackIcon = styled(ChevronBackCircle)`
  cursor: pointer;
`;

const HelloText = styled.h2`
  margin: 0;
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 1.5rem;

  @media only screen and (max-width: 767px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const WelcomeText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: #c4c4c4;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const OtherSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const BellWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const BellImg = styled(LazyLoadImage)``;

const NotifyShow = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d71920;
  border: 1px solid #f8f9fb;
  position: absolute;
  top: 0;
  right: 0;
`;

const VerticalLine = styled.div`
  border-left: 1px solid #07102f;
  height: 40px;
  opacity: 0.2;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const ProfilePicWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const ProfilePic = styled(LazyLoadImage)`
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const ProfileDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    gap: 10px;
  }
`;

const ProfileName = styled.h2`
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  color: #000;
  opacity: 80%;
  margin: 0;
`;

const DropdownIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(196, 196, 196, 0.2);
  border-radius: 3px;
  width: 21px;
  height: 17px;
  transform: ${(props) => props.visible === true && "rotate(180deg)"};
`;

const DropdownIconImg = styled(ChevronUp)``;

const DropdownMenuWrapper = styled.div`
  width: 156px;
  height: 114px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 1rem;
`;

const DropdownMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.6rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(243, 243, 243, 0.7);
    border-radius: 8px;
  }
`;

const DropdownItemIcon = styled.img``;

const DropdownItemName = styled.h2`
  font-weight: ${appTheme.WEIGHT.small};
  font-size: 16px;
  margin: 0;
  color: #000;
  color: rgba(196, 196, 196, 1);
  opacity: 80%;
`;

const HeaderCmp = ({
  backButton,
  backText,
  desc,
  pageTitle,
  loginData,
  setGetUserProfilePicture,
  getUserProfilePictureError,
  getUserProfilePictureLoading,
  getUserProfilePictureSuccess,
}) => {
  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState();

  const { width } = useWindowSize();

  const [menuToggle, setMenuToggle] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState();

  const DropdownArr = [
    { name: "Settings", path: "/settings", icon: `${SettingsIconImg}` },
    { name: "Log out", path: "/logout", icon: `${LogoutIconImg}` },
  ];

  const handleToggleOnMobile = (val) => {
    setMenuToggle(val);
  };
  const DropdownMenu = () => {
    return (
      <>
        <DropdownMenuWrapper>
          {DropdownArr.map((v, i) => {
            return (
              <DropdownMenuItem
                key={i}
                onClick={() => {
                  navigate(v.path);
                }}
              >
                <DropdownItemIcon src={v.icon} alt={v.name} />
                <DropdownItemName>{v.name}</DropdownItemName>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuWrapper>
      </>
    );
  };

  useEffect(() => {
    if (!getUserProfilePictureSuccess) {
      async function callGetUserProfilePicture() {
        const response = await setGetUserProfilePicture();
        setUserProfile(response);
      }

      callGetUserProfilePicture();
    } else {
      setUserProfile(getUserProfilePictureSuccess);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserProfilePictureSuccess]);
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <TitleSection>
            {backButton === false ||
              (!backButton && (
                <>
                  <Hamburger
                    size={22}
                    color="#323232"
                    onClick={() => {
                      setMenuToggle(!menuToggle);
                    }}
                  />
                  <GreetingWrapper>
                    <HelloText>{pageTitle}</HelloText>
                    {desc && <WelcomeText>{desc}</WelcomeText>}
                  </GreetingWrapper>
                </>
              ))}

            <>
              {backButton && (
                <BackIcon
                  size={24}
                  color="#ADB5BD"
                  onClick={() => navigate(-1)}
                />
              )}
              {backText && <HelloText>{backText}</HelloText>}
            </>
          </TitleSection>
          <OtherSectionWrapper>
            <BellWrapper>
              <BellImg src={BellSvg} alt="Notification" effect="blur" />
              <NotifyShow></NotifyShow>
            </BellWrapper>
            <VerticalLine></VerticalLine>
            <ProfileWrapper>
              <ProfilePicWrapper>
                <ProfilePic
                  src={
                    userProfile?.data?.profilePic
                      ? userProfile?.data?.profilePic?.url
                      : PlaceholderImg
                  }
                  alt="Profile"
                  effect="blur"
                />
              </ProfilePicWrapper>
              <Dropdown
                trigger={["click"]}
                overlay={DropdownMenu}
                animation="slide-up"
                overlayStyle={{ fontFamily: '"Nunito", sans-serif' }}
                onVisibleChange={(visible) => {
                  setDropdownVisible(visible);
                }}
              >
                <ProfileDropdown>
                  <ProfileName>{loginData?.username}</ProfileName>
                  <DropdownIconWrapper visible={dropdownVisible}>
                    <DropdownIconImg src={ChevronUp} color="#000" size={18} />
                  </DropdownIconWrapper>
                </ProfileDropdown>
              </Dropdown>
            </ProfileWrapper>
          </OtherSectionWrapper>
        </HeaderContainer>
      </HeaderWrapper>

      {width < 768 && (
        <SidebarCmp
          menuToggle={menuToggle}
          handleToggleOnMobile={handleToggleOnMobile}
        />
      )}
    </>
  );
};

const mapState = (state) => ({
  loginData: state.auth.data,
  getUserProfilePictureLoading: state.setting.getUserProfilePictureLoading,
  getUserProfilePictureError: state.setting.getUserProfilePictureError,
  getUserProfilePictureSuccess: state.setting.getUserProfilePictureSuccess,
});

const mapDispatch = (dispatch) => ({
  setGetUserProfilePicture: () => dispatch(getUserProfilePicture()),
});

export default connect(mapState, mapDispatch)(HeaderCmp);
