import React, { useEffect } from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getAllAds } from "../../redux/actions/dashboardAction";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";

const Wrapper = styled.div`
  max-width: 315px;
  width: 100%;
  height: 238px;
  background-size: cover;
  cursor: pointer;

  @media only screen and (max-width: 968px) {
    max-width: unset;
  }
`;

const AdImage = styled(LazyLoadImage)`
  background-size: cover;
  width: 100%;
  border-radius: 24px;
`;

const AdSectionCmp = ({
  allAdsLoading,
  allAdsSuccess,
  allAdsError,
  setGetAllAds,
}) => {
  useEffect(() => {
    async function callGetAllAds() {
      await setGetAllAds();
    }

    callGetAllAds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        autoplay={{
          delay: 5000,
        }}
        style={{ width: "315px", margin: "0" }}
        spaceBetween={50}
        loop={true}
      >
        {allAdsSuccess?.data?.results?.map((v, i) => {
          return (
            <SwiperSlide>
              <Wrapper
                onClick={() => {
                  window.open(v.link, "_blank");
                }}
              >
                <AdImage src={v.adPicUrl} alt={v.link} effect="blur" />
              </Wrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

const mapState = (state) => ({
  allAdsLoading: state.dashboard.allAdsLoading,
  allAdsSuccess: state.dashboard.allAdsSuccess,
  allAdsError: state.dashboard.allAdsError,
});

const mapDispatch = (dispatch) => ({
  setGetAllAds: () => dispatch(getAllAds()),
});

export default connect(mapState, mapDispatch)(AdSectionCmp);
