import React from "react";
import { Form } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled(Form.Control)`
  padding: 0.8rem 1.5rem 0.8rem 6rem;
  border: ${(props) => (props.message ? "1px solid red" : "1px solid #ADB5BD")};
  background: ${(props) =>
    props.background ? props.background : "transparent"};
  border-radius: 8px;
  color: #374151;
  font-weight: 400;
  font-size: 15px;
  &:focus {
    box-shadow: none;
    border: 1px solid ${appTheme.COLORS.blueSecondary};
    background: rgba(243, 244, 246, 0.7);
  }
  &::placeholder {
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(107, 114, 128, 0.3);
    font-weight: 400;
    font-size: 14px;
  }

  @media only screen and (max-width: 500px) {
    &::placeholder,
    &::-ms-input-placeholder,
    &:-ms-input-placeholder {
      font-size: 12px;
    }
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: baseline;
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
`;

const IconWrapper = styled.div`
  padding: 0 5px;
  margin: 0;
  border-right: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
`;

const FlagImg = styled(LazyLoadImage)`
  width: 22px;
  height: 15px;
  border: 1px solid #c4c4c4;
`;

const CountryCodeWrapper = styled.div`
  margin: 0;
  margin-left: 8px;
  border-radius: 2px;
  background-color: #f4f7fd;
  font-size: 12px;
  padding: 0px 5px;
  width: 39px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessageWrapper = styled.div`
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 13px;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
`;

function CustomPhoneInputCmp(props) {
  return (
    <>
      <Wrapper>
        <InfoWrapper>
          <IconWrapper>
            <FlagImg src={props.flag} effect="blur" alt="Flag" />
          </IconWrapper>
          <CountryCodeWrapper>{props?.phoneCode}</CountryCodeWrapper>
        </InfoWrapper>
        <Input
          background={props.background}
          required={props.required ? true : false || false}
          type={props.type}
          name={props.name}
          autocomplete="off"
          value={props.values ? props.values[props.name] : props.value}
          placeholder={props.placeholder}
          onChange={props.onChange || null}
          message={props.message || null}
          phoneCode={props.phoneCode || null}
          inputMode={"numeric"}
          pattern="[0-9]*"
          min={0}
        />

        <ErrorMessageWrapper>
          <ErrorMessage>{props.showMessage ? props.message : ""}</ErrorMessage>
        </ErrorMessageWrapper>
      </Wrapper>
    </>
  );
}

export default CustomPhoneInputCmp;
