/* eslint-disable no-useless-escape */
import * as yup from "yup";
import "yup-phone";

const addPhoneSchema = yup.object().shape({
  country: yup.string().required("Select a country"),
  phone: yup
    .string()
    .test(
      "Check if it include country code",
      "Pls remove country code",
      async function (value) {
        if (value && value.includes("+")) {
          return false;
        }
        return true;
      }
    )
    .phone(undefined, undefined, "Invalid phone number")
    .required(),
});

export default addPhoneSchema;
