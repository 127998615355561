/* eslint-disable no-useless-escape */
import axios from "axios";
import * as yup from "yup";
import { apiBaseUrl } from "../constants/url";

const signupBusinessSchema = yup.object().shape({
  businessName: yup
    .string()
    .min(5, "Must be a minimum of 5 characters")
    .required("Please add Business name")
    .test(
      "Unique business name",
      "Business name has been used",
      async function (value) {
        if (value) {
          const response = await axios.post(
            `${apiBaseUrl}/auth/business-name/check`,
            { businessName: value }
          );
          const data = response.data;
          if (data.message !== "Business name is available") {
            return false;
          }
          return true;
        }
      }
    ),
  email: yup
    .string()
    .email("It must be a valid email")
    .required("Provide a business email")
    .test(
      "Unique email",
      "Email has been used already",
      async function (value) {
        if (value) {
          const response = await axios.post(`${apiBaseUrl}/auth/email/check`, {
            email: value,
          });
          const data = response.data;
          if (data.message !== "Email is available") {
            return false;
          }
          return true;
        }
      }
    ),
  password: yup
    .string()
    .min(8, "Minimum of 8 characters")
    .required("Provide a strong password"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain minimum of 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Password must match"),
  referral: yup.string().optional(),
});

export default signupBusinessSchema;
