import axios from "axios";
import {
  GET_ALL_TRANSACTIONS_LOADING,
  GET_ALL_TRANSACTIONS_SUCCESS,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getAllTransactions = () => async (dispatch) => {
  dispatch({ type: GET_ALL_TRANSACTIONS_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/transaction/all`,
      headers()
    );
    dispatch({ type: GET_ALL_TRANSACTIONS_LOADING, payload: null });
    dispatch({ type: GET_ALL_TRANSACTIONS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_ALL_TRANSACTIONS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
