import React from "react";
import styled from "styled-components";
import ModalCmp from "../ModalCmp/ModalCmp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import JessieLogo from "../../assets/logo-color.svg";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  text-align: center;
  padding: ${(props) =>
    props.wrapperPadding ? props.wrapperPadding : "2rem 1rem"};
`;

const ImgWrapper = styled.div`
  padding-bottom: 1.5rem;
`;

const LogoImg = styled(LazyLoadImage)`
  width: 137px;
  height: 46px;
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  padding-bottom: 0.8px;
  text-align: left;
`;

const Text = styled.p`
  font-weight: ${appTheme.WEIGHT.small2};
  color: #c4c4c4;
  font-size: 14px;
  text-align: left;
  /* max-width: ${(props) =>
    props.titleMaxWidth ? props.titleMaxWidth : "300px"}; */
`;

const CheckMailModal = (props) => {
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: props.maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Success"
        id="success"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={props.showCloseIcon}
      >
        <Wrapper wrapperPadding={props.wrapperPadding}>
          <ImgWrapper>
            <LogoImg src={JessieLogo} alt="Jessie" effect="blur" />
          </ImgWrapper>
          <Title>Verify Email</Title>
          <Text titleMaxWidth={props.titleMaxWidth}>
            We’ve sent an email to you with a link to confirm your account.
          </Text>
          <Text>
            Didn't get the email? If you don’t see an email from us within 5
            minutes, one of these things could have happened:
          </Text>
          <Text>
            <ul>
              <li>
                The email might be in your spam folder. (If you use Gmail,
                please check your Promotions folder as well.)
              </li>
              <li>
                The email address you entered had a typo. You accidentally
                entered another email address. (Usually happens with
                auto-complete.)
              </li>
              <li>
                We can’t deliver the email to this address. (Usually because of
                corporate firewalls or filtering.)
              </li>
            </ul>
          </Text>
        </Wrapper>
      </ModalCmp>
    </>
  );
};

export default CheckMailModal;
