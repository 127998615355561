/* eslint-disable no-useless-escape */
import * as yup from "yup";
import { boolean } from "yup";

const signinSchema = yup.object().shape({
  isEmail: boolean(),
  identifier: yup.string().when("isEmail", {
    is: true,
    then: yup.string().email("Provide a valid email address"),
    otherwise: yup.string().required("Provide email/username"),
  }),
  password: yup
    .string()
    // .min(8, "Minimum of 8 characters")
    .required("Please Enter your password"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
});

export default signinSchema;
