import { Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { getCountryCallingCode } from "react-phone-number-input";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import CustomPhoneInputCmp from "../../../components/InputsCmp/CustomPhoneInputCmp";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../../constants/theme";
import { countryCodeSwap } from "../../../constants/countries";
import CardWithIconCmp from "../../../components/CardWithIconCmp/CardWithIconCmp";
import NairaIcon from "../../../assets/naira.svg";
import AedcIcon from "../../../assets/aedc.svg";
import IbedcIcon from "../../../assets/ibedc.svg";
import JedIcon from "../../../assets/jed.svg";
import KedcoIcon from "../../../assets/kedco.svg";
import KaedcoIcon from "../../../assets/kaedco.svg";
import EkedcIcon from "../../../assets/ekedc.svg";
import IkedcIcon from "../../../assets/ikedc.svg";
import PhedIcon from "../../../assets/phed.svg";
import CustomPriceInputCmp from "../../../components/InputsCmp/CustomPriceInputCmp";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useNavigate } from "react-router-dom";
import CustomCheckboxCmp from "../../../components/InputsCmp/CustomCheckboxCmp";
import buyElectricitySchema from "../../../validators/buyElectricityValidator";
import CustomMultiSelect from "../../../components/InputsCmp/CustomMultiSelect";
import { connect } from "react-redux";
import { getDataVariationCodes } from "../../../redux/actions/dataActions";
import { getMeterNumberVerificationDetails } from "../../../redux/actions/electricityActions";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import { getSettings } from "../../../redux/actions/walletActions";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  max-width: 550px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: 700;
  color: ${appTheme.COLORS.grey1};
  padding-bottom: 0.5rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const NairaImg = styled.img``;

const SelectWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ElectricityPurchase = ({ loading, setGetSettings }) => {
  const navigate = useNavigate();
  const [selectedWallet, setSelectedWallet] = useState("naira-wallet");
  const [settingsData, setSettingsData] = useState({});
  const [otherProviderArr] = useState([
    {
      type: "abuja-electric",
      text: "AEDC",
      icon: `${AedcIcon}`,
    },
    {
      type: "ibadan-electric",
      text: "IBEDC",
      icon: `${IbedcIcon}`,
    },
    {
      type: "kano-electric",
      text: "KEDCO",
      icon: `${KedcoIcon}`,
    },
    {
      type: "kaduna-electric",
      text: "KAEDCO",
      icon: `${KaedcoIcon}`,
    },
    {
      type: "jos-electric",
      text: "JED",
      icon: `${JedIcon}`,
    },
  ]);

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetSettings();

      setSettingsData(response.data);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchMeterNumberDetails = (props) => {
    const {
      values: {
        meterNumber,
        provider,
        subscriberName,
        checkedValue,
        otherProvider,
      },
      setFieldValue,
    } = useFormikContext();

    async function getSmartCardDetails() {
      const response = await getMeterNumberVerificationDetails({
        serviceId: provider || otherProvider?.value,
        billersCode: `${meterNumber}`,
        type: checkedValue,
      });
      return response;
    }

    useEffect(() => {
      async function fetchSmartCard() {
        if (
          (provider || otherProvider) &&
          checkedValue &&
          meterNumber &&
          `${meterNumber}`.length >= 11
        ) {
          setFieldValue("subscriberName", "loading...");
          const response = await getSmartCardDetails();
          if (response?.data?.Customer_Name) {
            setFieldValue("subscriberName", response?.data?.Customer_Name);
          } else {
            setFieldValue("subscriberName", "");
          }
        } else {
          setFieldValue("subscriberName", "");
        }
      }

      fetchSmartCard();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      provider,
      otherProvider?.value,
      checkedValue,
      meterNumber,
      setFieldValue,
    ]);

    return (
      <>
        <InputLabel style={{ marginTop: "-10px", fontWeight: "300" }}>
          {subscriberName}
        </InputLabel>
      </>
    );
  };
  return (
    <>
      <Formik
        validationSchema={buyElectricitySchema}
        initialValues={{
          provider: "",
          otherProvider: null,
          amount: "",
          phone: "",
          amountType: "",
          checkedState: "",
          checkedValue: "",
          meterNumber: "",
          charge: settingsData?.convenienceFee,
        }}
        onSubmit={async (values, { resetForm }) => {
          const fullPhoneNo = parsePhoneNumber(
            `+${getCountryCallingCode(countryCodeSwap[`Nigeria`])}${
              values.phone
            }`
          ).getNumber("e164");
          navigate("/products/renew/electricity/confirm", {
            state: {
              ...values,
              fullPhoneNo,
              charge: settingsData?.convenienceFee,
            },
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          setFieldValue,
          touched,
          setFieldTouched,
        }) => (
          <>
            <Wrapper2 noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <InputLabel>Select Metre Type</InputLabel>
                  <CardWrapper>
                    <CustomCheckboxCmp
                      id="prepaid"
                      handleChange={() => {
                        setFieldValue("checkedState", true);
                        setFieldValue("checkedValue", "prepaid");
                      }}
                      isInvalid={!values.checkedState}
                      checked={values.checkedValue === "prepaid" ? true : false}
                      desc="Prepaid Meter"
                      gap="30px"
                      height="55px"
                    />
                    <CustomCheckboxCmp
                      id="postpaid"
                      handleChange={() => {
                        setFieldValue("checkedState", true);
                        setFieldValue("checkedValue", "postpaid");
                      }}
                      isInvalid={!values.checkedState}
                      checked={
                        values.checkedValue === "postpaid" ? true : false
                      }
                      desc="Postpaid Meter"
                      gap="30px"
                      height="55px"
                    />
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Select Payment Method</InputLabel>
                  <CardWrapper>
                    <CardWithIconCmp
                      type="naira-wallet"
                      text="Naira Wallet"
                      icon={NairaIcon}
                      selectedItem={selectedWallet}
                      maxWidth={"150px"}
                      onSelect={() => {
                        setSelectedWallet("naira-wallet");
                      }}
                    />
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Select Provider</InputLabel>
                  <CardWrapper>
                    {[
                      {
                        type: "portharcourt-electric",
                        text: "PHED",
                        icon: `${PhedIcon}`,
                      },
                      {
                        type: "ikeja-electric",
                        text: "IKEDC",
                        icon: `${IkedcIcon}`,
                      },
                      {
                        type: "eko-electric",
                        text: "EKEDC",
                        icon: `${EkedcIcon}`,
                      },
                    ].map((v, i) => {
                      return (
                        <CardWithIconCmp
                          message={`${
                            errors["provider"] ? errors["provider"] : ""
                          }`}
                          type={v.type}
                          text={v.text}
                          icon={v.icon}
                          selectedItem={values.provider}
                          maxWidth={"110px"}
                          onSelect={() => {
                            setFieldValue("provider", v.type);
                            setFieldValue("otherProvider", null);
                          }}
                          hideText
                        />
                      );
                    })}
                    <CustomMultiSelect
                      height="60px"
                      maxWidth="150px"
                      width="100%"
                      name="otherProvider"
                      placeholder="Other Providers"
                      isMulti={false}
                      isSearchable={false}
                      options={[
                        ...otherProviderArr.map((v, i) => {
                          return {
                            icon: v.icon,
                            value: v.type,
                            label: v.type,
                          };
                        }),
                      ]}
                      formatOptionLabel={(label) => (
                        <>
                          <SelectWrapper>
                            {label && label.label && (
                              <img
                                style={{ width: "50px" }}
                                src={label.icon}
                                alt={label.label}
                              />
                            )}
                          </SelectWrapper>
                        </>
                      )}
                      message={`${
                        errors["otherProvider"] || errors["provider"]
                          ? errors["otherProvider"] || errors["provider"]
                          : ""
                      }`}
                      showMessage={false}
                      value={values?.otherProvider}
                      isValid={isValid}
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      otherChange={"provider"}
                      touched={touched}
                    />
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Enter Meter Number</InputLabel>
                  <CustomInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    placeholder="Enter smart card number"
                    required={true}
                    name={"meterNumber"}
                    onChange={handleChange}
                    values={values}
                    showMessage={false}
                    message={`${
                      errors["meterNumber"] ? errors["meterNumber"] : ""
                    }`}
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    min={0}
                  />
                </InputWrapper>
                <FetchMeterNumberDetails />
                <InputWrapper>
                  <InputLabel>Select Amount</InputLabel>
                  <div>
                    <CardWrapper>
                      {[
                        { type: "1000", text: "₦1000.00" },
                        { type: "5000", text: "₦5000.00" },
                        { type: "10000", text: "₦10000.00" },
                        { type: "20000", text: "₦20000.00" },
                        { type: "other", text: "other" },
                      ].map((v, i) => {
                        return (
                          <CardWithIconCmp
                            message={`${
                              errors["amount"] ? errors["amount"] : ""
                            }`}
                            removeIcon
                            type={v.type}
                            text={v.text}
                            icon={v.icon}
                            selectedItem={values.amount}
                            maxWidth={"80px"}
                            onSelect={() => {
                              if (v.type === "other") {
                                setFieldValue("amountType", "other");
                              } else {
                                setFieldValue("amountType", "");
                                setFieldValue("amount", v.type);
                              }
                            }}
                          />
                        );
                      })}
                    </CardWrapper>
                  </div>
                </InputWrapper>
                {values.amountType === "other" && (
                  <InputWrapper>
                    <CustomPriceInputCmp
                      background="rgba(225, 235, 245, 0.1)"
                      required={true}
                      icon={
                        <>
                          <NairaImg src={NairaIcon} alt="Naira" />
                        </>
                      }
                      type="text"
                      name="amount"
                      placeholder="Enter Amount"
                      values={values}
                      onChange={setFieldValue}
                      message={`${errors["amount"] ? errors["amount"] : ""}`}
                      showMessage={true}
                    />
                  </InputWrapper>
                )}

                <InputWrapper>
                  <InputLabel>Enter Phone Number</InputLabel>
                  <CustomPhoneInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    name="phone"
                    placeholder="Enter Receiver’s Phone Number"
                    values={values}
                    phoneCode={`+${getCountryCallingCode(
                      countryCodeSwap[`Nigeria`]
                    )}`}
                    flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      countryCodeSwap[`Nigeria`]
                    }.svg`}
                    onChange={handleChange}
                    message={`${errors["phone"] ? errors["phone"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
              </FormWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Continue"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={!isValid ? true : false}
                />
              </ButtonWrapper>
            </Wrapper2>
          </>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => ({
  getVariationCodesLoading: state.data.getVariationCodesLoading,
  getVariationCodesSuccess: state.data.getVariationCodesSuccess,
  getVariationCodesError: state.data.getVariationCodesError,
  verifyMeterNumberLoading: state.electricity.verifyMeterNumberLoading,
  verifyMeterNumberError: state.electricity.verifyMeterNumberError,
  verifyMeterNumberSuccess: state.electricity.verifyMeterNumberSuccess,
  settingsLoading: state.wallet.getSettingsLoading,
  settingsSuccess: state.wallet.getSettingsSuccess,
  settingsError: state.wallet.getSettingsError,
});

const mapDispatch = (dispatch) => ({
  setGetSettings: () => dispatch(getSettings()),
  setGetDataVariationCodes: (data) => dispatch(getDataVariationCodes(data)),
});

export default connect(mapState, mapDispatch)(ElectricityPurchase);
