import Dropdown from "rc-dropdown";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { getUserTxnLimit } from "../../redux/actions/dashboardAction";
import SolidButton from "../ButtonCmp/Button";
import LineProgressCmp from "../LineProgressCmp/LineProgressCmp";
import { connect } from "react-redux";
import MoneyFormatter from "../MoneyFormatterCmp/MoneyFormatter";

const Wrapper = styled.div`
  max-width: 350px;
  width: 100%;
  height: 238px;
  background-color: #fff;
  border-radius: 24px;
  padding: 2rem 1.5rem;
  border: 0.6px solid rgba(0, 0, 0, 0.5);

  @media only screen and (min-width: 1500px) {
    max-width: unset;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const DailySpendLimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DailySpendLimitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DailyText = styled.h2`
  color: #323232;
  font-size: 13px;
  font-weight: ${appTheme.WEIGHT.medium};
  margin: 0;
`;

const AmountText = styled.h2`
  font-size: 12px;
  color: #323232;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  margin: 0;
`;

const DailyWithdrawLimitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownMenuWrapper = styled.div`
  padding: 1rem;
  width: 180px;
  height: 182px;
  background-color: ${appTheme.COLORS.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
`;

const DropdownContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DropdownContent = styled.p`
  font-size: 8px;
  color: #323232;
  font-weight: ${appTheme.WEIGHT.small2};
`;

const DropdownContentSpan = styled.span`
  color: #016cba;
`;

const TxnLimitCmp = ({
  setGetuserTxnLimit,
  dashboardTxnLimitLoading,
  dashboardTxnLimitError,
  dashboardTxnLimit,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [dropdownVisible, setDropdownVisible] = useState();

  useEffect(() => {
    async function callGetUserTxnLimit() {
      await setGetuserTxnLimit();
    }

    callGetUserTxnLimit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DropdownMenu = () => {
    return (
      <>
        <DropdownMenuWrapper>
          <DropdownContentWrapper>
            <DropdownContent>
              A <DropdownContentSpan>One Time Spend Limit</DropdownContentSpan>
              is the maximum amount you can withdraw or spend at a single
              transaction, which can be repeated as long as you do not exceed
              the daily withdrawal limits.
            </DropdownContent>
            <DropdownContent>
              A{" "}
              <DropdownContentSpan>Daily Withdrawal Limit</DropdownContentSpan>
              is the maximum amount of money you can withdraw, in-app spending,
              transfer etc in a day (within 24hrs, which is bound by number of
              transactions.)
            </DropdownContent>
          </DropdownContentWrapper>
        </DropdownMenuWrapper>
      </>
    );
  };
  return (
    <>
      <Wrapper>
        <Container>
          <DailySpendLimitContainer>
            <LineProgressCmp
              percent={parseFloat(
                (dashboardTxnLimit?.data?.dailySpent * 100) /
                  dashboardTxnLimit?.data?.dailySpendLimit
              )}
            />
            <DailySpendLimitWrapper>
              <DailyText>Daily Spend Limit:</DailyText>
              <AmountText>
                <MoneyFormatter value={dashboardTxnLimit?.data?.dailySpent} /> /
                &nbsp;
                <MoneyFormatter
                  value={dashboardTxnLimit?.data?.dailySpendLimit}
                />
              </AmountText>
            </DailySpendLimitWrapper>
          </DailySpendLimitContainer>
          <DailySpendLimitContainer>
            <LineProgressCmp
              percent={parseFloat(
                (dashboardTxnLimit?.data?.dailyWithdrawn * 100) /
                  dashboardTxnLimit?.data?.dailyWithdrawalLimit
              )}
            />
            <DailyWithdrawLimitWrapper>
              <DailyText>Daily Withdraw Limit:</DailyText>
              <AmountText>
                <MoneyFormatter
                  value={dashboardTxnLimit?.data?.dailyWithdrawn}
                />
                / &nbsp;
                <MoneyFormatter
                  value={dashboardTxnLimit?.data?.dailyWithdrawalLimit}
                />
              </AmountText>
            </DailyWithdrawLimitWrapper>
          </DailySpendLimitContainer>
          <Dropdown
            trigger={["click"]}
            overlay={DropdownMenu}
            animation="slide-up"
            overlayStyle={{ fontFamily: '"Nunito", sans-serif' }}
            onVisibleChange={(visible) => {
              setDropdownVisible(visible);
            }}
          >
            <SolidButton
              text={"Learn about limits"}
              type="submit"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1rem"
              color="#8793AB"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              maxWidth="150px"
              borderRadius="6px"
              backColor="#F4F7FD"
              backgroundHoverColor="#F4F7FD"
              textHoverColor="#8793AB"
              margin="0rem auto 0"
            />
          </Dropdown>
        </Container>
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  dashboardTxnLimitLoading: state.dashboard.dashboardTxnLimitLoading,
  dashboardTxnLimit: state.dashboard.dashboardTxnLimit,
  dashboardTxnLimitError: state.dashboard.dashboardTxnLimitError,
});

const mapDispatch = (dispatch) => ({
  setGetuserTxnLimit: () => dispatch(getUserTxnLimit()),
});

export default connect(mapState, mapDispatch)(TxnLimitCmp);
