export const LOADING = "LOADING";
export const AUTHENTICATED = "AUTHENTICATED";
export const LOGIN_DATA = "LOGIN_DATA";
export const SHOW_EMAIL_VERIFY = "SHOW_EMAIL_VERIFY";
export const ERROR = "ERROR";
export const LOGIN_TOKEN = "LOGIN_TOKEN";
// export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT = "LOGOUT";

// account set up
export const USER_TYPE = "USER_TYPE";
export const BANK_LIST = "BANK_LIST";
export const USER_SETUP_STATUS = "USER_SETUP_STATUS";

// types used in DASHBOARD
export const DASHBOARD_BALANCE_LOADING = "DASHBOARD_BALANCE_LOADING";
export const DASHBOARD_BALANCE_SUCCESS = "DASBOARD_BALANCE_SUCCESS";
export const DASHBOARD_BALANCE_ERROR = "DASHBOARD_BALANCE_ERROR";

export const DASHBOARD_TXN_LIMIT_LOADING = "DASHBOARD_TXN_LIMIT_LOADING";
export const DASHBOARD_TXN_LIMIT_SUCCESS = "DASHBOARD_TXN_LIMIT_SUCCESS";
export const DASHBOARD_TXN_LIMIT_ERROR = "DASHBOARD_TXN_LIMIT_ERROR";

export const DASHBOARD_RECENT_TXN_LOADING = "DASHBOARD_RECENT_TXN_LOADING";
export const DASHBOARD_RECENT_TXN_SUCCESS = "DASHBOARD_RECENT_TXN_SUCCESS";
export const DASHBOARD_RECENT_TXN_ERROR = "DASHBOARD_RECENT_TXN_ERROR";

export const ALL_ADS_LOADING = "ALL_ADS_LOADING";
export const ALL_ADS_SUCCESS = "ALL_ADS_SUCCESS";
export const ALL_ADS_ERROR = "ALL_ADS_ERROR";

// types used in WALLET
export const DEPOSIT_NAIRA_LOADING = "DEPOSIT_NAIRA_LOADING";
export const DEPOSIT_NAIRA_SUCCESS = "EPOSIT_NAIRA_SUCCESS";
export const DEPOSIT_NAIRA_ERROR = "DEPOSIT_NAIRA_ERROR";

export const USER_BANK_DETAIL_LIST_LOADING = "USER_BANK_DETAIL_LIST_LOADING";
export const USER_BANK_DETAIL_LIST_SUCCESS = "USER_BANK_DETAIL_LIST_SUCCESS";
export const USER_BANK_DETAIL_LIST_ERROR = "USER_BANK_DETAIL_LIST_ERROR";

export const GET_SETTINGS_LOADING = "GET_SETTINGS_LOADING";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";

export const CHECK_USER_INFO_LOADING = "CHECK_USER_INFO_LOADING";
export const CHECK_USER_INFO_SUCCESS = "CHECK_USER_INFO_SUCCESS";
export const CHECK_USER_INFO_ERROR = "CHECK_USER_INFO_ERROR";

export const MAKE_WITHDRAWAL_LOADING = "MAKE_WITHDRAWAL_LOADING";
export const MAKE_WITHDRAWAL_SUCCESS = "MAKE_WITHDRAWAL_SUCCESS";
export const MAKE_WITHDRAWAL_ERROR = "MAKE_WITHDRAWAL_ERROR";

export const CHECK_SUFFICIENT_BALANCE_LOADING =
  "CHECK_SUFFICIENT_BALANCE_LOADING";
export const CHECK_SUFFICIENT_BALANCE_SUCCESS =
  "CHECK_SUFFICIENT_BALANCE_SUCCESS";
export const CHECK_SUFFICIENT_BALANCE_ERROR = "CHECK_SUFFICIENT_BALANCE_ERROR";

export const RECHARGE_AIRTIME_LOADING = "RECHARGE_AIRTIME_LOADING";
export const RECHARGE_AIRTIME_SUCCESS = "RECHARGE_AIRTIME_SUCCESS";
export const RECHARGE_AIRTIME_ERROR = "RECHARGE_AIRTIME_ERROR";

export const BUY_DATA_LOADING = "BUY_DATA_LOADING";
export const BUY_DATA_SUCCESS = "BUY_DATA_SUCCESS";
export const BUY_DATA_ERROR = "BUY_DATA_ERROR";

export const GET_DATA_VARIATION_CODES_LOADING =
  "GET_DATA_VARIATION_CODES_LOADING";
export const GET_DATA_VARIATION_CODES_SUCCESS =
  "GET_DATA_VARIATION_CODES_SUCCESS";
export const GET_DATA_VARIATION_CODES_ERROR = "GET_DATA_VARIATION_CODES_ERROR";

export const VERIFY_SMART_CARD_LOADING = "VERIFY_SMART_CARD_LOADING";
export const VERIFY_SMART_CARD_SUCCESS = "VERIFY_SMART_CARD_SUCCESS";
export const VERIFY_SMART_CARD_ERROR = "VERIFY_SMART_CARD_ERROR";

export const BUY_CABLE_LOADING = "BUY_CABLE_LOADING";
export const BUY_CABLE_SUCCESS = "BUY_CABLE_SUCCESS";
export const BUY_CABLE_ERROR = "BUY_CABLE_ERROR";

export const VERIFY_METER_NUMBER_LOADING = "VERIFY_METER_NUMBER_LOADING";
export const VERIFY_METER_NUMBER_SUCCESS = "VERIFY_METER_NUMBER_SUCCESS";
export const VERIFY_METER_NUMBER_ERROR = "VERIFY_METER_NUMBER_ERROR";

export const BUY_ELECTRICITY_LOADING = "BUY_ELECTRICITY_LOADING";
export const BUY_ELECTRICITY_SUCCESS = "BUY_ELECTRICITY_SUCCESS";
export const BUY_ELECTRICITY_ERROR = "BUY_ELECTRICITY_ERROR";

export const BUY_EDUCATIONAL_LOADING = "BUY_EDUCATIONAL_LOADING";
export const BUY_EDUCATIONAL_SUCCESS = "BUY_EDUCATIONAL_SUCCESS";
export const BUY_EDUCATIONAL_ERROR = "BUY_EDUCATIONAL_ERROR";

export const VERIFY_PROFILE_ID_LOADING = "VERIFY_PROFILE_ID_LOADING";
export const VERIFY_PROFILE_ID_SUCCESS = "VERIFY_PROFILE_ID_SUCCESS";
export const VERIFY_PROFILE_ID_ERROR = "VERIFY_PROFILE_ID_ERROR";

//types used in TRANSACTION
export const GET_ALL_TRANSACTIONS_ERROR = "GET_ALL_TRANSACTIONS_ERROR";
export const GET_ALL_TRANSACTIONS_LOADING = "GET_ALL_TRANSACTIONS_LOADING";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";

//types used in GIFTCARDS
export const GET_ALL_GIFTCARDS_BUY_ERROR = "GET_ALL_GIFTCARDS_BUY_ERROR";
export const GET_ALL_GIFTCARDS_BUY_LOADING = "GET_ALL_GIFTCARDS_BUY_LOADING";
export const GET_ALL_GIFTCARDS_BUY_SUCCESS = "GET_ALL_GIFTCARDS_BUY_SUCCESS";

export const GET_A_GIFTCARD_BUY_ERROR = "GET_A_GIFTCARD_BUY_ERROR";
export const GET_A_GIFTCARD_BUY_LOADING = "GET_A_GIFTCARD_BUY_LOADING";
export const GET_A_GIFTCARD_BUY_SUCCESS = "GET_A_GIFTCARD_BUY_SUCCESS";

export const GIFTCARD_BUY_ERROR = "GIFTCARD_BUY_ERROR";
export const GIFTCARD_BUY_LOADING = "GIFTCARD_BUY_LOADING";
export const GIFTCARD_BUY_SUCCESS = "GIFTCARD_BUY_SUCCESS";

export const GET_ALL_GIFTCARDS_SELL_ERROR = "GET_ALL_GIFTCARDS_SELL_ERROR";
export const GET_ALL_GIFTCARDS_SELL_LOADING = "GET_ALL_GIFTCARDS_SELL_LOADING";
export const GET_ALL_GIFTCARDS_SELL_SUCCESS = "GET_ALL_GIFTCARDS_SELL_SUCCESS";

export const GET_A_GIFTCARD_SELL_ERROR = "GET_A_GIFTCARD_SELL_ERROR";
export const GET_A_GIFTCARD_SELL_LOADING = "GET_A_GIFTCARD_SELL_LOADING";
export const GET_A_GIFTCARD_SELL_SUCCESS = "GET_A_GIFTCARD_SELL_SUCCESS";

export const GIFTCARD_SELL_ERROR = "GIFTCARD_SELL_ERROR";
export const GIFTCARD_SELL_LOADING = "GIFTCARD_SELL_LOADING";
export const GIFTCARD_SELL_SUCCESS = "GIFTCARD_SELL_SUCCESS";

//types used in 2FA
export const VALIDATE_2FA_ERROR = "VALIDATE_2FA_ERROR";
export const VALIDATE_2FA_LOADING = "VALIDATE_2FA_LOADING";
export const VALIDATE_2FA_SUCCESS = "VALIDATE_2FA_SUCCESS";
export const VALIDATE_2FA_ENABLED = "VALIDATE_2FA_ENABLED";

//types used in SETTINGS
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const GET_USER_PROFILE_LOADING = "GET_USER_PROFILE_LOADING";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const GET_USER_PROFILE_PICTURE_ERROR = "GET_USER_PROFILE_PICTURE_ERROR";
export const GET_USER_PROFILE_PICTURE_LOADING =
  "GET_USER_PROFILE_PICTURE_LOADING";
export const GET_USER_PROFILE_PICTURE_SUCCESS =
  "GET_USER_PROFILE_PICTURE_SUCCESS";

export const UPDATE_PROFILE_PICTURE_ERROR = "UPDATE_PROFILE_PICTURE_ERROR";
export const UPDATE_PROFILE_PICTURE_LOADING = "UPDATE_PROFILE_PICTURE_LOADING";
export const UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS";

export const DELETE_PROFILE_PICTURE_ERROR = "DELETE_PROFILE_PICTURE_ERROR";
export const DELETE_PROFILE_PICTURE_LOADING = "DELETE_PROFILE_PICTURE_LOADING";
export const DELETE_PROFILE_PICTURE_SUCCESS = "DELETE_PROFILE_PICTURE_SUCCESS";

export const ADD_BANK_ACCOUNT_ERROR = "ADD_BANK_ACCOUNT_ERROR";
export const ADD_BANK_ACCOUNT_LOADING = "ADD_BANK_ACCOUNT_LOADING";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";

export const GET_USER_LIMIT_ERROR = "GET_USER_LIMIT_ERROR";
export const GET_USER_LIMIT_LOADING = "GET_USER_LIMIT_LOADING";
export const GET_USER_LIMIT_SUCCESS = "GET_USER_LIMIT_SUCCESS";

export const GET_2FA_STATUS_ERROR = "GET_2FA_STATUS_ERROR";
export const GET_2FA_STATUS_LOADING = "GET_2FA_STATUS_LOADING";
export const GET_2FA_STATUS_SUCCESS = "GET_2FA_STATUS_SUCCESS";

export const TOGGLE_2FA_ERROR = "TOGGLE_2FA_ERROR";
export const TOGGLE_2FA_LOADING = "TOGGLE_2FA_LOADING";
export const TOGGLE_2FA_SUCCESS = "TOGGLE_2FA_SUCCESS";

export const GENERATE_2FA_CODE_ERROR = "GENERATE_2FA_CODE_ERROR";
export const GENERATE_2FA_CODE_LOADING = "GENERATE_2FA_CODE_LOADING";
export const GENERATE_2FA_CODE_SUCCESS = "GENERATE_2FA_CODE_SUCCESS";

export const VERIFY_2FA_CODE_ERROR = "VERIFY_2FA_CODE_ERROR";
export const VERIFY_2FA_CODE_LOADING = "VERIFY_2FA_CODE_LOADING";
export const VERIFY_2FA_CODE_SUCCESS = "VERIFY_2FA_CODE_SUCCESS";

export const PROVIDE_PASSWORD_ERROR = "PROVIDE_PASSWORD_ERROR";
export const PROVIDE_PASSWORD_LOADING = "PROVIDE_PASSWORD_LOADING";
export const PROVIDE_PASSWORD_SUCCESS = "PROVIDE_PASSWORD_SUCCESS";

export const ENTER_OTP_ERROR = "ENTER_OTP_ERROR";
export const ENTER_OTP_LOADING = "ENTER_OTP_LOADING";
export const ENTER_OTP_SUCCESS = "ENTER_OTP_SUCCESS";

export const CREATE_NEW_PASSWORD_ERROR = "CREATE_NEW_PASSWORD_ERROR";
export const CREATE_NEW_PASSWORD_LOADING = "CREATE_NEW_PASSWORD_LOADING";
export const CREATE_NEW_PASSWORD_SUCCESS = "CREATE_NEW_PASSWORD_SUCCESS";

export const CREATE_NEW_PIN_ERROR = "CREATE_NEW_PIN_ERROR";
export const CREATE_NEW_PIN_LOADING = "CREATE_NEW_PIN_LOADING";
export const CREATE_NEW_PIN_SUCCESS = "CREATE_NEW_PIN_SUCCESS";
