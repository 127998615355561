import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #adb5bd;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  gap: 3px;
  height: 100px;
  align-items: center;
  width: 100%;
  /* max-width: 300px; */
`;

const BankImg = styled.img`
  width: 80px;
`;

const Line = styled.hr`
  width: 50px;
  height: 1px;
  color: #adb5bd;
  background-color: #adb5bd;
  margin: 0;
  rotate: 90deg;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AccountName = styled.h2`
  font-weight: 600;
  font-size: 13px;
  margin: 0;
`;

const AccountNumber = styled.h2`
  font-weight: 200;
  font-size: 13px;
  margin: 0;
`;

const Name = styled.h2`
  font-weight: 200;
  font-size: 12px;
  margin: 0;
`;

const BankDetailsCard = ({ icon, accountName, accountNumber, bankName }) => {
  return (
    <>
      <Wrapper>
        <BankImg src={icon} alt={accountName} />
        <Line />
        <TextWrapper>
          <AccountName>{accountName}</AccountName>
          <AccountNumber>{accountNumber}</AccountNumber>
          <Name>{bankName}</Name>
        </TextWrapper>
      </Wrapper>
    </>
  );
};

export default BankDetailsCard;
