import {
  BUY_DATA_LOADING,
  BUY_DATA_SUCCESS,
  BUY_DATA_ERROR,
  GET_DATA_VARIATION_CODES_LOADING,
  GET_DATA_VARIATION_CODES_SUCCESS,
  GET_DATA_VARIATION_CODES_ERROR,
} from "./types";

const initialState = {
  buyDataLoading: null,
  buyDataSuccess: null,
  buyDataError: null,
  getDataVariationCodesLoading: null,
  getDataVariationCodesSuccess: null,
  getDataVariationCodesError: null,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case BUY_DATA_LOADING:
      return { ...state, buyDataLoading: action.payload };
    case BUY_DATA_SUCCESS:
      return { ...state, buyDataSuccess: action.payload };
    case BUY_DATA_ERROR:
      return { ...state, buyDataError: action.payload };
    case GET_DATA_VARIATION_CODES_LOADING:
      return { ...state, getDataVariationCodesLoading: action.payload };
    case GET_DATA_VARIATION_CODES_SUCCESS:
      return { ...state, getDataVariationCodesSuccess: action.payload };
    case GET_DATA_VARIATION_CODES_ERROR:
      return { ...state, getDataVariationCodesError: action.payload };
    default:
      return state;
  }
}
