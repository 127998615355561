import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const GiftcardImage = styled(LazyLoadImage)`
  border-radius: 15px;
  width: 100%;
`;

const Text = styled.h2`
  font-weight: 500;
  font-size: 18px;
  color: #323232;
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 599px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

const DisplayGiftcardCard = ({ image, name, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <GiftcardImage src={image} alt={name} effect="blur" />
      <Text>{name}</Text>
    </Wrapper>
  );
};

export default DisplayGiftcardCard;
