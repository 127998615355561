import { ArrowBack } from "@styled-icons/boxicons-regular";
import React from "react";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import QRCode from "qrcode.react";

const Wrapper = styled.div``;

const BackIconWrapper = styled.div`
  cursor: pointer;
`;

const Title = styled.h2`
  text-align: center;
  color: #323232;
  font-size: 1.75rem;
  font-weight: 700;
  max-width: 325px;
  margin: 0 auto;
`;

const Desc = styled.p`
  color: #323232;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 400;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AuthImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const TwoFaStep31 = ({ setStep, qrCodeLink }) => {
  return (
    <>
      <Wrapper>
        <BackIconWrapper
          onClick={() => {
            setStep(3);
          }}
        >
          <ArrowBack color="#323232" size={20} />
        </BackIconWrapper>
        <Title>QR code</Title>
        <Desc>Scan to sync your account</Desc>
        <AuthImgWrapper>
          <QRCode
            id="qrCode-2fa"
            value={qrCodeLink}
            size={215}
            level={"H"}
            includeMargin={true}
          />
        </AuthImgWrapper>
        <ButtonWrapper>
          <SolidButton
            text={"Continue"}
            type="submit"
            weighty="500"
            textFontSize="14px"
            specifyPadding="0.5rem 1rem"
            color="#fff"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            maxWidth="250px"
            borderRadius="8px"
            backColor="#1a8fdd"
            backgroundHoverColor="#1a8fdd"
            textHoverColor="#fff"
            margin="0rem auto 0"
            onClick={() => {
              setStep(5);
            }}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default TwoFaStep31;
