import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import CustomPinInputCmp from "../../../components/InputsCmp/CustomPinInputCmp";
import SolidButton from "../../../components/ButtonCmp/Button";
import { connect } from "react-redux";
import { enterOtp } from "../../../redux/actions/settingActions";
import { useNavigate } from "react-router-dom";
import appTheme from "../../../constants/theme";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";

const TitleText = styled.h2`
  color: #323232;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  padding-top: 0.8rem;
  text-align: center;
`;

const SetPinWrapper = styled.div`
  text-align: left;
`;

const PinGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PinWrapper = styled.div``;

const EnterOtpForm = ({
  title,
  path,
  setEnterOtp,
  getEnterOTPError,
  getEnterOTPLoading,
  getEnterOTPSuccess,
}) => {
  const navigate = useNavigate();
  const [pinDetails, setPinDetails] = useState("");
  const handlePinComplete = (value) => {
    setPinDetails(value);
  };

  const handleSubmit = async () => {
    if (!pinDetails) {
      return toast.error("Provide your pin");
    }
    if (pinDetails.length !== 6) {
      return toast.error("Pin digit not complete");
    }

    const response = await setEnterOtp({ pin: pinDetails });

    if (response.status === 200 && response.message === "Successful") {
      toast.success("OTP verified successfully");
      navigate(path, { state: { token: response.data.createNewPinToken } });
    }
  };
  return (
    <SetPinWrapper>
      <TitleText>{title}</TitleText>
      <PinGroup>
        <PinWrapper>
          <CustomPinInputCmp
            length={6}
            autoSelect={true}
            secret={true}
            initialValue=""
            onChange={handlePinComplete}
            onComplete={handlePinComplete}
            //   disabled={disableBtn === false ? true : false}
          />
        </PinWrapper>
      </PinGroup>
      <ButtonWrapper>
        <SolidButton
          onClick={handleSubmit}
          text={
            getEnterOTPLoading ? (
              <>
                <SpinnerCmp
                  enabled={true}
                  color={appTheme.COLORS.bluePrimary}
                  size={20}
                  secondaryColor={appTheme.COLORS.white}
                />
              </>
            ) : (
              "Continue"
            )
          }
          type="submit"
          weighty="500"
          disabled={getEnterOTPLoading ? true : false}
          textFontSize="14px"
          specifyPadding="1rem 1rem"
          color="#fff"
          widthWebkit="150px"
          widthMoz="150px"
          widthNormal="150px"
          borderRadius="8px"
          backColor="#1a8fdd"
          backgroundHoverColor="#1a8fdd"
          textHoverColor="#fff"
          margin="0rem auto 0"
        />
      </ButtonWrapper>
    </SetPinWrapper>
  );
};

const mapState = (state) => ({
  getEnterOTPLoading: state.setting.enterOTPLoading,
  getEnterOTPSuccess: state.setting.enterOTPSuccess,
  getEnterOTPError: state.setting.enterOTPError,
});

const mapDispatch = (dispatch) => ({
  setEnterOtp: (data) => dispatch(enterOtp(data)),
});

export default connect(mapState, mapDispatch)(EnterOtpForm);
