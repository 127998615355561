import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { getCountryCallingCode } from "react-phone-number-input";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import CustomPhoneInputCmp from "../../../components/InputsCmp/CustomPhoneInputCmp";
import appTheme from "../../../constants/theme";
import { countryCodeSwap } from "../../../constants/countries";
import CardWithIconCmp from "../../../components/CardWithIconCmp/CardWithIconCmp";
import NairaIcon from "../../../assets/naira.svg";
import WaecIcon from "../../../assets/waec.svg";
import { connect } from "react-redux";
import { getDataVariationCodes } from "../../../redux/actions/dataActions";
import { useEffect } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useNavigate } from "react-router-dom";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import CustomPriceInputCmp from "../../../components/InputsCmp/CustomPriceInputCmp";
import { getSettings } from "../../../redux/actions/walletActions";
import buyEducationalSchema from "../../../validators/buyEducationalValidator";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  max-width: 550px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: 700;
  color: ${appTheme.COLORS.grey1};
  padding-bottom: 0.5rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const NairaImg = styled.img``;

const Wrapper3 = styled.div`
  display: flex;
  gap: 20px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const WaecPurchase = ({ loading, setGetSettings }) => {
  const navigate = useNavigate();
  const [selectedWallet, setSelectedWallet] = useState("naira-wallet");
  const [settingsData, setSettingsData] = useState({});

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetSettings();

      setSettingsData(response.data);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchVariationCodes = (props) => {
    const {
      values: { examType, variationCode },
      setFieldValue,
      validateForm,
    } = useFormikContext();
    const [variationData, setVariationData] = useState();

    async function getVariationCodes() {
      const response = await getDataVariationCodes({
        serviceId: `${examType}`,
      });
      return response;
    }

    useEffect(() => {
      async function fetchCodes() {
        if (examType) {
          validateForm();
          const response = await getVariationCodes();
          if (response?.data) {
            setVariationData(response.data);
          } else {
            setVariationData({});
            setFieldValue("variationCode", "");
            setFieldValue("serviceId", "");
            setFieldValue("name", "");
            setFieldValue("amount", "");
          }
        } else {
          return;
        }
      }

      fetchCodes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examType, variationCode]);

    return (
      <>
        {variationData && variationData.length > 0 ? (
          <>
            <InputLabel>Select Variation</InputLabel>
            <div style={{ height: "fit-content", overflowY: "scroll" }}>
              <CardWrapper>
                {variationData.map((v, i) => {
                  return (
                    <CardWithIconCmp
                      key={i}
                      message={`${props.error}`}
                      showVariation
                      removeIcon
                      type={v.variation_code}
                      planPrice={v.variation_amount}
                      planName={v.name}
                      selectedItem={variationCode}
                      maxWidth={"100px"}
                      onSelect={() => {
                        if (variationData) {
                          setFieldValue("variationCode", v.variation_code);
                          setFieldValue("serviceId", examType);
                          setFieldValue("name", v.name);
                          setFieldValue("amount", v.variation_amount);
                        }
                      }}
                    />
                  );
                })}
              </CardWrapper>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      <Formik
        validationSchema={buyEducationalSchema}
        initialValues={{
          serviceId: "",
          amount: "",
          name: "",
          phone: "",
          variationCode: "",
          examType: "",
          email: "",
          quantity: 1,
          charge: settingsData?.educationCharge,
        }}
        onSubmit={async (values, { resetForm }) => {
          const fullPhoneNo = parsePhoneNumber(
            `+${getCountryCallingCode(countryCodeSwap[`Nigeria`])}${
              values.phone
            }`
          ).getNumber("e164");
          navigate("/products/renew/waec/confirm", {
            state: {
              ...values,
              fullPhoneNo,
              charge: settingsData.educationCharge,
            },
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          setFieldValue,
          touched,
        }) => (
          <>
            <Wrapper2 noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <InputLabel>Select Payment Method</InputLabel>
                  <CardWrapper>
                    <CardWithIconCmp
                      type="naira-wallet"
                      text="Naira Wallet"
                      icon={NairaIcon}
                      selectedItem={selectedWallet}
                      maxWidth={"150px"}
                      onSelect={() => {
                        setSelectedWallet("naira-wallet");
                      }}
                    />
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Exam Type</InputLabel>
                  <CardWrapper>
                    {[
                      {
                        type: "waec-registration",
                        text: "WASSCE / GCE",
                        icon: `${WaecIcon}`,
                      },
                      {
                        type: "waec",
                        text: "Result checking PIN",
                        icon: `${WaecIcon}`,
                      },
                    ].map((v, i) => {
                      return (
                        <CardWithIconCmp
                          message={`${
                            errors["examType"] ? errors["examType"] : ""
                          }`}
                          type={v.type}
                          text={v.text}
                          icon={v.icon}
                          selectedItem={values.examType}
                          maxWidth={"fit-content"}
                          onSelect={() => {
                            setFieldValue("examType", v.type);
                          }}
                        />
                      );
                    })}
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <FetchVariationCodes
                    error={`${
                      errors["variationCode"] ? errors["variationCode"] : ""
                    }`}
                    handleChange={handleChange}
                    value={values.examType}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Enter Phone Number</InputLabel>
                  <CustomPhoneInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    name="phone"
                    placeholder="Enter Receiver’s Phone Number"
                    values={values}
                    phoneCode={`+${getCountryCallingCode(
                      countryCodeSwap[`Nigeria`]
                    )}`}
                    flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      countryCodeSwap[`Nigeria`]
                    }.svg`}
                    onChange={handleChange}
                    message={`${errors["phone"] ? errors["phone"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Enter Email Address</InputLabel>
                  <CustomInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    placeholder="Email Address"
                    required={true}
                    name={"email"}
                    onChange={handleChange}
                    values={values}
                    showMessage={false}
                    message={`${errors["email"] ? errors["email"] : ""}`}
                    type="text"
                  />
                </InputWrapper>
                <Wrapper3>
                  <InputWrapper>
                    <InputLabel>Amount</InputLabel>
                    <CustomPriceInputCmp
                      background="rgba(225, 235, 245, 0.1)"
                      required={true}
                      icon={
                        <>
                          <NairaImg src={NairaIcon} alt="Naira" />
                        </>
                      }
                      type="text"
                      name="amount"
                      placeholder="Enter Amount"
                      values={values}
                      onChange={setFieldValue}
                      message={`${errors["amount"] ? errors["amount"] : ""}`}
                      showMessage={false}
                      disabled={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Select Quantity</InputLabel>
                    <CustomInputCmp
                      background="rgba(225, 235, 245, 0.1)"
                      placeholder="Quantity"
                      required={true}
                      name={"quantity"}
                      onChange={handleChange}
                      values={values}
                      showMessage={false}
                      message={`${
                        errors["quantity"] ? errors["quantity"] : ""
                      }`}
                      type="number"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      min={1}
                    />
                  </InputWrapper>
                </Wrapper3>
              </FormWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={"Pay"}
                  // onClick={() => {
                  //   console.log(errors);
                  // }}
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={!isValid ? true : false}
                />
              </ButtonWrapper>
            </Wrapper2>
          </>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => ({
  getVariationCodesLoading: state.data.getVariationCodesLoading,
  getVariationCodesSuccess: state.data.getVariationCodesSuccess,
  getVariationCodesError: state.data.getVariationCodesError,
});

const mapDispatch = (dispatch) => ({
  setGetSettings: () => dispatch(getSettings()),
  setGetDataVariationCodes: (data) => dispatch(getDataVariationCodes(data)),
});

export default connect(mapState, mapDispatch)(WaecPurchase);
