const ShortcutResponsive = {
  xlarge: {
    breakpoint: { min: 1600, max: 8000 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: 100,
  },
  large: {
    breakpoint: { min: 1340, max: 1599 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: 50,
  },
  large2: {
    breakpoint: { min: 840, max: 1339 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: 10,
  },
  large3: {
    breakpoint: { min: 768, max: 839 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: -10,
  },
  large4: {
    breakpoint: { min: 680, max: 767 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: 30,
  },
  large5: {
    breakpoint: { min: 630, max: 679 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: 10,
  },
  large6: {
    breakpoint: { min: 560, max: 629 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: -10,
  },
  large7: {
    breakpoint: { min: 500, max: 559 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: -30,
  },
  large8: {
    breakpoint: { min: 450, max: 499 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: 20,
  },
  large9: {
    breakpoint: { min: 405, max: 449 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: 0,
  },
  large10: {
    breakpoint: { min: 340, max: 404 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: -30,
  },
  large11: {
    breakpoint: { min: 100, max: 339 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.,
    partialVisibilityGutter: -50,
  },
};

export default ShortcutResponsive;
