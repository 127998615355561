/* eslint-disable no-useless-escape */
import * as yup from "yup";

const sellGiftcardNoticeSchema = yup.object().shape({
  giftcardType: yup.boolean().oneOf([true], "Required"),
  giftcardBrand: yup.boolean().oneOf([true], "Required"),
  currency: yup.boolean().oneOf([true], "Required"),
  amount: yup.boolean().oneOf([true], "Required"),
});

export default sellGiftcardNoticeSchema;
