import {
  GET_USER_PROFILE_LOADING,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  UPDATE_PROFILE_PICTURE_LOADING,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  UPDATE_PROFILE_PICTURE_ERROR,
  DELETE_PROFILE_PICTURE_LOADING,
  DELETE_PROFILE_PICTURE_SUCCESS,
  DELETE_PROFILE_PICTURE_ERROR,
  ADD_BANK_ACCOUNT_LOADING,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_ERROR,
  GET_USER_PROFILE_PICTURE_LOADING,
  GET_USER_PROFILE_PICTURE_SUCCESS,
  GET_USER_PROFILE_PICTURE_ERROR,
  GET_USER_LIMIT_LOADING,
  GET_USER_LIMIT_SUCCESS,
  GET_USER_LIMIT_ERROR,
  GET_2FA_STATUS_LOADING,
  GET_2FA_STATUS_SUCCESS,
  GET_2FA_STATUS_ERROR,
  TOGGLE_2FA_LOADING,
  TOGGLE_2FA_SUCCESS,
  TOGGLE_2FA_ERROR,
  GENERATE_2FA_CODE_LOADING,
  GENERATE_2FA_CODE_SUCCESS,
  GENERATE_2FA_CODE_ERROR,
  VERIFY_2FA_CODE_LOADING,
  VERIFY_2FA_CODE_SUCCESS,
  VERIFY_2FA_CODE_ERROR,
  PROVIDE_PASSWORD_LOADING,
  PROVIDE_PASSWORD_SUCCESS,
  PROVIDE_PASSWORD_ERROR,
  ENTER_OTP_LOADING,
  ENTER_OTP_SUCCESS,
  ENTER_OTP_ERROR,
  CREATE_NEW_PASSWORD_LOADING,
  CREATE_NEW_PASSWORD_SUCCESS,
  CREATE_NEW_PASSWORD_ERROR,
  CREATE_NEW_PIN_LOADING,
  CREATE_NEW_PIN_SUCCESS,
  CREATE_NEW_PIN_ERROR,
} from "./types";

const initialState = {
  getUserProfileLoading: null,
  getUserProfileSuccess: null,
  getUserProfileError: null,
  getUserProfilePictureLoading: null,
  getUserProfilePictureSuccess: null,
  getUserProfilePictureError: null,
  updateProfilePictureLoading: null,
  updateProfilePictureSuccess: null,
  updateProfilePictureError: null,
  deleteProfilePictureLoading: null,
  deleteProfilePictureSuccess: null,
  deleteProfilePictureError: null,
  addBankAccountLoading: null,
  addBankAccountError: null,
  addBankAccountSuccess: null,
  getUserLimitLoading: null,
  getUserLimitError: null,
  getUserLimitSuccess: null,
  get2faStatusLoading: null,
  get2faStatusError: null,
  get2faStatusSuccess: null,
  generate2faCodeLoading: null,
  generate2faCodeError: null,
  generate2faCodeSuccess: null,
  verify2faCodeLoading: null,
  verify2faCodeError: null,
  verify2faCodeSuccess: null,
  providePasswordLoading: null,
  providePasswordError: null,
  providePasswordSuccess: null,
  enterOTPLoading: null,
  enterOTPError: null,
  enterOTPSuccess: null,
  createNewPasswordLoading: null,
  createNewPasswordError: null,
  createNewPasswordSuccess: null,
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_PROFILE_LOADING:
      return { ...state, getUserProfileLoading: action.payload };
    case GET_USER_PROFILE_SUCCESS:
      return { ...state, getUserProfileSuccess: action.payload };
    case GET_USER_PROFILE_ERROR:
      return { ...state, getUserProfileError: action.payload };
    case GET_USER_PROFILE_PICTURE_LOADING:
      return { ...state, getUserProfilePictureLoading: action.payload };
    case GET_USER_PROFILE_PICTURE_SUCCESS:
      return { ...state, getUserProfilePictureSuccess: action.payload };
    case GET_USER_PROFILE_PICTURE_ERROR:
      return { ...state, getUserProfilePictureError: action.payload };
    case UPDATE_PROFILE_PICTURE_LOADING:
      return { ...state, updateProfilePictureLoading: action.payload };
    case UPDATE_PROFILE_PICTURE_SUCCESS:
      return { ...state, updateProfilePictureSuccess: action.payload };
    case UPDATE_PROFILE_PICTURE_ERROR:
      return { ...state, updateProfilePictureError: action.payload };
    case DELETE_PROFILE_PICTURE_LOADING:
      return { ...state, deleteProfilePictureLoading: action.payload };
    case DELETE_PROFILE_PICTURE_SUCCESS:
      return { ...state, deleteProfilePictureSuccess: action.payload };
    case DELETE_PROFILE_PICTURE_ERROR:
      return { ...state, deleteProfilePictureError: action.payload };
    case ADD_BANK_ACCOUNT_LOADING:
      return { ...state, addBankAccountLoading: action.payload };
    case ADD_BANK_ACCOUNT_SUCCESS:
      return { ...state, addBankAccountSuccess: action.payload };
    case ADD_BANK_ACCOUNT_ERROR:
      return { ...state, addBankAccountError: action.payload };
    case GET_USER_LIMIT_LOADING:
      return { ...state, getUserLimitLoading: action.payload };
    case GET_USER_LIMIT_SUCCESS:
      return { ...state, getUserLimitSuccess: action.payload };
    case GET_USER_LIMIT_ERROR:
      return { ...state, getUserLimitError: action.payload };
    case GET_2FA_STATUS_LOADING:
      return { ...state, get2faStatusLoading: action.payload };
    case GET_2FA_STATUS_SUCCESS:
      return { ...state, get2faStatusSuccess: action.payload };
    case GET_2FA_STATUS_ERROR:
      return { ...state, get2faStatusError: action.payload };
    case TOGGLE_2FA_LOADING:
      return { ...state, toggle2faLoading: action.payload };
    case TOGGLE_2FA_SUCCESS:
      return { ...state, toggle2faSuccess: action.payload };
    case TOGGLE_2FA_ERROR:
      return { ...state, toggle2faError: action.payload };
    case GENERATE_2FA_CODE_LOADING:
      return { ...state, generate2faCodeLoading: action.payload };
    case GENERATE_2FA_CODE_SUCCESS:
      return { ...state, generate2faCodeSuccess: action.payload };
    case GENERATE_2FA_CODE_ERROR:
      return { ...state, generate2faCodeError: action.payload };
    case VERIFY_2FA_CODE_LOADING:
      return { ...state, verify2faCodeLoading: action.payload };
    case VERIFY_2FA_CODE_SUCCESS:
      return { ...state, verify2faCodeSuccess: action.payload };
    case VERIFY_2FA_CODE_ERROR:
      return { ...state, verify2faCodeError: action.payload };
    case PROVIDE_PASSWORD_LOADING:
      return { ...state, providePasswordLoading: action.payload };
    case PROVIDE_PASSWORD_SUCCESS:
      return { ...state, providePasswordSuccess: action.payload };
    case PROVIDE_PASSWORD_ERROR:
      return { ...state, providePasswordError: action.payload };
    case ENTER_OTP_LOADING:
      return { ...state, enterOTPLoading: action.payload };
    case ENTER_OTP_SUCCESS:
      return { ...state, enterOTPSuccess: action.payload };
    case ENTER_OTP_ERROR:
      return { ...state, enterOTPError: action.payload };
    case CREATE_NEW_PASSWORD_LOADING:
      return { ...state, createNewPasswordLoading: action.payload };
    case CREATE_NEW_PASSWORD_SUCCESS:
      return { ...state, createNewPasswordSuccess: action.payload };
    case CREATE_NEW_PASSWORD_ERROR:
      return { ...state, createNewPasswordError: action.payload };
    case CREATE_NEW_PIN_LOADING:
      return { ...state, createNewPinLoading: action.payload };
    case CREATE_NEW_PIN_SUCCESS:
      return { ...state, createNewPinSuccess: action.payload };
    case CREATE_NEW_PIN_ERROR:
      return { ...state, createNewPinError: action.payload };
    default:
      return state;
  }
}
