import axios from "axios";
import {
  ALL_ADS_LOADING,
  ALL_ADS_SUCCESS,
  DASHBOARD_BALANCE_LOADING,
  DASHBOARD_BALANCE_SUCCESS,
  DASHBOARD_RECENT_TXN_LOADING,
  DASHBOARD_RECENT_TXN_SUCCESS,
  DASHBOARD_TXN_LIMIT_LOADING,
  DASHBOARD_TXN_LIMIT_SUCCESS,
} from "../reducers/types";
import { toast } from "react-toastify";
import headers from "./header";
import { apiBaseUrl } from "../../constants/url";

export const getUserBalance = () => async (dispatch) => {
  dispatch({ type: DASHBOARD_BALANCE_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/dashboard/get/balance/naira`,
      headers()
    );
    dispatch({ type: DASHBOARD_BALANCE_LOADING, payload: null });
    dispatch({ type: DASHBOARD_BALANCE_SUCCESS, payload: response.data });
    return;
  } catch (err) {
    dispatch({ type: DASHBOARD_BALANCE_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getUserTxnLimit = () => async (dispatch) => {
  dispatch({ type: DASHBOARD_TXN_LIMIT_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/dashboard/get/txn/limit`,
      headers()
    );
    dispatch({ type: DASHBOARD_TXN_LIMIT_LOADING, payload: null });
    dispatch({ type: DASHBOARD_TXN_LIMIT_SUCCESS, payload: response.data });
    return;
  } catch (err) {
    dispatch({ type: DASHBOARD_TXN_LIMIT_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getUserTxns = (page, limit) => async (dispatch) => {
  dispatch({ type: DASHBOARD_RECENT_TXN_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/dashboard/get/txns?${
        page && limit ? `page=${page}&limit=${limit}` : ""
      }`,
      headers()
    );
    dispatch({ type: DASHBOARD_RECENT_TXN_LOADING, payload: null });
    dispatch({ type: DASHBOARD_RECENT_TXN_SUCCESS, payload: response.data });
    return;
  } catch (err) {
    dispatch({ type: DASHBOARD_RECENT_TXN_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};

export const getAllAds = () => async (dispatch) => {
  dispatch({ type: ALL_ADS_LOADING, payload: true });
  try {
    const response = await axios.get(`${apiBaseUrl}/ads/all/public`, headers());
    dispatch({ type: ALL_ADS_LOADING, payload: null });
    dispatch({ type: ALL_ADS_SUCCESS, payload: response.data });
    return response;
  } catch (err) {
    dispatch({ type: ALL_ADS_LOADING, payload: null });
    return toast.error(err.response?.data?.message, {
      toastId: "Error",
    });
  }
};
