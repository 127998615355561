import {
  LOADING,
  USER_TYPE,
  BANK_LIST,
  ERROR,
  USER_SETUP_STATUS,
} from "./types";

const initialState = {
  loading: null,
  userType: null,
  userSetupStatus: null,
  bankList: null,
  error: null,
};

export default function setupReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case USER_TYPE:
      return { ...state, userType: action.payload };
    case USER_SETUP_STATUS:
      return { ...state, userSetupStatus: action.payload };
    case BANK_LIST:
      return { ...state, bankList: action.payload };
    case ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
