import {
  DEPOSIT_NAIRA_LOADING,
  DEPOSIT_NAIRA_SUCCESS,
  DEPOSIT_NAIRA_ERROR,
  USER_BANK_DETAIL_LIST_LOADING,
  USER_BANK_DETAIL_LIST_SUCCESS,
  USER_BANK_DETAIL_LIST_ERROR,
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
  CHECK_USER_INFO_LOADING,
  CHECK_USER_INFO_SUCCESS,
  CHECK_USER_INFO_ERROR,
  MAKE_WITHDRAWAL_LOADING,
  MAKE_WITHDRAWAL_SUCCESS,
  MAKE_WITHDRAWAL_ERROR,
  CHECK_SUFFICIENT_BALANCE_LOADING,
  CHECK_SUFFICIENT_BALANCE_SUCCESS,
  CHECK_SUFFICIENT_BALANCE_ERROR,
} from "./types";

const initialState = {
  depositNairaLoading: null,
  depositNairaSuccess: null,
  depositNairaError: null,
  userBankDetailListLoading: null,
  userBankDetailListSuccess: null,
  userBankDetailListError: null,
  getSettingsLoading: null,
  getSettingsSuccess: null,
  getSettingsError: null,
  checkUserInfoLoading: null,
  checkUserInfoSuccess: null,
  checkUserInfoError: null,
  makeWithdrawalLoading: null,
  makeWithdrawalSuccess: null,
  makeWithdrawalError: null,
  checkSufficientBalLoading: null,
  checkSufficientBalSuccess: null,
  checkSufficientBalError: null,
};

export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    case DEPOSIT_NAIRA_LOADING:
      return { ...state, depositNairaLoading: action.payload };
    case DEPOSIT_NAIRA_SUCCESS:
      return { ...state, depositNairaSuccess: action.payload };
    case DEPOSIT_NAIRA_ERROR:
      return { ...state, depositNairaError: action.payload };
    case USER_BANK_DETAIL_LIST_LOADING:
      return { ...state, userBankDetailListLoading: action.payload };
    case USER_BANK_DETAIL_LIST_SUCCESS:
      return { ...state, userBankDetailListSuccess: action.payload };
    case USER_BANK_DETAIL_LIST_ERROR:
      return { ...state, userBankDetailListError: action.payload };
    case GET_SETTINGS_LOADING:
      return { ...state, getSettingsLoading: action.payload };
    case GET_SETTINGS_SUCCESS:
      return { ...state, getSettingsSuccess: action.payload };
    case GET_SETTINGS_ERROR:
      return { ...state, getSettingsError: action.payload };
    case CHECK_USER_INFO_LOADING:
      return { ...state, checkUserInfoLoading: action.payload };
    case CHECK_USER_INFO_SUCCESS:
      return { ...state, checkUserInfoSuccess: action.payload };
    case CHECK_USER_INFO_ERROR:
      return { ...state, checkUserInfoError: action.payload };
    case MAKE_WITHDRAWAL_LOADING:
      return { ...state, makeWithdrawalLoading: action.payload };
    case MAKE_WITHDRAWAL_SUCCESS:
      return { ...state, makeWithdrawalSuccess: action.payload };
    case MAKE_WITHDRAWAL_ERROR:
      return { ...state, makeWithdrawalError: action.payload };
    case CHECK_SUFFICIENT_BALANCE_LOADING:
      return { ...state, checkSufficientBalLoading: action.payload };
    case CHECK_SUFFICIENT_BALANCE_SUCCESS:
      return { ...state, checkSufficientBalSuccess: action.payload };
    case CHECK_SUFFICIENT_BALANCE_ERROR:
      return { ...state, checkSufficientBalError: action.payload };
    default:
      return state;
  }
}
