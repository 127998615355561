import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import ElectricityPurchase from "./ElectricityPurchase";

const Wrapper = styled.div``;

const OuterWrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  max-width: 700px;
  width: 100%;
  /* padding: 1rem; */
  margin: 0 auto;
`;

const FormsWrapper = styled.div`
  padding: 2rem 2rem;

  @media only screen and (max-width: 600px) {
    padding: 1.5rem 1rem;
  }
`;

const Electricity = () => {
  return (
    <DashboardLayout backButton={true} backText="Buy Electricity">
      <Wrapper>
        <OuterWrapper>
          <FormsWrapper>
            <ElectricityPurchase />
          </FormsWrapper>
        </OuterWrapper>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Electricity;
