import React from "react";
import styled from "styled-components";
import ShortcutItemCmp from "../ShortcutItemCmp/ShortcutItemCmp";
import appTheme from "../../constants/theme";
import ShortcutList from "./ShortcutList";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ShortcutResponsive from "./ShortcutResponsive";
import "./ShortcutCmp.css";

const Wrapper = styled.div`
  padding: 1.5rem 0 1rem;
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 1rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  color: #323232;
`;

const ShortcutWrapper = styled.div`
  /* display: flex;
  width: 100%;
  gap: 10px; */
`;

const ShortcutCmp = () => {
  return (
    <>
      <Wrapper>
        <Title>Shortcuts</Title>
        <ShortcutWrapper>
          <Carousel
            swipeable={true}
            // centerMode={true}
            draggable={true}
            responsive={ShortcutResponsive}
            infinite={false}
            autoPlaySpeed={5000}
            autoPlay={false}
            partialVisbile={true}
            arrows={true}
            customTransition="transform 300ms ease-in-out"
            keyBoardControl={true}
            transitionDuration={500}
            removeArrowOnDeviceType={["mobile"]}
            sliderClass="dashboard-shortcut-slide"
            containerClass="dashboard-shortcut-slider-container"
          >
            {ShortcutList.map((v, i) => {
              return (
                <ShortcutItemCmp
                  key={i}
                  title={v.title}
                  desc={v.desc}
                  backColor={v.backColor}
                  iconText={v.iconText}
                  icon={v.icon}
                  path={v.path}
                />
              );
            })}
          </Carousel>
        </ShortcutWrapper>
      </Wrapper>
    </>
  );
};

export default ShortcutCmp;
