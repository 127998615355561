import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import JessieLogo from "../../assets/logo-color.svg";
import NairaIcon from "../../assets/naira.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Formik } from "formik";
import SolidButton from "../../components/ButtonCmp/Button";
import appTheme from "../../constants/theme";
import { Form } from "react-bootstrap";
import CustomPriceInputCmp from "../../components/InputsCmp/CustomPriceInputCmp";
import CustomMultiSelect from "../../components/InputsCmp/CustomMultiSelect";
import bankListWithIcon from "../../constants/bankListWithIcon";
import nairaDepositSchema from "../../validators/nairaDepositValidator";
import {
  getSettings,
  getUserBankAccountList,
} from "../../redux/actions/walletActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const OuterWrapper = styled.div`
  background: rgba(196, 196, 196, 0.05);
  border-radius: 20px;
  max-width: 700px;
  width: 100%;
  /* padding: 1rem; */
`;

const Wrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1rem;
  border-radius: 20px;
`;

const Logo = styled(LazyLoadImage)`
  width: 137px;
  height: 46px;
`;

const DescText = styled.p`
  font-weight: 400;
  font-size: 12px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  color: #323232;
  padding: 1rem 0;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  padding-top: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const NairaImg = styled.img``;

const SelectWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectText = styled.p`
  margin: 0;
  margin-bottom: 0.1rem;
  font-size: 14px;
`;

const NairaDeposit = ({
  setGetSettings,
  settingsSuccess,
  settingsLoading,
  settingsError,
  bankDetailListError,
  bankDetailListLoading,
  bankDetailListSuccess,
  setGetUserBankAccountList,
}) => {
  const navigate = useNavigate();
  const [settingsData, setSettingsData] = useState({});
  const [bankListData, setBankListData] = useState([""]);
  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetSettings();
      const bankRespData = await setGetUserBankAccountList();

      setSettingsData(response.data);
      setBankListData(bankRespData.data.bankDetail);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <DashboardLayout
        //  backButton={true}
        pageTitle="Deposit"
      >
        <Container>
          <OuterWrapper>
            <Wrapper>
              <Logo src={JessieLogo} effect="blur" />
              <DescText>
                You are about to make a P2P Deposit into your Jessiepay Account,
                Please select the Account you would be transferring from. Kindly
                note that for faster confirmation you are advised to make your
                deposit only from the account you selected below, As deposits
                from third party accounts would be delayed or returned
              </DescText>
              <Formik
                enableReinitialize={true}
                validationSchema={nairaDepositSchema}
                initialValues={{
                  bank: null,
                  amount: "",
                  charge: settingsData?.nairaWalletDepositFee,
                }}
                onSubmit={async (values, { resetForm }) => {
                  navigate("/wallets/deposit/confirm", {
                    state: { ...values, settingsData },
                  });
                  // resetForm();
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  setFieldTouched,
                  setFieldValue,
                  touched,
                }) => (
                  <>
                    <Wrapper2 noValidate onSubmit={handleSubmit}>
                      <FormWrapper>
                        <InputWrapper>
                          <InputLabel>Select Bank</InputLabel>
                          <CustomMultiSelect
                            name="bank"
                            placeholder="Select Account"
                            isMulti={false}
                            isSearchable={false}
                            options={[
                              ...bankListData.map((v, i) => {
                                return {
                                  ...v,
                                  value: v.bankName,
                                  label: v.bankName,
                                };
                              }),
                            ]}
                            formatOptionLabel={(label) => (
                              <>
                                <SelectWrapper>
                                  {label && label.label && (
                                    <img
                                      style={{ width: "50px" }}
                                      src={
                                        bankListWithIcon.find(
                                          (el) => el.name === label.label
                                        )?.logo
                                      }
                                      alt={label.label}
                                    />
                                  )}
                                  <div>
                                    <SelectText>{label.label}</SelectText>
                                    <SelectText>
                                      {label.accountNumber}
                                    </SelectText>
                                  </div>
                                </SelectWrapper>
                              </>
                            )}
                            message={`${errors["bank"] ? errors["bank"] : ""}`}
                            showMessage
                            value={values.bank}
                            isValid={isValid}
                            onBlur={setFieldTouched}
                            onChange={setFieldValue}
                            touched={touched}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel>Amount</InputLabel>
                          <CustomPriceInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            icon={
                              <>
                                <NairaImg src={NairaIcon} alt="Naira" />
                              </>
                            }
                            type="text"
                            name="amount"
                            placeholder="Enter Amount"
                            values={values}
                            onChange={setFieldValue}
                            message={`${
                              errors["amount"] ? errors["amount"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                      </FormWrapper>
                      <ButtonWrapper>
                        <SolidButton
                          text={"Continue"}
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          specifyPadding="0.8rem 1.5rem"
                          color="#fff"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          borderRadius="8px"
                          backColor="#1a8fdd"
                          backgroundHoverColor="#1a8fdd"
                          textHoverColor="#fff"
                          margin="0rem auto 0"
                          disabled={!isValid ? true : false}
                        />
                      </ButtonWrapper>
                    </Wrapper2>
                  </>
                )}
              </Formik>
            </Wrapper>
          </OuterWrapper>
        </Container>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  settingsLoading: state.wallet.getSettingsLoading,
  settingsSuccess: state.wallet.getSettingsSuccess,
  settingsError: state.wallet.getSettingsError,
  bankDetailListLoading: state.wallet.userBankDetailListLoading,
  bankDetailListSuccess: state.wallet.userBankDetailListSuccess,
  bankDetailListError: state.wallet.userBankDetailListError,
});

const mapDispatch = (dispatch) => ({
  setGetSettings: () => dispatch(getSettings()),
  setGetUserBankAccountList: () => dispatch(getUserBankAccountList()),
});

export default connect(mapState, mapDispatch)(NairaDeposit);
