export const searchThroughArrayOfObj = (arr, searchText) => {
  const matchingObjs = [];

  arr.forEach((obj) => {
    let match = false;

    // recursively search through the object's properties
    const searchProperties = (prop) => {
      if (
        typeof prop === "string" &&
        prop.toLowerCase().includes(searchText.toLowerCase())
      ) {
        match = true;
      } else if (Array.isArray(prop)) {
        prop.forEach((item) => searchProperties(item));
      } else if (typeof prop === "object" && prop !== null) {
        Object.values(prop).forEach((val) => searchProperties(val));
      }
    };

    // search through each property of the object
    Object.values(obj).forEach((val) => searchProperties(val));

    if (match) {
      matchingObjs.push(obj);
    }
  });

  return matchingObjs;
};
