import React, { useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import appTheme from "../../../constants/theme";
import JambPurchase from "./JambPurchase";
import WaecPurchase from "./WaecPurchase";

const Wrapper = styled.div``;

const OuterWrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  max-width: 700px;
  width: 100%;
  /* padding: 1rem; */
  margin: 0 auto;
`;

const TabsWrapper = styled.div`
  /* max-width: 400px; */
  border-radius: 12px 12px 0 0;
  background-color: rgba(225, 235, 245, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
  text-align: center;
`;

const Tab = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  /* flex-basis: 50%; */
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  border-radius: ${(props) =>
    props.activeTab === "Airtime" ? "24px 0 0 0" : "0 24px 0 0"};
`;

const Text = styled.h2`
  color: ${(props) => (props.active ? "#016cba" : "#6b7280")};
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.small};
  margin: 0;

  @media only screen and (max-width: 600px) {
    font-size: 13px;
  }
`;

const FormsWrapper = styled.div`
  padding: 1.5rem 2rem;

  @media only screen and (max-width: 600px) {
    padding: 1.5rem 1rem;
  }
`;

const Educational = () => {
  const [activeTab, setActiveTab] = useState("WAEC");
  return (
    <DashboardLayout backButton={true} backText="Buy Educational Payment">
      <Wrapper>
        <OuterWrapper>
          <TabsWrapper>
            <Tab
              activeTab={activeTab}
              active={activeTab === "WAEC" ? true : false}
              onClick={() => {
                setActiveTab("WAEC");
              }}
            >
              <Text active={activeTab === "WAEC" ? true : false}>WAEC</Text>
            </Tab>
            <Tab
              active={activeTab === "JAMB" ? true : false}
              activeTab={activeTab}
              onClick={() => {
                setActiveTab("JAMB");
              }}
            >
              <Text active={activeTab === "JAMB" ? true : false}>JAMB</Text>
            </Tab>
          </TabsWrapper>
          <FormsWrapper>
            {activeTab === "WAEC" && <WaecPurchase />}
            {activeTab === "JAMB" && <JambPurchase />}
          </FormsWrapper>
        </OuterWrapper>
      </Wrapper>
    </DashboardLayout>
  );
};

export default Educational;
