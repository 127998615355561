import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import {
  getUser2faStatus,
  toggle2fa,
} from "../../../redux/actions/settingActions";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ProfileWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

const TabTitle = styled.h2`
  font-weight: 700;
  font-size: 22px;
  color: #323232;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 0 0;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.h2`
  font-weight: 500;
  font-size: 15px;
  color: #323232;
`;

const SmallLabel = styled.p`
  color: #323232;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0;
  max-width: 230px;
  width: 100%;
`;

const ClickableText = styled.h2`
  color: #016cba;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
  text-align: left;
  max-width: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Security = ({
  getUser2faStatusLoading,
  getUser2faStatusSuccess,
  getUser2faStatusError,
  getToggle2faLoading,
  getToggle2faError,
  getToggle2faSuccess,
  setGetUser2faStatus,
  setToggle2fa,
}) => {
  const navigate = useNavigate();
  const [user2faStatus, setUser2faStatus] = useState();

  useEffect(() => {
    if (!getUser2faStatusSuccess) {
      async function callGetUser2fa() {
        const response = await setGetUser2faStatus();
        setUser2faStatus(response?.data);
      }

      callGetUser2fa();
    } else {
      setUser2faStatus(getUser2faStatusSuccess?.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser2faStatusSuccess]);

  return (
    <ProfileWrapper>
      <TabTitle>Preferences</TabTitle>
      <MainContent>
        <InnerWrapper>
          <div>
            <Label>Reset password</Label>
            <SmallLabel>
              Can't remember your password? Tap to reset password
            </SmallLabel>
          </div>
          <ClickableText
            onClick={() => {
              navigate("/settings/reset-password/provide");
            }}
          >
            Reset Password
          </ClickableText>
        </InnerWrapper>
        <InnerWrapper>
          <div>
            <Label>Transaction pin</Label>
            <SmallLabel>Create a secure pin for your transactions</SmallLabel>
          </div>
          <ClickableText
            onClick={() => {
              navigate("/settings/reset-pin/provide");
            }}
          >
            Reset Pin
          </ClickableText>
        </InnerWrapper>
        <InnerWrapper>
          <div>
            <Label>2FA</Label>
            <SmallLabel>
              Activate 2 factor authentication for extra security
            </SmallLabel>
          </div>
          <ClickableText>
            {user2faStatus?.twoFaEnabled ? "Deactivate" : "Activate"}
            <Form.Check
              type="switch"
              id="activate"
              name="activate"
              checked={user2faStatus?.twoFaEnabled}
              onChange={async (e) => {
                if (user2faStatus?.twoFaVerified === false) {
                  navigate("/settings/2fa/setup", { state: true });
                } else {
                  //call the toggle 2fa
                  await setToggle2fa({ status: e.target.checked });
                }
              }}
            />
          </ClickableText>
        </InnerWrapper>
      </MainContent>
    </ProfileWrapper>
  );
};

const mapState = (state) => ({
  getUser2faStatusLoading: state.setting.get2faStatusLoading,
  getUser2faStatusError: state.setting.get2faStatusError,
  getUser2faStatusSuccess: state.setting.get2faStatusSuccess,
  getToggle2faLoading: state.setting.toggle2faLoading,
  getToggle2faError: state.setting.toggle2faError,
  getToggle2faSuccess: state.setting.toggle2faSuccess,
});

const mapDispatch = (dispatch) => ({
  setGetUser2faStatus: () => dispatch(getUser2faStatus()),
  setToggle2fa: (data) => dispatch(toggle2fa(data)),
});

export default connect(mapState, mapDispatch)(Security);
