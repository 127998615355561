import GiftCardImg from "../../assets/giftcard.svg";
import AirtimeImg from "../../assets/airtime.svg";
import TvImg from "../../assets/tv-subscription.svg";
// import BitcoinImg from "../../assets/bitcoin.svg";
import ElectricityImg from "../../assets/electricity.svg";

const ShortcutList = [
  {
    title: "Sell Giftcards",
    desc: "Sell your giftcards at mouth watering rates",
    backColor: "rgba(26, 143, 221, 0.3)",
    iconText: "Giftcard",
    icon: `${GiftCardImg}`,
    path: "/products/giftcard/sell/international",
  },
  {
    title: "Buy Giftcards",
    desc: "Buy giftcards at mouth watering rates",
    backColor: "rgba(26, 143, 221, 0.3)",
    iconText: "Giftcard",
    icon: `${GiftCardImg}`,
    path: "/products/giftcard/buy/international",
  },
  {
    title: "Buy Airtime",
    desc: "Top up your airtime",
    backColor: "rgba(82, 67, 170, 0.3)",
    iconText: "Airtime",
    icon: `${AirtimeImg}`,
    path: "/products/renew/airtime-data-sub",
  },
  // {
  //   title: "Sell Bitcoin",
  //   desc: "Convert your bitcoin to instant cash",
  //   backColor: "rgba(251, 168, 46, 0.3)",
  //   iconText: "Bitcoin",
  //   icon: `${BitcoinImg}`,
  //   path: "/bitcoin",
  // },
  {
    title: "Electricity",
    desc: "Pay your electricity bills with ease",
    backColor: "rgba(12, 122, 147, 0.3)",
    iconText: "Electricity",
    icon: `${ElectricityImg}`,
    path: "/products/renew/electricity",
  },
  // {
  //   title: "Perfect Money",
  //   desc: "Make perfect money transcations",
  //   backColor: "rgba(222, 83, 72, 0.3)",
  //   iconText: "Perfect Money",
  //   icon: `${AirtimeImg}`,
  //   path: "/perfect-money",
  // },
  {
    title: "Tv Subscription",
    desc: "Tap to pay for tv subscription",
    backColor: "rgba(12, 58, 147, 0.3)",
    iconText: "Tv Subscription",
    icon: `${TvImg}`,
    path: "/products/renew/cable-internet-sub",
  },
  // {
  //   title: "Airtime to Cash",
  //   desc: "Convert your airtime to cash with ease",
  //   backColor: "rgba(48, 48, 48, 0.3)",
  //   iconText: "Airtime to Cash",
  //   icon: `${AirtimeImg}`,
  //   path: "/airtime-to-cash",
  // },
];

export default ShortcutList;
