import axios from "axios";
import { apiBaseUrl } from "../../constants/url";
import {
  BUY_EDUCATIONAL_ERROR,
  BUY_EDUCATIONAL_LOADING,
} from "../reducers/types";
import headers from "./header";
import { toast } from "react-toastify";

export const buyEducational = (data) => async (dispatch) => {
  dispatch({ type: BUY_EDUCATIONAL_LOADING, payload: true });
  dispatch({ type: BUY_EDUCATIONAL_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/education/renew`,
      data,
      headers()
    );
    dispatch({ type: BUY_EDUCATIONAL_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: BUY_EDUCATIONAL_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.amount}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getProfileIdDetails = async (data) => {
  try {
    const response = await axios.post(
      `${apiBaseUrl}/education/jamb/verify`,
      data,
      headers()
    );

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.serviceId}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
