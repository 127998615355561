import { configureStore } from "@reduxjs/toolkit";
import ThunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/rootReducer";
import { loadToken } from "./token";

const LoggerMiddleware = createLogger();

const persistedState = loadToken();

const middleware = [ThunkMiddleware];

if (process.env.REACT_APP_NODE_ENV === "development") {
  middleware.push(LoggerMiddleware);
}

// const ResetLoadingMiddleware =
//   ({ dispatch }) =>
//   (next) =>
//   (action) => {
//     dispatch({ type: LOADING, payload: false });

//     return next(action);
//   };

const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    auth: { authenticated: `${persistedState ? true : false}`, loading: null },
    setup: { loading: null },
  },
  middleware: [...middleware],
});

export default store;
