import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import SuccessModal from "../../../components/Modals/SuccessModal";
import P2PImg from "../../../assets/p2p.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const OuterWrapper = styled.div`
  background: rgba(196, 196, 196, 0.05);
  border-radius: 20px;
  max-width: 700px;
  width: 100%;
  /* padding: 1rem; */
`;

const Wrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1rem;
  border-radius: 20px;
`;

const DescText = styled.p`
  font-weight: 500;
  font-size: 20px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  color: #323232;
  padding: 1rem 0;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled.div`
  padding-top: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const BarWrapper = styled.div`
  border-radius: 8px;
  background-color: #fff;
  height: 60px;
  border: ${(props) =>
    props.type === props.selectedItem
      ? "1px solid #016cba"
      : "1px solid #ADB5BD"};
  padding: 1rem;
  cursor: pointer;
`;

const BarDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BarText = styled.p`
  color: #323232;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
`;

const ActionWrapper = styled.div`
  cursor: pointer;
`;

const InstantWrapper = styled.div`
  width: 75px;
  height: 30px;
  border: 1px solid #016cba;
  color: #fff;
  background-color: #016cba;
  opacity: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
`;

const ChooseWithdrawal = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const BarSelect = ({ type, text, onAction, onSelect }) => {
    return (
      <>
        <BarWrapper
          type={type}
          selectedItem={selectedItem}
          onClick={() => {
            onSelect();
          }}
        >
          <BarDiv>
            <BarText>{text}</BarText>
            <ActionWrapper
              onClick={(e) => {
                e.stopPropagation();
                onAction();
              }}
            >
              {type === "p2p" ? (
                <ArrowIosForwardOutline size={"20px"} color="#016cba" />
              ) : (
                <InstantWrapper>Instant</InstantWrapper>
              )}
            </ActionWrapper>
          </BarDiv>
        </BarWrapper>
      </>
    );
  };
  return (
    <>
      <DashboardLayout backButton={true} backText={"Back to Wallet"}>
        <Container>
          <OuterWrapper>
            <Wrapper>
              <DescText>Choose method of Withdrawal</DescText>
              <>
                <Wrapper2>
                  <FormWrapper>
                    <InputWrapper>
                      <BarSelect
                        text="Peer to Peer Withdrawal"
                        type="p2p"
                        onSelect={() => {
                          setSelectedItem("p2p");
                        }}
                        onAction={() => {
                          setShowModal(true);
                        }}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <BarSelect
                        onSelect={() => {
                          setSelectedItem("instant");
                        }}
                        text="Jessie User"
                        type="instant"
                      />
                    </InputWrapper>
                  </FormWrapper>
                  <ButtonWrapper>
                    <SolidButton
                      onClick={() => {
                        if (!selectedItem) {
                          return toast.error("Pick a withdrawal option");
                        } else {
                          if (selectedItem === "p2p") {
                            navigate("/wallets/withdrawal/p2p");
                          } else {
                            navigate("/wallets/withdrawal/instant");
                          }
                        }
                      }}
                      text={"Choose"}
                      type="submit"
                      weighty="500"
                      textFontSize="14px"
                      specifyPadding="0.8rem 1.5rem"
                      color="#fff"
                      widthWebkit="100%"
                      widthMoz="100%"
                      widthNormal="100%"
                      borderRadius="8px"
                      backColor="#1a8fdd"
                      backgroundHoverColor="#1a8fdd"
                      textHoverColor="#fff"
                      margin="0rem auto 0"
                    />
                  </ButtonWrapper>
                </Wrapper2>
              </>
            </Wrapper>
          </OuterWrapper>
        </Container>
        <SuccessModal
          heading="What is P2P Withdrawal?"
          title="P2P(Peer to Peer) Payments are safe and secure digital funds transactions between peer to peer using individual bank account "
          titleMaxWidth="300px"
          maxWidth="400px"
          showLogo={false}
          // wrapperPadding={"2rem 1rem 2rem"}
          successImgUrl={P2PImg}
          showCloseIcon={false}
          isOpen={showModal}
          buttonCloseText="Okay, Got it"
          onRequestClose={() => {
            setShowModal(false);
          }}
          actionButtonWidth="200px"
        />
      </DashboardLayout>
    </>
  );
};

export default ChooseWithdrawal;
