const DashboardStatResponsive = {
  large: {
    breakpoint: { max: 1082, min: 1010 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 250,
  },
  large2: {
    breakpoint: { max: 1009, min: 992 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 200,
  },
  large3: {
    breakpoint: { max: 991, min: 910 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 280,
  },

  large4: {
    breakpoint: { max: 909, min: 845 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 200,
  },

  large5: {
    breakpoint: { max: 844, min: 800 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 150,
  },
  small: {
    breakpoint: { max: 584, min: 545 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 110,
  },
  small2: {
    breakpoint: { max: 544, min: 530 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 90,
  },
  small3: {
    breakpoint: { max: 529, min: 470 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 60,
  },
  small4: {
    breakpoint: { max: 469, min: 450 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 45,
  },
  small5: {
    breakpoint: { max: 449, min: 420 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 20,
  },
  small6: {
    breakpoint: { max: 419, min: 390 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: -10,
  },
  small7: {
    breakpoint: { max: 389, min: 355 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: -5,
  },
  small8: {
    breakpoint: { max: 354, min: 350 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: -5,
  },
  small9: {
    breakpoint: { max: 349, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: -8,
  },
};

export default DashboardStatResponsive;
