import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { PlusCircle } from "@styled-icons/bootstrap";
import { DeleteBin } from "@styled-icons/remix-line";

const FileWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border: ${(props) =>
    props.message ? "1px solid red" : "1px dashed #016CBA"};
  /* background: #fff; */
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
  height: 252px;
  margin: 0 auto;
  background-image: ${(props) => props.bg && `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
`;

const PlaceholderTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
  margin: 0;
`;

const PlaceholderText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #323232;
  margin: 0;
`;

const ErrorMessageWrapper = styled.div`
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: 14px;
  font-size: 13px;
  padding: 0;
  padding-top: 0.5rem;
  max-width: 450px;
  margin: 0 auto;
`;

const IconWrapper = styled.div`
  padding-bottom: 1rem;
`;

const DeleteWrapper = styled.div`
  background: #c4c4c4;
  border-radius: 3px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 20px;
  position: absolute;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 1rem 0;
`;

const ThumbnailContainer = styled.div`
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
`;

const ThumbnailImg = styled.img`
  padding: 1rem 0.3rem;
  width: 50px;
  aspect-ratio: 2/3;
  background-size: contain;
`;

function CustomFileCmp(props) {
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (previewUrls.length > 0) {
      setActiveIndex(previewUrls.length - 1);
    }
  }, [previewUrls]);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: props.accept,
    maxSize: props.maxSize,
    maxFiles: props.maxFiles,
    onDrop: (acceptedFiles) => {
      const tempPreview = acceptedFiles.map((file, i) => {
        return { url: URL.createObjectURL(file), index: i };
      });
      setPreviewUrls([...previewUrls, ...tempPreview]);
      setFiles([...files, ...acceptedFiles]);
      props.setFieldValue("files", [...files, ...acceptedFiles]);
      setActiveIndex(acceptedFiles.length - 1);
      //   if (props.customFn) {
      //     props.customFn(acceptedFiles);
      //   }
      // console.log(acceptedFiles, props.message);
    },
  });
  return (
    <>
      <FileWrapper
        message={props.message}
        {...getRootProps({ className: "jessie-upload" })}
        onChange={props.onChange}
        bg={previewUrls.length > 0 && previewUrls[activeIndex]?.url}
      >
        {!previewUrls.length > 0 && (
          <>
            <IconWrapper>
              <PlusCircle color="#C4C4C4" size={50} />
            </IconWrapper>
            <PlaceholderWrapper>
              <PlaceholderTitle>Drag and drop an image here</PlaceholderTitle>
              <PlaceholderText>Or browse to choose a file</PlaceholderText>
            </PlaceholderWrapper>
            <input {...getInputProps()} />
          </>
        )}
        {previewUrls.length > 0 && (
          <DeleteWrapper
            onClick={(e) => {
              e.stopPropagation();
              setFiles(files.filter((e, i) => i !== activeIndex));
              URL.revokeObjectURL(previewUrls[activeIndex].url);
              setPreviewUrls(previewUrls.filter((e, i) => i !== activeIndex));
              props.setFieldValue(
                "files",
                files.filter((e, i) => i !== activeIndex)
              );
            }}
          >
            <DeleteBin size={10} color="#323232" />
          </DeleteWrapper>
        )}
      </FileWrapper>
      {previewUrls.length > 0 && (
        <ThumbnailWrapper>
          {previewUrls.map((v, i) => (
            <ThumbnailContainer
              key={i}
              onClick={() => {
                setActiveIndex(i);
              }}
            >
              <ThumbnailImg src={v.url} alt="image" />
            </ThumbnailContainer>
          ))}
          <ThumbnailContainer {...getRootProps({ className: "jessie-upload" })}>
            +
            <input {...getInputProps()} />
          </ThumbnailContainer>
        </ThumbnailWrapper>
      )}
      <ErrorMessageWrapper>
        <ErrorMessage>{props.showMessage ? props.message : ""}</ErrorMessage>
      </ErrorMessageWrapper>
    </>
  );
}

export default CustomFileCmp;
