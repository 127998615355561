import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import ProductSvg from "../../../assets/product-bg-svg.svg";
import BuyGiftCardSvg from "../../../assets/buy-giftcard.svg";
import SellGiftCardSvg from "../../../assets/sell-giftcard.svg";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div``;

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ProductCmpWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  flex: 0 0 32%;
  max-width: 32%;
  width: 100%;
  height: 156px;
  border-radius: 24px;
  background: ${(props) =>
    `url(${ProductSvg}),
    ${props.bgColor} no-repeat`};
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  @media only screen and (max-width: 1263px) {
    flex: 0 0 48%;
    max-width: 48%;
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

const ProductContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => `${props.textColor}`};
`;

const ProductTitle = styled.h2`
  font-weight: 700;
  font-size: 15px;
`;

const ProductDesc = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
`;

// const IconWrapper = styled.div`
//   width: 70px;
//   height: 70px;
//   background-color: #fff;
//   border-radius: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const IconImg = styled.img``;

const Giftcard = () => {
  const navigate = useNavigate();
  const ProductCmp = ({ bgColor, textColor, title, desc, iconImg, link }) => {
    return (
      <>
        <ProductCmpWrapper
          bgColor={bgColor}
          onClick={() => {
            navigate(link);
          }}
        >
          <ProductWrapper>
            <ProductContentWrapper textColor={textColor}>
              <ProductTitle>{title}</ProductTitle>
              <ProductDesc>{desc}</ProductDesc>
            </ProductContentWrapper>
            {/* <IconWrapper> */}
            <IconImg src={iconImg} alt={title} />
            {/* </IconWrapper> */}
          </ProductWrapper>
        </ProductCmpWrapper>
      </>
    );
  };
  const productsArr = [
    {
      title: "Buy Giftcard",
      desc: "Buy your desired Giftcards at mouth-watering rates.",
      bgColor: "rgba(0, 103, 143, 0.2)",
      textColor: "#323232",
      iconImg: `${BuyGiftCardSvg}`,
      link: "/products/giftcard/buy/international",
    },
    {
      title: "Sell Giftcard",
      desc: "Exchange your unused Giftcards for value the seamless way.",
      bgColor: "rgba(222, 83, 72, 0.2)",
      textColor: "#323232",
      iconImg: `${SellGiftCardSvg}`,
      link: "/products/giftcard/sell/international",
    },
  ];
  return (
    <>
      <DashboardLayout backButton={true} backText="Giftcard">
        <Wrapper>
          <ItemContainer>
            {productsArr.map((v, i) => {
              return (
                <>
                  <ProductCmp
                    key={i}
                    bgColor={v.bgColor}
                    iconImg={v.iconImg}
                    textColor={v.textColor}
                    title={v.title}
                    desc={v.desc}
                    link={v.link}
                  />
                </>
              );
            })}
          </ItemContainer>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Giftcard;
