import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import JessieLogo from "../../assets/logo-color.svg";
import AddPhoneForm from "../../components/FormsCmp/AddPhoneForm";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import EnvelopeSvg from "../../assets/envelope.svg";
import SuccessModal from "../../components/Modals/SuccessModal";

const AddPhoneWrapper = styled(Container)`
  background-color: ${appTheme.COLORS.white};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const LogoHolder = styled.div``;

const Logo = styled(LazyLoadImage)`
  width: 137px;
  height: 46px;
`;

const Title = styled.h2`
  color: #016cba;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 2rem;
  padding-top: 2rem;

  @media only screen and (max-width: 960px) {
    font-size: 1.5rem;
  }
`;

const Desc = styled.p`
  color: ${appTheme.COLORS.grey1};
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.small};
`;

const AddPhone = ({ loading, error, setVerifyEmail }) => {
  const { token } = useParams();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const navigate = useNavigate();

  const [verifyPhoneToken, setVerifyPhoneToken] = useState();
  // console.log(token);
  useEffect(() => {
    async function callVerifyEmail() {
      const response = await setVerifyEmail(token);
      // console.log(response, error);
      if (response && response.status === 200) {
        setVerifyPhoneToken(response.data.token);
        setShowSuccessModal(true);
        // return toast.success("Email verification successful", {
        //   toastId: "Email Success",
        // });
      } else {
        navigate("/login");
      }
      // if ((response && response.status !== 200) || error) {
      //   return toast.error("Email verification failed", {
      //     toastId: "Email Failed",
      //   });
      // }

      // if (response?.status !== 200) {
      //   navigate("/login");
      //   return toast.error(
      //     `${
      //       response?.message ||
      //       (error && error[0]?.token) ||
      //       "Email verification failed"
      //     }`,
      //     {
      //       toastId: "Email verification failed",
      //     }
      //   );
      // }
    }

    callVerifyEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AddPhoneWrapper fluid>
        <Wrapper>
          <LogoHolder>
            <Logo src={JessieLogo} effect="blur" />
          </LogoHolder>
          <Title>Almost there chief</Title>
          <Desc>Please add your phone number</Desc>
          <AddPhoneForm
            verifyPhoneToken={verifyPhoneToken && verifyPhoneToken}
          />
        </Wrapper>
        <SuccessModal
          title="Thank you, your email has been verified. You can continue 
        with the sign up process by setting up your phone number 
        and transaction pin"
          titleMaxWidth="300px"
          maxWidth="400px"
          showLogo={true}
          buttonCloseText={"Continue"}
          wrapperPadding={"1rem 1rem 2rem"}
          //   showCloseIcon={true}
          isOpen={showSuccessModal}
          heading={"Your email has been verified!"}
          successImgUrl={EnvelopeSvg}
          onRequestClose={() => {
            setShowSuccessModal(false);
          }}
        />
      </AddPhoneWrapper>
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatch = (dispatch) => ({
  setVerifyEmail: (token) => dispatch(verifyEmail(token)),
});

export default connect(mapState, mapDispatch)(AddPhone);
