/* eslint-disable no-useless-escape */
import * as yup from "yup";

const buyEducationalSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Type an amount")
    .test("Check minimum value", "Minimum of ₦100", function (value) {
      if (parseInt(this.parent.amount) < 100) {
        return false;
      }
      return true;
    })
    .strict(),
  email: yup.string().when("serviceId", {
    is: "waec-registeration" || "waec",
    then: yup
      .string()
      .email("It must be a valid email")
      .required("Provide an email"),
  }),
  quantity: yup.number().required().min(1, "Minimum of 1"),
  examType: yup.string().when("serviceId", {
    is: "waec-registeration" || "waec",
    then: yup.string().required("Select exam type"),
  }),
  billersCode: yup.string().when("serviceId", {
    is: "jamb",
    then: yup.string().required("Required"),
  }),
  variationCode: yup.string().required("Variation code is required"),
  serviceId: yup.string().required("Service id is required"),
  phone: yup
    .string()
    .test(
      "Check if it include country code",
      "Pls remove country code",
      async function (value) {
        if (value && value.includes("+")) {
          return false;
        }
        return true;
      }
    )
    .phone(undefined, undefined, "Invalid phone number")
    .required(),
});

export default buyEducationalSchema;
