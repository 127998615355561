import {
  DASHBOARD_BALANCE_ERROR,
  DASHBOARD_BALANCE_LOADING,
  DASHBOARD_BALANCE_SUCCESS,
  DASHBOARD_RECENT_TXN_ERROR,
  DASHBOARD_RECENT_TXN_LOADING,
  DASHBOARD_RECENT_TXN_SUCCESS,
  DASHBOARD_TXN_LIMIT_ERROR,
  DASHBOARD_TXN_LIMIT_LOADING,
  DASHBOARD_TXN_LIMIT_SUCCESS,
  ALL_ADS_LOADING,
  ALL_ADS_SUCCESS,
  ALL_ADS_ERROR,
} from "./types";

const initialState = {
  dashboardBalanceLoading: null,
  dashboardBalance: null,
  dashboardBalanceError: null,
  dashboardTxnLimitLoading: null,
  dashboardTxnLimit: null,
  dashboardTxnLimitError: null,
  dashboardRecentTxnLoading: null,
  dashboardRecentTxn: null,
  dashboardRecentTxnError: null,
  allAdsLoading: null,
  allAdsSuccess: null,
  allAdsError: null,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_BALANCE_LOADING:
      return { ...state, dashboardBalanceLoading: action.payload };
    case DASHBOARD_BALANCE_SUCCESS:
      return { ...state, dashboardBalance: action.payload };
    case DASHBOARD_BALANCE_ERROR:
      return { ...state, dashboardBalanceError: action.payload };
    case DASHBOARD_TXN_LIMIT_LOADING:
      return { ...state, dashboardTxnLimitLoading: action.payload };
    case DASHBOARD_TXN_LIMIT_SUCCESS:
      return { ...state, dashboardTxnLimit: action.payload };
    case DASHBOARD_TXN_LIMIT_ERROR:
      return { ...state, dashboardTxnLimitError: action.payload };
    case DASHBOARD_RECENT_TXN_LOADING:
      return { ...state, dashboardRecentTxnLoading: action.payload };
    case DASHBOARD_RECENT_TXN_SUCCESS:
      return { ...state, dashboardRecentTxn: action.payload };
    case DASHBOARD_RECENT_TXN_ERROR:
      return { ...state, dashboardRecentTxnError: action.payload };
    case ALL_ADS_LOADING:
      return { ...state, allAdsLoading: action.payload };
    case ALL_ADS_SUCCESS:
      return { ...state, allAdsSuccess: action.payload };
    case ALL_ADS_ERROR:
      return { ...state, allAdsError: action.payload };
    default:
      return state;
  }
}
