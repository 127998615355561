import {
  GET_ALL_GIFTCARDS_BUY_LOADING,
  GET_ALL_GIFTCARDS_BUY_SUCCESS,
  GET_ALL_GIFTCARDS_BUY_ERROR,
  GET_A_GIFTCARD_BUY_LOADING,
  GET_A_GIFTCARD_BUY_SUCCESS,
  GET_A_GIFTCARD_BUY_ERROR,
  GIFTCARD_BUY_LOADING,
  GIFTCARD_BUY_SUCCESS,
  GIFTCARD_BUY_ERROR,
  GET_ALL_GIFTCARDS_SELL_LOADING,
  GET_ALL_GIFTCARDS_SELL_SUCCESS,
  GET_ALL_GIFTCARDS_SELL_ERROR,
  GET_A_GIFTCARD_SELL_LOADING,
  GET_A_GIFTCARD_SELL_SUCCESS,
  GET_A_GIFTCARD_SELL_ERROR,
  GIFTCARD_SELL_LOADING,
  GIFTCARD_SELL_SUCCESS,
  GIFTCARD_SELL_ERROR,
} from "./types";

const initialState = {
  getAllGiftcardsBuyLoading: null,
  getAllGiftcardsBuySuccess: null,
  getAllGiftcardsBuyError: null,
  getAGiftcardBuyLoading: null,
  getAGiftcardBuyError: null,
  getAGiftcardBuySuccess: null,
  giftcardBuyLoading: null,
  giftcardBuyError: null,
  giftcardBuySuccess: null,
  getAllGiftcardsSellLoading: null,
  getAllGiftcardsSellSuccess: null,
  getAllGiftcardsSellError: null,
  getAGiftcardSellLoading: null,
  getAGiftcardSellError: null,
  getAGiftcardSellSuccess: null,
  giftcardSellLoading: null,
  giftcardSellError: null,
  giftcardSellSuccess: null,
};

export default function giftcardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GIFTCARDS_BUY_LOADING:
      return { ...state, getAllGiftcardsBuyLoading: action.payload };
    case GET_ALL_GIFTCARDS_BUY_SUCCESS:
      return { ...state, getAllGiftcardsBuySuccess: action.payload };
    case GET_ALL_GIFTCARDS_BUY_ERROR:
      return { ...state, getAllGiftcardsBuyError: action.payload };
    case GET_A_GIFTCARD_BUY_LOADING:
      return { ...state, getAGiftcardBuyLoading: action.payload };
    case GET_A_GIFTCARD_BUY_SUCCESS:
      return { ...state, getAGiftcardBuySuccess: action.payload };
    case GET_A_GIFTCARD_BUY_ERROR:
      return { ...state, getAGiftcardBuyError: action.payload };
    case GIFTCARD_BUY_LOADING:
      return { ...state, giftcardBuyLoading: action.payload };
    case GIFTCARD_BUY_SUCCESS:
      return { ...state, giftcardBuySuccess: action.payload };
    case GIFTCARD_BUY_ERROR:
      return { ...state, giftcardBuyError: action.payload };
    case GET_ALL_GIFTCARDS_SELL_LOADING:
      return { ...state, getAllGiftcardsSellLoading: action.payload };
    case GET_ALL_GIFTCARDS_SELL_SUCCESS:
      return { ...state, getAllGiftcardsSellSuccess: action.payload };
    case GET_ALL_GIFTCARDS_SELL_ERROR:
      return { ...state, getAllGiftcardsSellError: action.payload };
    case GET_A_GIFTCARD_SELL_LOADING:
      return { ...state, getAGiftcardSellLoading: action.payload };
    case GET_A_GIFTCARD_SELL_SUCCESS:
      return { ...state, getAGiftcardSellSuccess: action.payload };
    case GET_A_GIFTCARD_SELL_ERROR:
      return { ...state, getAGiftcardSellError: action.payload };
    case GIFTCARD_SELL_LOADING:
      return { ...state, giftcardSellLoading: action.payload };
    case GIFTCARD_SELL_SUCCESS:
      return { ...state, giftcardSellSuccess: action.payload };
    case GIFTCARD_SELL_ERROR:
      return { ...state, giftcardSellError: action.payload };
    default:
      return state;
  }
}
