import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import CustomInputSelectCmp from "../InputsCmp/CustomInputSelectCmp";
import { Lock } from "@styled-icons/boxicons-regular";
import appTheme from "../../constants/theme";
import SolidButton from "../ButtonCmp/Button";
import { Formik } from "formik";
import CustomPhoneInputCmp from "../InputsCmp/CustomPhoneInputCmp";
import addPhoneSchema from "../../validators/addPhoneValidator";
import { countries, countryCodeSwap } from "../../constants/countries";
import { getCountryCallingCode } from "react-phone-number-input";
import { parsePhoneNumber } from "awesome-phonenumber";
import VerifyPhoneModal from "../Modals/VerifyPhoneModal";
import { addPhone } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import { toast } from "react-toastify";

const Wrapper = styled(Form)`
  padding: 0.5rem 1rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputWrapper = styled.div``;

const TextWrapper = styled.div`
  padding-top: 0.5rem;
`;

const Text = styled.p`
  padding-top: 0.5rem;
  color: ${appTheme.COLORS.grey1};
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.small};
  text-align: center;
`;

const ButtonWrapper = styled.div``;

const AddPhoneForm = ({ loading, error, setAddPhone, verifyPhoneToken }) => {
  const [phoneModal, setPhoneModal] = useState(false);
  const [phoneNo, setPhoneNo] = useState();
  return (
    <>
      <Formik
        validationSchema={addPhoneSchema}
        initialValues={{
          country: "Nigeria",
          phone: "",
          phoneCode: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          // console.log(values);
          const fullPhoneNo = parsePhoneNumber(
            `+${getCountryCallingCode(countryCodeSwap[`${values.country}`])}${
              values.phone
            }`
          ).getNumber("e164");
          setPhoneNo(fullPhoneNo);
          const response = await setAddPhone(
            {
              country: values.country,
              phone: fullPhoneNo,
            },
            verifyPhoneToken
          );

          if (
            response &&
            response.status === 201 &&
            response.message === "Code sent to phone number"
          ) {
            toast.success(response.message, { toastId: "Code sent" });
            return setPhoneModal(true);
          }
          // console.log(response);

          // resetForm();
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <>
            <Wrapper noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <CustomInputSelectCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    name="country"
                    values={values}
                    onChange={handleChange}
                    placeholder="Select country"
                    data={countries.map((v) => v.country)}
                    message={`${errors["country"] ? errors["country"] : ""}`}
                    showMessage={false}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomPhoneInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Lock size={30} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    name="phone"
                    placeholder="Phone Number"
                    values={values}
                    phoneCode={`+${getCountryCallingCode(
                      countryCodeSwap[`${values.country}`]
                    )}`}
                    flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      countryCodeSwap[`${values.country}`]
                    }.svg`}
                    onChange={handleChange}
                    message={`${errors["phone"] ? errors["phone"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
              </FormWrapper>
              <TextWrapper>
                <Text>
                  A confirmation code will be sent to the provided number
                </Text>
              </TextWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Send Code"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={loading ? true : false}
                />
              </ButtonWrapper>
            </Wrapper>
            <VerifyPhoneModal
              isOpen={phoneModal}
              phoneNo={phoneNo && phoneNo}
              onRequestClose={async () => {
                setPhoneModal(false);
              }}
            />
          </>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatch = (dispatch) => ({
  setAddPhone: (data, token) => dispatch(addPhone(data, token)),
});

export default connect(mapState, mapDispatch)(AddPhoneForm);
