import {
  BUY_ELECTRICITY_LOADING,
  BUY_ELECTRICITY_SUCCESS,
  BUY_ELECTRICITY_ERROR,
  VERIFY_METER_NUMBER_LOADING,
  VERIFY_METER_NUMBER_SUCCESS,
  VERIFY_METER_NUMBER_ERROR,
} from "./types";

const initialState = {
  buyElectricityLoading: null,
  buyElectricitySuccess: null,
  buyElectricityError: null,
  verifyMeterNumberLoading: null,
  verifyMeterNumberError: null,
  verifyMeterNumberSuccess: null,
};

export default function electricityReducer(state = initialState, action) {
  switch (action.type) {
    case BUY_ELECTRICITY_LOADING:
      return { ...state, buyElectricityLoading: action.payload };
    case BUY_ELECTRICITY_SUCCESS:
      return { ...state, buyElectricitySuccess: action.payload };
    case BUY_ELECTRICITY_ERROR:
      return { ...state, buyElectricityError: action.payload };
    case VERIFY_METER_NUMBER_LOADING:
      return { ...state, verifyMeterNumberLoading: action.payload };
    case VERIFY_METER_NUMBER_SUCCESS:
      return { ...state, verifyMeterNumberSuccess: action.payload };
    case VERIFY_METER_NUMBER_ERROR:
      return { ...state, verifyMeterNumberError: action.payload };
    default:
      return state;
  }
}
