import { Formik } from "formik";
import React from "react";
import styled from "styled-components";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import PlaceholderImg from "../../../assets/placeholder.svg";
import SolidButton from "../../../components/ButtonCmp/Button";
import {
  deleteProfilePicture,
  getUserProfile,
  getUserProfilePicture,
  updateProfilePicture,
} from "../../../redux/actions/settingActions";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import appTheme from "../../../constants/theme";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { GET_USER_PROFILE_PICTURE_SUCCESS } from "../../../redux/reducers/types";

const ProfileWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

const TabTitle = styled.h2`
  font-weight: 700;
  font-size: 22px;
  color: #323232;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.h2`
  font-weight: 500;
  font-size: 15px;
  color: #323232;
`;

const InputWrapper = styled.div`
  width: 100%;
  max-width: 300px;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PictureWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 300px;
  width: 100%;
`;

const ProfileImage = styled.img`
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
`;

const Profile = ({
  setGetUserProfile,
  getUserProfileSuccess,
  getUserProfileError,
  getUserProfileLoading,
  setUpdateProfilePicture,
  getUpdateProfilePictureSuccess,
  getUpdateProfilePictureError,
  getUpdateProfilePictureLoading,
  setDeleteProfilePicture,
  deleteProfilePictureError,
  deleteProfilePictureLoading,
  deleteProfilePictureSuccess,
}) => {
  const [userProfile, setUserProfile] = useState();
  const [files, setFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!getUserProfileSuccess) {
      async function callGetUserProfile() {
        const response = await setGetUserProfile();
        setUserProfile(response);
      }

      callGetUserProfile();
    } else {
      setUserProfile(getUserProfileSuccess);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxSize: 1000000,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      const tempPreview = acceptedFiles.map((file, i) => {
        return { url: URL.createObjectURL(file), index: i };
      });
      setPreviewUrl(tempPreview[0]?.url);
      setFiles([...files, ...acceptedFiles]);
    },
  });
  return (
    <>
      {getUserProfileLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SpinnerCmp
            enabled={true}
            color={appTheme.COLORS.bluePrimary}
            size={40}
            secondaryColor={appTheme.COLORS.white}
          />
        </div>
      ) : (
        <ProfileWrapper>
          <TabTitle>Profile</TabTitle>
          <MainContent>
            <InnerWrapper>
              <div></div>
              <PictureWrapper style={{ textAlign: "left" }}>
                <div {...getRootProps({ className: "jessie-profile-upload" })}>
                  <ProfileImage
                    src={
                      previewUrl
                        ? previewUrl
                        : userProfile?.data?.profilePic
                        ? userProfile?.data?.profilePic?.url
                        : PlaceholderImg
                    }
                    alt="Placeholder"
                  />
                  <input {...getInputProps()} />
                </div>
                <SolidButton
                  height="40px"
                  text={
                    getUpdateProfilePictureLoading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Upload picture"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="10px"
                  specifyPadding="0"
                  color="#fff"
                  widthWebkit="120px"
                  widthMoz="120px"
                  widthNormal="120px"
                  borderRadius="8px"
                  backColor="#0896C6"
                  backgroundHoverColor="#0896C6"
                  textHoverColor="#fff"
                  margin="0rem"
                  disabled={getUpdateProfilePictureLoading ? true : false}
                  onClick={async () => {
                    if (!files.length) {
                      return toast.error(
                        "Pls select a picture by tapping the picture icon"
                      );
                    }
                    let formData = new FormData();
                    formData.append("image", files[0], files[0].name);
                    const response = await setUpdateProfilePicture(formData);
                    if (
                      response.message === "Profile pic uploaded successfully"
                    ) {
                      dispatch({
                        type: GET_USER_PROFILE_PICTURE_SUCCESS,
                        payload: null,
                      });
                      dispatch(getUserProfilePicture());
                      toast.success("Profile picture upload successful");
                    }
                  }}
                />
                <SolidButton
                  height="40px"
                  text={
                    deleteProfilePictureLoading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Delete"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="10px"
                  specifyPadding="0"
                  color="#8793AB"
                  widthWebkit="90px"
                  widthMoz="90px"
                  widthNormal="90px"
                  borderRadius="8px"
                  backColor="#F4F7FD"
                  backgroundHoverColor="#F4F7FD"
                  textHoverColor="#8793AB"
                  margin="0rem"
                  disabled={deleteProfilePictureLoading ? true : false}
                  onClick={async () => {
                    const response = await setDeleteProfilePicture();
                    if (
                      response.message === "Profile pic deleted successfully"
                    ) {
                      setPreviewUrl(`${PlaceholderImg}`);
                      dispatch({
                        type: GET_USER_PROFILE_PICTURE_SUCCESS,
                        payload: null,
                      });
                      toast.success("Profile picture deleted successfully");
                    }
                  }}
                />
              </PictureWrapper>
            </InnerWrapper>
            <Formik
              enableReinitialize={true}
              initialValues={{
                username:
                  userProfile?.data?.userType === "individual"
                    ? userProfile?.data?.username
                    : userProfile?.data?.businessName,
                phone: userProfile?.data?.phone,
                email: userProfile?.data?.email,
              }}
              onSubmit={async (values, { resetForm }) => {
                return;
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <>
                  <FormWrapper noValidate onSubmit={handleSubmit}>
                    <InnerWrapper>
                      <Label>Username</Label>
                      <InputWrapper>
                        <CustomInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="username"
                          placeholder="Enter Username"
                          values={values}
                          onChange={handleChange}
                          message={`${
                            errors["username"] ? errors["username"] : ""
                          }`}
                          showMessage={false}
                          disabled={true}
                        />
                      </InputWrapper>
                    </InnerWrapper>
                    <InnerWrapper>
                      <Label>Email</Label>
                      <InputWrapper>
                        <CustomInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="email"
                          placeholder="Enter email"
                          values={values}
                          onChange={handleChange}
                          message={`${errors["email"] ? errors["email"] : ""}`}
                          showMessage={false}
                          disabled={true}
                        />
                      </InputWrapper>
                    </InnerWrapper>
                    <InnerWrapper>
                      <Label>Phone Number</Label>
                      <InputWrapper>
                        <CustomInputCmp
                          background="rgba(225, 235, 245, 0.1)"
                          required={true}
                          type="text"
                          name="phone"
                          placeholder="Enter Phone Number"
                          values={values}
                          onChange={handleChange}
                          message={`${errors["phone"] ? errors["phone"] : ""}`}
                          showMessage={false}
                          disabled={true}
                        />
                      </InputWrapper>
                    </InnerWrapper>
                  </FormWrapper>
                </>
              )}
            </Formik>
          </MainContent>
        </ProfileWrapper>
      )}
    </>
  );
};

const mapState = (state) => ({
  getUserProfileLoading: state.setting.getUserProfileLoading,
  getUserProfileError: state.setting.getUserProfileError,
  getUserProfileSuccess: state.setting.getUserProfileSuccess,
  getUpdateProfilePictureLoading: state.setting.updateProfilePictureLoading,
  getUpdateProfilePictureError: state.setting.updateProfilePictureError,
  getUpdateProfilePictureSuccess: state.setting.updateProfilePicture,
  deleteProfilePictureLoading: state.setting.deleteProfilePictureLoading,
  deleteProfilePictureError: state.setting.deleteProfilePictureError,
  deleteProfilePictureSuccess: state.setting.deleteProfilePicture,
});

const mapDispatch = (dispatch) => ({
  setGetUserProfile: () => dispatch(getUserProfile()),
  setUpdateProfilePicture: (data) => dispatch(updateProfilePicture(data)),
  setDeleteProfilePicture: () => dispatch(deleteProfilePicture()),
});

export default connect(mapState, mapDispatch)(Profile);
