import styled from "styled-components";
import { Form } from "react-bootstrap";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import SolidButton from "../../../components/ButtonCmp/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import PinModal from "../../../components/Modals/PinModal";
import { connect } from "react-redux";
import { makeWithdrawal } from "../../../redux/actions/walletActions";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterWrapper = styled.div`
  background: rgba(196, 196, 196, 0.05);
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  /* padding: 1rem; */
`;

const Wrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1.5rem;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 500px;
`;

const DescWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

const DescText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #016cba;
  margin-bottom: 0;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  padding-top: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const BarWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 8px;
  background-color: #f4f7fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const BarTitle = styled.h2`
  font-weight: 400;
  color: #323232;
  font-size: 14px;
  margin-bottom: 0;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 1rem;
`;

const DetailText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #323232;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Detail = styled.div``;

const DetailWrapperText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
`;

const TitleText = styled.h2`
  font-weight: 700;
  color: #323232;
  font-size: 14px;
  margin-bottom: 0;
`;

const DescText2 = styled.p`
  margin-bottom: 0;
  font-weight: 300;
  font-size: 14px;
  color: #000;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #adb5bd;
  background-color: #adb5bd;
  margin: 0;
`;

const ConfirmWithdrawal = ({
  makeWithdrawalLoading,
  makeWithdrawalSuccess,
  makeWithdrawalError,
  setMakeWithdrawal,
}) => {
  const { state } = useLocation();
  const [showPinModal, setShowPinModal] = useState(false);
  const [pin, setPin] = useState("");
  const navigate = useNavigate();
  console.log(state);

  useEffect(() => {
    if (!state) {
      navigate("/wallets/withdrawal/choose");
      return toast.error("Select a withdrawal option", {
        toastId: "Select a withdrawal option",
      });
    }
  }, [state, navigate]);
  const BarCmp = (props) => {
    return (
      <>
        <BarWrapper>
          <BarTitle>
            {props.title}
            <span style={{ fontWeight: "600" }}>{props.value}</span>
          </BarTitle>
        </BarWrapper>
      </>
    );
  };
  return (
    <>
      <DashboardLayout backButton={true} backText={"Back to Wallet"}>
        <Container>
          <OuterWrapper>
            <Wrapper>
              <DescWrapper>
                <DescText>Transaction Details</DescText>
              </DescWrapper>
              <DetailContainer>
                <BarCmp
                  title="Transaction date: "
                  value={new Date().toDateString()}
                />
                <DetailText>Details</DetailText>
                {state.withdrawalType === "p2p" ? (
                  <DetailWrapper>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Account Name:</TitleText>
                        <DescText2>{state?.bank?.accountName}</DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Account Number:</TitleText>
                        <DescText2>{state?.bank?.accountNumber}</DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Bank Name:</TitleText>
                        <DescText2>{state?.bank?.bankName}</DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Amount:</TitleText>
                        <DescText2>
                          <NumberFormat
                            thousandSeparator
                            value={state?.amount}
                            decimalScale={2}
                            prefix="₦"
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Transaction:</TitleText>
                        <DescText2>Withdrawal</DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Withdrawal Fee:</TitleText>
                        <DescText2>
                          <NumberFormat
                            thousandSeparator
                            value={state?.charge}
                            decimalScale={2}
                            prefix="₦"
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Deducted From Wallet:</TitleText>
                        <DescText2>
                          <NumberFormat
                            thousandSeparator
                            value={
                              parseFloat(state?.charge) +
                              parseFloat(state?.amount)
                            }
                            decimalScale={2}
                            prefix="₦"
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Narration:</TitleText>
                        <DescText2>
                          {state?.narration ? state?.narration : "----"}
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                  </DetailWrapper>
                ) : (
                  <DetailWrapper>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Beneficiary Name:</TitleText>
                        <DescText2>{state?.receiverName}</DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Amount:</TitleText>
                        <DescText2>
                          <NumberFormat
                            thousandSeparator
                            value={state?.amount}
                            decimalScale={2}
                            prefix="₦"
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Transaction:</TitleText>
                        <DescText2>Transfer</DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Transfer Fee:</TitleText>
                        <DescText2>
                          <NumberFormat
                            thousandSeparator
                            value={state?.charge}
                            decimalScale={2}
                            prefix="₦"
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Deducted From Wallet:</TitleText>
                        <DescText2>
                          <NumberFormat
                            thousandSeparator
                            value={
                              parseFloat(state?.charge) +
                              parseFloat(state?.amount)
                            }
                            decimalScale={2}
                            prefix="₦"
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                    <Detail>
                      <Line />
                      <DetailWrapperText>
                        <TitleText>Narration:</TitleText>
                        <DescText2>
                          {state?.narration ? state?.narration : "----"}
                        </DescText2>
                      </DetailWrapperText>
                    </Detail>
                  </DetailWrapper>
                )}
              </DetailContainer>
              <Wrapper2>
                <ButtonWrapper>
                  <SolidButton
                    text="Proceed"
                    type="submit"
                    weighty="500"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPinModal(true);
                    }}
                    textFontSize="14px"
                    specifyPadding="0.8rem 1.5rem"
                    color="#fff"
                    widthWebkit="100%"
                    widthMoz="100%"
                    widthNormal="100%"
                    borderRadius="8px"
                    backColor="#1a8fdd"
                    backgroundHoverColor="#1a8fdd"
                    textHoverColor="#fff"
                    margin="0rem auto 0"
                  />
                </ButtonWrapper>
              </Wrapper2>
            </Wrapper>
          </OuterWrapper>
        </Container>
      </DashboardLayout>
      <PinModal
        isOpen={showPinModal}
        onRequestClose={() => {
          setShowPinModal(false);
        }}
        setPin={setPin}
        loading={makeWithdrawalLoading}
        txnAction={async () => {
          let data;
          if (state.withdrawalType === "p2p") {
            const { bankName, bankCode, accountName, accountNumber } =
              state?.bank;
            data = {
              bankName,
              bankCode,
              accountName,
              accountNumber,
              withdrawalType: state?.withdrawalType,
              charge: state?.charge,
              amount: state?.amount,
              pin,
            };
          } else {
            data = {
              amount: state?.amount,
              charge: state?.charge,
              pin,
              withdrawalType: state?.withdrawalType,
              receiverName: state?.receiverName,
              receiverPhone: state?.receiverPhone,
              identifier: state?.identifier,
            };
          }
          const response = await setMakeWithdrawal(data);
          if (
            response &&
            response.message === "Withdrawal created successfully"
          ) {
            setShowPinModal(false);
            navigate("/dashboard");
            toast.success("Withdrawal successful", {
              toastId: "withdrawal successful",
            });
          } else {
            setShowPinModal(false);
          }
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  makeWithdrawalLoading: state.wallet.makeWithdrawalLoading,
  makeWithdrawalSuccess: state.wallet.makeWithdrawalSuccess,
  makeWithdrawalError: state.wallet.makeWithdrawalError,
});

const mapDispatch = (dispatch) => ({
  setMakeWithdrawal: (data) => dispatch(makeWithdrawal(data)),
});

export default connect(mapState, mapDispatch)(ConfirmWithdrawal);
