import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import JessieLogo from "../../assets/logo-color.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SidebarItem from "./SidebarItem";
import sidebarData from "./sidebarData";
import { CloseOutline } from "@styled-icons/evaicons-outline";

const SidebarWrapper = styled.div`
  flex: 0 0 17%;
  background-color: ${appTheme.COLORS.white};
  height: 100vh;
  position: fixed;
  max-width: 17%;
  width: 100%;
  top: 0;
  z-index: 1070;

  @media only screen and (max-width: 991px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
    left: -300px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: display 0.3s ease all;
    transition: display 0.3s ease all;
    max-width: 30%;
    flex: 0 0 30%;

    &.open {
      -webkit-transform: translateX(300px);
      transform: translateX(300px);
      display: unset;
    }
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 45%;
    max-width: 45%;
  }

  @media only screen and (max-width: 500px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (max-width: 400px) {
    flex: 0 0 60%;
    max-width: 60%;
  }
`;

const LogoWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    padding-top: 1rem;
    padding-right: 1rem;
    text-align: right;
  }
`;

const CloseIconWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 767px) {
    display: unset;
  }
`;

const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  margin: 1rem 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const LogoImg = styled(LazyLoadImage)`
  width: 137px;
  height: 46px;
`;

const SidebarContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 3.75rem);
  padding-bottom: 2rem;

  @media only screen and (max-width: 767px) {
    padding-top: 2rem;
  }
`;

const SidebarCmp = ({ menuToggle, handleToggleOnMobile }) => {
  return (
    <>
      <SidebarWrapper className={`${menuToggle === true && "open"}`}>
        <LogoWrapper>
          <CloseIconWrapper>
            <CloseIcon
              size={20}
              color="#323232"
              onClick={() => {
                handleToggleOnMobile(!menuToggle);
              }}
            />
          </CloseIconWrapper>
          <LogoContainer>
            <LogoImg src={JessieLogo} alt="Jessie" effect="blur" />
          </LogoContainer>
        </LogoWrapper>
        <SidebarContainer>
          {sidebarData.map((item, index) => {
            return <SidebarItem key={index} item={item} />;
          })}
        </SidebarContainer>
      </SidebarWrapper>
    </>
  );
};

export default SidebarCmp;
