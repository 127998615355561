import React from "react";
import BalanceCmp from "../BalanceCmp/BalanceCmp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import DashboardStatResponsive from "./DashboardStatResponsive";
import "./DashboardStatCmp.css";
import TxnLimitCmp from "../TxnLimitCmp/TxnLimitCmp";

const ContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 1082px) {
    display: unset;
  }
`;

const ContainerDesktop = styled.div`
  display: flex;
  gap: 15px;
  @media only screen and (max-width: 1082px) {
    display: none;
  }
`;

const DashboardStatCmp = () => {
  return (
    <>
      <ContainerMobile>
        <Carousel
          swipeable={true}
          // centerMode={true}
          draggable={true}
          responsive={DashboardStatResponsive}
          infinite={false}
          autoPlaySpeed={5000}
          autoPlay={false}
          partialVisbile={true}
          arrows={false}
          customTransition="transform 300ms ease-in-out"
          keyBoardControl={true}
          transitionDuration={500}
          removeArrowOnDeviceType={["mobile"]}
          sliderClass="dashboard-stat-slide"
          //   itemClass="carousel-library-item"
        >
          <BalanceCmp />
          <TxnLimitCmp />
        </Carousel>
      </ContainerMobile>

      <ContainerDesktop>
        <BalanceCmp />
        <TxnLimitCmp />
      </ContainerDesktop>
    </>
  );
};

export default DashboardStatCmp;
