import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthTemplateCmp from "../../components/AuthTemplateCmp/AuthTemplateCmp";
import LoginForm from "../../components/FormsCmp/LoginForm";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Text = styled.p`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small2};
  font-size: 14px;
  padding-top: 0rem;
  text-align: center;
`;

const Span = styled.span`
  color: #1a8fdd;
  cursor: pointer;
`;

const Login = () => {
  const navigate = useNavigate();

  const Content = () => {
    return (
      <>
        <Wrapper>
          <LoginForm />
          <Text>
            Don't have an account?{" "}
            <Span
              onClick={() => {
                navigate("/get-started");
              }}
            >
              Sign up
            </Span>
          </Text>
        </Wrapper>
      </>
    );
  };
  return (
    <>
      <AuthTemplateCmp
        pageTitle="Welcome Back!"
        pageDesc="Continue to your awesome account."
        content={<Content />}
        mobilePadding={"2rem 1rem 2rem"}
      />
    </>
  );
};

export default Login;
