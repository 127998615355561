import axios from "axios";
import { apiBaseUrl } from "../../constants/url";
import headers from "./header";
import { toast } from "react-toastify";
import {
  GET_ALL_GIFTCARDS_BUY_LOADING,
  GET_ALL_GIFTCARDS_SELL_LOADING,
  GET_A_GIFTCARD_BUY_LOADING,
  GET_A_GIFTCARD_SELL_LOADING,
  GIFTCARD_BUY_ERROR,
  GIFTCARD_BUY_LOADING,
  GIFTCARD_SELL_ERROR,
  GIFTCARD_SELL_LOADING,
} from "../reducers/types";

export const getAllGiftcardsBuy = () => async (dispatch) => {
  dispatch({ type: GET_ALL_GIFTCARDS_BUY_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/all/buy`,
      headers()
    );

    dispatch({ type: GET_ALL_GIFTCARDS_BUY_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: GET_ALL_GIFTCARDS_BUY_LOADING, payload: null });
    return toast.error(err.response?.data?.message);
  }
};

export const getAGiftcardBuy = (brandId) => async (dispatch) => {
  dispatch({ type: GET_A_GIFTCARD_BUY_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/get/buy/${brandId}`,
      headers()
    );

    dispatch({ type: GET_A_GIFTCARD_BUY_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: GET_A_GIFTCARD_BUY_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.brandId}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const buyGiftcard = (data) => async (dispatch) => {
  dispatch({ type: GIFTCARD_BUY_LOADING, payload: true });
  dispatch({ type: GIFTCARD_BUY_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/giftcard/buy`,
      data,
      headers()
    );
    dispatch({ type: GIFTCARD_BUY_LOADING, payload: null });

    if (
      response.data.status !== 200 &&
      response?.data?.message !== "Insufficient balance"
    ) {
      return toast.error(response.data.message);
    } else if (response?.data?.message === "Insufficient balance") {
      dispatch({ type: GIFTCARD_BUY_ERROR, payload: "Insufficient balance" });
    }
    return response.data;
  } catch (err) {
    dispatch({ type: GIFTCARD_BUY_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.amount}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getAllGiftcardsSell = () => async (dispatch) => {
  dispatch({ type: GET_ALL_GIFTCARDS_SELL_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/all/sell`,
      headers()
    );

    dispatch({ type: GET_ALL_GIFTCARDS_SELL_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: GET_ALL_GIFTCARDS_SELL_LOADING, payload: null });
    return toast.error(err.response?.data?.message);
  }
};

export const getAGiftcardSell = (brandId) => async (dispatch) => {
  dispatch({ type: GET_A_GIFTCARD_SELL_LOADING, payload: true });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/giftcard/get/sell/${brandId}`,
      headers()
    );

    dispatch({ type: GET_A_GIFTCARD_SELL_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: GET_A_GIFTCARD_SELL_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.brandId}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const sellGiftcard = (data) => async (dispatch) => {
  dispatch({ type: GIFTCARD_SELL_LOADING, payload: true });
  dispatch({ type: GIFTCARD_SELL_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/giftcard/sell`,
      data,
      headers()
    );
    dispatch({ type: GIFTCARD_SELL_LOADING, payload: null });

    // if (
    //   response.data.status !== 200 &&
    //   response?.data?.message !== "Insufficient balance"
    // ) {
    //   return toast.error(response.data.message);
    // } else if (response?.data?.message === "Insufficient balance") {
    //   dispatch({ type: GIFTCARD_SELL_ERROR, payload: "Insufficient balance" });
    // }
    return response.data;
  } catch (err) {
    dispatch({ type: GIFTCARD_SELL_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.amount}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
