import React from "react";
import styled from "styled-components";
import SolidButton from "../ButtonCmp/Button";
import ModalCmp from "../ModalCmp/ModalCmp";
import HourGlassImage from "../../assets/hourglass.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  width: 100%;
  padding: 3rem 0;
  margin: 0 auto;
`;

const PayText = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  color: #323232;
`;

const DescText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #323232;
  text-align: center;
  max-width: 265px;
  width: 100%;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  max-width: 300px;
  width: 100%;
  justify-content: center;
`;

const Image = styled.img``;

const InProgressModal = (props) => {
  return (
    <ModalCmp
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentStyles={{ maxWidth: props.maxWidth || "350px" }}
      shouldFocusAfterRender={false}
      contentLabel="Success"
      id="success"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      showCloseIcon={props.showCloseIcon}
    >
      <Wrapper>
        <Image src={HourGlassImage} alt="error" />
        <PayText>Transaction in Progress!</PayText>
        <DescText>
          This will take a moment. You will be notified once successful
        </DescText>
        <BtnWrapper>
          <SolidButton
            text="Finish"
            type="submit"
            weighty="500"
            onClick={() => {
              props.finishFn();
            }}
            textFontSize="14px"
            specifyPadding="0.8rem 1.5rem"
            color="#fff"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            borderRadius="8px"
            backColor="#016CBA"
            backgroundHoverColor="#016CBA"
            textHoverColor="#fff"
            margin="0rem auto 0"
          />
        </BtnWrapper>
      </Wrapper>
    </ModalCmp>
  );
};

export default InProgressModal;
