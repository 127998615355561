import {
  VALIDATE_2FA_LOADING,
  VALIDATE_2FA_SUCCESS,
  VALIDATE_2FA_ERROR,
  VALIDATE_2FA_ENABLED,
} from "./types";

const initialState = {
  validate2FaLoading: null,
  validate2FaSuccess: null,
  validate2FaError: null,
  validate2FaEnabled: null,
};

export default function twoFaReducer(state = initialState, action) {
  switch (action.type) {
    case VALIDATE_2FA_LOADING:
      return { ...state, validate2FaLoading: action.payload };
    case VALIDATE_2FA_SUCCESS:
      return { ...state, validate2FaSuccess: action.payload };
    case VALIDATE_2FA_ERROR:
      return { ...state, validate2FaError: action.payload };
    case VALIDATE_2FA_ENABLED:
      return { ...state, validate2FaEnabled: true };
    default:
      return state;
  }
}
