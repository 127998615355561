import React, { useEffect } from "react";
import DashboardLayout from "../../../../components/DashboardLayout/DashboardLayout";
import styled from "styled-components";
import SolidButton from "../../../../components/ButtonCmp/Button";
import CustomPinInputCmp from "../../../../components/InputsCmp/CustomPinInputCmp";
import { useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { createNewPin } from "../../../../redux/actions/settingActions";
import { connect } from "react-redux";
import SpinnerCmp from "../../../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../../../constants/theme";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  @media only screen and (max-width: 767px) {
    padding: 1rem 0;
  }
`;

const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  border-radius: 24px;
  background: #fff;
  box-shadow: 1px 1px 17px 0px rgba(218, 216, 216, 0.33);
  padding: 3rem 1.5rem;

  @media only screen and (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleText = styled.h2`
  color: #323232;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
`;

const SmallText = styled.p`
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
  max-width: 362px;
  width: 100%;
`;

const PinGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PinWrapper = styled.div``;

const PinText = styled.h2`
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #323232;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  padding-top: 0.8rem;
  text-align: right;
`;

const EnterNewPin = ({
  setCreateNewPin,
  getCreateNewPinError,
  getCreateNewPinLoading,
  getCreateNewPinSuccess,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state && !location.state?.token) {
      navigate("/settings");
    }
  }, [location.state, navigate]);

  const [pinDetails, setPinDetails] = useState({});
  const handlePinComplete = (value, index) => {
    setPinDetails({ ...pinDetails, pin: value });
  };
  const handleResetPinComplete = (value, index) => {
    setPinDetails({ ...pinDetails, confirmPin: value });
  };

  const handleSubmit = async () => {
    const { pin, confirmPin } = pinDetails;
    if ((pin && pin.length !== 6) || (confirmPin && confirmPin.length !== 6)) {
      return toast.error("Pin digit not complete");
    }
    if (pin !== confirmPin) {
      return toast.error("Pins do not match");
    }

    const response = await setCreateNewPin({
      pin,
      confirmPin,
      createNewPinToken: location.state?.token,
    });

    if (
      response &&
      response.status === 200 &&
      response.message === "Transaction pin set successfully"
    ) {
      toast.success(response.message, {
        toastId: "Transaction pin set successfully",
      });
      navigate("/settings");
    }
  };
  return (
    <>
      <DashboardLayout backButton={true}>
        <Container>
          <Wrapper>
            <TextWrapper>
              <TitleText>Setup your transaction pin</TitleText>
              <SmallText>
                This new pin will now be used to authorize all transactions
              </SmallText>
            </TextWrapper>
            <PinGroup>
              <PinWrapper>
                <PinText>Input pin</PinText>
                <CustomPinInputCmp
                  length={6}
                  autoSelect={true}
                  secret={true}
                  initialValue=""
                  onChange={handlePinComplete}
                  onComplete={handlePinComplete}
                  //   disabled={disableBtn === false ? true : false}
                />
              </PinWrapper>
              <PinWrapper>
                <PinText>Verify pin</PinText>
                <CustomPinInputCmp
                  length={6}
                  autoSelect={true}
                  secret={true}
                  initialValue=""
                  onChange={handleResetPinComplete}
                  onComplete={handleResetPinComplete}
                  //   disabled={disableBtn === false ? true : false}
                />
              </PinWrapper>
            </PinGroup>
            <ButtonWrapper>
              <SolidButton
                onClick={handleSubmit}
                text={
                  getCreateNewPinLoading ? (
                    <>
                      <SpinnerCmp
                        enabled={true}
                        color={appTheme.COLORS.bluePrimary}
                        size={20}
                        secondaryColor={appTheme.COLORS.white}
                      />
                    </>
                  ) : (
                    "Submit"
                  )
                }
                type="submit"
                weighty="500"
                textFontSize="14px"
                specifyPadding="0.8rem 1.5rem"
                color="#fff"
                widthWebkit="107px"
                widthMoz="107px"
                widthNormal="107px"
                borderRadius="6px"
                backColor="#1a8fdd"
                backgroundHoverColor="#1a8fdd"
                textHoverColor="#fff"
                margin="0rem auto 0"
                disabled={getCreateNewPinLoading ? true : false}
              />
            </ButtonWrapper>
          </Wrapper>
        </Container>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  getCreateNewPinLoading: state.setting.createNewPinLoading,
  getCreateNewPinSuccess: state.setting.createNewPinSuccess,
  getCreateNewPinError: state.setting.createNewPinError,
});

const mapDispatch = (dispatch) => ({
  setCreateNewPin: (data) => dispatch(createNewPin(data)),
});

export default connect(mapState, mapDispatch)(EnterNewPin);
