import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";
import {
  BANK_LIST,
  ERROR,
  LOADING,
  USER_SETUP_STATUS,
  USER_TYPE,
} from "../reducers/types";
import headers from "./header";

export const getUserSetupStatus = () => async (dispatch) => {
  // dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/setup/user/status`,
      headers()
    );
    dispatch({ type: LOADING, payload: null });
    dispatch({ type: USER_SETUP_STATUS, payload: response.data });
    return;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    return toast.error(`There is an error`, { toastId: "There is an error" });
  }
};

export const getAccountType = () => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/setup/user/type`,
      headers()
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message, {
        toastId: "Error",
      });
    }

    dispatch({ type: USER_TYPE, payload: response.data.data.type });
    return;
    // return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.type}`, {
        toastId: "Validation error",
      });
    } else {
      return toast.error(`There is an error`, { toastId: "There is an error" });
    }
  }
};

export const getListOfBanks = () => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/setup/banks/list`,
      headers()
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message, {
        toastId: "Error",
      });
    }
    dispatch({ type: BANK_LIST, payload: response.data.data });
    return;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.data}`, {
        toastId: "Validation error",
      });
    } else {
      return toast.error(`There is an error`, { toastId: "There is an error" });
    }
  }
};

export const getAccountNumberInfo = async (bankCode, accountNumber) => {
  try {
    const response = await axios.get(
      `${apiBaseUrl}/setup/account-number/info?bankCode=${bankCode}&accountNumber=${accountNumber}`,
      headers()
    );
    if (response.data.status !== 200) {
      return toast.error(response.data.message, {
        toastId: "Error",
      });
    }
    return response.data.data;
  } catch (err) {
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.data}`, {
        toastId: "Validation error",
      });
    } else {
      return toast.error(`There is an error`, {
        toastId: "There is an error",
      });
    }
  }
};

export const fillBankDetails = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/setup/bank`,
      data,
      headers()
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.accountNumber}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const addTxnPin = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/setup/pin/set`,
      data,
      headers()
    );
    dispatch({ type: LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.pin}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
