import { Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import SolidButton from "../../../components/ButtonCmp/Button";
import {
  getUserProfile,
  addBankAccount,
} from "../../../redux/actions/settingActions";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import { connect } from "react-redux";
import appTheme from "../../../constants/theme";
import { toast } from "react-toastify";
import BankDetailsCard from "./BankDetailsCard";
import bankListWithIcon from "../../../constants/bankListWithIcon";
import { ArrowBackCircle } from "@styled-icons/ionicons-outline";
import CustomInputSelectCmp from "../../../components/InputsCmp/CustomInputSelectCmp";
import {
  getAccountNumberInfo,
  getListOfBanks,
} from "../../../redux/actions/setupActions";
import addBankInProfileSchema from "../../../validators/addBankInProfileValidator";

const ProfileWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
`;

const TabTitle = styled.h2`
  font-weight: 700;
  font-size: 22px;
  color: #323232;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 0 0;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.h2`
  font-weight: 500;
  font-size: 15px;
  color: #323232;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 320px;
  width: 100%;
`;

const AccountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AddNewAccount = styled.h2`
  font-weight: 700;
  font-size: 18px;
  color: #016cba;
  text-align: left;
  cursor: pointer;
`;

const NoteWrapper = styled.div`
  background: rgba(244, 247, 253, 0.5);
  border-radius: 6px;
  padding: 1.5rem;
  font-weight: 300;
  font-size: 15px;
  color: #323232;
`;

const BackWrapper = styled.div`
  padding: 1rem 0 0;
  cursor: pointer;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputWrapper = styled.div`
  width: 100%;
  max-width: 300px;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  text-align: right;
`;

const Bank = ({
  setGetUserProfile,
  getUserProfileSuccess,
  getUserProfileError,
  getUserProfileLoading,
  bankList,
  setGetBankList,
  setAddBankAccount,
  addBankAccountLoading,
  addBankAccountError,
  addBankAccountSuccess,
}) => {
  const [userProfile, setUserProfile] = useState();
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (!getUserProfileSuccess) {
      async function callGetUserProfile() {
        const response = await setGetUserProfile();
        setUserProfile(response);
      }

      callGetUserProfile();
    } else {
      setUserProfile(getUserProfileSuccess);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function callGetBankList() {
      await setGetBankList();
    }

    callGetBankList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchAccountDetails = (props) => {
    const {
      values: { accountNumber, bankCode },
      setFieldValue,
    } = useFormikContext();

    async function getAccount() {
      const response = await getAccountNumberInfo(bankCode, accountNumber);
      return response;
    }

    useEffect(() => {
      async function fetchAccountName() {
        if (
          accountNumber &&
          accountNumber.length === 10 &&
          bankCode &&
          bankCode.length > 0
        ) {
          const response = await getAccount();
          setFieldValue("accountName", response.accountName);
        } else {
          setFieldValue("accountName", "");
        }
      }

      fetchAccountName();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountNumber, bankCode, setFieldValue]);

    return (
      <>
        <CustomInputCmp
          background="rgba(225, 235, 245, 0.1)"
          required={true}
          type="text"
          name="accountName"
          placeholder="Account Name"
          disabled={true}
          value={props.value}
          onChange={props.handleChange}
          message={props.error}
          showMessage={false}
        />
      </>
    );
  };
  return (
    <>
      {getUserProfileLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SpinnerCmp
            enabled={true}
            color={appTheme.COLORS.bluePrimary}
            size={40}
            secondaryColor={appTheme.COLORS.white}
          />
        </div>
      ) : (
        <ProfileWrapper>
          <TabTitle>Bank Information</TabTitle>
          {activePage !== 1 && (
            <BackWrapper>
              <ArrowBackCircle
                size={30}
                color="#323232"
                onClick={() => {
                  setActivePage(1);
                }}
              />
            </BackWrapper>
          )}
          <MainContent>
            <InnerWrapper>
              <Label>Account details</Label>
              {activePage === 1 ? (
                <RightWrapper>
                  <AccountsWrapper>
                    {userProfile?.data?.bank?.length > 0 &&
                      userProfile?.data?.bank?.map((v) => {
                        return (
                          <BankDetailsCard
                            icon={
                              bankListWithIcon.find(
                                (el) => el.code === v.bankCode
                              )?.logo
                            }
                            accountName={v.accountName}
                            accountNumber={v.accountNumber}
                            bankName={v.bankName}
                          />
                        );
                      })}
                  </AccountsWrapper>
                  <AddNewAccount
                    onClick={() => {
                      setActivePage(2);
                    }}
                  >
                    Add another account
                  </AddNewAccount>
                  <NoteWrapper>
                    If you need to change your saved bank account, Kindly reach
                    out to{" "}
                    <span style={{ color: "#016CBA" }}>
                      support@jessiepay.co
                    </span>
                  </NoteWrapper>
                </RightWrapper>
              ) : (
                <Formik
                  validationSchema={addBankInProfileSchema}
                  initialValues={{
                    accountNumber: "",
                    accountName: "",
                    bankName: "",
                    bankCode: "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    // resetForm();

                    const response = await setAddBankAccount({
                      ...values,
                    });

                    if (
                      response &&
                      response.status === 200 &&
                      response.message === "Bank details added successfully"
                    ) {
                      setActivePage(1);
                      const response = await setGetUserProfile();
                      setUserProfile(response);
                      return toast.success("Bank details added successfully");
                    }
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors }) => (
                    <>
                      <FormWrapper noValidate onSubmit={handleSubmit}>
                        <InputWrapper>
                          <CustomInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            type="text"
                            name="accountNumber"
                            placeholder="Enter Account Number"
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["accountNumber"]
                                ? errors["accountNumber"]
                                : ""
                            }`}
                            showMessage={false}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <CustomInputSelectCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            name="bankName"
                            values={values}
                            onChange={handleChange}
                            placeholder="Select Bank"
                            data={
                              bankList &&
                              bankList.length > 0 &&
                              bankList.map((v, i) => {
                                return v.name;
                              })
                            }
                            message={`${
                              errors["bankName"] ? errors["bankName"] : ""
                            }`}
                            showMessage={false}
                          />
                        </InputWrapper>
                        <InputWrapper style={{ display: "none" }}>
                          <CustomInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            type="text"
                            name="bankCode"
                            placeholder="Bank Code"
                            disabled={true}
                            value={
                              (values.bankCode =
                                bankList &&
                                bankList.length > 0 &&
                                bankList.filter((obj) => {
                                  return obj.name === values.bankName;
                                })[0]?.code)
                            }
                            onChange={handleChange}
                            message={`${
                              errors["bankCode"] ? errors["bankCode"] : ""
                            }`}
                            showMessage={false}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <FetchAccountDetails
                            error={`${
                              errors["accountName"] ? errors["accountName"] : ""
                            }`}
                            handleChange={handleChange}
                            value={values.accountName}
                          />
                        </InputWrapper>
                        <ButtonWrapper>
                          <SolidButton
                            text={
                              addBankAccountLoading ? (
                                <>
                                  <SpinnerCmp
                                    enabled={true}
                                    color={appTheme.COLORS.bluePrimary}
                                    size={20}
                                    secondaryColor={appTheme.COLORS.white}
                                  />
                                </>
                              ) : (
                                "Add Account"
                              )
                            }
                            type="submit"
                            weighty="500"
                            textFontSize="14px"
                            specifyPadding="1rem 1rem"
                            color="#fff"
                            widthWebkit="100%"
                            widthMoz="100%"
                            widthNormal="100%"
                            maxWidth="120px"
                            borderRadius="8px"
                            backColor="#1a8fdd"
                            backgroundHoverColor="#1a8fdd"
                            textHoverColor="#fff"
                            margin="0rem auto 0"
                            disabled={addBankAccountLoading ? true : false}
                          />
                        </ButtonWrapper>
                      </FormWrapper>
                    </>
                  )}
                </Formik>
              )}
            </InnerWrapper>
          </MainContent>
        </ProfileWrapper>
      )}
    </>
  );
};

const mapState = (state) => ({
  getUserProfileLoading: state.setting.getUserProfileLoading,
  getUserProfileError: state.setting.getUserProfileError,
  getUserProfileSuccess: state.setting.getUserProfileSuccess,
  addBankAccountLoading: state.setting.addBankAccountLoading,
  addBankAccountError: state.setting.addBankAccountError,
  addBankAccountSuccess: state.setting.addBankAccountSuccess,
  bankList: state.setup.bankList,
});

const mapDispatch = (dispatch) => ({
  setGetUserProfile: () => dispatch(getUserProfile()),
  setGetBankList: () => dispatch(getListOfBanks()),
  setAddBankAccount: (data) => dispatch(addBankAccount(data)),
});

export default connect(mapState, mapDispatch)(Bank);
