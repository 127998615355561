import React from "react";
import NumericInput from "react-numeric-input";

const NumericInputCmp = ({ onChange, value }) => {
  return (
    <NumericInput
      className="jessie-quantity-selector"
      name="jessie-quantity-selector"
      value={value}
      min={0}
      step={1}
      size={4}
      precision={0}
      inputMode="numeric"
      strict
      mobile
      style={{
        wrap: { height: "34px" },
        input: {
          height: "34px",
          border: "none",
          backgroundColor: "transparent",
          width: "110px",
        },
        "input:not(.form-control)": { border: "none" },
        "input:focus": { border: "none", outline: "none" },
        "btnUp.mobile": {
          background: "#E3E5E5",
          borderRadius: "4px",
          padding: "15px",
          border: "none",
          cursor: "pointer",
          height: "16px",
          width: "30px",
        },
        "btnDown.mobile": {
          background: "#E3E5E5",
          borderRadius: "4px",
          padding: "15px",
          border: "none",
          cursor: "pointer",
          height: "16px",
          width: "30px",
        },
        plus: { background: "#01525F" },
        minus: { background: "#01525F" },
      }}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );
};

export default NumericInputCmp;
