import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowBackCircle } from "@styled-icons/ionicons-outline";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import StepperCmp from "../../../components/StepperCmp/StepperCmp";
import NumberFormat from "react-number-format";
import SolidButton from "../../../components/ButtonCmp/Button";
import { getUserBalance } from "../../../redux/actions/dashboardAction";
import { connect } from "react-redux";
import VerifyPayModal from "../../../components/Modals/VerifyPayModal";
import PinModal from "../../../components/Modals/PinModal";
import { buyGiftcard } from "../../../redux/actions/giftcardActions";
import InsufficientBalModal from "../../../components/Modals/InsufficientBalModal";
import InProgressModal from "../../../components/Modals/InProgressModal";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const BackWrapper = styled.div`
  padding: 1rem 0 0;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
`;

const WalletBalanceWrapper = styled.div`
  background: #f2f8fc;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  width: 100%;
  margin-top: 1rem;
`;

const WalletBalText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  color: #646464;
`;

const WalletBalAmount = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 28px;
  color: #323232;
`;

const TransactionWrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1.5rem;
  border-radius: 20px;
  margin: 0 auto;
  width: 100%;
`;

const DescWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

const DescText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #016cba;
  margin-bottom: 0;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const BarWrapper = styled.div`
  height: 60px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(1, 108, 186, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const BarTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  color: #c4c4c4;
  margin-bottom: 0;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const BarSpan = styled.p`
  font-weight: 700;
  color: #323232;
  font-size: 20px;
  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 1rem;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Detail = styled.div``;

const DetailWrapperText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0;
`;

const TitleText = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: #323232;
  margin-bottom: 0;
`;

const DescText2 = styled.p`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #323232;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #adb5bd;
  background-color: #adb5bd;
  margin: 0;
`;

const Wrapper2 = styled.div``;

const CardValueTableHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0;
`;

const CardValueContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0;
`;

const BuyGiftcardSummary = ({
  dashboardBalanceLoading,
  dashboardBalance,
  dashboardBalanceError,
  setGetUserBalance,
  loginData,
  giftcardBuyLoading,
  giftcardBuyError,
  giftcardBuySuccess,
  setBuyGiftcard,
}) => {
  const BarCmp = (props) => {
    return (
      <>
        <BarWrapper>
          <BarTitle>{props.title}</BarTitle>
          <BarSpan>{props.value}</BarSpan>
        </BarWrapper>
      </>
    );
  };
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state) {
      navigate("/products/giftcard/buy/international");
    }

    async function callGetUserBalance() {
      await setGetUserBalance();
    }

    callGetUserBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, navigate]);
  const [openVerifyPay, setOpenVerifyPay] = useState(false);
  const [openPin, setOpenPin] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [insufficientBal, setInsufficientBal] = useState(false);
  const [pin, setPin] = useState("");
  return (
    <DashboardLayout pageTitle="Buy Giftcard">
      <Wrapper>
        <StepperCmp
          activeStep={3}
          stepData={[
            { title: "Card" },
            { title: "Country" },
            { title: "Value" },
            { title: "Summary" },
            { title: "Finish" },
          ]}
        />
        <BackWrapper>
          <ArrowBackCircle
            size={30}
            color="#1A8FDD"
            onClick={() => navigate(-1)}
          />
        </BackWrapper>
        <ContentWrapper>
          <WalletBalanceWrapper>
            <WalletBalText>Wallet Balance</WalletBalText>
            <WalletBalAmount>
              <NumberFormat
                thousandSeparator
                value={dashboardBalance?.data?.balance}
                prefix="₦"
                decimalScale={2}
                defaultValue="0.00"
                allowNegative={false}
                placeholder="0.00"
                displayType="text"
                disabled
              />
            </WalletBalAmount>
          </WalletBalanceWrapper>
          <TransactionWrapper>
            <DescWrapper>
              <DescText>Transaction Summary</DescText>
            </DescWrapper>
            <DetailContainer>
              <DetailWrapper>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Email</TitleText>
                    <DescText2>{loginData?.email}</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Giftcard</TitleText>
                    <DescText2>{location?.state?.brand?.brandName}</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Type</TitleText>
                    <DescText2>Buying</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Country</TitleText>
                    <DescText2>{location?.state?.country?.isoName}</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <CardValueTableHeadingWrapper>
                    <TitleText style={{ flex: "1", textAlign: "left" }}>
                      Card value
                    </TitleText>
                    <TitleText style={{ flex: "2" }}>Quantity</TitleText>
                    <TitleText style={{ flex: "1", textAlign: "right" }}>
                      Rate
                    </TitleText>
                  </CardValueTableHeadingWrapper>
                  {location?.state?.productItems?.map((v, i) => {
                    return (
                      <CardValueContentWrapper key={i}>
                        <DescText2 style={{ flex: "1", textAlign: "left" }}>
                          $
                          <NumberFormat
                            thousandSeparator
                            value={v.fixedRecipientDenomination}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                        <DescText2 style={{ flex: "2" }}>
                          {v.quantity}
                        </DescText2>
                        <DescText2 style={{ flex: "1", textAlign: "right" }}>
                          <NumberFormat
                            thousandSeparator
                            value={v.itemPrice}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                          /$
                        </DescText2>
                      </CardValueContentWrapper>
                    );
                  })}
                </Detail>
              </DetailWrapper>
              <BarCmp
                title="Transaction value "
                value={
                  <NumberFormat
                    thousandSeparator
                    value={location?.state?.totalPrice}
                    decimalScale={2}
                    prefix="₦"
                    defaultValue="0.00"
                    allowNegative={false}
                    placeholder="0.00"
                    displayType="text"
                    disabled
                  />
                }
              />
            </DetailContainer>
            <Wrapper2>
              <ButtonWrapper>
                <SolidButton
                  text="Pay"
                  type="submit"
                  weighty="500"
                  onClick={() => {
                    setOpenVerifyPay(true);
                  }}
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                />
              </ButtonWrapper>
            </Wrapper2>
          </TransactionWrapper>
        </ContentWrapper>
      </Wrapper>
      <VerifyPayModal
        maxWidth="350px"
        showCloseIcon={false}
        isOpen={openVerifyPay}
        onRequestClose={() => {
          setOpenVerifyPay(false);
        }}
        giftcardData={location?.state}
        payFn={() => {
          setOpenVerifyPay(false);
          setOpenPin(true);
        }}
      />
      <InsufficientBalModal
        maxWidth="350px"
        showCloseIcon={false}
        isOpen={insufficientBal}
        onRequestClose={() => {
          setInsufficientBal(false);
        }}
        walletBalance={dashboardBalance?.data?.balance}
        topUpFn={() => {
          setInsufficientBal(false);
          navigate("/wallets/deposit/naira");
        }}
      />
      <InProgressModal
        maxWidth="350px"
        showCloseIcon={false}
        isOpen={inProgress}
        onRequestClose={() => {
          setInProgress(false);
        }}
        finishFn={() => {
          setInProgress(false);
          navigate("/dashboard");
        }}
      />
      <PinModal
        isOpen={openPin}
        onRequestClose={() => {
          setOpenPin(false);
        }}
        setPin={setPin}
        loading={giftcardBuyLoading}
        txnAction={async () => {
          const data = {
            brand: location?.state?.brand,
            totalPriceOfProductPurchased:
              location?.state?.totalPrice.toString(),
            products: location?.state?.productItems?.map((v) => {
              return { ...v, totalPrice: v.totalPrice.toString() };
            }),
            pin,
          };

          const response = await setBuyGiftcard(data);
          if (response && response.message === "Giftcard purchase successful") {
            setOpenPin(false);
            setInProgress(true);
          } else if (giftcardBuyError === "Insufficient balance") {
            setOpenPin(false);
            setInsufficientBal(true);
          } else {
            setOpenPin(false);
          }
        }}
      />
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  dashboardBalanceLoading: state.dashboard.dashboardBalanceLoading,
  dashboardBalance: state.dashboard.dashboardBalance,
  dashboardBalanceError: state.dashboard.dashboardBalanceError,
  loginData: state.auth.data,
  giftcardBuyLoading: state.giftcard.giftcardBuyLoading,
  giftcardBuySuccess: state.giftcard.giftcardBuySuccess,
  giftcardBuyError: state.giftcard.giftcardBuyError,
});

const mapDispatch = (dispatch) => ({
  setGetUserBalance: () => dispatch(getUserBalance()),
  setBuyGiftcard: (data) => dispatch(buyGiftcard(data)),
});

export default connect(mapState, mapDispatch)(BuyGiftcardSummary);
