import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import StepperCmp from "../../../components/StepperCmp/StepperCmp";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBackCircle } from "@styled-icons/ionicons-outline";
import SolidButton from "../../../components/ButtonCmp/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GiftcardValueCmp from "./Components/GiftcardValueCmp";
import NumberFormat from "react-number-format";
import { calculateTotalPriceAndQuantity } from "../../../utilities/giftcard";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const BackWrapper = styled.div`
  padding: 1rem 0 0;
  cursor: pointer;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 1rem;
  align-items: center;

  @media only screen and (max-width: 960px) {
    padding: 1rem 0 10px;
  }
`;

const TopTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 960px) {
    gap: 5px;
  }
`;

const TopTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
  margin: 0;

  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const RightSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const AmountWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

const AmountWrapperMobile = styled.div`
  display: none;

  @media only screen and (max-width: 960px) {
    display: flex;
    gap: 12px;
    align-items: center;
  }
`;

const GetText = styled.h2`
  font-weight: 700;
  font-size: 12px;
  color: #adb5bd;
  margin: 0;
`;

const Amount = styled.h2`
  font-weight: 700;
  font-size: 24px;
  color: #016cba;
  margin: 0;
`;

const ButtonWrapperDesktop = styled.div`
  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

const CardImageMobile = styled(LazyLoadImage)`
  display: none;

  @media only screen and (max-width: 960px) {
    display: unset;
    width: 100%;
    max-width: 80px;
    border-radius: 12px;
    aspect-ratio: 3/2;
  }
`;

const GiftcardDisplaySection = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 1.5rem 0;
`;

const GiftcardItemWrapper = styled.div`
  flex: 0 0 23%;
  width: 23%;

  @media only screen and (max-width: 1263px) {
    flex: 0 0 32%;
    width: 32%;
  }

  @media only screen and (max-width: 1038px) {
    flex: 0 0 48%;
    width: 48%;
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const ButtonWrapperMobile = styled.div`
  display: none;

  @media only screen and (max-width: 960px) {
    display: unset;
    width: 100%;
  }
`;

const BuyGiftcardValue = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state) {
      navigate("/products/giftcard/buy/international");
    }
  }, [location.state, navigate]);

  const [productItems, setProductItems] = useState(
    location?.state?.productItem
  );
  return (
    <DashboardLayout pageTitle="Buy Giftcard">
      <Wrapper>
        <StepperCmp
          activeStep={2}
          stepData={[
            { title: "Card" },
            { title: "Country" },
            { title: "Value" },
            { title: "Summary" },
            { title: "Finish" },
          ]}
        />
        <BackWrapper>
          <ArrowBackCircle
            size={30}
            color="#1A8FDD"
            onClick={() => navigate(-1)}
          />
        </BackWrapper>
        <TopWrapper>
          <TopTitleWrapper>
            <TopTitle>Select Giftcard Value</TopTitle>
            <AmountWrapperMobile>
              <GetText>YOU PAY:</GetText>
              <Amount>
                <NumberFormat
                  thousandSeparator
                  value={
                    calculateTotalPriceAndQuantity(productItems)?.totalPrice
                  }
                  prefix="₦"
                  decimalScale={2}
                  defaultValue="0.00"
                  allowNegative={false}
                  placeholder="0.00"
                  displayType="text"
                  disabled
                />
              </Amount>
            </AmountWrapperMobile>
          </TopTitleWrapper>
          <RightSection>
            <AmountWrapper>
              <GetText>YOU PAY:</GetText>
              <Amount>
                <NumberFormat
                  thousandSeparator
                  value={
                    calculateTotalPriceAndQuantity(productItems)?.totalPrice
                  }
                  prefix="₦"
                  decimalScale={2}
                  defaultValue="0.00"
                  allowNegative={false}
                  placeholder="0.00"
                  displayType="text"
                  disabled
                />
              </Amount>
            </AmountWrapper>
            <ButtonWrapperDesktop>
              <SolidButton
                text={"Continue"}
                type="submit"
                weighty="500"
                textFontSize="14px"
                specifyPadding="0.8rem 1.5rem"
                color="#fff"
                widthWebkit="150px"
                widthMoz="150px"
                widthNormal="150px"
                borderRadius="8px"
                backColor="#1a8fdd"
                backgroundHoverColor="#1a8fdd"
                textHoverColor="#fff"
                margin="0rem auto 0"
                onClick={() => {
                  if (
                    calculateTotalPriceAndQuantity(productItems)
                      ?.totalQuantity > 0 &&
                    parseFloat(
                      calculateTotalPriceAndQuantity(productItems)?.totalPrice
                    ) > 0
                  ) {
                    navigate("/products/giftcard/buy/summary", {
                      state: {
                        productItems: productItems?.filter(
                          (v) => v.quantity > 0
                        ),
                        ...calculateTotalPriceAndQuantity(productItems),
                        country: location?.state?.country,
                        brand: location?.state?.brand,
                      },
                    });
                  }
                }}
                disabled={
                  calculateTotalPriceAndQuantity(productItems)?.totalQuantity >
                    0 &&
                  parseFloat(
                    calculateTotalPriceAndQuantity(productItems)?.totalPrice
                  ) > 0
                    ? false
                    : true
                }
              />
            </ButtonWrapperDesktop>
            <CardImageMobile
              src={location?.state?.logoUrl}
              alt="Giftcard"
              effect="blur"
            />
          </RightSection>
        </TopWrapper>

        <GiftcardDisplaySection>
          {productItems?.map((v, i) => {
            return (
              <GiftcardItemWrapper key={i}>
                <GiftcardValueCmp
                  fixedRecipientDenomination={v.fixedRecipientDenomination}
                  itemPrice={v.itemPrice}
                  quantity={v.quantity}
                  productId={v.productId}
                  totalPrice={v.totalPrice}
                  logoUrl={location?.state?.logoUrl}
                  brand={location?.state?.brand}
                  index={i}
                  setProductItems={setProductItems}
                  productItems={productItems}
                  recipientCurrencyCode={location?.state?.recipientCurrencyCode}
                />
              </GiftcardItemWrapper>
            );
          })}
        </GiftcardDisplaySection>
        <ButtonWrapperMobile>
          <SolidButton
            text={"Continue"}
            type="submit"
            weighty="500"
            textFontSize="14px"
            specifyPadding="0.8rem 1.5rem"
            color="#fff"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            borderRadius="8px"
            backColor="#1a8fdd"
            backgroundHoverColor="#1a8fdd"
            textHoverColor="#fff"
            margin="0rem auto 0"
            onClick={() => {
              if (
                calculateTotalPriceAndQuantity(productItems)?.totalQuantity >
                  0 &&
                parseFloat(
                  calculateTotalPriceAndQuantity(productItems)?.totalPrice
                ) > 0
              ) {
                navigate("/products/giftcard/buy/summary", {
                  state: {
                    productItems: productItems?.filter((v) => v.quantity > 0),
                    ...calculateTotalPriceAndQuantity(productItems),
                    country: location?.state?.country,
                    brand: location?.state?.brand,
                  },
                });
              }
            }}
            disabled={
              calculateTotalPriceAndQuantity(productItems)?.totalQuantity > 0 &&
              parseFloat(
                calculateTotalPriceAndQuantity(productItems)?.totalPrice
              ) > 0
                ? false
                : true
            }
          />
        </ButtonWrapperMobile>
      </Wrapper>
    </DashboardLayout>
  );
};

export default BuyGiftcardValue;
