import React from "react";
import styled from "styled-components";
import AdSectionCmp from "../../components/AdSectionCmp/AdSectionCmp";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import DashboardStatCmp from "../../components/DashboardStatCmp/DashboardStatCmp";
import RecentTxnCmp from "../../components/RecentTxnCmp/RecentTxnCmp";
import ShortcutCmp from "../../components/ShortcutCmp/ShortcutCmp";

const Wrapper = styled.div`
  display: flex;
  gap: 15px;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const LeftWrapper = styled.div`
  flex-basis: 67%;
  width: 67%;

  @media only screen and (max-width: 1145px) {
    flex-basis: 80%;
    width: 80%;
  }

  @media only screen and (max-width: 991px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const RightWrapper = styled.div`
  width: 33%;
  flex-basis: 33%;

  @media only screen and (max-width: 1145px) {
    display: none;
  }

  /* @media only screen and (max-width: 991px) {
    flex-basis: 100%;
    width: 100%;
  } */
`;

const AdWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
    padding-top: 1rem;
  }
`;

const Dashboard = () => {
  return (
    <>
      <DashboardLayout
        // backButton={true}
        // backText={"Back to Wallet"}
        pageTitle="Hello Chief!"
        desc={"Welcome to your dashboard"}
      >
        <Wrapper>
          <LeftWrapper>
            <DashboardStatCmp />
            <ShortcutCmp />
            <AdWrapper>
              <AdSectionCmp />
            </AdWrapper>
            <RecentTxnCmp />
          </LeftWrapper>
          <RightWrapper>
            <AdSectionCmp />
          </RightWrapper>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
