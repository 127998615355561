import React from "react";
import { Line } from "rc-progress";

const LineProgressCmp = (props) => {
  return (
    <>
      <Line
        percent={props.percent}
        strokeWidth={props.strokeWidth || 3}
        trailWidth={props.trailWidth || 3}
        strokeColor={props.strokeColor || "#1A8FDD"}
        trailColor={props.trailColor || "#F4F7FD"}
      />
    </>
  );
};

export default LineProgressCmp;
