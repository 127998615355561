import { Search } from "@styled-icons/boxicons-regular";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import CustomInputIconCmp from "../../../components/InputsCmp/CustomInputIconCmp";
import StepperCmp from "../../../components/StepperCmp/StepperCmp";
import appTheme from "../../../constants/theme";
import { getAGiftcardBuy } from "../../../redux/actions/giftcardActions";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import { searchThroughArrayOfObj } from "../../../utilities/searchThroughArrayOfObj";
import NotFound from "./Components/NotFound";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackCircle } from "@styled-icons/ionicons-outline";
import DisplayCountryCard from "./Components/DisplayCountryCard";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { rearrangeCountries } from "../../../utilities/utils";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const BackWrapper = styled.div`
  padding: 1rem 0 0;
  cursor: pointer;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const LargeGiftCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0 0;
`;

const BgCardWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(1, 108, 186, 0.5) -19.7%,
    rgba(7, 112, 188, 0.485) -5.4%,
    rgba(26, 123, 193, 0.45) 11.1%,
    rgba(58, 141, 201, 0.39) 27.59%,
    rgba(102, 166, 213, 0.3) 46.29%,
    rgba(159, 199, 229, 0.19) 64.99%,
    rgba(227, 239, 247, 0.055) 83.69%,
    rgba(255, 255, 255, 0) 90.29%
  );
  border-radius: 20px;
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 3/2;
  gap: 10px;
`;

const GiftcardImage = styled(LazyLoadImage)`
  max-width: 180px;
  width: 100%;
  border-radius: 15px;
`;

const GiftcardText = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
  margin: 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Question = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
  max-width: 300px;
  width: 100%;
  margin: 0;
`;

const FormWrapper = styled.div`
  max-width: 250px;
  width: 100%;

  @media only screen and (max-width: 960px) {
    max-width: 100%;
  }
`;

const CountryWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 1.5rem 0;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    /* justify-content: space-between; */
    gap: 10px;
  }

  @media only screen and (max-width: 500px) {
    justify-content: space-between;
  }
`;

const BuyGiftcardCountry = ({ getAGiftcardBuyLoading, setGetAGiftcardBuy }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [giftcardsData, setGiftcardsData] = useState([]);
  const [tempStoreGiftCardData, setTempStoreGiftCardData] = useState([]);
  const [fullGiftcardData, setFullGiftcardData] = useState();
  const [priorityCountries] = useState([
    "US",
    "GB",
    "CA",
    "AU",
    "GR",
    "PT",
    "NL",
    "CH",
    "BE",
  ]);

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetAGiftcardBuy(params?.brandId);

      setGiftcardsData(
        rearrangeCountries(
          response?.data?.supportedCountries,
          priorityCountries
        )
      );
      setTempStoreGiftCardData(
        rearrangeCountries(
          response?.data?.supportedCountries,
          priorityCountries
        )
      );
      setFullGiftcardData(response?.data);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout pageTitle="Buy Giftcard">
      <Wrapper>
        <StepperCmp
          activeStep={1}
          stepData={[
            { title: "Card" },
            { title: "Country" },
            { title: "Value" },
            { title: "Summary" },
            { title: "Finish" },
          ]}
        />
        <BackWrapper>
          <ArrowBackCircle
            size={30}
            color="#1A8FDD"
            onClick={() => navigate(-1)}
          />
        </BackWrapper>
        <LargeGiftCardWrapper>
          <BgCardWrapper>
            <GiftcardImage
              src={fullGiftcardData?.logoUrls[0]}
              alt={fullGiftcardData?.brand?.brandName}
            />
            <GiftcardText>{fullGiftcardData?.brand?.brandName}</GiftcardText>
          </BgCardWrapper>
        </LargeGiftCardWrapper>
        <TopWrapper>
          <QuestionWrapper>
            <Question>Select giftcard currency</Question>
          </QuestionWrapper>
          <FormWrapper>
            <CustomInputIconCmp
              background="rgba(225, 235, 245, 0.1)"
              required={true}
              icon={
                <>
                  <Search size={20} color="rgba(107, 114, 128, 0.5)" />
                </>
              }
              type="text"
              name="searchQuery"
              placeholder="Search for a particular currency"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                if (e.target.value) {
                  const result = searchThroughArrayOfObj(
                    tempStoreGiftCardData,
                    e.target.value
                  );
                  setGiftcardsData(result);
                } else {
                  setGiftcardsData(tempStoreGiftCardData);
                }
              }}
              showMessage={false}
            />
          </FormWrapper>
        </TopWrapper>

        {getAGiftcardBuyLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={40}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : !giftcardsData?.length ? (
          <NotFound text={searchQuery} titleText="Country not found" />
        ) : (
          <CountryWrapper>
            {giftcardsData?.map((v, i) => {
              return (
                <DisplayCountryCard
                  key={i}
                  flagIcon={v?.country?.flagUrl}
                  country={v?.country?.isoName}
                  onClick={() => {
                    const giftcardDetails = {
                      ...v,
                      productItem: v?.fixedRecipientDenominations?.map(
                        (denomination) => {
                          return {
                            productId: v?.productId,
                            quantity: 0,
                            itemPrice: v?.nairaDollarRate,
                            totalPrice:
                              parseFloat(v?.nairaDollarRate) * denomination * 0,
                            fixedRecipientDenomination: denomination,
                          };
                        }
                      ),
                      brand: fullGiftcardData?.brand,
                      logoUrl: fullGiftcardData?.logoUrls[0],
                    };
                    navigate("/products/giftcard/buy/card-value", {
                      state: giftcardDetails,
                    });
                  }}
                />
              );
            })}
          </CountryWrapper>
        )}
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  getAGiftcardBuyLoading: state.giftcard.getAGiftcardBuyLoading,
});

const mapDispatch = (dispatch) => ({
  setGetAGiftcardBuy: (brandId) => dispatch(getAGiftcardBuy(brandId)),
});

export default connect(mapState, mapDispatch)(BuyGiftcardCountry);
