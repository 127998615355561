import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";
import {
  AUTHENTICATED,
  ERROR,
  LOADING,
  LOGIN_DATA,
  LOGIN_TOKEN,
  SHOW_EMAIL_VERIFY,
  VALIDATE_2FA_ENABLED,
} from "../reducers/types";
import { saveToken } from "../token";
import { getUserSetupStatus } from "./setupActions";

export const registerUser = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/auth/register`, data);
    dispatch({ type: LOADING, payload: null });
    dispatch({ type: LOGIN_DATA, payload: true });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.email}`);
    }
    // dispatch({ type: ERROR, payload: err.response.data.errors });
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  // dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/auth/email-confirm/${token}`
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.token}`, {
        toastId: "Email verification failed",
      });
    } else {
      return toast.error(err.response?.data?.message);
    }
    // if (err.response.status === 422) {
    //   return toast.error(`${err.response?.data?.errors[0]?.email}`);
    // }
  }
};

export const addPhone = (data, token) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/auth/add-phone/${token}`,
      data
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.token}`);
    }
  }
};

export const verifyPhone = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(`${apiBaseUrl}/auth/verify-phone`, data);
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.phone}`);
    }
  }
};

export const loginUser = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  dispatch({ type: SHOW_EMAIL_VERIFY, payload: false });
  try {
    const response = await axios.post(`${apiBaseUrl}/auth/login`, data);
    dispatch({ type: LOADING, payload: null });
    if (response.data.message === "Login successful") {
      dispatch({ type: LOGIN_DATA, payload: response.data.data.user });
      if (response.data.data.user.twoFaEnabled) {
        dispatch({ type: VALIDATE_2FA_ENABLED, payload: null });
        dispatch({ type: LOGIN_TOKEN, payload: response.data.data.token });
      } else {
        saveToken(response.data.data.token);
        dispatch(getUserSetupStatus());
        dispatch({ type: AUTHENTICATED, payload: true });
      }

      return response.data;
    }
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    if (response.data.message === "Email verification sent") {
      dispatch({ type: SHOW_EMAIL_VERIFY, payload: true });
    }

    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    // console.log(err.response);
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.identifier}`);
    }
  }
};

export const forgetPassword = (data) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/auth/password-reset`,
      data
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    // console.log(err.response);
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.email}`);
    }
  }
};

export const verifyResetPassword = (token) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/auth/verify-reset/${token}`
    );
    dispatch({ type: LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(
        `${err.response?.data?.errors[0]?.token}` || "Invalid Reset Token"
      );
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const resetPassword = (data, token) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/auth/reset-password/${token}`,
      data
    );
    dispatch({ type: LOADING, payload: null });

    console.log(response.data);
    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: LOADING, payload: null });
    console.log(err.response);
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.password}`);
    }
  }
};
