import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";
import {
  CHECK_SUFFICIENT_BALANCE_ERROR,
  CHECK_SUFFICIENT_BALANCE_LOADING,
  CHECK_USER_INFO_ERROR,
  CHECK_USER_INFO_LOADING,
  DEPOSIT_NAIRA_ERROR,
  DEPOSIT_NAIRA_LOADING,
  GET_SETTINGS_ERROR,
  GET_SETTINGS_LOADING,
  MAKE_WITHDRAWAL_ERROR,
  MAKE_WITHDRAWAL_LOADING,
  USER_BANK_DETAIL_LIST_ERROR,
  USER_BANK_DETAIL_LIST_LOADING,
} from "../reducers/types";
import headers from "./header";

export const depositNaira = (data) => async (dispatch) => {
  dispatch({ type: DEPOSIT_NAIRA_LOADING, payload: true });
  dispatch({ type: DEPOSIT_NAIRA_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/wallet/deposit/naira`,
      data,
      headers()
    );
    dispatch({ type: DEPOSIT_NAIRA_LOADING, payload: null });

    if (response.data.status !== 201) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: DEPOSIT_NAIRA_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.amount}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getUserBankAccountList = () => async (dispatch) => {
  dispatch({ type: USER_BANK_DETAIL_LIST_LOADING, payload: true });
  dispatch({ type: USER_BANK_DETAIL_LIST_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/wallet/get/user/banks`,
      headers()
    );
    dispatch({ type: USER_BANK_DETAIL_LIST_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message, {
        toastId: "Error",
      });
    }
    return response.data;
  } catch (err) {
    dispatch({ type: USER_BANK_DETAIL_LIST_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.data}`, {
        toastId: "Validation error",
      });
    } else {
      return toast.error(`There is an error`, { toastId: "There is an error" });
    }
  }
};

export const getSettings = () => async (dispatch) => {
  dispatch({ type: GET_SETTINGS_LOADING, payload: true });
  dispatch({ type: GET_SETTINGS_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/other/app/setting`,
      headers()
    );
    dispatch({ type: GET_SETTINGS_LOADING, payload: null });
    if (response.data.status !== 200) {
      return toast.error(response.data.message, {
        toastId: "Error",
      });
    }

    return response.data;
  } catch (err) {
    dispatch({ type: GET_SETTINGS_LOADING, payload: null });
    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.data}`, {
        toastId: "Validation error",
      });
    } else {
      return toast.error(`There is an error`, { toastId: "There is an error" });
    }
  }
};

export const checkUserInfo = (data) => async (dispatch) => {
  dispatch({ type: CHECK_USER_INFO_LOADING, payload: true });
  dispatch({ type: CHECK_USER_INFO_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/wallet/user/info`,
      data,
      headers()
    );
    dispatch({ type: CHECK_USER_INFO_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: CHECK_USER_INFO_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.identifier}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const makeWithdrawal = (data) => async (dispatch) => {
  dispatch({ type: MAKE_WITHDRAWAL_LOADING, payload: true });
  dispatch({ type: MAKE_WITHDRAWAL_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/wallet/withdraw/naira`,
      data,
      headers()
    );
    dispatch({ type: MAKE_WITHDRAWAL_LOADING, payload: null });

    if (response.data.status !== 201) {
      return toast.error(response.data.message || "There is an error");
    }
    return response.data;
  } catch (err) {
    dispatch({ type: MAKE_WITHDRAWAL_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error("There is an error");
    } else {
      return toast.error(err.response?.data?.message || "There is an error");
    }
  }
};

export const checkSufficientBalance = (data) => async (dispatch) => {
  dispatch({ type: CHECK_SUFFICIENT_BALANCE_LOADING, payload: true });
  dispatch({ type: CHECK_SUFFICIENT_BALANCE_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/wallet/balance/check`,
      data,
      headers()
    );
    dispatch({ type: CHECK_SUFFICIENT_BALANCE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }
    return response.data;
  } catch (err) {
    dispatch({ type: CHECK_SUFFICIENT_BALANCE_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.amount}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};
