import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TxnLimitCmp from "../../../components/TxnLimitCmp/TxnLimitCmp";
import { CheckCircle } from "@styled-icons/boxicons-solid";
import { getUserLimit } from "../../../redux/actions/settingActions";
import { connect } from "react-redux";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../../constants/theme";
import MoneyFormatter from "../../../components/MoneyFormatterCmp/MoneyFormatter";

const LimitsWrapper = styled.div`
  width: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

const InnerTopWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LimitTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const LimitText = styled.h2`
  margin: 0;
  color: #323232;
  font-size: 0.9375rem;
  font-weight: 500;
`;

const LimitDesc = styled.p`
  max-width: 180px;
  margin: 0;
  color: #323232;
  font-size: 0.75rem;
  font-weight: 400;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #adb5bd;
  background-color: #adb5bd;
  margin: 2rem 0;
`;

const DownWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

const KycText = styled.h2`
  color: #323232;
  font-size: 1.375rem;
  font-weight: 700;
`;

// const StepWrapper = styled.div`
//   &.limit-wrapper {
//     rotate: 90deg;
//     height: 40px;
//   }

//   & a {
//     display: none;
//   }
// `;

const StepContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 200px;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const EachTextWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const LevelText = styled.h2`
  color: #323232;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
`;

const SmallLevelText = styled.p`
  margin: 0;
  color: #323232;
  font-size: 0.75rem;
  font-weight: 400;
`;

const Limits = ({
  getUserLimitError,
  getUserLimitLoading,
  getUserLimitSuccess,
  setGetUserLimit,
}) => {
  const [userLimitData, setUserLimitData] = useState();
  console.log(userLimitData);

  useEffect(() => {
    if (!getUserLimitSuccess) {
      async function callGetUserLimit() {
        const response = await setGetUserLimit();
        setUserLimitData(response);
      }

      callGetUserLimit();
    } else {
      setUserLimitData(getUserLimitSuccess);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {getUserLimitLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SpinnerCmp
            enabled={true}
            color={appTheme.COLORS.bluePrimary}
            size={40}
            secondaryColor={appTheme.COLORS.white}
          />
        </div>
      ) : (
        <LimitsWrapper>
          <MainContent>
            <InnerTopWrapper>
              <LimitTextWrapper>
                <LimitText>Limits</LimitText>
                <LimitDesc>
                  This highlights the limits for your transactions
                </LimitDesc>
              </LimitTextWrapper>
              <TxnLimitCmp />
            </InnerTopWrapper>
          </MainContent>
          <Line />
          <DownWrapper>
            <KycText>KYC Verification</KycText>
            <StepContainer>
              {/* <StepWrapper className="limit-wrapper">
              <Stepper
                steps={[{ title: null }, { title: null }]}
                activeStep={2}
                activeColor="#F4F7FD"
                defaultColor="#F4F7FD"
                completeColor="#016CBA"
                activeTitleColor="#ADB5BD"
                completeTitleColor="#ADB5BD"
                defaultTitleColor="#ADB5BD"
                circleFontColor="#fff"
                completeBarColor="#016CBA"
                defaultBorderColor="#ADB5BD"
                titleFontSize={12}
                circleFontSize={12}
                circleTop={10}
                size={20}
                titleTop={3}
                defaultBorderWidth={2}
              />
            </StepWrapper> */}
              <TextContainer>
                <EachTextWrapper>
                  <LevelText>
                    Level 1: Phone & Email Verification{" "}
                    {userLimitData?.data?.phoneVerified &&
                      userLimitData?.data?.emailVerified && (
                        <CheckCircle color="#07AF4A" size={13} />
                      )}
                  </LevelText>{" "}
                  {userLimitData?.data?.phoneVerified &&
                    userLimitData?.data?.emailVerified && (
                      <SmallLevelText>
                        Your phone and email Address has been verified. Your
                        one-time and daily transaction limit is{" "}
                        <MoneyFormatter value={1000} />
                      </SmallLevelText>
                    )}
                </EachTextWrapper>
                <EachTextWrapper>
                  <LevelText>
                    Level 2: BVN{" "}
                    {(userLimitData?.data?.isSetBvn ||
                      userLimitData?.data?.isSetTin) && (
                      <CheckCircle color="#07AF4A" size={13} />
                    )}
                  </LevelText>{" "}
                  {(userLimitData?.data?.isSetBvn ||
                    userLimitData?.data?.isSetTin) && (
                    <SmallLevelText>
                      Your BVN has been verified. Your one time and daily
                      transaction limit is{" "}
                      {
                        <MoneyFormatter
                          value={userLimitData?.data?.dailySpendLimit}
                        />
                      }{" "}
                      and{" "}
                      {
                        <MoneyFormatter
                          value={userLimitData?.data?.dailyWithdrawalLimit}
                        />
                      }{" "}
                      respectively.
                    </SmallLevelText>
                  )}
                </EachTextWrapper>
              </TextContainer>
            </StepContainer>
          </DownWrapper>
        </LimitsWrapper>
      )}
    </>
  );
};

const mapState = (state) => ({
  getUserLimitLoading: state.setting.getUserLimitLoading,
  getUserLimitError: state.setting.getUserLimitError,
  getUserLimitSuccess: state.setting.getUserLimitSuccess,
});

const mapDispatch = (dispatch) => ({
  setGetUserLimit: () => dispatch(getUserLimit()),
});

export default connect(mapState, mapDispatch)(Limits);
