import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";

const Wrapper = styled.div``;

const Title = styled.h2`
  text-align: center;
  color: #323232;
  font-size: 1.75rem;
  font-weight: 700;
`;

const Desc = styled.p`
  color: #323232;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 400;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
`;

const List = styled.ol`
  padding: 1rem 2rem;
`;

const ListItems = styled.li`
  padding-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Cancel = styled.h2`
  color: #646464;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
`;

const TwoFaStep1 = ({ setStep }) => {
  const navigate = useNavigate();
  return (
    <>
      <Wrapper>
        <Title>Kindly read</Title>
        <Desc>
          We strongly recommend you to enable two factor authentication on your
          account.
        </Desc>
        <List>
          <ListItems>
            2-factor Authentication is an additional security layer for user
            authentication and Identity confirmation. It is highly recommended
            but optional.
          </ListItems>
          <ListItems>
            Enabling 2-factor authentication involves you downloading the Google
            authenticator app on your mobile device (Android or Iphone),
            scanning a QR code generated using the app, and then using the
            Authenticator code every time you want to login, thereby verifying
            verifying your account ownership.{" "}
          </ListItems>
          <ListItems>
            To set up 2 factor authentication, Please click next and follow the
            instructions carefully.
          </ListItems>
        </List>
        <ButtonWrapper>
          <SolidButton
            text={"Next"}
            type="submit"
            weighty="500"
            textFontSize="14px"
            specifyPadding="0.5rem 1rem"
            color="#fff"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            maxWidth="250px"
            borderRadius="8px"
            backColor="#1a8fdd"
            backgroundHoverColor="#1a8fdd"
            textHoverColor="#fff"
            margin="0rem auto 0"
            onClick={() => {
              setStep(2);
            }}
          />
        </ButtonWrapper>
        <Cancel
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Cancel>
      </Wrapper>
    </>
  );
};

export default TwoFaStep1;
