import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { ArrowBackCircle } from "@styled-icons/ionicons-outline";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import StepperCmp from "../../../components/StepperCmp/StepperCmp";
import CustomFileCmp from "../../../components/InputsCmp/CustomFileCmp";
import { Formik } from "formik";
import sellGiftcardUploadSchema from "../../../validators/sellGiftcardUploadValidator";
import SolidButton from "../../../components/ButtonCmp/Button";
import { useState } from "react";
import GiftcardSellNoticeModal from "../../../components/Modals/GiftcardSellNoticeModal";
import PinModal from "../../../components/Modals/PinModal";
import { connect, useDispatch } from "react-redux";
import { sellGiftcard } from "../../../redux/actions/giftcardActions";
import InProgressModal from "../../../components/Modals/InProgressModal";
import { getUserBalance } from "../../../redux/actions/dashboardAction";
import { GIFTCARD_SELL_LOADING } from "../../../redux/reducers/types";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const BackWrapper = styled.div`
  padding: 1rem 0 0;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-top: 1rem;
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  text-align: left;
`;

const Heading = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 28px;
  color: #323232;
  max-width: 307px;
  width: 100%;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: #323232;
  max-width: 227px;
  width: 100%;
`;

const UploadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  text-align: right;
  max-width: 450px;
  width: 100%;
  padding-top: 2rem;
`;

const SellGiftcardUpload = ({
  giftcardSellLoading,
  giftcardSellError,
  giftcardSellSuccess,
  setSellGiftcard,
  dashboardBalanceLoading,
  dashboardBalance,
  dashboardBalanceError,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [files, setFiles] = useState([]);
  const [showNotice, setShowNotice] = useState(false);
  const [openPin, setOpenPin] = useState(false);
  const [pin, setPin] = useState("");
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (!location?.state) {
      navigate("/products/giftcard/sell/international");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, navigate]);

  return (
    <DashboardLayout pageTitle="Sell Giftcard">
      <Wrapper>
        <StepperCmp
          activeStep={4}
          stepData={[
            { title: "Card" },
            { title: "Country" },
            { title: "Value" },
            { title: "Summary" },
            { title: "Upload cards" },
            { title: "Finish" },
          ]}
        />
        <BackWrapper>
          <ArrowBackCircle
            size={30}
            color="#1A8FDD"
            onClick={() => navigate(-1)}
          />
        </BackWrapper>
        <ContentWrapper>
          <TextWrapper>
            <Heading>Upload a picture of your card</Heading>
            <Text>Drag and drop the image of your Giftcard below</Text>
          </TextWrapper>

          <UploadWrapper>
            <Formik
              initialValues={{
                files: null,
              }}
              isInitialValid={false}
              validationSchema={sellGiftcardUploadSchema}
              onSubmit={async (values, { resetForm }) => {
                setFiles(values.files);
                setShowNotice(true);
                // let formData = new FormData();
                // formData.append("adImage", values.files[0], values.files[0].name);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
                setFieldTouched,
                isValid,
                touched,
              }) => (
                <>
                  <form
                    style={{ width: "100%" }}
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <CustomFileCmp
                      maxFiles={2}
                      maxSize={1000000}
                      accept={{
                        "image/png": [".png"],
                        "image/jpeg": [".jpg", ".jpeg"],
                      }}
                      //   customFn={(blob) => {
                      //     setImagePreview(blob);
                      //   }}
                      type="text"
                      values={values}
                      setFieldValue={setFieldValue}
                      name="files"
                      showMessage
                      message={`${errors["files"] ? errors["files"] : ""}`}
                    />
                    <ButtonWrapper>
                      <SolidButton
                        text="Submit"
                        type="submit"
                        weighty="500"
                        onClick={() => {
                          navigate("/products/giftcard/sell/upload", {
                            state: location?.state,
                          });
                        }}
                        textFontSize="14px"
                        specifyPadding="0.8rem 1.5rem"
                        color="#fff"
                        widthWebkit="107px"
                        widthMoz="107px"
                        widthNormal="107px"
                        borderRadius="8px"
                        backColor="#1a8fdd"
                        backgroundHoverColor="#1a8fdd"
                        textHoverColor="#fff"
                        margin="0rem auto 0"
                        disabled={isValid ? false : true}
                      />
                    </ButtonWrapper>
                  </form>
                </>
              )}
            </Formik>
          </UploadWrapper>
        </ContentWrapper>
      </Wrapper>
      <GiftcardSellNoticeModal
        wrapperPadding={"0rem 1.5rem 1rem"}
        maxWidth="350px"
        showCloseIcon={true}
        isOpen={showNotice}
        onRequestClose={() => {
          setShowNotice(false);
        }}
        data={location?.state}
        btnAction={() => {
          setShowNotice(false);
          setOpenPin(true);
        }}
      />
      <PinModal
        isOpen={openPin}
        onRequestClose={() => {
          setOpenPin(false);
        }}
        setPin={setPin}
        loading={giftcardSellLoading}
        txnAction={async () => {
          dispatch({ type: GIFTCARD_SELL_LOADING, payload: true });
          const imgData = [];
          let formData = new FormData();
          for (const image of files) {
            formData.append("file", image, image?.name);
            formData.append(
              "upload_preset",
              process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
            );
            formData.append(
              "cloud_name",
              process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
            );
            const response = await axios.post(
              `${process.env.REACT_APP_CLOUDINARY_BASE_URL}/image/upload`,
              formData
            );
            imgData.push({
              url: response?.data?.secure_url,
              imgId: response?.data?.public_id,
            });
          }

          const data = {
            pin,
            brand: location?.state?.brand,
            giftcardType: location?.state?.giftcardType,
            products: [
              {
                ...location?.state?.giftcardValue[0],
                totalPrice: `${location?.state?.giftcardValue[0]?.totalPrice}`,
              },
            ],
            images: imgData,
            currencyCode: location?.state?.recipientCurrencyCode,
          };

          const response = await setSellGiftcard(data);
          if (response && response.message === "Giftcard sell successful") {
            setOpenPin(false);
            setInProgress(true);
          } else {
            setOpenPin(false);
          }
        }}
      />
      <InProgressModal
        maxWidth="350px"
        showCloseIcon={false}
        isOpen={inProgress}
        onRequestClose={() => {
          setInProgress(false);
        }}
        finishFn={() => {
          setInProgress(false);
          navigate("/dashboard");
        }}
      />
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  dashboardBalanceLoading: state.dashboard.dashboardBalanceLoading,
  dashboardBalance: state.dashboard.dashboardBalance,
  dashboardBalanceError: state.dashboard.dashboardBalanceError,
  giftcardSellLoading: state.giftcard.giftcardSellLoading,
  giftcardSellSuccess: state.giftcard.giftcardSellSuccess,
  giftcardSellError: state.giftcard.giftcardSellError,
});

const mapDispatch = (dispatch) => ({
  setGetUserBalance: () => dispatch(getUserBalance()),
  setSellGiftcard: (data) => dispatch(sellGiftcard(data)),
});

export default connect(mapState, mapDispatch)(SellGiftcardUpload);
