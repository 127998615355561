import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import JessieLogo from "../../assets/logo-color.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Copy, InfoCircle } from "@styled-icons/boxicons-regular";
import SolidButton from "../../components/ButtonCmp/Button";
import { Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { depositNaira } from "../../redux/actions/walletActions";
import SuccessModal from "../../components/Modals/SuccessModal";
import SpinnerCmp from "../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../constants/theme";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterWrapper = styled.div`
  background: rgba(196, 196, 196, 0.05);
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  /* padding: 1rem; */
`;

const Wrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1rem;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 500px;
`;

const Logo = styled(LazyLoadImage)`
  width: 137px;
  height: 46px;
`;

const DescWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

const DescText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
`;

const DangerText = styled.p`
  font-weight: 400;
  font-size: 13px;
  color: #d71920;
  margin-bottom: 0;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  padding-top: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const FooterWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const FooterText = styled.p`
  font-size: 12px;
  color: #323232;
  margin-bottom: 0;
  text-align: left;
`;

const BarWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 8px;
  background-color: #f4f7fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const BarTitle = styled.h2`
  font-weight: 400;
  color: #323232;
  font-size: 14px;
  margin-bottom: 0;
`;

const BarValue = styled.h2`
  font-weight: 600;
  color: #323232;
  font-size: 15px;
  margin-bottom: 0;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 1rem;
`;

const ConfirmDeposit = ({
  setNairaDeposit,
  depositNairaError,
  depositNairaLoading,
  depositNairaSuccess,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state) {
      navigate("/wallets/deposit/naira");
    }
  }, [location.state, navigate]);

  const [showModal, setShowModal] = useState(false);

  console.log(location.state);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await setNairaDeposit({
      accountName: location.state.settingsData.primaryAccountName,
      accountNumber: location.state.settingsData.primaryAccountNumber,
      bankCode: location.state.settingsData.primaryBankCode,
      bankName: location.state.settingsData.primaryBankName,
      fromBank: location.state.bank.bankName,
      fromName: location.state.bank.accountName,
      fromAccount: location.state.bank.accountNumber,
      fromCode: location.state.bank.bankCode,
      amount: location.state.amount,
      charge: location.state.charge,
    });

    if (
      response &&
      response.status === 201 &&
      response.message === "Deposit created successfully"
    ) {
      return setShowModal(true);
    }
  };
  const BarCmp = (props) => {
    return (
      <>
        <BarWrapper>
          <BarTitle>{props.title}</BarTitle>
          <div style={{ position: "relative" }}>
            <BarValue>{props.value}</BarValue>
            {props.clipboard && (
              <>
                <CopyToClipboard
                  text={props.value}
                  onCopy={() => {
                    toast.success("Copied!", { toastId: "Clipboard" });
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-12px",
                      right: "-10px",
                      cursor: "pointer",
                    }}
                  >
                    <Copy size={12} color="#016CBA" />
                  </div>
                </CopyToClipboard>
              </>
            )}
          </div>
        </BarWrapper>
      </>
    );
  };
  return (
    <>
      <DashboardLayout backButton={true} backText={"Back to Deposit"}>
        <Container>
          <OuterWrapper>
            <Wrapper>
              <Logo src={JessieLogo} effect="blur" />
              <DescWrapper>
                <DescText>
                  Pay{" "}
                  <span style={{ fontWeight: "700" }}>
                    <NumberFormat
                      thousandSeparator
                      value={
                        parseFloat(location.state.amount) +
                        parseFloat(location.state.charge)
                      }
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />{" "}
                    NGN
                  </span>{" "}
                  to the account below
                </DescText>
                <DangerText>
                  Please do not use any cryptocurrency related narration in your
                  transaction
                </DangerText>
              </DescWrapper>
              <DetailContainer>
                <BarCmp
                  title="Account Name"
                  value={location.state.settingsData.primaryAccountName}
                />
                <BarCmp
                  title="Account Number:"
                  value={location.state.settingsData.primaryAccountNumber}
                  clipboard
                />
                <BarCmp
                  title="Bank Name:"
                  value={location.state.settingsData.primaryBankName}
                />
              </DetailContainer>
              <FooterWrapper>
                <div>
                  <InfoCircle color="#323232" size={17} />
                </div>
                <FooterText>
                  Please, only send the amount specified on this page. A stamp
                  duty of N
                  <NumberFormat
                    thousandSeparator
                    value={parseInt(location.state.charge)}
                    decimalScale={2}
                    defaultValue="0.00"
                    allowNegative={false}
                    placeholder="0.00"
                    displayType="text"
                    disabled
                  />{" "}
                  would be charged on all transactions. Your transaction will
                  not be successful if you send any amount below or above this
                </FooterText>
              </FooterWrapper>
              <Wrapper2>
                <ButtonWrapper>
                  <SolidButton
                    text={
                      depositNairaLoading ? (
                        <>
                          <SpinnerCmp
                            enabled={true}
                            color={appTheme.COLORS.bluePrimary}
                            size={20}
                            secondaryColor={appTheme.COLORS.white}
                          />
                        </>
                      ) : (
                        "I have transferred, Next"
                      )
                    }
                    type="submit"
                    weighty="500"
                    textFontSize="14px"
                    specifyPadding="0.8rem 1.5rem"
                    color="#fff"
                    widthWebkit="100%"
                    widthMoz="100%"
                    widthNormal="100%"
                    borderRadius="8px"
                    backColor="#1a8fdd"
                    backgroundHoverColor="#1a8fdd"
                    textHoverColor="#fff"
                    margin="0rem auto 0"
                    disabled={depositNairaLoading ? true : false}
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  />
                </ButtonWrapper>
              </Wrapper2>
            </Wrapper>
          </OuterWrapper>
        </Container>
        <SuccessModal
          title="We are confirming your deposit. Successful deposits should reflect shortly"
          titleMaxWidth="300px"
          maxWidth="400px"
          showLogo={true}
          // wrapperPadding={"2rem 1rem 2rem"}
          showCloseIcon={false}
          isOpen={showModal}
          buttonCloseText="Done"
          onRequestClose={() => {
            setShowModal(false);
            navigate("/wallets/deposit/naira");
          }}
          actionButtonWidth="200px"
        />
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  depositNairaLoading: state.wallet.depositNairaLoading,
  depositNairaSuccess: state.wallet.depositNairaSuccess,
  depositNairaError: state.wallet.depositNairaError,
});

const mapDispatch = (dispatch) => ({
  setNairaDeposit: (data) => dispatch(depositNaira(data)),
});

export default connect(mapState, mapDispatch)(ConfirmDeposit);
