import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { verifyPhone } from "../../redux/actions/authActions";
import SolidButton from "../ButtonCmp/Button";
import CustomPinInputCmp from "../InputsCmp/CustomPinInputCmp";
import ModalCmp from "../ModalCmp/ModalCmp";
import SuccessModal from "./SuccessModal";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";

const VerifyPhoneWrapper = styled.div`
  padding: 2rem 1.5rem;
  text-align: center;
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 16px;
  font-weight: ${appTheme.WEIGHT.mediumBold};
`;

const Desc = styled.p`
  max-width: 200px;
  color: #c4c4c4;
  line-height: 16px;
  margin: 0 auto;
`;

const FooterText = styled.h2`
  color: #000;
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.medium};
  padding-top: 0.7rem;
`;

const FooterSpan = styled.span`
  color: #016cba;
`;

const PinWrapper = styled.div`
  padding: 1.5rem 0;
`;

const ButtonWrapper = styled.div``;

const VerifyPhoneModal = ({ loading, error, setVerifyPhone, ...props }) => {
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleComplete = async (value, index) => {
    console.log(value, index);
    setDisableBtn(false);

    const response = await setVerifyPhone({
      code: value,
      phone: props.phoneNo,
    });

    if (
      response &&
      response.status === 201 &&
      response.message === "Phone number verified successfully"
    ) {
      return setShowSuccessModal(true);
    }

    if (response?.status !== 201 || error) {
      return toast.error(
        `${
          response?.message || error.message || "Phone number validation failed"
        }`,
        {
          toastId: "Phone number validation failed",
        }
      );
    }
  };
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: "450px" }}
        shouldFocusAfterRender={false}
        contentLabel="Verify Phone"
        id="verify-phone"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={false}
      >
        <VerifyPhoneWrapper>
          <Title>Please verify your phone number</Title>
          <Desc>Enter the 6 digit OTP sent to your phone</Desc>
          <PinWrapper>
            <CustomPinInputCmp
              length={6}
              autoSelect={true}
              secret={false}
              initialValue=""
              onComplete={handleComplete}
              //   disabled={disableBtn === false ? true : false}
            />
          </PinWrapper>
          <ButtonWrapper>
            <SolidButton
              onClick={props.onRequestClose}
              text={
                loading ? (
                  <>
                    <SpinnerCmp
                      enabled={true}
                      color={appTheme.COLORS.bluePrimary}
                      size={20}
                      secondaryColor={appTheme.COLORS.white}
                    />
                  </>
                ) : (
                  "Confirm"
                )
              }
              type="submit"
              weighty="500"
              disabled={disableBtn}
              textFontSize="14px"
              specifyPadding="1rem 1.5rem"
              color="#fff"
              widthWebkit="150px"
              widthMoz="150px"
              widthNormal="150px"
              borderRadius="8px"
              backColor="#1a8fdd"
              backgroundHoverColor="#1a8fdd"
              textHoverColor="#fff"
              margin="0rem auto 0"
            />
          </ButtonWrapper>
          <FooterText>
            Did not receive OTP? <FooterSpan>Resend OTP</FooterSpan>
          </FooterText>
        </VerifyPhoneWrapper>
      </ModalCmp>
      <SuccessModal
        title="Congratulations, you have successfully set up
        your account"
        titleMaxWidth="300px"
        maxWidth="400px"
        //   showLogo={true}
        buttonCloseText={"Finish"}
        //   wrapperPadding={"1rem 1rem 2rem"}
        //   showCloseIcon={true}
        isOpen={showSuccessModal}
        heading={"Verification Successful"}
        //   successImgUrl={SuccessImg}
        onRequestClose={() => {
          setShowSuccessModal(false);
          navigate("/login");
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatch = (dispatch) => ({
  setVerifyPhone: (data) => dispatch(verifyPhone(data)),
});

export default connect(mapState, mapDispatch)(VerifyPhoneModal);
