import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { Icon } from "@iconify/react";

const MarketContainer = styled(Container)`
  padding: 4rem 4rem 4rem;
  background-color: #fff;

  @media only screen and (max-width: 767px) {
    padding: 5rem 4rem 3rem;
  }

  @media only screen and (max-width: 580px) {
    padding: 4rem 1rem 3rem;
  }
`;

const MarketWrapper = styled.div`
  max-width: 1536px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
`;

// const MarketTitle = styled.h2`
//   font-weight: ${appTheme.WEIGHT.mediumBold};
//   text-align: center;
//   padding-top: 2rem;
//   font-size: 36px;
//   color: ${appTheme.COLORS.grey1};

//   @media only screen and (max-width: 800px) {
//     font-size: 30px;
//   }

//   @media only screen and (max-width: 650px) {
//     font-size: 25px;
//   }

//   @media only screen and (max-width: 450px) {
//     font-size: 22px;
//   }

//   @media only screen and (max-width: 355px) {
//     font-size: 18px;
//   }
// `;

const CoinWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid ${appTheme.COLORS.bluePrimary};
  width: 200px;
  height: 200px;
  margin: 1rem;
  padding: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CoinIcon = styled(Icon)`
  margin-bottom: 0.7rem;
`;

const CoinName = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 18px;
  font-weight: 700;
`;

const CoinSlug = styled.p`
  font-weight: 300;
  font-size: 13px;
  color: ${appTheme.COLORS.grey1};
`;

const PriceWrapper = styled.div`
  display: flex;
  padding-top: 0.6rem;
  align-items: center;
`;

const Price = styled(CurrencyFormat)`
  color: ${appTheme.COLORS.grey1};
  font-weight: 700;
  font-size: 16px;
`;

const TrendSpan = styled.span`
  font-size: 13px;
  padding-left: 2px;
  font-weight: 500;
  color: ${(props) => props.color && props.color};
`;

function MarketCmp() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL_PROD}/other/crypto/listing`
        );
        if (res.data.error) {
          setError("An error occured while loading data");
        } else {
          setData(res.data);
          setIsLoading(false);
        }
      })();
      console.log(data, "data");
    } catch (error) {
      setIsLoading(false);
      setError("An error occured while loading data");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(data, "data");
  return (
    <>
      <MarketContainer fluid>
        {/* <MarketTitle>Today’s Cryptocurrency Prices</MarketTitle> */}
        <MarketWrapper>
          {data &&
            data?.data?.data?.length > 0 &&
            data.data.data.map((v, i) => {
              return (
                <CoinWrapper key={i}>
                  <div>
                    <CoinIcon
                      color={appTheme.COLORS.bluePrimary}
                      width={30}
                      height={30}
                      icon={`cryptocurrency:${v.symbol.toLowerCase()}`}
                    />
                  </div>
                  <CoinName>{v.name}</CoinName>
                  <CoinSlug>{v.symbol}</CoinSlug>
                  <PriceWrapper>
                    <Price
                      value={v.quote.USD.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                    <TrendSpan
                      color={
                        v.quote.USD.percent_change_1h < 0
                          ? "#ea3943"
                          : "#16c784"
                      }
                    >
                      {`${Math.abs(v.quote.USD.percent_change_1h.toFixed(2))}%`}
                    </TrendSpan>
                  </PriceWrapper>
                </CoinWrapper>
              );
            })}
          {isLoading && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {error && <CoinName>There is an error loading data</CoinName>}
        </MarketWrapper>
      </MarketContainer>
    </>
  );
}

export default MarketCmp;
