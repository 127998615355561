import React from "react";
import styled from "styled-components";
import BalanceCmp from "../../components/BalanceCmp/BalanceCmp";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import TradeCryptoCardCmp from "../../components/TradeCryptoCardCmp/TradeCryptoCardCmp";
import appTheme from "../../constants/theme";
import BitcoinImg from "../../assets/bitcoin-icon.svg";

const WalletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.medium};
  color: #323232;
`;

const CryptoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 1.5rem;
  max-width: 768px;
  width: 100%;
`;

const CryptoCardWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

const Wallet = () => {
  return (
    <>
      <DashboardLayout pageTitle="Wallet">
        <WalletWrapper>
          <Title>Naira wallet</Title>
          <BalanceCmp />
        </WalletWrapper>
        {false && (
          <CryptoWrapper>
            <Title>Crypto wallet</Title>
            <CryptoCardWrapper>
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
              <TradeCryptoCardCmp
                coinIcon={BitcoinImg}
                coinName="Bitcoin"
                coinSymbol="BTC"
                link="/trade/1"
              />
            </CryptoCardWrapper>
          </CryptoWrapper>
        )}
      </DashboardLayout>
    </>
  );
};

export default Wallet;
