import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Logout from "./components/LogoutCmp/LogoutCmp";
import PrivateRoute from "./components/PrivateRouteCmp/PrivateRoute";
import AddPhone from "./pages/Auth/AddPhone";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import GetStarted from "./pages/Auth/GetStarted";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import SignupBusiness from "./pages/Auth/SignupBusiness";
import SignupIndividual from "./pages/Auth/SignupIndividual";
import Dashboard from "./pages/Dashboard/Dashboard";
import Market from "./pages/Market/Market";
import CableInternet from "./pages/Product/Cable-Internet/Cable-Internet";
import ConfirmCablePurchase from "./pages/Product/Cable-Internet/ConfirmCablePurchase";
import ConfirmInternetPurchase from "./pages/Product/Cable-Internet/ConfirmInternetPurchase";
import ConfirmJambPurchase from "./pages/Product/Educational/ConfirmJambPurchase";
import ConfirmWaecPurchase from "./pages/Product/Educational/ConfirmWaecPurchase";
import Educational from "./pages/Product/Educational/Educational";
import ConfirmElectricityPurchase from "./pages/Product/Electricity/ConfirmElectricityPurchase";
import Electricity from "./pages/Product/Electricity/Electricity";
import BuyGiftcard from "./pages/Product/Giftcard/BuyGiftcard";
import BuyGiftcardCountry from "./pages/Product/Giftcard/BuyGiftcardCountry";
import BuyGiftcardSummary from "./pages/Product/Giftcard/BuyGiftcardSummary";
import BuyGiftcardValue from "./pages/Product/Giftcard/BuyGiftcardValue";
import Giftcard from "./pages/Product/Giftcard/Giftcard";
import SellGiftcardValue from "./pages/Product/Giftcard/SelectGiftcardValue";
import SellGiftcard from "./pages/Product/Giftcard/SellGiftcard";
import SellGiftcardCountry from "./pages/Product/Giftcard/SellGiftcardCountry";
import SellGiftcardSummary from "./pages/Product/Giftcard/SellGiftcardSummary";
import SellGiftcardUpload from "./pages/Product/Giftcard/SellGiftcardUpload";
import Product from "./pages/Product/Product";
import Airtime from "./pages/Product/Renew/Airtime";
import ConfirmAirtimePurchase from "./pages/Product/Renew/ConfirmAirtimePurchase";
import ConfirmDataPurchase from "./pages/Product/Renew/ConfirmDataPurchase";
import Renew from "./pages/Product/Renew/Renew";
import Setup2fa from "./pages/Setting/Security/Setup2fa";
import Setting from "./pages/Setting/Setting";
import AddBank from "./pages/Setup/AddBank";
import AllTransactions from "./pages/Transaction/AllTransactions";
import ConfirmDeposit from "./pages/Wallet/ConfirmDeposit";
import NairaDeposit from "./pages/Wallet/NairaDeposit";
import Wallet from "./pages/Wallet/Wallet";
import ChooseWithdrawal from "./pages/Wallet/Withdrawal/ChooseWithdrawal";
import ConfirmWithdrawal from "./pages/Wallet/Withdrawal/ConfirmWithdrawal";
import JessieUserWithdrawal from "./pages/Wallet/Withdrawal/JessieUserWithdrawal";
import P2pWithdrawal from "./pages/Wallet/Withdrawal/P2pWithdrawal";
import { getUserSetupStatus } from "./redux/actions/setupActions";
import { loadToken } from "./redux/token";
import EnterCurrentPassword from "./pages/Setting/Security/Password/EnterCurrentPassword";
import EnterOtpForPasswordReset from "./pages/Setting/Security/Password/EnterOtpForPasswordReset";
import EnterNewPassword from "./pages/Setting/Security/Password/EnterNewPassword";
import EnterCurrentPasswordForPin from "./pages/Setting/Security/Pin/EnterCurrentPasswordForPin";
import EnterOtpForPinReset from "./pages/Setting/Security/Pin/EnterOtpForPinReset";
import EnterNewPin from "./pages/Setting/Security/Pin/EnterNewPin";

const RouteCmp = ({ userSetupStatus, setUserSetupStatus, auth }) => {
  useEffect(() => {
    setUserSetupStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/signup/business" exact element={<SignupBusiness />} />
        <Route path="/signup/individual" element={<SignupIndividual />} />
        <Route path="/forgot/password" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPassword />} />
        <Route path="/verify-email/phone/:token" element={<AddPhone />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        {!auth.authenticated ||
          (!loadToken() && (
            <Route path="*" element={<Navigate to="/login" />} />
          ))}

        <Route path="/" element={<PrivateRoute />}>
          {userSetupStatus &&
            userSetupStatus?.data?.isTxnPinSet === true &&
            userSetupStatus?.data?.bankVerifiedOnRegister === true && (
              <>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="wallets" element={<Wallet />} />
                <Route path="markets" element={<Market />} />
                <Route
                  path="wallets/deposit/naira"
                  element={<NairaDeposit />}
                />
                <Route
                  path="wallets/deposit/confirm"
                  element={<ConfirmDeposit />}
                />
                <Route
                  path="wallets/withdrawal/choose"
                  element={<ChooseWithdrawal />}
                />
                <Route
                  path="wallets/withdrawal/p2p"
                  element={<P2pWithdrawal />}
                />
                <Route
                  path="wallets/withdrawal/instant"
                  element={<JessieUserWithdrawal />}
                />
                <Route
                  path="wallets/withdrawal/confirm"
                  element={<ConfirmWithdrawal />}
                />
                <Route path="products" element={<Product />} />
                <Route path="products/renew" element={<Renew />} />
                <Route
                  path="products/renew/airtime-data-sub"
                  element={<Airtime />}
                />
                <Route
                  path="products/renew/airtime/confirm"
                  element={<ConfirmAirtimePurchase />}
                />
                <Route
                  path="products/renew/data/confirm"
                  element={<ConfirmDataPurchase />}
                />
                <Route
                  path="products/renew/cable-internet-sub"
                  element={<CableInternet />}
                />
                <Route
                  path="products/renew/cable/confirm"
                  element={<ConfirmCablePurchase />}
                />
                <Route
                  path="products/renew/internet/confirm"
                  element={<ConfirmInternetPurchase />}
                />
                <Route
                  path="products/renew/electricity"
                  element={<Electricity />}
                />
                <Route
                  path="products/renew/electricity/confirm"
                  element={<ConfirmElectricityPurchase />}
                />
                <Route
                  path="products/renew/educational"
                  element={<Educational />}
                />
                <Route
                  path="products/renew/waec/confirm"
                  element={<ConfirmWaecPurchase />}
                />
                <Route
                  path="products/renew/jamb/confirm"
                  element={<ConfirmJambPurchase />}
                />
                <Route path="products/giftcard" element={<Giftcard />} />
                <Route
                  path="products/giftcard/buy/international"
                  element={<BuyGiftcard />}
                />
                <Route
                  path="products/giftcard/buy/international/:brandId"
                  element={<BuyGiftcardCountry />}
                />
                <Route
                  path="products/giftcard/buy/card-value"
                  element={<BuyGiftcardValue />}
                />
                <Route
                  path="products/giftcard/buy/summary"
                  element={<BuyGiftcardSummary />}
                />
                <Route
                  path="products/giftcard/sell/international"
                  element={<SellGiftcard />}
                />
                <Route
                  path="products/giftcard/sell/international/:brandId"
                  element={<SellGiftcardCountry />}
                />
                <Route
                  path="products/giftcard/sell/card-value"
                  element={<SellGiftcardValue />}
                />
                <Route
                  path="products/giftcard/sell/summary"
                  element={<SellGiftcardSummary />}
                />
                <Route
                  path="products/giftcard/sell/upload"
                  element={<SellGiftcardUpload />}
                />
                <Route path="transaction-log" element={<AllTransactions />} />
                <Route path="settings" element={<Setting />} />
                <Route path="settings/2fa/setup" element={<Setup2fa />} />
                <Route
                  path="settings/reset-password/provide"
                  element={<EnterCurrentPassword />}
                />
                <Route
                  path="settings/reset-password/otp"
                  element={<EnterOtpForPasswordReset />}
                />
                <Route
                  path="settings/reset-password/new"
                  element={<EnterNewPassword />}
                />
                <Route
                  path="settings/reset-pin/provide"
                  element={<EnterCurrentPasswordForPin />}
                />
                <Route
                  path="settings/reset-pin/otp"
                  element={<EnterOtpForPinReset />}
                />
                <Route
                  path="settings/reset-pin/new"
                  element={<EnterNewPin />}
                />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </>
            )}
          {!userSetupStatus ||
            (userSetupStatus?.data?.isTxnPinSet === false &&
              userSetupStatus?.data?.bankVerifiedOnRegister === false && (
                <>
                  <Route
                    index
                    element={<Navigate to="/dashboard/setup/add-bank" />}
                  />
                  <Route
                    path="dashboard/setup/add-bank"
                    element={<AddBank />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to="/dashboard/setup/add-bank" />}
                  />
                </>
              ))}
        </Route>
      </Routes>
    </>
  );
};

const mapState = (state) => ({
  userSetupStatus: state.setup.userSetupStatus,
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  setUserSetupStatus: () => dispatch(getUserSetupStatus()),
});

export default connect(mapState, mapDispatch)(RouteCmp);
