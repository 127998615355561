import airtimeReducer from "./airtimeReducer";
import authReducer from "./authReducer";
import cableReducer from "./cableReducer";
import dashboardReducer from "./dashboardReducer";
import dataReducer from "./dataReducer";
import electricityReducer from "./electricityReducer";
import setupReducer from "./setupReducer";
import walletReducer from "./walletReducer";
import transactionReducer from "./transactionReducer";
import educationalReducer from "./educationalReducer";
import giftcardReducer from "./giftcardReducer";
import twoFaReducer from "./twoFaReducer";
import settingReducer from "./settingReducer";

const rootReducer = {
  auth: authReducer,
  twoFa: twoFaReducer,
  setup: setupReducer,
  dashboard: dashboardReducer,
  wallet: walletReducer,
  data: dataReducer,
  airtime: airtimeReducer,
  cable: cableReducer,
  electricity: electricityReducer,
  educational: educationalReducer,
  giftcard: giftcardReducer,
  transaction: transactionReducer,
  setting: settingReducer,
};

export default rootReducer;
