import {
  GET_ALL_TRANSACTIONS_ERROR,
  GET_ALL_TRANSACTIONS_LOADING,
  GET_ALL_TRANSACTIONS_SUCCESS,
} from "./types";

const initialState = {
  allTransactionLoading: null,
  allTransaction: null,
  allTransactionError: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TRANSACTIONS_LOADING:
      return { ...state, allTransactionsLoading: action.payload };
    case GET_ALL_TRANSACTIONS_SUCCESS:
      return { ...state, allTransactions: action.payload };
    case GET_ALL_TRANSACTIONS_ERROR:
      return { ...state, allTransactionsError: action.payload };
    default:
      return state;
  }
}
