import React from "react";
import DashboardLayout from "../../../../components/DashboardLayout/DashboardLayout";
import styled from "styled-components";
import EnterCurrentPasswordForm from "../EnterCurrentPasswordForm";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  @media only screen and (max-width: 767px) {
    padding: 1rem 0;
  }
`;

const Wrapper = styled.div`
  max-width: 491px;
  width: 100%;
  border-radius: 24px;
  background: #fff;
  box-shadow: 1px 1px 17px 0px rgba(218, 216, 216, 0.33);
  padding: 3rem 1.5rem;

  @media only screen and (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TitleText = styled.h2`
  color: #323232;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
`;

const SmallText = styled.p`
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
`;

const EnterCurrentPassword = () => {
  return (
    <>
      <DashboardLayout backButton={true}>
        <Container>
          <Wrapper>
            <TextWrapper>
              <TitleText>Reset Password</TitleText>
              <SmallText>Enter your current password to continue</SmallText>
            </TextWrapper>
            <EnterCurrentPasswordForm path={"/settings/reset-password/otp"} />
          </Wrapper>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default EnterCurrentPassword;
