import { ArrowBack } from "@styled-icons/boxicons-regular";
import React from "react";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import AuthenticatorSvg from "../../../assets/authenticator.svg";
import AppleSvg from "../../../assets/apple.svg";
import PlaystoreSvg from "../../../assets/playstore.svg";

const Wrapper = styled.div``;

const BackIconWrapper = styled.div`
  cursor: pointer;
`;

const Title = styled.h2`
  text-align: center;
  color: #323232;
  font-size: 1.75rem;
  font-weight: 700;
  max-width: 325px;
  margin: 0 auto;
`;

const Desc = styled.p`
  color: #323232;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 400;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AuthImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const AuthImg = styled.img``;

const StoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding-bottom: 1rem;
`;

const PlaystoreImg = styled.img``;

const AppleImg = styled.img``;

const TwoFaStep2 = ({ setStep }) => {
  return (
    <>
      <Wrapper>
        <BackIconWrapper
          onClick={() => {
            setStep(1);
          }}
        >
          <ArrowBack color="#323232" size={20} />
        </BackIconWrapper>
        <Title>Let’s setup your account!</Title>
        <Desc>Download and install the Google Authentication app</Desc>
        <AuthImgWrapper>
          <AuthImg src={AuthenticatorSvg} alt="Auth-image" />
        </AuthImgWrapper>
        <StoreWrapper>
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <PlaystoreImg src={PlaystoreSvg} alt="Google Playstore" />
          </a>
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
            rel="noreferrer"
          >
            <AppleImg src={AppleSvg} alt="Apple Store" />
          </a>
        </StoreWrapper>
        <ButtonWrapper>
          <SolidButton
            text={"Continue"}
            type="submit"
            weighty="500"
            textFontSize="14px"
            specifyPadding="0.5rem 1rem"
            color="#fff"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            maxWidth="250px"
            borderRadius="8px"
            backColor="#1a8fdd"
            backgroundHoverColor="#1a8fdd"
            textHoverColor="#fff"
            margin="0rem auto 0"
            onClick={() => {
              setStep(3);
            }}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default TwoFaStep2;
