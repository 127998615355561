import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
// import InsuranceSvg from "../../../assets/insurance-svg.svg";
import EducationalSvg from "../../../assets/educational-payment-svg.svg";
import ElectricitySvg from "../../../assets/electricity-bill-svg.svg";
import CableSvg from "../../../assets/cable-tv-svg.svg";
import AirtimeDataSvg from "../../../assets/airtime-data-sub-svg.svg";
import SolidButton from "../../../components/ButtonCmp/Button";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div``;

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ProductCmpWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  flex: 0 0 32%;
  max-width: 32%;
  width: 100%;
  height: 156px;
  border-radius: 24px;
  background-color: #fff;
  cursor: pointer;

  @media only screen and (max-width: 1263px) {
    flex: 0 0 48%;
    max-width: 48%;
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
`;

const ProductContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => `${props.textColor}`};
`;

const ProductTitle = styled.h2`
  font-weight: 600;
  font-size: 16px;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 57px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconImg = styled.img``;

const Renew = () => {
  const navigate = useNavigate();
  const ProductCmp = ({ title, iconImg, link }) => {
    return (
      <>
        <ProductCmpWrapper>
          <ProductWrapper>
            <ProductContentWrapper>
              <ProductTitle>{title}</ProductTitle>
              <div>
                <SolidButton
                  onClick={() => {
                    navigate(link);
                  }}
                  text={"Buy Now"}
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.7rem"
                  color="#fff"
                  widthWebkit="90px"
                  widthMoz="90px"
                  widthNormal="90px"
                  maxWidth="90px"
                  borderRadius="10px"
                  backColor="#0092C6"
                  backgroundHoverColor="#0092C6"
                  textHoverColor="#fff"
                  disabled={false}
                />
              </div>
            </ProductContentWrapper>
            <IconWrapper>
              <IconImg src={iconImg} alt={title} />
            </IconWrapper>
          </ProductWrapper>
        </ProductCmpWrapper>
      </>
    );
  };
  const productsArr = [
    {
      title: "Airtime & Data Subscription",
      iconImg: `${AirtimeDataSvg}`,
      link: "/products/renew/airtime-data-sub",
    },
    {
      title: "Cable  TV & Internet Subscription",
      iconImg: `${CableSvg}`,
      link: "/products/renew/cable-internet-sub",
    },
    {
      title: "Electricity Bill Payments",
      iconImg: `${ElectricitySvg}`,
      link: "/products/renew/electricity",
    },
    {
      title: "Educational Payment",
      iconImg: `${EducationalSvg}`,
      link: "/products/renew/educational",
    },
    // {
    //   title: "Buy Insurance",
    //   iconImg: `${InsuranceSvg}`,
    //   link: "/products/renew/insurance",
    // },
  ];
  return (
    <>
      <DashboardLayout backButton={true} backText="Renew">
        <Wrapper>
          <ItemContainer>
            {productsArr.map((v, i) => {
              return (
                <>
                  <ProductCmp
                    key={i}
                    iconImg={v.iconImg}
                    title={v.title}
                    link={v.link}
                  />
                </>
              );
            })}
          </ItemContainer>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Renew;
