import { ArrowBack } from "@styled-icons/boxicons-regular";
import React from "react";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import LockSvg from "../../../assets/lock.svg";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import { Formik } from "formik";
import setup2faSchema from "../../../validators/setup2faValidator";
import { toast } from "react-toastify";
import { verify2fa } from "../../../redux/actions/settingActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../../constants/theme";

const Wrapper = styled.div``;

const BackIconWrapper = styled.div`
  cursor: pointer;
`;

const Title = styled.h2`
  text-align: center;
  color: #323232;
  font-size: 1.75rem;
  font-weight: 700;
  max-width: 325px;
  margin: 0 auto;
`;

const Desc = styled.p`
  color: #323232;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 400;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AuthImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const AuthImg = styled.img``;

const InputWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 1rem auto;
`;

const TwoFaStep4 = ({
  setStep,
  setVerify2faCode,
  verify2faCodeError,
  verify2faCodeLoading,
  verify2faCodeSuccess,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Wrapper>
        <BackIconWrapper
          onClick={() => {
            setStep(3);
          }}
        >
          <ArrowBack color="#323232" size={20} />
        </BackIconWrapper>
        <AuthImgWrapper>
          <AuthImg src={LockSvg} alt="Lock" />
        </AuthImgWrapper>
        <Title>Secret Key</Title>
        <Desc>
          Enter secret key displayed on your authenticator app to complete the
          process
        </Desc>
        <Formik
          validationSchema={setup2faSchema}
          initialValues={{
            secretKey: "",
          }}
          onSubmit={async (values, { resetForm }) => {
            // resetForm();

            const response = await setVerify2faCode({
              code: `${values?.secretKey}`,
            });

            if (
              response &&
              response.status === 200 &&
              response.message === "2FA setup successfully"
            ) {
              navigate("/settings");
              return toast.success("2FA Enabled on your account ");
            }
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <>
              <form noValidate onSubmit={handleSubmit}>
                <InputWrapper>
                  <CustomInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    type="number"
                    name="secretKey"
                    placeholder=""
                    values={values}
                    onChange={handleChange}
                    message={`${
                      errors["secretKey"] ? errors["secretKey"] : ""
                    }`}
                    showMessage={false}
                  />
                </InputWrapper>
                <ButtonWrapper>
                  <SolidButton
                    text={
                      verify2faCodeLoading ? (
                        <>
                          <SpinnerCmp
                            enabled={true}
                            color={appTheme.COLORS.bluePrimary}
                            size={20}
                            secondaryColor={appTheme.COLORS.white}
                          />
                        </>
                      ) : (
                        "Continue"
                      )
                    }
                    type="submit"
                    weighty="500"
                    textFontSize="14px"
                    specifyPadding="0.5rem 1rem"
                    color="#fff"
                    widthWebkit="100%"
                    widthMoz="100%"
                    widthNormal="100%"
                    maxWidth="150px"
                    borderRadius="8px"
                    backColor="#1a8fdd"
                    backgroundHoverColor="#1a8fdd"
                    textHoverColor="#fff"
                    margin="0rem auto 0"
                    disabled={verify2faCodeLoading}
                  />
                </ButtonWrapper>
              </form>
            </>
          )}
        </Formik>
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  verify2faCodeLoading: state.setting.verify2faCodeLoading,
  verify2faCodeError: state.setting.verify2faCodeError,
  verify2faCodeSuccess: state.setting.verify2faCodeSuccess,
});

const mapDispatch = (dispatch) => ({
  setVerify2faCode: (data) => dispatch(verify2fa(data)),
});

export default connect(mapState, mapDispatch)(TwoFaStep4);
