import React, { useEffect, useState } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { Form } from "react-bootstrap";
import CustomInputCmp from "../InputsCmp/CustomInputCmp";
import { InfoCircle } from "@styled-icons/boxicons-regular/InfoCircle";
import SolidButton from "../ButtonCmp/Button";
import CustomInputSelectCmp from "../InputsCmp/CustomInputSelectCmp";
import { Formik, useFormikContext } from "formik";
import addBankFormIndividualSchema from "../../validators/addBankFormIndividualValidator";
import {
  fillBankDetails,
  getAccountNumberInfo,
  getListOfBanks,
} from "../../redux/actions/setupActions";
import { connect } from "react-redux";
import SuccessModal from "../Modals/SuccessModal";
import SetPinModal from "../Modals/SetPinModal";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";

const Wrapper = styled(Form)`
  padding: 3rem 0;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputWrapper = styled.div``;

const FormTitle = styled.h2`
  color: #323232;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 1rem;
  padding-bottom: 1.5rem;
`;

const FormTitle2 = styled(FormTitle)`
  padding: 1.5rem 0;
`;

const NoteWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  gap: 5px;
  align-items: flex-start;
`;

const IconWrapper = styled.div``;

const InfoIcon = styled(InfoCircle)`
  opacity: 0.2;
`;

const InfoText = styled.h2`
  font-size: 11px;
  font-weight: ${appTheme.WEIGHT.small};
  color: #323232;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  text-align: right;
`;

const AddBankFormIndividual = ({
  setGetBankList,
  setFillBankDetails,
  loading,
  bankList,
  error,
}) => {
  const [bankDetailsSuccess, setBankDetailsSuccess] = useState(false);
  const [showTxnPinModal, setShowTxnPinModal] = useState(false);
  const FetchAccountDetails = (props) => {
    const {
      values: { accountNumber, bankCode },
      setFieldValue,
    } = useFormikContext();

    async function getAccount() {
      const response = await getAccountNumberInfo(bankCode, accountNumber);
      return response;
    }

    useEffect(() => {
      async function fetchAccountName() {
        if (
          accountNumber &&
          accountNumber.length === 10 &&
          bankCode &&
          bankCode.length > 0
        ) {
          const response = await getAccount();
          setFieldValue("accountName", response.accountName);
        } else {
          setFieldValue("accountName", "");
        }
      }

      fetchAccountName();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountNumber, bankCode, setFieldValue]);

    return (
      <>
        <CustomInputCmp
          background="rgba(225, 235, 245, 0.1)"
          required={true}
          type="text"
          name="accountName"
          placeholder="Account Name"
          disabled={true}
          value={props.value}
          onChange={props.handleChange}
          message={props.error}
          showMessage={false}
        />
      </>
    );
  };

  useEffect(() => {
    async function callGetBankList() {
      await setGetBankList();
    }

    callGetBankList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Formik
      validationSchema={addBankFormIndividualSchema}
      initialValues={{
        accountNumber: "",
        accountName: "",
        bankName: "",
        bankCode: "",
        bvn: "",
      }}
      onSubmit={async (values, { resetForm }) => {
        // resetForm();

        const response = await setFillBankDetails({
          ...values,
        });

        if (
          response &&
          response.status === 201 &&
          response.message === "Bank details created successfully"
        ) {
          return setBankDetailsSuccess(true);
        }
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <>
          <Wrapper noValidate onSubmit={handleSubmit}>
            <Container>
              <FormTitle>Add Bank Account</FormTitle>
              <FormWrapper>
                <InputWrapper>
                  <CustomInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    type="text"
                    name="accountNumber"
                    placeholder="Enter Account Number"
                    values={values}
                    onChange={handleChange}
                    message={`${
                      errors["accountNumber"] ? errors["accountNumber"] : ""
                    }`}
                    showMessage={false}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInputSelectCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    name="bankName"
                    values={values}
                    onChange={handleChange}
                    placeholder="Select Bank"
                    data={
                      bankList &&
                      bankList.length > 0 &&
                      bankList.map((v, i) => {
                        return v.name;
                      })
                    }
                    message={`${errors["bankName"] ? errors["bankName"] : ""}`}
                    showMessage={false}
                  />
                </InputWrapper>
                <InputWrapper style={{ display: "none" }}>
                  <CustomInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    type="text"
                    name="bankCode"
                    placeholder="Bank Code"
                    disabled={true}
                    value={
                      (values.bankCode =
                        bankList &&
                        bankList.length > 0 &&
                        bankList.filter((obj) => {
                          return obj.name === values.bankName;
                        })[0]?.code)
                    }
                    onChange={handleChange}
                    message={`${errors["bankCode"] ? errors["bankCode"] : ""}`}
                    showMessage={false}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FetchAccountDetails
                    error={`${
                      errors["accountName"] ? errors["accountName"] : ""
                    }`}
                    handleChange={handleChange}
                    value={values.accountName}
                  />
                </InputWrapper>
              </FormWrapper>
              <FormTitle2>Add BVN Verification</FormTitle2>
              <FormWrapper>
                <InputWrapper>
                  <CustomInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    type="text"
                    name="bvn"
                    placeholder="Enter Bank Verification Number"
                    values={values}
                    onChange={handleChange}
                    message={`${errors["bvn"] ? errors["bvn"] : ""}`}
                    showMessage={false}
                  />
                </InputWrapper>
              </FormWrapper>
              <NoteWrapper>
                <IconWrapper>
                  <InfoIcon size={18} color="#000" />
                </IconWrapper>
                <InfoText>
                  We are a digitial bank and just like your regular bank, we
                  need your BVN to be able to process transcation. Dial *565*0#
                  on your mobile phone to get your BVN.
                </InfoText>
              </NoteWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Add Account"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="1rem 1rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  maxWidth="120px"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={loading ? true : false}
                />
              </ButtonWrapper>
            </Container>
            <SuccessModal
              title="Congratulations, you have successfully set up your account"
              titleMaxWidth="300px"
              maxWidth="400px"
              // showLogo={false}
              // wrapperPadding={"2rem 1rem 2rem"}
              showCloseIcon={false}
              isOpen={bankDetailsSuccess}
              heading="Verification Successful!"
              buttonCloseText="Set Transaction Pin"
              onRequestClose={() => {
                setShowTxnPinModal(true);
              }}
              actionButtonWidth="200px"
            />
            <SetPinModal isOpen={showTxnPinModal} />
          </Wrapper>
        </>
      )}
    </Formik>
  );
};

const mapState = (state) => ({
  loading: state.setup.loading,
  bankList: state.setup.bankList,
  error: state.setup.error,
});

const mapDispatch = (dispatch) => ({
  setGetBankList: () => dispatch(getListOfBanks()),
  setFillBankDetails: (data) => dispatch(fillBankDetails(data)),
});

export default connect(mapState, mapDispatch)(AddBankFormIndividual);
