import Dashboard from "../../assets/dashboard.svg";
import DashboardHover from "../../assets/dashboard-hover.svg";
import Wallets from "../../assets/wallets.svg";
import WalletsHover from "../../assets/wallets-hover.svg";
import Products from "../../assets/products.svg";
import ProductsHover from "../../assets/products-hover.svg";
import TransactionLog from "../../assets/transaction-log.svg";
import TransactionLogHover from "../../assets/transaction-log-hover.svg";
import Markets from "../../assets/markets.svg";
import MarketsHover from "../../assets/markets-hover.svg";
import Settings from "../../assets/settings.svg";
import SettingsHover from "../../assets/settings-hover.svg";
import Help from "../../assets/help.svg";
import HelpHover from "../../assets/help-hover.svg";

// const sidebarData = [
//   {
//     title: "Dashboard",
//     children: [
//       {
//         title: "Home",
//         path: "/",
//       },
//       {
//         title: "About",
//       },
//       {
//         title: "FAQ",
//       },
//     ],
//   },
//   {
//     title: "Dashboard",
//     children: [
//       {
//         title: "Home",
//         path: "/",
//       },
//       {
//         title: "About",
//       },
//       {
//         title: "FAQ",
//       },
//     ],
//   },
//   {
//     title: "Support",
//   },
// ];

const sidebarData = [
  {
    title: "Dashboard",
    link: "/dashboard",
    submenu: false,
    icons: [`${Dashboard}`, `${DashboardHover}`],
  },
  {
    title: "Wallets",
    link: "/wallets",
    submenu: false,
    icons: [`${Wallets}`, `${WalletsHover}`],
  },
  {
    title: "Products",
    link: "/products",
    submenu: false,
    icons: [`${Products}`, `${ProductsHover}`],
  },
  {
    title: "Transaction Log",
    link: "/transaction-log",
    submenu: false,
    icons: [`${TransactionLog}`, `${TransactionLogHover}`],
  },
  {
    title: "Markets",
    link: "/markets",
    submenu: false,
    icons: [`${Markets}`, `${MarketsHover}`],
  },
  {
    title: "Settings",
    link: "/settings",
    submenu: false,
    icons: [`${Settings}`, `${SettingsHover}`],
  },
  {
    title: "Help",
    link: "/help",
    submenu: false,
    icons: [`${Help}`, `${HelpHover}`],
  },
];

export default sidebarData;
