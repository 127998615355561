import React from "react";
import PinInput from "react-pin-input";
import appTheme from "../../constants/theme";

const CustomPinInputCmp = (props) => {
  return (
    <>
      <PinInput
        length={props.length}
        initialValue={props.initialValue}
        disabled={props.disabled}
        secret={props.secret}
        // onChange={(value, index) => {}}
        onChange={props.onChange}
        type={props.type || "numeric"}
        inputMode="numeric"
        min={0}
        style={{ padding: "10px" }}
        inputStyle={{
          border: "none",
          backgroundColor: "#f4f5f7",
          borderRadius: "10px",
          margin: "4px 4px",
          width: "45px",
          height: "45px",
        }}
        inputFocusStyle={{ border: `1px solid ${appTheme.COLORS.bluePrimary}` }}
        onComplete={(value, index) => {
          props.onComplete(value, index);
        }}
        autoSelect={props.autoSelect}
        // regexCriteria={props.regexCriteria || /^[ A-Za-z0-9_@./#&+-]*$/}
        regexCriteria={props.regexCriteria || /^[0-9]*$/}
      />
    </>
  );
};

export default CustomPinInputCmp;
