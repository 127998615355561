import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import { Copy } from "@styled-icons/boxicons-regular";
import SolidButton from "../../../components/ButtonCmp/Button";
import { Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import SuccessModal from "../../../components/Modals/SuccessModal";
import PinModal from "../../../components/Modals/PinModal";
import { buyElectricity } from "../../../redux/actions/electricityActions";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterWrapper = styled.div`
  background: rgba(196, 196, 196, 0.05);
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  /* padding: 1rem; */
`;

const Wrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1rem;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 500px;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  padding-top: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const FooterWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
`;

const FooterTitle = styled.h2`
  color: #323232;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
`;

const FooterText = styled.p`
  font-size: 12px;
  color: #323232;
  margin: 0 auto;
  text-align: center;
  max-width: 240px;
`;

const BarWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 8px;
  background-color: #f4f7fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const BarTitle = styled.h2`
  font-weight: 400;
  color: #323232;
  font-size: 14px;
  margin-bottom: 0;
`;

const BarValue = styled.h2`
  font-weight: 600;
  color: #323232;
  font-size: 15px;
  margin-bottom: 0;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 1rem;
`;

const ConfirmElectricityPurchase = ({
  setBuyElectricity,
  buyElectricityError,
  buyElectricityLoading,
  buyElectricitySuccess,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location.state) {
      navigate("/products/renew/electricity");
    }
  }, [location.state, navigate]);

  const [pin, setPin] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);

  const BarCmp = (props) => {
    return (
      <>
        <BarWrapper>
          <BarTitle>{props.title}</BarTitle>
          <div style={{ position: "relative" }}>
            <BarValue>{props.value}</BarValue>
            {props.clipboard && (
              <>
                <CopyToClipboard
                  text={props.value}
                  onCopy={() => {
                    toast.success("Copied!", { toastId: "Clipboard" });
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "-12px",
                      right: "-10px",
                      cursor: "pointer",
                    }}
                  >
                    <Copy size={12} color="#016CBA" />
                  </div>
                </CopyToClipboard>
              </>
            )}
          </div>
        </BarWrapper>
      </>
    );
  };
  return (
    <>
      <DashboardLayout backButton={true} backText={"Back to Renew"}>
        <Container>
          <OuterWrapper>
            <Wrapper>
              <DetailContainer>
                <BarCmp title="Transaction" value={"Electricity Purchase"} />
                <BarCmp
                  title="Subscriber Name"
                  value={location.state?.subscriberName}
                />
                <BarCmp
                  title="Provider"
                  value={
                    location.state?.provider.toUpperCase() ||
                    location.state?.otherProvider?.value.toUpperCase()
                  }
                />
                <BarCmp
                  title="Product Type"
                  value={location.state?.checkedValue.toUpperCase()}
                />
                <BarCmp
                  title="Amount"
                  value={
                    <NumberFormat
                      thousandSeparator
                      value={location.state?.amount}
                      suffix=" NGN"
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  }
                />
                <BarCmp
                  title="Convenience Fee"
                  value={
                    <NumberFormat
                      thousandSeparator
                      value={location.state?.charge}
                      suffix=" NGN"
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  }
                />
                <BarCmp title="Phone" value={location.state?.fullPhoneNo} />
                <BarCmp
                  title="Deducted from Wallet"
                  value={
                    <NumberFormat
                      thousandSeparator
                      value={
                        parseFloat(location.state?.amount) +
                        parseFloat(location?.state.charge)
                      }
                      suffix=" NGN"
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  }
                />
              </DetailContainer>
              <FooterWrapper>
                <FooterTitle>Confirm your transaction</FooterTitle>
                <FooterText>
                  Review all the details of your transaction before proceeding
                </FooterText>
              </FooterWrapper>
              <Wrapper2>
                <ButtonWrapper>
                  <SolidButton
                    text={"Continue"}
                    type="submit"
                    weighty="500"
                    textFontSize="14px"
                    specifyPadding="0.8rem 1.5rem"
                    color="#fff"
                    widthWebkit="200px"
                    widthMoz="200px"
                    widthNormal="200px"
                    borderRadius="8px"
                    backColor="#1a8fdd"
                    backgroundHoverColor="#1a8fdd"
                    textHoverColor="#fff"
                    margin="0rem auto 0"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPinModal(true);
                    }}
                  />
                </ButtonWrapper>
              </Wrapper2>
              <FooterText
                style={{ color: "#D60000", cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel transaction
              </FooterText>
            </Wrapper>
          </OuterWrapper>
        </Container>
        <SuccessModal
          heading="Transaction Success"
          title="Your bill transaction was successful"
          titleMaxWidth="300px"
          maxWidth="400px"
          //   showLogo={true}
          // wrapperPadding={"2rem 1rem 2rem"}
          showCloseIcon={false}
          isOpen={showModal}
          buttonCloseText="Done"
          onRequestClose={() => {
            setShowModal(false);
            navigate("/products/renew/electricity");
          }}
          actionButtonWidth="200px"
        />
        <PinModal
          isOpen={showPinModal}
          onRequestClose={() => {
            setShowPinModal(false);
          }}
          setPin={setPin}
          loading={buyElectricityLoading}
          txnAction={async () => {
            const response = await setBuyElectricity({
              ...location?.state,
              phone: location?.state?.fullPhoneNo,
              billersCode: `${location?.state?.meterNumber}`,
              variationCode: `${location?.state?.checkedValue}`,
              serviceId:
                location.state?.provider ||
                location.state?.otherProvider?.value,
              charge: location?.state?.charge,
              pin,
            });
            if (
              response &&
              response.message === "Electricity purchase successful"
            ) {
              setShowPinModal(false);
              setShowModal(true);
            } else {
              setShowPinModal(false);
            }
          }}
        />
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  buyElectricityLoading: state.electricity.buyElectricityLoading,
  buyElectricitySuccess: state.electricity.buyElectricitySuccess,
  buyElectricityError: state.electricity.buyElectricityError,
});

const mapDispatch = (dispatch) => ({
  setBuyElectricity: (data) => dispatch(buyElectricity(data)),
});

export default connect(mapState, mapDispatch)(ConfirmElectricityPurchase);
