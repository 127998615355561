import { Formik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import CustomMultiSelect from "../../../components/InputsCmp/CustomMultiSelect";
import CustomTextAreaCmp from "../../../components/InputsCmp/CustomTextAreaCmp";
import CustomPriceInputCmp from "../../../components/InputsCmp/CustomPriceInputCmp";
import bankListWithIcon from "../../../constants/bankListWithIcon";
import appTheme from "../../../constants/theme";
import NairaIcon from "../../../assets/naira.svg";
import { InfoCircle } from "@styled-icons/boxicons-regular";
import Dropdown from "rc-dropdown";
import { useState } from "react";
import {
  getSettings,
  getUserBankAccountList,
} from "../../../redux/actions/walletActions";
import { connect } from "react-redux";
import p2pWithdrawalSchema from "../../../validators/p2pWithdrawalValidator";
import NumberFormat from "react-number-format";
import { getUserBalance } from "../../../redux/actions/dashboardAction";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const OuterWrapper = styled.div`
  background: rgba(196, 196, 196, 0.05);
  border-radius: 20px;
  max-width: 700px;
  width: 100%;
  /* padding: 1rem; */
`;

const Wrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1rem;
  border-radius: 20px;
`;

const DescText = styled.p`
  font-weight: 600;
  font-size: 20px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  color: #323232;
  padding: 1rem 0;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  padding-top: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const NairaImg = styled.img``;

const SelectWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectText = styled.p`
  margin: 0;
  margin-bottom: 0.1rem;
  font-size: 14px;
`;

const FooterWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const FooterText = styled.p`
  font-size: 12px;
  color: #323232;
  margin-bottom: 0;
  text-align: left;
`;

const InfoText = styled.p`
  margin-bottom: 0.1rem;
  color: #016cba;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
`;

const InfoSpan = styled.span`
  font-weight: 600;
`;

const DropdownMenuWrapper = styled.div`
  padding: 1rem;
  width: 180px;
  height: 182px;
  background-color: ${appTheme.COLORS.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
`;

const DropdownContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DropdownContent = styled.p`
  font-size: 12px;
  color: #323232;
  font-weight: ${appTheme.WEIGHT.small2};
`;

const P2pWithdrawal = ({
  loading,
  setGetSettings,
  settingsSuccess,
  settingsLoading,
  settingsError,
  bankDetailListError,
  bankDetailListLoading,
  bankDetailListSuccess,
  setGetUserBankAccountList,
  setGetUserBalance,
  dashboardBalance,
}) => {
  const [settingsData, setSettingsData] = useState({});
  const [bankListData, setBankListData] = useState([""]);

  const navigate = useNavigate();

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetSettings();
      const bankRespData = await setGetUserBankAccountList();
      await setGetUserBalance();

      setSettingsData(response.data);
      setBankListData(bankRespData.data.bankDetail);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [, setDropdownVisible] = useState();
  const DropdownMenu = () => {
    return (
      <>
        <DropdownMenuWrapper>
          <DropdownContentWrapper>
            <DropdownContent>
              Withdrawal Fee: In a bid to achieve a secure and seamless
              transaction, we partner with some financial institutions to
              facilitate transactions. This fee serves as a premium for this
              service.
            </DropdownContent>
          </DropdownContentWrapper>
        </DropdownMenuWrapper>
      </>
    );
  };
  return (
    <>
      <DashboardLayout backButton={true} backText={"Back to Wallet"}>
        <Container>
          <OuterWrapper>
            <Wrapper>
              <DescText>P2P Withdrawal</DescText>
              <Formik
                enableReinitialize={true}
                validationSchema={p2pWithdrawalSchema}
                initialValues={{
                  bank: null,
                  amount: "",
                  narration: "",
                  charge: settingsData?.peerWithdrawalFee,
                  balance: dashboardBalance?.data?.balance,
                }}
                onSubmit={async (values, { resetForm }) => {
                  navigate("/wallets/withdrawal/confirm", {
                    state: { ...values, withdrawalType: "p2p" },
                  });
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  setFieldTouched,
                  setFieldValue,
                  touched,
                }) => (
                  <>
                    <Wrapper2 noValidate onSubmit={handleSubmit}>
                      <FormWrapper>
                        <InputWrapper>
                          <InputLabel>Select Account</InputLabel>
                          <CustomMultiSelect
                            name="bank"
                            placeholder="Select Account"
                            isMulti={false}
                            isSearchable={false}
                            options={[
                              ...bankListData.map((v, i) => {
                                return {
                                  ...v,
                                  value: v.bankName,
                                  label: v.bankName,
                                };
                              }),
                            ]}
                            formatOptionLabel={(label) => (
                              <>
                                <SelectWrapper>
                                  {label && label.label && (
                                    <img
                                      style={{ width: "50px" }}
                                      src={
                                        bankListWithIcon.find(
                                          (el) => el.name === label.label
                                        )?.logo
                                      }
                                      alt={label.label}
                                    />
                                  )}
                                  <div>
                                    <SelectText>{label.label}</SelectText>
                                    <SelectText>
                                      {label.accountNumber}
                                    </SelectText>
                                  </div>
                                </SelectWrapper>
                              </>
                            )}
                            message={`${errors["bank"] ? errors["bank"] : ""}`}
                            showMessage
                            value={values.bank}
                            isValid={isValid}
                            onBlur={setFieldTouched}
                            onChange={setFieldValue}
                            touched={touched}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel>Withdrawal Amount</InputLabel>
                          <CustomPriceInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            icon={
                              <>
                                <NairaImg src={NairaIcon} alt="Naira" />
                              </>
                            }
                            type="text"
                            name="amount"
                            placeholder="Enter Amount"
                            values={values}
                            onChange={setFieldValue}
                            message={`${
                              errors["amount"] ? errors["amount"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel>Narration (Optional)</InputLabel>
                          <CustomTextAreaCmp
                            background="rgba(225, 235, 245, 0.1)"
                            as="textarea"
                            rows={3}
                            required={true}
                            onChange={handleChange}
                            values={values}
                            type="text"
                            name="narration"
                            placeholder="Enter content"
                            showMessage
                            message={`${
                              errors["narration"] ? errors["narration"] : ""
                            }`}
                          />
                        </InputWrapper>
                      </FormWrapper>
                      <FooterWrapper>
                        <div>
                          <InfoCircle color="#323232" size={17} />
                        </div>
                        <div>
                          <FooterText>
                            Please note that withdrawals are completed in less
                            than 30mins. For cases where NIBSS is down, it could
                            take upto 24hrs - 72hrs. Also deposit might not be
                            from Jessie but from the user that has been paired
                            to you.
                          </FooterText>
                          <div>
                            <InfoText>
                              Withdrawal fee applies
                              <Dropdown
                                trigger={["click"]}
                                overlay={DropdownMenu}
                                animation="slide-up"
                                overlayStyle={{
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                                onVisibleChange={(visible) => {
                                  setDropdownVisible(visible);
                                }}
                              >
                                <span style={{ cursor: "pointer" }}>
                                  &nbsp;
                                  <InfoCircle color="#323232" size={15} />
                                </span>
                              </Dropdown>
                            </InfoText>
                            <InfoText>
                              You would be charged:{" "}
                              <InfoSpan>
                                <NumberFormat
                                  thousandSeparator
                                  value={settingsData?.peerWithdrawalFee}
                                  decimalScale={2}
                                  prefix="₦"
                                  defaultValue="0.00"
                                  allowNegative={false}
                                  placeholder="0.00"
                                  displayType="text"
                                  disabled
                                />
                              </InfoSpan>
                            </InfoText>
                            <InfoText>
                              You would receive:{" "}
                              <InfoSpan>
                                <NumberFormat
                                  thousandSeparator
                                  value={values.amount}
                                  decimalScale={2}
                                  prefix="₦"
                                  defaultValue="0.00"
                                  allowNegative={false}
                                  placeholder="0.00"
                                  displayType="text"
                                  disabled
                                />
                              </InfoSpan>
                            </InfoText>
                          </div>
                        </div>
                      </FooterWrapper>
                      <ButtonWrapper>
                        <SolidButton
                          text={"Continue"}
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          specifyPadding="0.8rem 1.5rem"
                          color="#fff"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          borderRadius="8px"
                          backColor="#1a8fdd"
                          backgroundHoverColor="#1a8fdd"
                          textHoverColor="#fff"
                          margin="0rem auto 0"
                          disabled={!isValid ? true : false}
                        />
                      </ButtonWrapper>
                    </Wrapper2>
                  </>
                )}
              </Formik>
            </Wrapper>
          </OuterWrapper>
        </Container>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  settingsLoading: state.wallet.getSettingsLoading,
  settingsSuccess: state.wallet.getSettingsSuccess,
  settingsError: state.wallet.getSettingsError,
  bankDetailListLoading: state.wallet.userBankDetailListLoading,
  bankDetailListSuccess: state.wallet.userBankDetailListSuccess,
  bankDetailListError: state.wallet.userBankDetailListError,
  dashboardBalance: state.dashboard.dashboardBalance,
});

const mapDispatch = (dispatch) => ({
  setGetSettings: () => dispatch(getSettings()),
  setGetUserBankAccountList: () => dispatch(getUserBankAccountList()),
  setGetUserBalance: () => dispatch(getUserBalance()),
});

export default connect(mapState, mapDispatch)(P2pWithdrawal);
