import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AUTHENTICATED, LOGIN_TOKEN } from "../../redux/reducers/types";
import ls from "localstorage-slim";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: AUTHENTICATED, payload: false });
    dispatch({ type: LOGIN_TOKEN, payload: null });
    ls.clear();
    navigate("/login", { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
