/* eslint-disable no-useless-escape */
import * as yup from "yup";

const buyInternetSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Type an amount")
    .test("Check minimum value", "Minimum of ₦100", function (value) {
      if (parseInt(this.parent.amount) < 100) {
        return false;
      }
      return true;
    })
    .strict(),
  provider: yup.string().required("Select a provider"),
  planName: yup.string().required("Select a plan"),
  subscriberName: yup.string().when("provider", {
    is: (val) => val !== "spectranet",
    then: yup.string().required(),
  }),
  variationCode: yup.string().required("Variation code is required"),
  serviceId: yup.string().required("Service id is required"),
  phone: yup
    .string()
    .test(
      "Check if it include country code",
      "Pls remove country code",
      async function (value) {
        if (value && value.includes("+")) {
          return false;
        }
        return true;
      }
    )
    .phone(undefined, undefined, "Invalid phone number")
    .required(),
});

export default buyInternetSchema;
