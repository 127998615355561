import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import NumericInputCmp from "../../../../components/NumericInputCmp/NumericInputCmp";
import getSymbolFromCurrency from "currency-symbol-map";

const Wrapper = styled.div`
  background-color: #f8f9fb;
  background-color: ${(props) => props.bgColor || "#f8f9fb"};
  border-radius: 24px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
`;

const GiftcardImage = styled.img`
  border-radius: 12px;
  width: 65%;
  aspect-ratio: 3/2;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const ValueText = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: #323232;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const CostRateWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;

  @media only screen and (max-width: 654px) {
    flex-direction: column;
    gap: 7px;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const CostRateWrapperMobile = styled.div`
  display: none;

  @media only screen and (max-width: 600px) {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }
`;

const CostWrapperDesktop = styled.div`
  background-color: #f4f7fd;
  border: 0.6px solid #f4f7fd;
  border-radius: 8px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  max-width: 100px;
  width: 100%;

  @media only screen and (max-width: 654px) {
    max-width: 100%;
  }
`;

const CostTitle = styled.h2`
  font-weight: 700;
  font-size: 12px;
  color: #c4c4c4;
  margin: 0;
`;

const CostAmount = styled.h2`
  font-weight: 700;
  font-size: 14px;
  margin: 0;
`;

const QuantityWrapper = styled.div`
  text-align: center;

  @media only screen and (max-width: 654px) {
    padding-top: 10px;
  }
`;

const QuantityText = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.2);
`;

const QuantityInputWrapper = styled.div`
  padding: 12px 0;
`;

const GiftcardCostAmount = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  color: #323232;
`;

const GiftcardItemPrice = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  color: #323232;
`;

const GiftcardItemRate = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 11px;
  color: #323232;
`;

const GiftcardValueCmp = ({
  fixedRecipientDenomination,
  recipientCurrencyCode,
  itemPrice,
  quantity,
  productId,
  totalPrice,
  logoUrl,
  brand,
  index,
  productItems,
  setProductItems,
  trigger,
  setTrigger,
  bgColor,
}) => {
  return (
    <>
      <Wrapper bgColor={bgColor}>
        <GiftcardImage src={logoUrl} alt="Giftcard" />
        <ValueText>
          {getSymbolFromCurrency(recipientCurrencyCode)}
          {fixedRecipientDenomination} {brand?.brandName}
        </ValueText>
        <CostRateWrapper>
          <CostWrapperDesktop>
            <CostTitle>Cost</CostTitle>
            <CostAmount>
              <NumberFormat
                thousandSeparator
                value={
                  quantity === 0
                    ? itemPrice * fixedRecipientDenomination
                    : totalPrice
                }
                prefix="₦"
                decimalScale={2}
                defaultValue="0.00"
                allowNegative={false}
                placeholder="0.00"
                displayType="text"
                disabled
              />
            </CostAmount>
          </CostWrapperDesktop>
          <CostWrapperDesktop>
            <CostTitle>Rate</CostTitle>
            <CostAmount>
              @
              <NumberFormat
                thousandSeparator
                value={itemPrice}
                prefix="₦"
                decimalScale={0}
                defaultValue="0.00"
                allowNegative={false}
                placeholder="0.00"
                displayType="text"
                disabled
              />
              /$
            </CostAmount>
          </CostWrapperDesktop>
        </CostRateWrapper>
        <CostRateWrapperMobile>
          <GiftcardCostAmount>
            {getSymbolFromCurrency(recipientCurrencyCode)}
            {fixedRecipientDenomination} {brand?.brandName}
          </GiftcardCostAmount>
          <GiftcardItemPrice>
            <NumberFormat
              thousandSeparator
              value={
                quantity === 0
                  ? itemPrice * fixedRecipientDenomination
                  : totalPrice
              }
              prefix="₦"
              decimalScale={2}
              defaultValue="0.00"
              allowNegative={false}
              placeholder="0.00"
              displayType="text"
              disabled
            />
          </GiftcardItemPrice>
          <GiftcardItemRate>
            Rate: @
            <NumberFormat
              thousandSeparator
              value={itemPrice}
              prefix="₦"
              decimalScale={0}
              defaultValue="0.00"
              allowNegative={false}
              placeholder="0.00"
              displayType="text"
              disabled
            />
            /$
          </GiftcardItemRate>
        </CostRateWrapperMobile>
        <QuantityWrapper>
          <QuantityText>Quantity</QuantityText>
          <QuantityInputWrapper>
            <NumericInputCmp
              onChange={(val) => {
                const editedArr = [
                  ...productItems.slice(0, index),
                  {
                    fixedRecipientDenomination: fixedRecipientDenomination,
                    itemPrice: itemPrice,
                    quantity: val,
                    productId: productId,
                    totalPrice:
                      fixedRecipientDenomination * val * parseFloat(itemPrice),
                  },
                  ...productItems.slice(index + 1),
                ];
                setProductItems(editedArr);

                if (setTrigger) {
                  setTrigger(!trigger);
                }
              }}
              value={quantity}
            />
          </QuantityInputWrapper>
        </QuantityWrapper>
      </Wrapper>
    </>
  );
};

export default GiftcardValueCmp;
