import React from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SolidButton from "../ButtonCmp/Button";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  background-color: ${appTheme.COLORS.white};
  width: 174px;
  height: 214px;
  border-radius: 24px;
  padding: 1.5rem;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    width: 156px;
  }

  @media only screen and (max-width: 358px) {
    margin: 0 auto;
  }
`;

const Icon = styled(LazyLoadImage)``;

const CoinName = styled.h2`
  margin: 0;
  font-weight: ${appTheme.WEIGHT.medium};
  font-size: 14px;
  color: #323232;
`;

const TradeCryptoCardCmp = ({ coinIcon, coinName, coinSymbol, link }) => {
  const navigate = useNavigate();
  return (
    <>
      <Wrapper>
        <Icon src={coinIcon} effect="blur" />
        <CoinName>
          {coinName} (${coinSymbol})
        </CoinName>
        <SolidButton
          text={"Trade now"}
          type="submit"
          weighty="500"
          textFontSize="14px"
          onClick={() => {
            navigate(link);
          }}
          specifyPadding="0.6rem 0.5rem"
          color="#fff"
          widthWebkit="100%"
          widthMoz="100%"
          widthNormal="100%"
          maxWidth="125px"
          borderRadius="6px"
          backColor="#1a8fdd"
          backgroundHoverColor="#1a8fdd"
          textHoverColor="#fff"
          margin="0rem auto 0"
        />
      </Wrapper>
    </>
  );
};

export default TradeCryptoCardCmp;
