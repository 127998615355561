import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import { calculateTotalPriceAndQuantity } from "../../../utilities/giftcard";
import GiftcardValueCmp from "./Components/GiftcardValueCmp";

const DrawerStyled = styled(Drawer)`
  &.giftcard-sell-drawer {
    background-color: #f8f9fb !important;
    border-radius: 24px 24px 0px 0px !important;
  }
`;

const Wrapper = styled.div`
  padding: 0.8rem 1rem;
  overflow-y: scroll;
  height: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 1rem;
  align-items: center;

  @media only screen and (max-width: 960px) {
    padding: 1rem 0 10px;
  }
`;

const TopTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 960px) {
    gap: 5px;
  }
`;

const TopTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
  margin: 0;

  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const RightSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const AmountWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

const AmountWrapperMobile = styled.div`
  display: none;

  @media only screen and (max-width: 960px) {
    display: flex;
    gap: 12px;
    align-items: center;
  }
`;

const GetText = styled.h2`
  font-weight: 700;
  font-size: 12px;
  color: #adb5bd;
  margin: 0;
`;

const Amount = styled.h2`
  font-weight: 700;
  font-size: 24px;
  color: #016cba;
  margin: 0;
`;

const ButtonWrapperDesktop = styled.div`
  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

const CardImageMobile = styled(LazyLoadImage)`
  display: none;

  @media only screen and (max-width: 960px) {
    display: unset;
    width: 100%;
    max-width: 80px;
    border-radius: 12px;
    aspect-ratio: 3/2;
  }
`;

const GiftcardDisplaySection = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 1.5rem 0;
`;

const GiftcardItemWrapper = styled.div`
  flex: 0 0 23%;
  width: 23%;

  @media only screen and (max-width: 1263px) {
    flex: 0 0 32%;
    width: 32%;
  }

  @media only screen and (max-width: 1038px) {
    flex: 0 0 48%;
    width: 48%;
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const ButtonWrapperMobile = styled.div`
  display: none;

  @media only screen and (max-width: 960px) {
    display: unset;
    width: 100%;
  }
`;

const CloseWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: #f8f9fb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: -50px;
  cursor: pointer;

  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

const SelectGiftcardValueDrawer = ({
  isOpen,
  onClose,
  data,
  selectedGiftcardType,
  setFieldValue,
  giftcardValue,
}) => {
  const [productItems, setProductItems] = useState([]);
  const [tempProductItems, setTempProductItems] = useState([]);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!giftcardValue || selectedGiftcardType) {
      const processedData = data?.giftcardType
        ?.filter((v) => v.type === selectedGiftcardType)[0]
        ?.fixedRecipientDenominationsDetails?.map((v) => {
          return {
            ...v,
            quantity: 0,
            itemPrice: v?.nairaDollarRate,
            totalPrice: parseFloat(v?.nairaDollarRate) * v?.denomination * 0,
            fixedRecipientDenomination: v?.denomination,
            productId: data?.productId,
          };
        });
      setProductItems(processedData);
      setTempProductItems(processedData);
    }
  }, [
    data?.giftcardType,
    selectedGiftcardType,
    giftcardValue,
    data?.productId,
  ]);

  useEffect(() => {
    if (productItems?.length) {
      const filteredItem = productItems?.filter((v) => v.quantity > 0);
      if (filteredItem?.length) {
        setProductItems(filteredItem);
      } else {
        setProductItems(tempProductItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <>
      <DrawerStyled
        className="giftcard-sell-drawer"
        open={isOpen}
        onClose={onClose}
        direction="bottom"
        zIndex={1500}
        size="80vh"
      >
        <Wrapper>
          <CloseWrapper
            onClick={() => {
              onClose();
            }}
          >
            X
          </CloseWrapper>
          <TopWrapper>
            <TopTitleWrapper>
              <TopTitle>Select Giftcard Value</TopTitle>
              <AmountWrapperMobile>
                <GetText>YOU GET:</GetText>
                <Amount>
                  <NumberFormat
                    thousandSeparator
                    value={
                      calculateTotalPriceAndQuantity(productItems)?.totalPrice
                    }
                    prefix="₦"
                    decimalScale={2}
                    defaultValue="0.00"
                    allowNegative={false}
                    placeholder="0.00"
                    displayType="text"
                    disabled
                  />
                </Amount>
              </AmountWrapperMobile>
            </TopTitleWrapper>
            <RightSection>
              <AmountWrapper>
                <GetText>YOU GET:</GetText>
                <Amount>
                  <NumberFormat
                    thousandSeparator
                    value={
                      calculateTotalPriceAndQuantity(productItems)?.totalPrice
                    }
                    prefix="₦"
                    decimalScale={2}
                    defaultValue="0.00"
                    allowNegative={false}
                    placeholder="0.00"
                    displayType="text"
                    disabled
                  />
                </Amount>
              </AmountWrapper>
              <ButtonWrapperDesktop>
                <SolidButton
                  text={"Continue"}
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="150px"
                  widthMoz="150px"
                  widthNormal="150px"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  onClick={() => {
                    if (
                      calculateTotalPriceAndQuantity(productItems)
                        ?.totalQuantity > 0 &&
                      parseFloat(
                        calculateTotalPriceAndQuantity(productItems)?.totalPrice
                      ) > 0
                    ) {
                      setFieldValue(
                        "giftcardValue",
                        productItems.map((v) => {
                          return {
                            ...v,
                            label: `${getSymbolFromCurrency(
                              data.recipientCurrencyCode
                            )} ${v.fixedRecipientDenomination} x ${v.quantity}`,
                          };
                        })
                      );
                      onClose();
                    }
                  }}
                  disabled={
                    calculateTotalPriceAndQuantity(productItems)
                      ?.totalQuantity > 0 &&
                    parseFloat(
                      calculateTotalPriceAndQuantity(productItems)?.totalPrice
                    ) > 0
                      ? false
                      : true
                  }
                />
              </ButtonWrapperDesktop>
              <CardImageMobile
                src={data?.logoUrls[0]}
                alt="Giftcard"
                effect="blur"
              />
            </RightSection>
          </TopWrapper>
          <GiftcardDisplaySection>
            {productItems?.map((v, i) => {
              return (
                <GiftcardItemWrapper key={i}>
                  <GiftcardValueCmp
                    bgColor="#fff"
                    fixedRecipientDenomination={v.fixedRecipientDenomination}
                    itemPrice={v.itemPrice}
                    quantity={v.quantity}
                    productId={v.productId}
                    totalPrice={v.totalPrice}
                    logoUrl={data?.logoUrls[0]}
                    brand={data?.brand}
                    index={i}
                    setProductItems={setProductItems}
                    setTrigger={setTrigger}
                    trigger={trigger}
                    productItems={productItems}
                    recipientCurrencyCode={data.recipientCurrencyCode}
                  />
                </GiftcardItemWrapper>
              );
            })}
          </GiftcardDisplaySection>
          <ButtonWrapperMobile>
            <SolidButton
              text={"Continue"}
              type="submit"
              weighty="500"
              textFontSize="14px"
              specifyPadding="0.8rem 1.5rem"
              color="#fff"
              widthWebkit="100%"
              widthMoz="100%"
              widthNormal="100%"
              borderRadius="8px"
              backColor="#1a8fdd"
              backgroundHoverColor="#1a8fdd"
              textHoverColor="#fff"
              margin="0rem auto 0"
              onClick={() => {
                if (
                  calculateTotalPriceAndQuantity(productItems)?.totalQuantity >
                    0 &&
                  parseFloat(
                    calculateTotalPriceAndQuantity(productItems)?.totalPrice
                  ) > 0
                ) {
                  setFieldValue(
                    "giftcardValue",
                    productItems.map((v) => {
                      return {
                        ...v,
                        label: `${getSymbolFromCurrency(
                          data.recipientCurrencyCode
                        )} ${v.fixedRecipientDenomination} x ${v.quantity}`,
                      };
                    })
                  );
                  onClose();
                }
              }}
              disabled={
                calculateTotalPriceAndQuantity(productItems)?.totalQuantity >
                  0 &&
                parseFloat(
                  calculateTotalPriceAndQuantity(productItems)?.totalPrice
                ) > 0
                  ? false
                  : true
              }
            />
          </ButtonWrapperMobile>
        </Wrapper>
      </DrawerStyled>
    </>
  );
};

export default SelectGiftcardValueDrawer;
