import React from "react";
import { Lock } from "@styled-icons/boxicons-regular";
import styled from "styled-components";
import { Formik } from "formik";
import enterCurrentPasswordSchema from "../../../validators/enterCurrentPasswordValidator";
import CustomInputPasswordIconCmp from "../../../components/InputsCmp/CustomInputPasswordIconCmp";
import SolidButton from "../../../components/ButtonCmp/Button";
import { connect } from "react-redux";
import { providePassword } from "../../../redux/actions/settingActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import appTheme from "../../../constants/theme";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputWrapper = styled.div``;

const ButtonWrapper = styled.div`
  padding-top: 0.8rem;
  text-align: right;
`;

const EnterCurrentPasswordForm = ({
  path,
  getProvidePasswordError,
  getProvidePasswordLoading,
  getProvidePasswordSuccess,
  setSubmitPassword,
}) => {
  const navigate = useNavigate();
  return (
    <Formik
      validationSchema={enterCurrentPasswordSchema}
      initialValues={{
        password: "",
      }}
      onSubmit={async (values, { resetForm }) => {
        // resetForm();
        // console.log(response, path);

        let data = {};
        if (path === "/settings/reset-password/otp") {
          data = { isPassword: true };
        }

        const response = await setSubmitPassword({
          ...data,
          password: values?.password,
        });

        if (
          response.status === 200 &&
          response.message === "Reset Pin OTP sent"
        ) {
          toast.success(
            `OTP to reset ${
              path === "/settings/reset-password/otp" ? "password" : "pin"
            } has been sent to your email`
          );
          navigate(path, { state: "otp sent" });
        }
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <>
          <form noValidate onSubmit={handleSubmit}>
            <FormWrapper>
              <InputWrapper>
                <CustomInputPasswordIconCmp
                  background="rgba(225, 235, 245, 0.1)"
                  required={true}
                  icon={
                    <>
                      <Lock size={30} color="rgba(107, 114, 128, 0.5)" />
                    </>
                  }
                  name="password"
                  placeholder="Enter current password"
                  values={values}
                  onChange={handleChange}
                  message={`${errors["password"] ? errors["password"] : ""}`}
                  showMessage={true}
                />
              </InputWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    getProvidePasswordLoading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Submit"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="107px"
                  widthMoz="107px"
                  widthNormal="107px"
                  borderRadius="6px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={getProvidePasswordLoading ? true : false}
                />
              </ButtonWrapper>
            </FormWrapper>
          </form>
        </>
      )}
    </Formik>
  );
};

const mapState = (state) => ({
  getProvidePasswordLoading: state.setting.providePasswordLoading,
  getProvidePasswordSuccess: state.setting.providePasswordSuccess,
  getProvidePasswordError: state.setting.providePasswordError,
});

const mapDispatch = (dispatch) => ({
  setSubmitPassword: (data) => dispatch(providePassword(data)),
});

export default connect(mapState, mapDispatch)(EnterCurrentPasswordForm);
