import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthTemplateCmp from "../../components/AuthTemplateCmp/AuthTemplateCmp";
import SolidButton from "../../components/ButtonCmp/Button";
import CustomCheckboxCmp from "../../components/InputsCmp/CustomCheckboxCmp";

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonWrapper = styled.div``;

const GetStarted = () => {
  const [checkedValue, setCheckedValue] = useState("");

  const [checkedState, setCheckedState] = useState(true);

  const navigate = useNavigate();

  const handleClick = () => {
    if (checkedValue === "Individual") {
      return navigate("/signup/individual");
    } else if (checkedValue === "Business") {
      return navigate("/signup/business");
    } else {
      setCheckedState(false);
    }
  };

  const GetStartedContent = () => {
    return (
      <>
        <CheckBoxWrapper>
          <CustomCheckboxCmp
            id="Individual"
            handleChange={() => {
              setCheckedState(true);
              setCheckedValue("Individual");
            }}
            isInvalid={!checkedState}
            checked={checkedValue === "Individual" ? true : false}
            title="As Individual"
            desc="For Sole Traders, Freelancers & Unregistered Businesses."
          />
          <CustomCheckboxCmp
            id="Business"
            handleChange={() => {
              setCheckedState(true);
              setCheckedValue("Business");
            }}
            checked={checkedValue === "Business" ? true : false}
            isInvalid={!checkedState}
            title="A Registered Business"
            desc="For Businesses with Business Bank Account."
          />
        </CheckBoxWrapper>
        <ButtonWrapper>
          <SolidButton
            text="Next"
            onClick={handleClick}
            type="button"
            weighty="500"
            textFontSize="14px"
            specifyPadding="0.8rem 1.5rem"
            color="#fff"
            widthWebkit="100%"
            widthMoz="100%"
            widthNormal="100%"
            borderRadius="10px"
            backColor="#1a8fdd"
            backgroundHoverColor="#1a8fdd"
            textHoverColor="#fff"
            margin="1.5rem auto 0"
          />
        </ButtonWrapper>
      </>
    );
  };
  return (
    <AuthTemplateCmp
      content={<GetStartedContent />}
      pageTitle="Get Started As"
      pageDesc="Let’s get you trading right away."
      mobilePadding={"1rem 1rem 2rem"}
    />
  );
};

export default GetStarted;
