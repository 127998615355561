exports.calculateTotalPriceAndQuantity = (products) => {
  let totalPrice = 0;
  let totalQuantity = 0;
  for (let i = 0; i < products?.length; i++) {
    totalPrice += parseFloat(products[i].totalPrice);
    totalQuantity += products[i].quantity;
  }
  return {
    totalPrice,
    totalQuantity,
  };
};
