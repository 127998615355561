import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Stepper from "react-stepper-horizontal";

const StepWrapper = styled.div``;

const StepperCmp = ({ activeStep, stepData }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(stepData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StepWrapper>
        <Stepper
          steps={data}
          activeStep={activeStep}
          activeColor="#F4F7FD"
          defaultColor="#F4F7FD"
          completeColor="#016CBA"
          activeTitleColor="#ADB5BD"
          completeTitleColor="#ADB5BD"
          defaultTitleColor="#ADB5BD"
          circleFontColor="#fff"
          completeBarColor="#016CBA"
          defaultBorderColor="#ADB5BD"
          titleFontSize={12}
          circleFontSize={12}
          circleTop={10}
          size={20}
          titleTop={3}
          defaultBorderWidth={2}
        />
      </StepWrapper>
    </>
  );
};

export default StepperCmp;
