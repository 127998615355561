import {
  LOADING,
  AUTHENTICATED,
  LOGOUT,
  ERROR,
  LOGIN_DATA,
  SHOW_EMAIL_VERIFY,
  LOGIN_TOKEN,
} from "./types";

const initialState = {
  loading: null,
  authenticated: null,
  error: null,
  data: null,
  emailVerify: null,
  token: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case LOGIN_TOKEN:
      return { ...state, token: action.payload };
    case AUTHENTICATED:
      return { ...state, authenticated: action.payload || true };
    case LOGIN_DATA:
      return { ...state, data: action.payload };
    case SHOW_EMAIL_VERIFY:
      return { ...state, emailVerify: action.payload };
    case ERROR:
      return { ...state, error: action.payload };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
