import { Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { getCountryCallingCode } from "react-phone-number-input";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import CustomPhoneInputCmp from "../../../components/InputsCmp/CustomPhoneInputCmp";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../../constants/theme";
import { countryCodeSwap } from "../../../constants/countries";
import CardWithIconCmp from "../../../components/CardWithIconCmp/CardWithIconCmp";
import NairaIcon from "../../../assets/naira.svg";
import JambIcon from "../../../assets/jamb.svg";
import CustomPriceInputCmp from "../../../components/InputsCmp/CustomPriceInputCmp";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getDataVariationCodes } from "../../../redux/actions/dataActions";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import { getSettings } from "../../../redux/actions/walletActions";
import buyEducationalSchema from "../../../validators/buyEducationalValidator";
import { getProfileIdDetails } from "../../../redux/actions/educationalActions";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  max-width: 550px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: 700;
  color: ${appTheme.COLORS.grey1};
  padding-bottom: 0.5rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const NairaImg = styled.img``;

const JambPurchase = ({ loading, setGetSettings }) => {
  const navigate = useNavigate();
  const [selectedWallet, setSelectedWallet] = useState("naira-wallet");
  const [settingsData, setSettingsData] = useState({});

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetSettings();

      setSettingsData(response.data);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchVariationCodes = (props) => {
    const {
      values: { name },
      setFieldValue,
      validateForm,
    } = useFormikContext();
    const [variationData, setVariationData] = useState();

    async function getVariationCodes() {
      const response = await getDataVariationCodes({
        serviceId: `jamb`,
      });
      return response;
    }

    useEffect(() => {
      async function fetchCodes() {
        validateForm();
        const response = await getVariationCodes();

        setVariationData(response.data);
      }

      fetchCodes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        {variationData && variationData.length > 0 ? (
          <>
            <div style={{ height: "fit-content", overflowY: "scroll" }}>
              <InputWrapper>
                <InputLabel>Exam Type</InputLabel>
                <CardWrapper>
                  {variationData?.map((v, i) => {
                    return (
                      <CardWithIconCmp
                        key={i}
                        message={`${
                          props.errors?.variationCode
                            ? props.errors?.variationCode
                            : ""
                        }`}
                        type={v.name}
                        text={v.name}
                        icon={JambIcon}
                        selectedItem={name}
                        maxWidth={"fit-content"}
                        onSelect={() => {
                          setFieldValue("variationCode", v.variation_code);
                          setFieldValue("name", v.name);
                          setFieldValue("amount", v.variation_amount);
                        }}
                      />
                    );
                  })}
                </CardWrapper>
              </InputWrapper>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const FetchProfileDetails = (props) => {
    const {
      values: { serviceId, billersCode, variationCode, customerName },
      setFieldValue,
    } = useFormikContext();

    async function getJambProfileIdDetails() {
      const response = await getProfileIdDetails({
        serviceId: serviceId,
        billersCode: `${billersCode}`,
        type: `${variationCode}`,
      });
      return response;
    }

    useEffect(() => {
      async function fetchDetails() {
        if (billersCode && variationCode && `${billersCode}`.length === 10) {
          setFieldValue("customerName", "loading...");
          const response = await getJambProfileIdDetails();
          if (response?.data?.Customer_Name) {
            setFieldValue("customerName", response?.data?.Customer_Name);
          } else {
            setFieldValue("customerName", "");
          }
        } else {
          setFieldValue("customerName", "");
        }
      }

      fetchDetails();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billersCode, variationCode, setFieldValue]);

    return (
      <>
        <InputLabel style={{ marginTop: "-10px", fontWeight: "300" }}>
          {customerName}
        </InputLabel>
      </>
    );
  };
  return (
    <>
      <Formik
        validationSchema={buyEducationalSchema}
        initialValues={{
          serviceId: "jamb",
          amount: "",
          name: "",
          phone: "",
          variationCode: "",
          billersCode: "",
          customerName: "",
          quantity: 1,
          charge: settingsData?.educationCharge,
        }}
        onSubmit={async (values, { resetForm }) => {
          const fullPhoneNo = parsePhoneNumber(
            `+${getCountryCallingCode(countryCodeSwap[`Nigeria`])}${
              values.phone
            }`
          ).getNumber("e164");
          navigate("/products/renew/jamb/confirm", {
            state: {
              ...values,
              fullPhoneNo,
              charge: settingsData?.educationCharge,
            },
          });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          setFieldValue,
          touched,
          setFieldTouched,
        }) => (
          <>
            <Wrapper2 noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <InputLabel>Select Payment Method</InputLabel>
                  <CardWrapper>
                    <CardWithIconCmp
                      type="naira-wallet"
                      text="Naira Wallet"
                      icon={NairaIcon}
                      selectedItem={selectedWallet}
                      maxWidth={"150px"}
                      onSelect={() => {
                        setSelectedWallet("naira-wallet");
                      }}
                    />
                  </CardWrapper>
                </InputWrapper>
                <FetchVariationCodes
                  error={`${
                    errors["variationCode"] ? errors["variationCode"] : ""
                  }`}
                  errors={errors}
                  handleChange={handleChange}
                  value={values.variationCode}
                />
                <InputWrapper>
                  <InputLabel>Enter JAMB Profile ID</InputLabel>
                  <CustomInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    placeholder="Enter  JAMB Profile ID"
                    required={true}
                    name={"billersCode"}
                    onChange={handleChange}
                    values={values}
                    showMessage={false}
                    message={`${
                      errors["billersCode"] ? errors["billersCode"] : ""
                    }`}
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    min={0}
                  />
                </InputWrapper>
                <FetchProfileDetails />

                <InputWrapper>
                  <InputLabel>Enter Phone Number</InputLabel>
                  <CustomPhoneInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    name="phone"
                    placeholder="Enter Receiver’s Phone Number"
                    values={values}
                    phoneCode={`+${getCountryCallingCode(
                      countryCodeSwap[`Nigeria`]
                    )}`}
                    flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      countryCodeSwap[`Nigeria`]
                    }.svg`}
                    onChange={handleChange}
                    message={`${errors["phone"] ? errors["phone"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Amount</InputLabel>
                  <CustomPriceInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <NairaImg src={NairaIcon} alt="Naira" />
                      </>
                    }
                    type="text"
                    name="amount"
                    placeholder="Enter Amount"
                    values={values}
                    onChange={setFieldValue}
                    message={`${errors["amount"] ? errors["amount"] : ""}`}
                    showMessage={false}
                    disabled={true}
                  />
                </InputWrapper>
              </FormWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Continue"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={!isValid ? true : false}
                />
              </ButtonWrapper>
            </Wrapper2>
          </>
        )}
      </Formik>
    </>
  );
};

const mapState = (state) => ({
  getVariationCodesLoading: state.data.getVariationCodesLoading,
  getVariationCodesSuccess: state.data.getVariationCodesSuccess,
  getVariationCodesError: state.data.getVariationCodesError,
  verifyProfileIdLoading: state.educational.verifyProfileIdLoading,
  verifyProfileIdError: state.educational.verifyProfileIdError,
  verifyProfileIdSuccess: state.educational.verifyProfileIdSuccess,
  settingsLoading: state.wallet.getSettingsLoading,
  settingsSuccess: state.wallet.getSettingsSuccess,
  settingsError: state.wallet.getSettingsError,
});

const mapDispatch = (dispatch) => ({
  setGetSettings: () => dispatch(getSettings()),
  setGetDataVariationCodes: (data) => dispatch(getDataVariationCodes(data)),
  setGetProfileIdDetails: (data) => dispatch(getProfileIdDetails(data)),
});

export default connect(mapState, mapDispatch)(JambPurchase);
