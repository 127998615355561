/* eslint-disable no-useless-escape */
import * as yup from "yup";

const buyElectricitySchema = yup.object().shape({
  amount: yup
    .string()
    .required("Type an amount")
    .test("Check minimum value", "Minimum of ₦1000", function (value) {
      if (parseInt(this.parent.amount) < 1000) {
        return false;
      }
      return true;
    })
    .strict(),
  meterNumber: yup
    .string()
    .required("Type a meter number")
    .min(11, "Minimum of 11 digits"),
  provider: yup.string().when("otherProvider", {
    is: "" || null,
    then: yup.string().required("Select a provider"),
  }),
  otherProvider: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .required("Select a provider")
    .optional(),
  checkedValue: yup.string().required("Select metre type"),
  subscriberName: yup.string().required(),
  phone: yup
    .string()
    .test(
      "Check if it include country code",
      "Pls remove country code",
      async function (value) {
        if (value && value.includes("+")) {
          return false;
        }
        return true;
      }
    )
    .phone(undefined, undefined, "Invalid phone number")
    .required(),
});

export default buyElectricitySchema;
