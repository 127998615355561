import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import CustomInputIconCmp from "../InputsCmp/CustomInputIconCmp";
import { User, Lock } from "@styled-icons/boxicons-regular";
import CustomInputPasswordIconCmp from "../InputsCmp/CustomInputPasswordIconCmp";
import appTheme from "../../constants/theme";
import SolidButton from "../ButtonCmp/Button";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import signinSchema from "../../validators/signinValidator";
import { loginUser } from "../../redux/actions/authActions";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import CheckMailModal from "../Modals/CheckMailModal";
import {
  AUTHENTICATED,
  LOGIN_DATA,
  SHOW_EMAIL_VERIFY,
} from "../../redux/reducers/types";
import TwoFaModal from "../Modals/TwoFaModal";
import { validateTwoFa } from "../../redux/actions/twoFaActions";
import { useEffect } from "react";
import { saveToken } from "../../redux/token";
import { getUserSetupStatus } from "../../redux/actions/setupActions";

const Wrapper = styled(Form)`
  padding-top: 0.5rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputWrapper = styled.div``;

const TextWrapper = styled.div`
  padding-top: 0.5rem;
`;

const Text = styled.p`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 14px;
  text-align: right;
`;

const Span = styled.span`
  color: #1a8fdd;
  cursor: pointer;
`;

const ButtonWrapper = styled.div``;

const LoginForm = ({
  loading,
  error,
  authenticated,
  loginData,
  token,
  setLoginUser,
  emailVerify,
  setValidateTwoFa,
  validate2FaError,
  validate2FaLoading,
  validate2FaSuccess,
  validate2FaEnabled,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show2FaModal, setShow2FaModal] = useState(false);
  const [otpPin, setOtpPin] = useState("");
  const [userToken, setUserToken] = useState({});

  useEffect(() => {
    if (token) {
      setShow2FaModal(true);
      setUserToken(token);
    }
  }, [token]);
  return (
    <>
      <Formik
        validationSchema={signinSchema}
        initialValues={{
          identifier: "",
          password: "",
          isEmail: false,
        }}
        onSubmit={async (values, { resetForm }) => {
          // resetForm();
          const response = await setLoginUser({
            identifier: values.identifier,
            password: values.password,
          });
          if (
            response &&
            response.status === 200 &&
            response.message === "Login successful"
          ) {
            if (response?.data?.user?.twoFaEnabled) {
              setShow2FaModal(true);
            } else {
              navigate("/dashboard");
              return toast.success("Login successful");
            }
          } else if (
            response &&
            response.status === 200 &&
            response.message === "Verify phone"
          ) {
            window.open(response?.data?.url, "_self");
          }
          // console.log(response);
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <>
            <Wrapper noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <CustomInputIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <User size={20} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    type="text"
                    name="identifier"
                    placeholder="Username or Email"
                    values={values}
                    onChange={handleChange}
                    message={`${
                      errors["identifier"] ? errors["identifier"] : ""
                    }`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInputPasswordIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Lock size={30} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    name="password"
                    placeholder="Choose Password"
                    values={values}
                    onChange={handleChange}
                    message={`${errors["password"] ? errors["password"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper style={{ display: "none" }}>
                  <CustomInputIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <User size={20} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    type="text"
                    name="isEmail"
                    placeholder="Username or Email"
                    value={
                      (values.isEmail = `${values.identifier.includes("@")}`)
                    }
                    onChange={handleChange}
                    message={`${errors["isEmail"] ? errors["isEmail"] : ""}`}
                    showMessage={false}
                  />
                </InputWrapper>
              </FormWrapper>
              <TextWrapper>
                <Text>
                  <Span
                    onClick={() => {
                      navigate("/forgot/password");
                    }}
                  >
                    Forgot Password?
                  </Span>
                </Text>
              </TextWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Sign In"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={loading ? true : false}
                />
              </ButtonWrapper>
            </Wrapper>
          </>
        )}
      </Formik>
      <CheckMailModal
        titleMaxWidth="300px"
        maxWidth="400px"
        showCloseIcon={true}
        isOpen={emailVerify ? true : false}
        onRequestClose={() => {
          dispatch({ type: LOGIN_DATA, payload: false });
          dispatch({ type: SHOW_EMAIL_VERIFY, payload: false });
          navigate("/login");
        }}
      />
      <TwoFaModal
        isOpen={show2FaModal}
        onRequestClose={() => {
          setShow2FaModal(false);
        }}
        setPin={setOtpPin}
        loading={validate2FaLoading}
        txnAction={async () => {
          const response = await setValidateTwoFa(
            {
              code: otpPin,
            },
            userToken
          );
          if (response && response.message === "Successful") {
            setShow2FaModal(false);
            saveToken(userToken);
            dispatch(getUserSetupStatus());
            dispatch({ type: AUTHENTICATED, payload: true });
            dispatch({ type: LOGIN_DATA, payload: loginData });
            navigate("/dashboard");
            return toast.success("Login successful");
          } else {
            // setShow2FaModal(false);
          }
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  authenticated: state.auth.authenticated,
  loginData: state.auth.data,
  token: state.auth.token,
  emailVerify: state.auth.emailVerify,
  validate2FaLoading: state.twoFa.validate2FaLoading,
  validate2FaSuccess: state.twoFa.validate2FaSuccess,
  validate2FaError: state.twoFa.validate2FaError,
  validate2FaEnabled: state.twoFa.validate2FaEnabled,
});

const mapDispatch = (dispatch) => ({
  setLoginUser: (data) => dispatch(loginUser(data)),
  setValidateTwoFa: (data, token) => dispatch(validateTwoFa(data, token)),
});

export default connect(mapState, mapDispatch)(LoginForm);
