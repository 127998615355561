import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import SolidButton from "../ButtonCmp/Button";
import CustomPinInputCmp from "../InputsCmp/CustomPinInputCmp";
import ModalCmp from "../ModalCmp/ModalCmp";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";

const SetPinWrapper = styled.div`
  padding: 2rem 1.5rem;
  text-align: center;
`;

const Title = styled.h2`
  color: ${appTheme.COLORS.grey1};
  font-size: 16px;
  font-weight: ${appTheme.WEIGHT.mediumBold};
`;

const Desc = styled.p`
  max-width: 300px;
  color: #c4c4c4;
  line-height: 16px;
  font-size: 14px;
  margin: 0 auto;
  padding-bottom: 1rem;
`;

const PinGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PinWrapper = styled.div``;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const TwoFaModal = ({ loading, txnAction, setPin, ...props }) => {
  const [pinDetails, setPinDetails] = useState("");
  const handlePinComplete = (value) => {
    setPinDetails(value);
    setPin(value);
  };

  const handleSubmit = async () => {
    if (!pinDetails) {
      return toast.error("Provide your OTP");
    }
    if (pinDetails.length !== 6) {
      return toast.error("OTP digit not complete");
    }

    txnAction();
  };

  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: "400px" }}
        shouldFocusAfterRender={false}
        contentLabel="Provide OTP"
        id="provide-otp"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={false}
      >
        <SetPinWrapper>
          <Title>Two-factor authentication</Title>
          <Desc>Enter the 6-digit code shown in the Authenticator app.</Desc>
          <PinGroup>
            <PinWrapper>
              <CustomPinInputCmp
                length={6}
                autoSelect={true}
                secret={false}
                initialValue=""
                onChange={handlePinComplete}
                onComplete={handlePinComplete}
                //   disabled={disableBtn === false ? true : false}
              />
            </PinWrapper>
          </PinGroup>
          <ButtonWrapper>
            <SolidButton
              onClick={handleSubmit}
              text={
                loading ? (
                  <>
                    <SpinnerCmp
                      enabled={true}
                      color={appTheme.COLORS.bluePrimary}
                      size={20}
                      secondaryColor={appTheme.COLORS.white}
                    />
                  </>
                ) : (
                  "Submit OTP"
                )
              }
              type="submit"
              weighty="500"
              disabled={loading ? true : false}
              textFontSize="14px"
              specifyPadding="1rem 1rem"
              color="#fff"
              widthWebkit="150px"
              widthMoz="150px"
              widthNormal="150px"
              borderRadius="8px"
              backColor="#1a8fdd"
              backgroundHoverColor="#1a8fdd"
              textHoverColor="#fff"
              margin="0rem auto 0"
            />
          </ButtonWrapper>
        </SetPinWrapper>
      </ModalCmp>
    </>
  );
};

export default TwoFaModal;
