import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  display: flex;
  border-radius: 8px;
  /* border: 1px solid #adb5bd; */
  border: ${(props) =>
    props.isInvalid ? "1px solid red" : "1px solid #adb5bd"};
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  height: ${(props) => (props.height ? `${props.height}` : "100px")};
  gap: ${(props) => (props.gap ? `${props.gap}` : "")};

  @media only screen and (max-width: 380px) {
    margin: 0 1rem;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  text-align: left;
`;

const Title = styled.h2`
  margin: 0;
  color: ${appTheme.COLORS.black};
  opacity: 0.2;
  font-size: 1rem;
  font-weight: ${appTheme.WEIGHT.small3};
`;

const Desc = styled.p`
  font-size: 12px;
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small};
  margin: 0;
`;

const FormCheck = styled(Form.Check)``;

const CheckInput = styled(Form.Check.Input)`
  &:checked {
    background-color: #016cba;
    border-color: #016cba;
  }
`;

const CustomCheckboxCmp = ({
  title,
  desc,
  handleChange,
  id,
  isInvalid,
  checked,
  gap,
  height,
}) => {
  return (
    <>
      <Wrapper
        onClick={handleChange}
        isInvalid={isInvalid}
        gap={gap}
        height={height}
      >
        <TextWrapper>
          <Title>{title}</Title>
          <Desc>{desc}</Desc>
        </TextWrapper>
        <FormCheck type="radio" id={id} isInvalid={isInvalid}>
          <CheckInput
            onChange={handleChange}
            id={id}
            isInvalid={isInvalid}
            checked={checked}
            type="radio"
          />
        </FormCheck>
      </Wrapper>
    </>
  );
};

export default CustomCheckboxCmp;
