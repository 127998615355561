import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { iconCategory, statusColorMap } from "../../constants/others";
import appTheme from "../../constants/theme";
import { getUserTxns } from "../../redux/actions/dashboardAction";
import SolidButton from "../ButtonCmp/Button";
import TableCmp from "../TableCmp/TableCmp";

const Wrapper = styled.div`
  padding-top: 1rem;
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 1rem;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  color: #323232;
`;

const TxnWrapper = styled.div`
  background-color: ${appTheme.COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  height: 430px;
`;

const NoTxnWrapper = styled.div`
  max-width: 264px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
`;

const NoTxnTitle = styled.h2`
  margin: 0;
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 18px;
  color: #323232;
`;

const NoTxnDesc = styled.p`
  margin: 0;
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 14px;
  color: #000000;
`;

const RecentTxnCmp = ({ setGetRecentTxn, loading, error, success }) => {
  const navigate = useNavigate();

  useEffect(() => {
    async function callGetRecentTxn() {
      await setGetRecentTxn(1, 5);
    }

    callGetRecentTxn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper>
        <Title>Recents Transactions</Title>
        {!success?.data?.results ? (
          <TxnWrapper>
            <NoTxnWrapper>
              <NoTxnTitle>No transaction yet</NoTxnTitle>
              <NoTxnDesc>
                You haven’t made any transactions. Your recent transaction would
                appear here.
              </NoTxnDesc>
              <SolidButton
                text={"Make a transaction"}
                type="submit"
                weighty="500"
                textFontSize="14px"
                onClick={() => {
                  navigate("/transaction");
                }}
                specifyPadding="0.8rem 0.5rem"
                color="#fff"
                widthWebkit="100%"
                widthMoz="100%"
                widthNormal="100%"
                maxWidth="170px"
                borderRadius="6px"
                backColor="#1a8fdd"
                backgroundHoverColor="#1a8fdd"
                textHoverColor="#fff"
                margin="1rem auto 0"
              />
            </NoTxnWrapper>
          </TxnWrapper>
        ) : (
          <TableCmp
            // enablePagination={true}
            // showSearch={true}
            // showCustomFilter={true}
            // searchPlaceholderText={"Transaction ID"}
            containerHeight="350px"
            defaultPageSize={2}
            data={success?.data?.results}
            columns={[
              {
                Header: "",
                accessor: "txn",
                id: "icon",
                maxWidth: 100,
                minWidth: 60,
                width: 100,
                Cell: (props) => {
                  return (
                    <LazyLoadImage
                      style={{ width: "40px", height: "40px" }}
                      src={iconCategory[props.value.txnType]}
                      alt="icon"
                      effect="blur"
                    />
                  );
                },
              },
              {
                Header: "Transaction ID",
                accessor: "txn",
                Cell: (props) => {
                  return <p style={{ margin: "0" }}>{props.value.txnId}</p>;
                },
              },
              {
                Header: "Category",
                accessor: "txn",
                id: "txnType",
                maxWidth: 200,
                minWidth: 180,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        fontWeight: "700",
                        margin: "0",
                      }}
                    >
                      {props.value.txnType}
                    </p>
                  );
                },
              },
              {
                Header: "Status",
                accessor: "txn",
                id: "status",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        color: statusColorMap[props.value.status],
                        margin: "0",
                        fontWeight: "700",
                      }}
                    >
                      {props.value.status}
                    </p>
                  );
                },
              },
              {
                Header: "Amount",
                accessor: "txn",
                id: "amount",
                maxWidth: 200,
                minWidth: 100,
                width: 200,
                Cell: (props) => {
                  return (
                    <p
                      style={{
                        fontWeight: "700",
                        margin: "0",
                      }}
                    >
                      {props.value.otherPrice ||
                        props.value.totalAmount ||
                        props.value.amount}
                    </p>
                  );
                },
              },
              {
                Header: "Date",
                accessor: "txn",
                id: "date",
                maxWidth: 200,
                minWidth: 140,
                width: 200,
                Cell: (props) => {
                  return (
                    <p style={{ margin: "0" }}>
                      {new Date(props.value.createdAt).toDateString()}
                    </p>
                  );
                },
              },
            ]}
          />
        )}
      </Wrapper>
    </>
  );
};

const mapState = (state) => ({
  loading: state.dashboard.dashboardRecentTxnLoading,
  error: state.dashboard.dashboardRecentTxnError,
  success: state.dashboard.dashboardRecentTxn,
});

const mapDispatch = (dispatch) => ({
  setGetRecentTxn: (page, limit) => dispatch(getUserTxns(page, limit)),
});

export default connect(mapState, mapDispatch)(RecentTxnCmp);
