/* eslint-disable no-useless-escape */
import * as yup from "yup";

const p2pWithdrawalSchema = yup.object().shape({
  bank: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
      accountNumber: yup.string().required(),
    })
    .nullable()
    .required("Select bank detail"),
  amount: yup
    .string()
    .required("Type an amount")
    .test(
      "Check minimum value",
      "Withdraw minimum of ₦1,000",
      function (value) {
        if (parseFloat(this.parent.amount) < 1000) {
          return false;
        }
        return true;
      }
    )
    .test(
      "Check balance with input amount",
      "Insufficient Balance",
      async function (value) {
        if (value) {
          const { balance, amount, charge } = this.parent;
          if (parseFloat(amount) + parseFloat(charge) > parseFloat(balance)) {
            return false;
          }
          return true;
        }
        return false;
      }
    )
    .strict(),
  narration: yup.string().optional(),
});

export default p2pWithdrawalSchema;
