import { Formik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { getCountryCallingCode } from "react-phone-number-input";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import CustomPhoneInputCmp from "../../../components/InputsCmp/CustomPhoneInputCmp";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import appTheme from "../../../constants/theme";
import { countryCodeSwap } from "../../../constants/countries";
import CardWithIconCmp from "../../../components/CardWithIconCmp/CardWithIconCmp";
import NairaIcon from "../../../assets/naira.svg";
import GloIcon from "../../../assets/glo.svg";
import MtnIcon from "../../../assets/mtn.svg";
import NineMobileIcon from "../../../assets/9mobile.svg";
import AirtelIcon from "../../../assets/airtel.svg";
import CustomPriceInputCmp from "../../../components/InputsCmp/CustomPriceInputCmp";
import renewAirtimeSchema from "../../../validators/renewAirtimeValidator";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useNavigate } from "react-router-dom";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  max-width: 550px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: 700;
  color: ${appTheme.COLORS.grey1};
  padding-bottom: 0.5rem;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const NairaImg = styled.img``;

const AirtimePurchase = ({ loading }) => {
  const navigate = useNavigate();
  const [selectedWallet, setSelectedWallet] = useState("naira-wallet");
  return (
    <>
      <Formik
        validationSchema={renewAirtimeSchema}
        initialValues={{
          provider: "",
          amount: "",
          phone: "",
          amountType: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          const fullPhoneNo = parsePhoneNumber(
            `+${getCountryCallingCode(countryCodeSwap[`Nigeria`])}${
              values.phone
            }`
          ).getNumber("e164");
          navigate("/products/renew/airtime/confirm", {
            state: { ...values, fullPhoneNo },
          });

          // const response = await setLoginUser({
          //   identifier: values.identifier,
          //   password: values.password,
          // });

          // if (
          //   response &&
          //   response.status === 200 &&
          //   response.message === "Login successful"
          // ) {
          //   navigate("/dashboard");
          //   return toast.success("Login successful");
          // }
          // console.log(response);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          setFieldValue,
        }) => (
          <>
            <Wrapper2 noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <InputLabel>Select Payment Method</InputLabel>
                  <CardWrapper>
                    <CardWithIconCmp
                      type="naira-wallet"
                      text="Naira Wallet"
                      icon={NairaIcon}
                      selectedItem={selectedWallet}
                      maxWidth={"150px"}
                      onSelect={() => {
                        setSelectedWallet("naira-wallet");
                      }}
                    />
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Select Provider</InputLabel>
                  <CardWrapper>
                    {[
                      { type: "mtn", text: "MTN", icon: `${MtnIcon}` },
                      {
                        type: "etisalat",
                        text: "9MOBILE",
                        icon: `${NineMobileIcon}`,
                      },
                      { type: "airtel", text: "AIRTEL", icon: `${AirtelIcon}` },
                      { type: "glo", text: "GLO", icon: `${GloIcon}` },
                    ].map((v, i) => {
                      return (
                        <CardWithIconCmp
                          message={`${
                            errors["provider"] ? errors["provider"] : ""
                          }`}
                          type={v.type}
                          text={v.text}
                          icon={v.icon}
                          selectedItem={values.provider}
                          maxWidth={"110px"}
                          onSelect={() => {
                            setFieldValue("provider", v.type);
                          }}
                        />
                      );
                    })}
                  </CardWrapper>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel>Select Amount</InputLabel>
                  <div>
                    <CardWrapper>
                      {[
                        { type: "100", text: "₦100.00" },
                        { type: "200", text: "₦200.00" },
                        { type: "500", text: "₦500.00" },
                        { type: "1000", text: "₦1000.00" },
                        { type: "other", text: "other" },
                      ].map((v, i) => {
                        return (
                          <CardWithIconCmp
                            message={`${
                              errors["amount"] ? errors["amount"] : ""
                            }`}
                            removeIcon
                            type={v.type}
                            text={v.text}
                            icon={v.icon}
                            selectedItem={values.amount}
                            maxWidth={"80px"}
                            onSelect={() => {
                              if (v.type === "other") {
                                setFieldValue("amountType", "other");
                              } else {
                                setFieldValue("amountType", "");
                                setFieldValue("amount", v.type);
                              }
                            }}
                          />
                        );
                      })}
                    </CardWrapper>
                  </div>
                </InputWrapper>
                {values.amountType === "other" && (
                  <InputWrapper>
                    <CustomPriceInputCmp
                      background="rgba(225, 235, 245, 0.1)"
                      required={true}
                      icon={
                        <>
                          <NairaImg src={NairaIcon} alt="Naira" />
                        </>
                      }
                      type="text"
                      name="amount"
                      placeholder="Enter Amount"
                      values={values}
                      onChange={setFieldValue}
                      message={`${errors["amount"] ? errors["amount"] : ""}`}
                      showMessage={true}
                    />
                  </InputWrapper>
                )}
                <InputWrapper>
                  <InputLabel>Enter Phone Number</InputLabel>
                  <CustomPhoneInputCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    name="phone"
                    placeholder="Enter Receiver’s Phone Number"
                    values={values}
                    phoneCode={`+${getCountryCallingCode(
                      countryCodeSwap[`Nigeria`]
                    )}`}
                    flag={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                      countryCodeSwap[`Nigeria`]
                    }.svg`}
                    onChange={handleChange}
                    message={`${errors["phone"] ? errors["phone"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
              </FormWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Continue"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={loading || !isValid ? true : false}
                />
              </ButtonWrapper>
            </Wrapper2>
          </>
        )}
      </Formik>
    </>
  );
};

export default AirtimePurchase;
