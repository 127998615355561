/* eslint-disable no-useless-escape */
import * as yup from "yup";

const addBankInProfileSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .required("Provide account number")
    .matches(/^\d+$/, "Only digit is allowed")
    .test(
      "length",
      "Provide 10 digits account number",
      (val) => val && val.length === 10
    ),
  accountName: yup.string().required("Provide account name"),
  bankName: yup.string().required("Select a bank"),
  bankCode: yup.string().required("Provide bank code"),
});

export default addBankInProfileSchema;
