import { Search } from "@styled-icons/boxicons-regular";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import CustomInputIconCmp from "../../../components/InputsCmp/CustomInputIconCmp";
import StepperCmp from "../../../components/StepperCmp/StepperCmp";
import appTheme from "../../../constants/theme";
import { getAllGiftcardsSell } from "../../../redux/actions/giftcardActions";
import DisplayGiftcardCard from "./Components/DisplayGiftcardCard";
import SpinnerCmp from "../../../components/SpinnerCmp/SpinnerCmp";
import { searchThroughArrayOfObj } from "../../../utilities/searchThroughArrayOfObj";
import NotFound from "./Components/NotFound";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 2rem;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Question = styled.h2`
  font-weight: 700;
  font-size: 28px;
  color: #323232;
  max-width: 300px;
  width: 100%;
  margin: 0;
`;

const Desc = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  color: #323232;
`;

const FormWrapper = styled.div`
  max-width: 250px;
  width: 100%;

  @media only screen and (max-width: 960px) {
    max-width: 100%;
  }
`;

const GiftcardsWrapper = styled.div`
  display: flex;
  row-gap: 25px;
  column-gap: 10px;
  flex-wrap: wrap;
  padding: 1.5rem 0;

  @media only screen and (max-width: 815px) {
    column-gap: 7px;
  }

  @media only screen and (max-width: 599px) {
    column-gap: 10px;
  }
`;

const GiftcardItem = styled.div`
  flex: 0 0 24%;
  width: 24%;

  @media only screen and (max-width: 1250px) {
    flex: 0 0 32%;
    width: 32%;
  }

  @media only screen and (max-width: 599px) {
    flex: 0 0 48%;
    width: 48%;
  }
`;

const SellGiftcard = ({
  getAllGiftcardsSellLoading,
  setGetAllGiftcardsSell,
}) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [giftcardsData, setGiftcardsData] = useState([]);
  const [tempStoreGiftCardData, setTempStoreGiftCardData] = useState([]);

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetAllGiftcardsSell();

      setGiftcardsData(response.data);
      setTempStoreGiftCardData(response.data);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout pageTitle="Sell Giftcard">
      <Wrapper>
        <StepperCmp
          activeStep={0}
          stepData={[
            { title: "Card" },
            { title: "Country" },
            { title: "Value" },
            { title: "Summary" },
            { title: "Upload cards" },
            { title: "Finish" },
          ]}
        />
        <TopWrapper>
          <QuestionWrapper>
            <Question>What card would you want to sell?</Question>
            <Desc>Select the card you will like to sell</Desc>
          </QuestionWrapper>
          <FormWrapper>
            <CustomInputIconCmp
              background="rgba(225, 235, 245, 0.1)"
              required={true}
              icon={
                <>
                  <Search size={20} color="rgba(107, 114, 128, 0.5)" />
                </>
              }
              type="text"
              name="searchQuery"
              placeholder="Search for giftcards by name"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                if (e.target.value) {
                  const result = searchThroughArrayOfObj(
                    tempStoreGiftCardData,
                    e.target.value
                  );
                  setGiftcardsData(result);
                } else {
                  setGiftcardsData(tempStoreGiftCardData);
                }
              }}
              showMessage={false}
            />
          </FormWrapper>
        </TopWrapper>

        {getAllGiftcardsSellLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <SpinnerCmp
              enabled={true}
              color={appTheme.COLORS.bluePrimary}
              size={40}
              secondaryColor={appTheme.COLORS.white}
            />
          </div>
        ) : !giftcardsData?.length ? (
          <NotFound text={searchQuery} />
        ) : (
          <GiftcardsWrapper>
            {giftcardsData?.map((v, i) => {
              return (
                <GiftcardItem key={i}>
                  <DisplayGiftcardCard
                    name={v?.brand?.brandName}
                    image={v?.logoUrls[0]}
                    onClick={() => {
                      navigate(
                        `/products/giftcard/sell/international/${v?.brand?.brandId}`,
                        { state: { brand: v?.brand, logoUrls: v?.logoUrls } }
                      );
                    }}
                  />
                </GiftcardItem>
              );
            })}
          </GiftcardsWrapper>
        )}
      </Wrapper>
    </DashboardLayout>
  );
};

const mapState = (state) => ({
  getAllGiftcardsSellLoading: state.giftcard.getAllGiftcardsSellLoading,
});

const mapDispatch = (dispatch) => ({
  setGetAllGiftcardsSell: () => dispatch(getAllGiftcardsSell()),
});

export default connect(mapState, mapDispatch)(SellGiftcard);
