import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../constants/url";
import {
  ADD_BANK_ACCOUNT_ERROR,
  ADD_BANK_ACCOUNT_LOADING,
  CREATE_NEW_PASSWORD_ERROR,
  CREATE_NEW_PASSWORD_LOADING,
  CREATE_NEW_PASSWORD_SUCCESS,
  CREATE_NEW_PIN_ERROR,
  CREATE_NEW_PIN_LOADING,
  CREATE_NEW_PIN_SUCCESS,
  DELETE_PROFILE_PICTURE_ERROR,
  DELETE_PROFILE_PICTURE_LOADING,
  DELETE_PROFILE_PICTURE_SUCCESS,
  ENTER_OTP_ERROR,
  ENTER_OTP_LOADING,
  ENTER_OTP_SUCCESS,
  GENERATE_2FA_CODE_ERROR,
  GENERATE_2FA_CODE_LOADING,
  GENERATE_2FA_CODE_SUCCESS,
  GET_2FA_STATUS_ERROR,
  GET_2FA_STATUS_LOADING,
  GET_2FA_STATUS_SUCCESS,
  GET_USER_LIMIT_ERROR,
  GET_USER_LIMIT_LOADING,
  GET_USER_LIMIT_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USER_PROFILE_LOADING,
  GET_USER_PROFILE_PICTURE_ERROR,
  GET_USER_PROFILE_PICTURE_LOADING,
  GET_USER_PROFILE_PICTURE_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  PROVIDE_PASSWORD_ERROR,
  PROVIDE_PASSWORD_LOADING,
  PROVIDE_PASSWORD_SUCCESS,
  TOGGLE_2FA_ERROR,
  TOGGLE_2FA_LOADING,
  TOGGLE_2FA_SUCCESS,
  UPDATE_PROFILE_PICTURE_ERROR,
  UPDATE_PROFILE_PICTURE_LOADING,
  VERIFY_2FA_CODE_ERROR,
  VERIFY_2FA_CODE_LOADING,
  VERIFY_2FA_CODE_SUCCESS,
} from "../reducers/types";
import headers from "./header";

export const getUserProfile = () => async (dispatch) => {
  dispatch({ type: GET_USER_PROFILE_LOADING, payload: true });
  dispatch({ type: GET_USER_PROFILE_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/user-settings/profile/get`,
      headers()
    );
    dispatch({ type: GET_USER_PROFILE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_USER_PROFILE_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const getUserProfilePicture = () => async (dispatch) => {
  dispatch({ type: GET_USER_PROFILE_PICTURE_LOADING, payload: true });
  dispatch({ type: GET_USER_PROFILE_PICTURE_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/user-settings/picture/retrieve`,
      headers()
    );
    dispatch({ type: GET_USER_PROFILE_PICTURE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({
      type: GET_USER_PROFILE_PICTURE_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_USER_PROFILE_PICTURE_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const updateProfilePicture = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_PICTURE_LOADING, payload: true });
  dispatch({ type: UPDATE_PROFILE_PICTURE_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/user-settings/picture/update`,
      data,
      headers()
    );
    dispatch({ type: UPDATE_PROFILE_PICTURE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    return response.data;
  } catch (err) {
    dispatch({ type: UPDATE_PROFILE_PICTURE_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.image}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const deleteProfilePicture = () => async (dispatch) => {
  dispatch({ type: DELETE_PROFILE_PICTURE_LOADING, payload: true });
  dispatch({ type: DELETE_PROFILE_PICTURE_ERROR, payload: null });
  try {
    const response = await axios.delete(
      `${apiBaseUrl}/user-settings/picture/delete`,
      headers()
    );
    dispatch({ type: DELETE_PROFILE_PICTURE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: DELETE_PROFILE_PICTURE_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: DELETE_PROFILE_PICTURE_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const addBankAccount = (data) => async (dispatch) => {
  dispatch({ type: ADD_BANK_ACCOUNT_LOADING, payload: true });
  dispatch({ type: ADD_BANK_ACCOUNT_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/user-settings/add/bank`,
      data,
      headers()
    );
    dispatch({ type: ADD_BANK_ACCOUNT_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    return response.data;
  } catch (err) {
    dispatch({ type: ADD_BANK_ACCOUNT_LOADING, payload: null });

    if (err.response.status === 422) {
      return toast.error(`${err.response?.data?.errors[0]?.accountNumber}`);
    } else {
      return toast.error(err.response?.data?.message);
    }
  }
};

export const getUserLimit = () => async (dispatch) => {
  dispatch({ type: GET_USER_LIMIT_LOADING, payload: true });
  dispatch({ type: GET_USER_LIMIT_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/user-settings/limits/details`,
      headers()
    );
    dispatch({ type: GET_USER_LIMIT_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: GET_USER_LIMIT_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_USER_LIMIT_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const getUser2faStatus = () => async (dispatch) => {
  dispatch({ type: GET_2FA_STATUS_LOADING, payload: true });
  dispatch({ type: GET_2FA_STATUS_ERROR, payload: null });
  try {
    const response = await axios.get(`${apiBaseUrl}/2fa/status`, headers());
    dispatch({ type: GET_2FA_STATUS_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: GET_2FA_STATUS_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GET_2FA_STATUS_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const toggle2fa = (data) => async (dispatch) => {
  dispatch({ type: TOGGLE_2FA_LOADING, payload: true });
  dispatch({ type: TOGGLE_2FA_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/2fa/toggle`,
      data,
      headers()
    );
    dispatch({ type: TOGGLE_2FA_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: TOGGLE_2FA_SUCCESS, payload: response.data });
    toast.success(response.data.message);
    dispatch(getUser2faStatus());
    return response.data;
  } catch (err) {
    dispatch({ type: TOGGLE_2FA_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const generate2faCode = () => async (dispatch) => {
  dispatch({ type: GENERATE_2FA_CODE_LOADING, payload: true });
  dispatch({ type: GENERATE_2FA_CODE_ERROR, payload: null });
  try {
    const response = await axios.get(`${apiBaseUrl}/2fa/generate`, headers());
    dispatch({ type: GENERATE_2FA_CODE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: GENERATE_2FA_CODE_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: GENERATE_2FA_CODE_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const verify2fa = (data) => async (dispatch) => {
  dispatch({ type: VERIFY_2FA_CODE_LOADING, payload: true });
  dispatch({ type: VERIFY_2FA_CODE_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/2fa/verify`,
      data,
      headers()
    );
    dispatch({ type: VERIFY_2FA_CODE_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: VERIFY_2FA_CODE_SUCCESS, payload: response.data });
    dispatch(getUser2faStatus());
    return response.data;
  } catch (err) {
    dispatch({ type: VERIFY_2FA_CODE_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const providePassword = (data) => async (dispatch) => {
  dispatch({ type: PROVIDE_PASSWORD_LOADING, payload: true });
  dispatch({ type: PROVIDE_PASSWORD_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/user-settings/reset/detail`,
      data,
      headers()
    );
    dispatch({ type: PROVIDE_PASSWORD_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: PROVIDE_PASSWORD_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: PROVIDE_PASSWORD_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const enterOtp = (data) => async (dispatch) => {
  dispatch({ type: ENTER_OTP_LOADING, payload: true });
  dispatch({ type: ENTER_OTP_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/user-settings/submit/otp/txn-pin`,
      data,
      headers()
    );
    dispatch({ type: ENTER_OTP_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: ENTER_OTP_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: ENTER_OTP_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const createNewPassword = (data) => async (dispatch) => {
  dispatch({ type: CREATE_NEW_PASSWORD_LOADING, payload: true });
  dispatch({ type: CREATE_NEW_PASSWORD_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/user-settings/create/new/password`,
      data,
      headers()
    );
    dispatch({ type: CREATE_NEW_PASSWORD_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: CREATE_NEW_PASSWORD_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_NEW_PASSWORD_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};

export const createNewPin = (data) => async (dispatch) => {
  dispatch({ type: CREATE_NEW_PIN_LOADING, payload: true });
  dispatch({ type: CREATE_NEW_PIN_ERROR, payload: null });
  try {
    const response = await axios.post(
      `${apiBaseUrl}/user-settings/create/new/txn-pin`,
      data,
      headers()
    );
    dispatch({ type: CREATE_NEW_PIN_LOADING, payload: null });

    if (response.data.status !== 200) {
      return toast.error(response.data.message);
    }

    dispatch({ type: CREATE_NEW_PIN_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: CREATE_NEW_PIN_LOADING, payload: null });

    return toast.error(err.response?.data?.message);
  }
};
