import { Dashboard } from "@styled-icons/boxicons-solid";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import appTheme from "../../constants/theme";
// import CaretIcon from "../../assets/caret.svg";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { useNavigate, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useHover from "@react-hook/hover";

const ItemText = styled.h2`
  margin: 0;
  padding: 0;
  color: #6a707e;
  font-size: 15px;
  font-weight: ${appTheme.WEIGHT.medium};
  /* line-height: 0; */
`;

const IconWrapper = styled.div`
  color: #aaaaaa;
`;

const IconImage = styled(LazyLoadImage)``;

const Container = styled.div`
  padding: 1rem 0.8rem;
  cursor: pointer;
  background: ${(props) => (props.isActive === true ? "#e6f0f8" : "#fff")};

  ${ItemText}, ${IconWrapper} {
    color: ${(props) => props.isActive === true && "#016cba"};
  }

  &:hover {
    background: #e6f0f8;
    /* mix-blend-mode: normal;
    opacity: 0.1;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); */
  }

  &:hover ${ItemText}, &:hover ${IconWrapper} {
    color: #016cba !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconTextWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ToggleIconWrapper = styled.div`
  transition: transform 0.3s;
  /* transform: rotate(90deg); */
  transform: ${(props) => props.open && "rotate(90deg)"};
`;

const CaretImg = styled(ChevronRight)`
  color: #d3d6e4;
`;

const SubMenuText = styled.h2`
  margin: 0;
  padding: 0;
  color: #6a707e;
  font-size: 15px;
  font-weight: ${appTheme.WEIGHT.medium};
`;

const SubMenuContainer = styled.div`
  height: ${(props) => (props.open ? "auto" : "0")};
  overflow: ${(props) => (props.open === false ? "hidden" : "unset")};
`;

const SubMenuWrapper = styled.div`
  padding: 1rem 0.8rem 1rem 2.7rem;
  cursor: pointer;

  &:hover ${SubMenuText} {
    color: #016cba !important;
  }
`;

const SidebarItem = ({ item, isActive }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const target = useRef(null);
  const isHovering = useHover(target);
  // console.log(location.pathname.includes(item.link), "pathhh");

  return (
    <>
      {item.children ? (
        <>
          <Container
            isActive={location.pathname.includes(item.link) ? true : false}
            onClick={() => {
              setOpen(!open);
              navigate(item.link);
            }}
          >
            <Wrapper>
              <IconTextWrapper>
                <IconWrapper>
                  <Dashboard size={22} />
                </IconWrapper>
                <ItemText>{item.title}</ItemText>
              </IconTextWrapper>
              <ToggleIconWrapper open={open}>
                <CaretImg size={22} />
              </ToggleIconWrapper>
            </Wrapper>
          </Container>
          <SubMenuContainer open={open}>
            {item.children.map((value, index) => {
              return (
                <SubMenuWrapper key={index}>
                  <SubMenuText>{value.title}</SubMenuText>
                </SubMenuWrapper>
              );
            })}
          </SubMenuContainer>
        </>
      ) : (
        <>
          <Container
            ref={target}
            isActive={location.pathname.includes(item.link) ? true : false}
            onClick={() => {
              setOpen(!open);
              navigate(item.link);
            }}
          >
            <Wrapper>
              <IconTextWrapper>
                <IconWrapper>
                  <IconImage
                    src={
                      location.pathname.includes(item.link) || isHovering
                        ? item.icons[1]
                        : item.icons[0]
                    }
                    alt="icon"
                    effect="blur"
                  />
                </IconWrapper>
                <ItemText>{item.title}</ItemText>
              </IconTextWrapper>
            </Wrapper>
          </Container>
        </>
      )}
    </>
  );
};

export default SidebarItem;
