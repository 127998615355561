import ls from "localstorage-slim";
import encUTF8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

// configure localstorage-slim to use CryptoJS for Encryption/Decryption
ls.config.encrypt = true;
ls.config.secret = process.env.REACT_APP_T_SEC;

ls.config.encrypter = (data, secret) =>
  AES.encrypt(JSON.stringify(data), secret).toString();
ls.config.decrypter = (data, secret) => {
  try {
    return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
  } catch (e) {
    // incorrect secret, return the encrypted data instead / or null
    return data; // or return null;
  }
};

const loadToken = () => {
  try {
    const serializedState = ls.get("t");
    // console.log(serializedState);
    if (serializedState === null) {
      return undefined;
    }
    return serializedState;
  } catch (err) {
    return undefined;
  }
};

const saveToken = (t) => {
  try {
    // const serializedState = JSON.stringify(t);
    ls.set("t", t, { ttl: 3600 });
  } catch (err) {
    //
  }
};

export { loadToken, saveToken };
