import React from "react";
import styled from "styled-components";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Profile from "./Profile/Profile";
import Bank from "./Bank/Bank";
import Limits from "./Limits/Limits";
import Security from "./Security/Security";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const TabsWrapper = styled.div`
  & .settings-tab .react-tabs__tab-list .react-tabs__tab {
    font-weight: 500;
    font-size: 15px;
    color: #323232;
    padding: 12px;
  }

  & .settings-tab .react-tabs__tab-list .react-tabs__tab--selected {
    font-weight: 700;
    border: none;
    border-bottom: 2px solid #016cba;
  }

  & .settings-tab .react-tabs__tab-list {
    border-bottom: 1px solid #c4c4c4;
  }

  & .settings-tab .react-tabs__tab-panel--selected {
    padding: 2rem 0;
  }
`;

const Setting = () => {
  return (
    <>
      <DashboardLayout pageTitle="Settings">
        <Wrapper>
          <TabsWrapper>
            <Tabs
              disableUpDownKeys={true}
              focusTabOnClick={false}
              defaultFocus={false}
              className="settings-tab"
            >
              <TabList>
                {[
                  "Profile",
                  "Bank information",
                  // "Preferences",
                  "Security",
                  "Limits & Verification",
                ].map((v, i) => {
                  return <Tab key={i}>{v}</Tab>;
                })}
              </TabList>

              <TabPanel>
                <Profile />
              </TabPanel>
              <TabPanel>
                <Bank />
              </TabPanel>
              <TabPanel>
                <Security />
              </TabPanel>
              <TabPanel>
                <Limits />
              </TabPanel>
            </Tabs>
          </TabsWrapper>
        </Wrapper>
      </DashboardLayout>
    </>
  );
};

export default Setting;
