import { Formik, useFormikContext } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import SolidButton from "../../../components/ButtonCmp/Button";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import CustomTextAreaCmp from "../../../components/InputsCmp/CustomTextAreaCmp";
import CustomPriceInputCmp from "../../../components/InputsCmp/CustomPriceInputCmp";
import appTheme from "../../../constants/theme";
import NairaIcon from "../../../assets/naira.svg";
import CustomInputCmp from "../../../components/InputsCmp/CustomInputCmp";
import {
  checkUserInfo,
  getSettings,
} from "../../../redux/actions/walletActions";
import { getUserBalance } from "../../../redux/actions/dashboardAction";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import instantWithdrawalSchema from "../../../validators/instantWithdrawalValidator";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const OuterWrapper = styled.div`
  background: rgba(196, 196, 196, 0.05);
  border-radius: 20px;
  max-width: 700px;
  width: 100%;
  /* padding: 1rem; */
`;

const Wrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1rem;
  border-radius: 20px;
`;

const DescText = styled.p`
  font-weight: 600;
  font-size: 20px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  color: #323232;
  padding: 1rem 0;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const Wrapper2 = styled(Form)`
  padding-top: 0.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const InputLabel = styled.h2`
  font-size: 14px;
  font-weight: ${appTheme.WEIGHT.small2};
  color: ${appTheme.COLORS.grey1};
`;

const NairaImg = styled.img``;

const FooterWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const InfoText = styled.p`
  margin-bottom: 0.1rem;
  color: #016cba;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
`;

const InfoSpan = styled.span`
  font-weight: 600;
`;

const JessieUserWithdrawal = ({
  loading,
  settingsError,
  settingsLoading,
  settingsSuccess,
  dashboardBalance,
  setGetSettings,
  setGetUserBalance,
  setCheckUserInfo,
}) => {
  const [settingsData, setSettingsData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const loadNecessaryData = async () => {
      const response = await setGetSettings();
      await setGetUserBalance();

      setSettingsData(response.data);
    };
    loadNecessaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchAccountDetails = (props) => {
    const {
      values: { identifier, receiverName, receiverPhone },
      setFieldValue,
    } = useFormikContext();

    async function getAccount() {
      const response = await setCheckUserInfo({ identifier });
      return response;
    }

    useEffect(() => {
      async function fetchAccount() {
        if (identifier) {
          const response = await getAccount();
          if (response) {
            setFieldValue(
              "receiverName",
              response?.data?.username || response?.data?.fullName
            );
            setFieldValue("receiverPhone", response?.data?.phone);
          } else {
            setFieldValue("receiverName", "");
            setFieldValue("receiverPhone", "");
          }
        }
      }

      // fetchAccount();
      const debounce = setTimeout(fetchAccount, 1500);
      return () => clearTimeout(debounce);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identifier, setFieldValue]);

    return (
      <>
        {receiverName && receiverPhone && (
          <div style={{ textAlign: "left" }}>
            <InputLabel>Verify Receiver Details</InputLabel>
            <InputWrapper>
              <CustomInputCmp
                background="rgba(225, 235, 245, 0.1)"
                placeholder="Enter receiver name"
                name={"receiverName"}
                values={props.values}
                showMessage={false}
                disabled={true}
              />
            </InputWrapper>
            <InputWrapper>
              <CustomInputCmp
                background="rgba(225, 235, 245, 0.1)"
                placeholder="Enter receiver phone"
                name={"receiverPhone"}
                values={{
                  ...props.values,
                  receiverPhone: props.values.receiverPhone.replace(
                    /.+(.{4})$/,
                    "**** $1"
                  ),
                }}
                showMessage={false}
                disabled={true}
              />
            </InputWrapper>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <DashboardLayout backButton={true} backText={"Back to Wallet"}>
        <Container>
          <OuterWrapper>
            <Wrapper>
              <DescText>Transfer To Jessie User</DescText>
              <Formik
                enableReinitialize={true}
                validationSchema={instantWithdrawalSchema}
                initialValues={{
                  identifier: "",
                  amount: "",
                  narration: "",
                  charge: settingsData?.jessieUserFee,
                  balance: dashboardBalance?.data?.balance,
                  receiverName: "",
                  receiverPhone: "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  navigate("/wallets/withdrawal/confirm", {
                    state: { ...values, withdrawalType: "jessie-transfer" },
                  });
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  setFieldTouched,
                  setFieldValue,
                  touched,
                }) => (
                  <>
                    <Wrapper2 noValidate onSubmit={handleSubmit}>
                      <FormWrapper>
                        <InputWrapper>
                          <InputLabel>Username/Email</InputLabel>
                          <CustomInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            placeholder="Enter Username/Email"
                            name={"identifier"}
                            values={values}
                            onChange={handleChange}
                            message={`${
                              errors["identifier"] ? errors["identifier"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <FetchAccountDetails values={values} />
                        <InputWrapper>
                          <InputLabel>Withdrawal Amount</InputLabel>
                          <CustomPriceInputCmp
                            background="rgba(225, 235, 245, 0.1)"
                            required={true}
                            icon={
                              <>
                                <NairaImg src={NairaIcon} alt="Naira" />
                              </>
                            }
                            type="text"
                            name="amount"
                            placeholder="Enter Amount"
                            values={values}
                            onChange={setFieldValue}
                            message={`${
                              errors["amount"] ? errors["amount"] : ""
                            }`}
                            showMessage={true}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel>Narration (Optional)</InputLabel>
                          <CustomTextAreaCmp
                            background="rgba(225, 235, 245, 0.1)"
                            as="textarea"
                            rows={3}
                            required={true}
                            onChange={handleChange}
                            values={values}
                            type="text"
                            name="narration"
                            placeholder="Enter content"
                            showMessage
                            message={`${
                              errors["narration"] ? errors["narration"] : ""
                            }`}
                          />
                        </InputWrapper>
                      </FormWrapper>
                      <FooterWrapper>
                        <div>
                          <InfoText>
                            Transfer fee:{" "}
                            <InfoSpan>
                              <NumberFormat
                                thousandSeparator
                                value={settingsData?.jessieUserFee}
                                decimalScale={2}
                                prefix="₦"
                                defaultValue="0.00"
                                allowNegative={false}
                                placeholder="0.00"
                                displayType="text"
                                disabled
                              />
                            </InfoSpan>
                          </InfoText>
                          <InfoText>
                            *This transaction is irreversible once sent
                          </InfoText>
                        </div>
                      </FooterWrapper>
                      <ButtonWrapper>
                        <SolidButton
                          text={"Continue"}
                          type="submit"
                          weighty="500"
                          textFontSize="14px"
                          specifyPadding="0.8rem 1.5rem"
                          color="#fff"
                          widthWebkit="100%"
                          widthMoz="100%"
                          widthNormal="100%"
                          borderRadius="8px"
                          backColor="#1a8fdd"
                          backgroundHoverColor="#1a8fdd"
                          textHoverColor="#fff"
                          margin="0rem auto 0"
                          disabled={!isValid ? true : false}
                        />
                      </ButtonWrapper>
                    </Wrapper2>
                  </>
                )}
              </Formik>
            </Wrapper>
          </OuterWrapper>
        </Container>
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  settingsLoading: state.wallet.getSettingsLoading,
  settingsSuccess: state.wallet.getSettingsSuccess,
  settingsError: state.wallet.getSettingsError,
  dashboardBalance: state.dashboard.dashboardBalance,
});

const mapDispatch = (dispatch) => ({
  setGetSettings: () => dispatch(getSettings()),
  setGetUserBalance: () => dispatch(getUserBalance()),
  setCheckUserInfo: (data) => dispatch(checkUserInfo(data)),
});

export default connect(mapState, mapDispatch)(JessieUserWithdrawal);
