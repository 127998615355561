const convertToLocalDateTime = (date) => {
  return `${new Date(date).toLocaleDateString()} ${new Date(
    date
  ).toLocaleTimeString()}`;
};

const trimText = (text, noOfChar) => {
  return text.length > noOfChar
    ? `${text.substring(0, noOfChar)}...`
    : text.substring(0, noOfChar);
};

const rearrangeCountries = (supportedCountries, providedIsoNames) => {
  // Filter out the provided countries
  const providedCountries = supportedCountries.filter((country) =>
    providedIsoNames.includes(country.country.isoName)
  );

  // Filter out the remaining countries
  const remainingCountries = supportedCountries.filter(
    (country) => !providedIsoNames.includes(country.country.isoName)
  );

  // Sort the provided countries based on their position in the priority list, skipping missing isoNames
  providedCountries.sort((a, b) => {
    const aIndex = providedIsoNames.indexOf(a.country.isoName);
    const bIndex = providedIsoNames.indexOf(b.country.isoName);
    if (aIndex === -1) return 1; // Skip missing isoName in the priority list
    if (bIndex === -1) return -1; // Skip missing isoName in the priority list
    return aIndex - bIndex;
  });

  // Sort the remaining countries alphabetically
  remainingCountries.sort((a, b) =>
    a.country.name.localeCompare(b.country.name)
  );

  // Concatenate the two arrays and return the result
  return providedCountries.concat(remainingCountries);
};

export { convertToLocalDateTime, trimText, rearrangeCountries };
