import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowBackCircle } from "@styled-icons/ionicons-outline";
import DashboardLayout from "../../../components/DashboardLayout/DashboardLayout";
import NumberFormat from "react-number-format";
import SolidButton from "../../../components/ButtonCmp/Button";
import { ErrorWarning } from "@styled-icons/remix-line/ErrorWarning";
import StepperCmp from "../../../components/StepperCmp/StepperCmp";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  padding: 2rem;

  @media only screen and (max-width: 767px) {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  @media only screen and (max-width: 375px) {
    padding: 2rem 1rem;
  }
`;

const BackWrapper = styled.div`
  padding: 1rem 0 0;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
  padding-top: 1rem;
`;

const TransactionWrapper = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #adb5bd;
  padding: 1.5rem;
  border-radius: 20px;
  margin: 0 auto;
  width: 100%;
`;

const DescWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

const DescText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #016cba;
  margin-bottom: 0;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const BarWrapper = styled.div`
  height: 60px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(1, 108, 186, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const BarTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  color: #c4c4c4;
  margin-bottom: 0;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const BarSpan = styled.p`
  font-weight: 700;
  color: #323232;
  font-size: 20px;
  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 1rem;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Detail = styled.div``;

const DetailWrapperText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0;
`;

const TitleText = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: #323232;
  margin-bottom: 0;
`;

const DescText2 = styled.p`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #323232;
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  color: #adb5bd;
  background-color: #adb5bd;
  margin: 0;
`;

const Wrapper2 = styled.div``;

const CardValueTableHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0;
`;

const CardValueContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0;
`;

const NoteWrapper = styled.div`
  border: 1px solid rgba(222, 83, 72, 1);
  background: rgba(222, 83, 72, 0.04);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const IconWrapper = styled.div`
  max-width: 20px;
  width: 100%;

  @media only screen and (max-width: 374px) {
    display: none;
  }
`;

const NoteText = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 12px;
  color: #323232;
  text-align: left;
`;

const SellGiftcardSummary = () => {
  const BarCmp = (props) => {
    return (
      <>
        <BarWrapper>
          <BarTitle>{props.title}</BarTitle>
          <BarSpan>{props.value}</BarSpan>
        </BarWrapper>
      </>
    );
  };
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state) {
      navigate("/products/giftcard/sell/international");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, navigate]);

  return (
    <DashboardLayout pageTitle="Sell Giftcard">
      <Wrapper>
        <StepperCmp
          activeStep={3}
          stepData={[
            { title: "Card" },
            { title: "Country" },
            { title: "Value" },
            { title: "Summary" },
            { title: "Upload cards" },
            { title: "Finish" },
          ]}
        />
        <BackWrapper>
          <ArrowBackCircle
            size={30}
            color="#1A8FDD"
            onClick={() => navigate(-1)}
          />
        </BackWrapper>
        <ContentWrapper>
          <TransactionWrapper>
            <DescWrapper>
              <DescText>Transaction Summary</DescText>
            </DescWrapper>
            <DetailContainer>
              <DetailWrapper>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Giftcard</TitleText>
                    <DescText2>{location?.state?.brand?.brandName}</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Type</TitleText>
                    <DescText2>Selling</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Country</TitleText>
                    <DescText2>{location?.state?.country?.isoName}</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <DetailWrapperText>
                    <TitleText>Card type</TitleText>
                    <DescText2>{location?.state?.giftcardType}</DescText2>
                  </DetailWrapperText>
                </Detail>
                <Detail>
                  <Line />
                  <CardValueTableHeadingWrapper>
                    <TitleText style={{ flex: "1", textAlign: "left" }}>
                      Card value
                    </TitleText>
                    <TitleText style={{ flex: "2" }}>Quantity</TitleText>
                    <TitleText style={{ flex: "1", textAlign: "right" }}>
                      Rate
                    </TitleText>
                  </CardValueTableHeadingWrapper>
                  {location?.state?.giftcardValue?.map((v, i) => {
                    return (
                      <CardValueContentWrapper key={i}>
                        <DescText2 style={{ flex: "1", textAlign: "left" }}>
                          $
                          <NumberFormat
                            thousandSeparator
                            value={v.fixedRecipientDenomination}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </DescText2>
                        <DescText2 style={{ flex: "2" }}>
                          {v.quantity}
                        </DescText2>
                        <DescText2 style={{ flex: "1", textAlign: "right" }}>
                          <NumberFormat
                            thousandSeparator
                            value={v.itemPrice}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                          /$
                        </DescText2>
                      </CardValueContentWrapper>
                    );
                  })}
                </Detail>
              </DetailWrapper>
              <BarCmp
                title="Transaction value "
                value={
                  <NumberFormat
                    thousandSeparator
                    value={location?.state?.giftcardValue[0]?.totalPrice}
                    decimalScale={2}
                    prefix="₦"
                    defaultValue="0.00"
                    allowNegative={false}
                    placeholder="0.00"
                    displayType="text"
                    disabled
                  />
                }
              />
            </DetailContainer>
            <NoteWrapper>
              <IconWrapper>
                <ErrorWarning color={"#DE5348"} size={20} />
              </IconWrapper>
              <NoteText>
                <span style={{ color: "#de5348", fontWeight: "700" }}>
                  Note:
                </span>{" "}
                Giftcard upload on a wrong channel will be transferred to the
                appropriate channel and treated at the current rate in that
                channel
              </NoteText>
            </NoteWrapper>
            <Wrapper2>
              <ButtonWrapper>
                <SolidButton
                  text="Proceed"
                  type="submit"
                  weighty="500"
                  onClick={() => {
                    navigate("/products/giftcard/sell/upload", {
                      state: location?.state,
                    });
                  }}
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                />
              </ButtonWrapper>
            </Wrapper2>
          </TransactionWrapper>
        </ContentWrapper>
      </Wrapper>
    </DashboardLayout>
  );
};

export default SellGiftcardSummary;
