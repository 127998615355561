import { Formik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import sellGiftcardNoticeSchema from "../../validators/giftcardSellNoticeValidator";
import SolidButton from "../ButtonCmp/Button";
import ModalCmp from "../ModalCmp/ModalCmp";
import getSymbolFromCurrency from "currency-symbol-map";

const Wrapper = styled.div`
  text-align: center;
  padding: ${(props) =>
    props.wrapperPadding ? props.wrapperPadding : "2rem 1rem"};
`;

const ContentWrapper = styled.div`
  padding: 1rem 0;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  margin: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const SmallTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  text-align: left;
`;

const TextCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

const Terms = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`;

const FormCheck = styled(Form.Check)``;

const CheckInput = styled(Form.Check.Input)`
  &:checked {
    background-color: #016cba;
    border-color: #016cba;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 1rem;
`;

const GiftcardSellNoticeModal = (props) => {
  return (
    <>
      <ModalCmp
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        contentStyles={{ maxWidth: props.maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Notice"
        id="notice"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={props.showCloseIcon}
      >
        <Wrapper wrapperPadding={props.wrapperPadding}>
          <Title>Important Notice</Title>
          <ContentWrapper>
            <TextWrapper>
              <SmallTitle>I agree that the</SmallTitle>

              <Formik
                isInitialValid={false}
                initialValues={{
                  giftcardType: false,
                  giftcardBrand: false,
                  currency: false,
                  amount: false,
                }}
                validationSchema={sellGiftcardNoticeSchema}
                onSubmit={async (values, { resetForm }) => {
                  props.btnAction();
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                  setFieldTouched,
                  isValid,
                  touched,
                }) => (
                  <>
                    <form
                      style={{ width: "100%" }}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <TextCheckBoxWrapper>
                        <FormCheck type="checkbox" id={"giftcardBrand"}>
                          <CheckInput
                            onChange={handleChange}
                            id={"giftcardBrand"}
                            name="giftcardBrand"
                            checked={values.giftcardBrand}
                            type="checkbox"
                          />
                        </FormCheck>
                        <Terms>
                          Giftcard is a/an {props?.data?.brand?.brandName}{" "}
                          giftcard
                        </Terms>
                      </TextCheckBoxWrapper>
                      <TextCheckBoxWrapper>
                        <FormCheck type="checkbox" id={"currency"}>
                          <CheckInput
                            onChange={handleChange}
                            id={"currency"}
                            name="currency"
                            checked={values.currency}
                            type="checkbox"
                          />
                        </FormCheck>
                        <Terms>
                          Giftcard currency is{" "}
                          {props?.data?.recipientCurrencyCode}
                        </Terms>
                      </TextCheckBoxWrapper>{" "}
                      <TextCheckBoxWrapper>
                        <FormCheck type="checkbox" id={"giftcardType"}>
                          <CheckInput
                            name="giftcardType"
                            onChange={handleChange}
                            id={"giftcardType"}
                            checked={values.giftcardType}
                            type="checkbox"
                          />
                        </FormCheck>
                        <Terms>
                          Giftcard type is a/an {props?.data?.giftcardType}
                        </Terms>
                      </TextCheckBoxWrapper>
                      <SmallTitle style={{ paddingTop: "10px" }}>
                        I confirm that I uploaded
                      </SmallTitle>
                      <TextCheckBoxWrapper>
                        <FormCheck type="checkbox" id={"amount"}>
                          <CheckInput
                            name="amount"
                            onChange={handleChange}
                            id={"amount"}
                            checked={values.amount}
                            type="checkbox"
                          />
                        </FormCheck>
                        <Terms>
                          {props?.data?.giftcardValue[0]?.quantity}{" "}
                          quantity(ies) of{" "}
                          {getSymbolFromCurrency(
                            props?.data?.recipientCurrencyCode
                          )}
                          {
                            props?.data?.giftcardValue[0]
                              ?.fixedRecipientDenomination
                          }{" "}
                          {props?.data?.brand?.brandName} giftcard{" "}
                        </Terms>
                      </TextCheckBoxWrapper>
                      <ButtonWrapper>
                        <SolidButton
                          text="I agree"
                          type="submit"
                          weighty="500"
                          onClick={() => {}}
                          textFontSize="14px"
                          specifyPadding="0.6rem 1.2rem"
                          color="#fff"
                          widthWebkit="107px"
                          widthMoz="107px"
                          widthNormal="107px"
                          borderRadius="8px"
                          backColor="#1a8fdd"
                          backgroundHoverColor="#1a8fdd"
                          textHoverColor="#fff"
                          margin="0rem auto 0"
                          disabled={isValid ? false : true}
                        />
                      </ButtonWrapper>
                    </form>
                  </>
                )}
              </Formik>
            </TextWrapper>
          </ContentWrapper>
        </Wrapper>
      </ModalCmp>
    </>
  );
};

export default GiftcardSellNoticeModal;
