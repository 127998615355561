import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthTemplateCmp from "../../components/AuthTemplateCmp/AuthTemplateCmp";
import SignupIndividualForm from "../../components/FormsCmp/SignupIndividualForm";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Text = styled.p`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small2};
  font-size: 14px;
  padding-top: 0rem;
  text-align: center;
`;

const Span = styled.span`
  color: #1a8fdd;
  cursor: pointer;
`;

const SignupIndividual = () => {
  const navigate = useNavigate();

  const Content = () => {
    return (
      <>
        <Wrapper>
          <SignupIndividualForm />
          <Text>
            Already have an account?{" "}
            <Span
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign in
            </Span>
          </Text>
        </Wrapper>
      </>
    );
  };
  return (
    <>
      <AuthTemplateCmp
        pageTitle="Create Account"
        pageDesc="Experience the Best way to Pay."
        content={<Content />}
        mobilePadding={"12rem 1rem 2rem"}
      />
    </>
  );
};

export default SignupIndividual;
