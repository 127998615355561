import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import appTheme from "../../constants/theme";
import "./CustomMultiSelect.css";

const ErrorMessageWrapper = styled.div`
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 13px;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
`;

// const CustomSelectWrapper = styled.div`
//   padding: 0.6rem 0rem;
// `;

function CustomMultiSelect(props) {
  const handleChange = (value) => {
    props.onChange(props.name, value);
    if (props.otherChange) {
      props.onChange(props.otherChange, "");
    }
  };

  const handleBlur = () => {
    props.onBlur(props.name, true);
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0.5rem 0.3rem 0.2rem 0.5rem",
      borderRadius: "8px",
      border: `${
        props.message
          ? "1px solid red"
          : state.isFocused || props.value
          ? `1px solid ${appTheme.COLORS.blueSecondary}`
          : "1px solid #ADB5BD !important"
      }`,
      boxShadow: "none",
      height: `${props.height ? `${props.height}` : ""}`,
      maxWidth: `${props.maxWidth ? `${props.maxWidth}` : ""}`,
      width: `${props.width ? `${props.width}` : ""}`,
      backgroundColor: `${
        state.isFocused ? "rgba(243, 244, 246, 0.7)" : "#fff"
      }`,
      "&:hover": {
        boxShadow: "none !important",
        border:
          state.isFocused &&
          `1px solid ${appTheme.COLORS.blueSecondary} !important`,
      },
      "&:active": {
        boxShadow: "none !important",
        border:
          (state.isFocused || props.value) &&
          `1px solid ${appTheme.COLORS.blueSecondary} !important`,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "unset",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: `rgba(107, 114, 128, 0.3)`,
      opacity: "1",
      fontSize: `14px`,
      fontWeight: `400`,
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      paddingRight: "0px",
      color: "#212529",
    }),
    multiValue: (provided) => ({
      ...provided,
      flexDirection: "row-reverse",
    }),
  };
  return (
    <>
      {props.async ? (
        <AsyncSelect
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          styles={styles}
          classNamePrefix="custom-multiselect"
        />
      ) : (
        <Select
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          styles={styles}
          classNamePrefix="custom-multiselect"
        />
      )}
      <ErrorMessageWrapper>
        <ErrorMessage>{props.showMessage ? props.message : ""}</ErrorMessage>
      </ErrorMessageWrapper>
    </>
  );
}

export default CustomMultiSelect;
