import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

const BarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  max-width: ${(props) => props.maxWidth || "100%"};
  height: 60px;
  border: ${(props) =>
    props.type === props.selectedItem
      ? "1px solid #016cba"
      : !props.selectedItem && props.message
      ? "1px solid red"
      : "1px solid #ADB5BD"};
  padding: 1rem;
  cursor: pointer;
`;

const BarDiv = styled.div`
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
`;

const BarIcon = styled(LazyLoadImage)`
  width: 30px;
  height: 30px;
`;

const BarIcon2 = styled(LazyLoadImage)`
  width: 100%;
`;

const BarText = styled.p`
  color: #323232;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
`;

const CardWithIconCmp = ({
  type,
  text,
  icon,
  onSelect,
  selectedItem,
  maxWidth,
  removeIcon,
  message,
  showVariation,
  planPrice,
  planName,
  dataSize,
  hideText,
}) => {
  return (
    <>
      <BarWrapper
        maxWidth={maxWidth}
        type={type}
        selectedItem={selectedItem}
        message={message}
        onClick={() => {
          onSelect();
        }}
      >
        <BarDiv>
          {hideText ? (
            <>
              {!removeIcon && <BarIcon2 src={icon} alt={type} effect="blur" />}
            </>
          ) : (
            <>
              {!removeIcon && <BarIcon src={icon} alt={type} effect="blur" />}
            </>
          )}
          {!hideText && (
            <>
              {" "}
              {showVariation ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#323232",
                      fontWeight: "700",
                      fontSize: "10px",
                    }}
                  >
                    {planPrice}
                  </div>
                  <div
                    style={{
                      color: "#323232",
                      fontWeight: "500",
                      fontSize: "8px",
                      textAlign: "center",
                    }}
                  >
                    {planName}
                  </div>
                </div>
              ) : (
                <BarText>{text}</BarText>
              )}
            </>
          )}
        </BarDiv>
      </BarWrapper>
    </>
  );
};

export default CardWithIconCmp;
