import {
  RECHARGE_AIRTIME_LOADING,
  RECHARGE_AIRTIME_SUCCESS,
  RECHARGE_AIRTIME_ERROR,
} from "./types";

const initialState = {
  rechargeAirtimeLoading: null,
  rechargeAirtimeSuccess: null,
  rechargeAirtimeError: null,
};

export default function airtimeReducer(state = initialState, action) {
  switch (action.type) {
    case RECHARGE_AIRTIME_LOADING:
      return { ...state, rechargeAirtimeLoading: action.payload };
    case RECHARGE_AIRTIME_SUCCESS:
      return { ...state, rechargeAirtimeSuccess: action.payload };
    case RECHARGE_AIRTIME_ERROR:
      return { ...state, rechargeAirtimeError: action.payload };
    default:
      return state;
  }
}
