import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import CustomInputIconCmp from "../InputsCmp/CustomInputIconCmp";
import { User, Lock } from "@styled-icons/boxicons-regular";
import { Email } from "@styled-icons/material-outlined";
import CustomInputPasswordIconCmp from "../InputsCmp/CustomInputPasswordIconCmp";
import appTheme from "../../constants/theme";
import SolidButton from "../ButtonCmp/Button";
import { Formik } from "formik";
import signupBusinessSchema from "../../validators/signupBusinsessValidator";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../redux/actions/authActions";
import { connect, useDispatch } from "react-redux";
import SpinnerCmp from "../SpinnerCmp/SpinnerCmp";
import CheckMailModal from "../Modals/CheckMailModal";
import { LOGIN_DATA } from "../../redux/reducers/types";

const Wrapper = styled(Form)`
  padding-top: 0.5rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputWrapper = styled.div``;

const TextWrapper = styled.div`
  padding-top: 0.5rem;
`;

const Text = styled.p`
  color: ${appTheme.COLORS.grey1};
  font-weight: ${appTheme.WEIGHT.small3};
  font-size: 14px;
`;

const Span = styled.span`
  color: #1a8fdd;
  cursor: pointer;
`;

const ButtonWrapper = styled.div``;

const SignupBusinessForm = ({ loading, error, setRegisterUser, success }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Formik
        validationSchema={signupBusinessSchema}
        initialValues={{
          businessName: "",
          email: "",
          password: "",
          confirmPassword: "",
          referral: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          const response = await setRegisterUser({
            ...values,
            userType: "business",
          });
          if (response.status === 201) {
            resetForm();
          }
          // if (error) {
          //   return toast.error("There is an error while registering");
          // }
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <>
            <Wrapper noValidate onSubmit={handleSubmit}>
              <FormWrapper>
                <InputWrapper>
                  <CustomInputIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <User size={20} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    type="text"
                    name="businessName"
                    placeholder="Business Name"
                    values={values}
                    onChange={handleChange}
                    message={`${
                      errors["businessName"] ? errors["businessName"] : ""
                    }`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInputIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Email size={20} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    values={values}
                    onChange={handleChange}
                    message={`${errors["email"] ? errors["email"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInputPasswordIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Lock size={30} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    name="password"
                    placeholder="Choose Password"
                    values={values}
                    onChange={handleChange}
                    message={`${errors["password"] ? errors["password"] : ""}`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInputPasswordIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <Lock size={30} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    values={values}
                    onChange={handleChange}
                    message={`${
                      errors["confirmPassword"] ? errors["confirmPassword"] : ""
                    }`}
                    showMessage={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInputIconCmp
                    background="rgba(225, 235, 245, 0.1)"
                    required={true}
                    icon={
                      <>
                        <User size={20} color="rgba(107, 114, 128, 0.5)" />
                      </>
                    }
                    type="text"
                    name="referral"
                    placeholder="Referral (Optional)"
                    values={values}
                    onChange={handleChange}
                    message={`${errors["referral"] ? errors["referral"] : ""}`}
                    showMessage={false}
                  />
                </InputWrapper>
              </FormWrapper>
              <TextWrapper>
                <Text>
                  By creating an account, you have read and agreed to Jessie’s{" "}
                  <Span
                    onClick={() => {
                      window.open(
                        "https://jessiepay.co/terms-of-service",
                        "_blank"
                      );
                    }}
                  >
                    Terms of Service,{" "}
                  </Span>
                  <Span
                    onClick={() => {
                      window.open(
                        "https://jessiepay.co/anti-money-laundering-policy",
                        "_blank"
                      );
                    }}
                  >
                    AML,{" "}
                  </Span>
                  <Span
                    onClick={() => {
                      window.open(
                        "https://jessiepay.co/privacy-policy",
                        "_blank"
                      );
                    }}
                  >
                    & Privacy Policy
                  </Span>
                </Text>
              </TextWrapper>
              <ButtonWrapper>
                <SolidButton
                  text={
                    loading ? (
                      <>
                        <SpinnerCmp
                          enabled={true}
                          color={appTheme.COLORS.bluePrimary}
                          size={20}
                          secondaryColor={appTheme.COLORS.white}
                        />
                      </>
                    ) : (
                      "Sign Up"
                    )
                  }
                  type="submit"
                  weighty="500"
                  textFontSize="14px"
                  specifyPadding="0.8rem 1.5rem"
                  color="#fff"
                  widthWebkit="100%"
                  widthMoz="100%"
                  widthNormal="100%"
                  borderRadius="8px"
                  backColor="#1a8fdd"
                  backgroundHoverColor="#1a8fdd"
                  textHoverColor="#fff"
                  margin="0rem auto 0"
                  disabled={loading ? true : false}
                />
              </ButtonWrapper>
            </Wrapper>
          </>
        )}
      </Formik>
      <CheckMailModal
        titleMaxWidth="300px"
        maxWidth="400px"
        showCloseIcon={true}
        isOpen={success ? true : false}
        onRequestClose={() => {
          dispatch({ type: LOGIN_DATA, payload: false });
          navigate("/login");
        }}
      />
    </>
  );
};

const mapState = (state) => ({
  loading: state.auth.loading,
  authenticated: state.auth.authenticated,
  error: state.auth.error,
  success: state.auth.data,
});

const mapDispatch = (dispatch) => ({
  setRegisterUser: (data) => dispatch(registerUser(data)),
});

export default connect(mapState, mapDispatch)(SignupBusinessForm);
