import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import appTheme from "../../constants/theme";

const Wrapper = styled.div`
  width: 174px;
  height: 214px;
  background-color: ${appTheme.COLORS.white};
  border-radius: 24px;
  cursor: pointer;
  padding: 2rem 1.5rem;
  border: 0.4px solid transparent;

  &:hover {
    border: 0.4px solid #016cba;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: ${(props) => props.backColor && props.backColor};
`;

const IconImg = styled(LazyLoadImage)``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h2`
  font-weight: ${appTheme.WEIGHT.mediumBold};
  font-size: 14px;
  color: #323232;
  margin: 0;
`;

const Desc = styled.p`
  text-align: center;
  font-size: 11px;
  font-weight: ${appTheme.WEIGHT.small2}
  color: #323232;
`;

const ShortcutItemCmp = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Wrapper
        onClick={() => {
          navigate(props.path);
        }}
      >
        <Container>
          <IconWrapper backColor={props.backColor}>
            <IconImg src={props.icon} alt={props.iconText} effect="blur" />
          </IconWrapper>
          <ContentWrapper>
            <Title>{props.title}</Title>
            <Desc>{props.desc}</Desc>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default ShortcutItemCmp;
